import { Component } from '@angular/core';

/**
 * Component for the footer of the application.
 * 
 * @example
 * <app-footer></app-footer>
 */
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    /**
     * The current date.
     * 
     * @type {Date}
     */
    currentDate: Date = new Date();
}
