// Import necessary Angular modules and services
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { WizardComponent } from 'angular-archwizard';

/**
 * Wrapper component for the wizard functionality.
 * @component
 */
@Component({
  selector: 'wizard-wrapper',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardWrapperComponent implements OnInit {
  /**
   * Language input property.
   * @type {string}
   */
  @Input() lang: string;

  /**
   * Modal input property.
   * @type {any}
   */
  @Input() modal: any;

  /**
   * Boolean input property to check if it's a signup modal.
   * @type {boolean}
   */
  @Input() isSignupModal: boolean;

  /**
   * ViewChild reference for the wizard component.
   * @type {WizardComponent}
   */
  @ViewChild('wizard') public wizard: WizardComponent;

  /**
   * Constructor to initialize dependencies.
   * @param {AuthService} authService - The authentication service.
   * @param {ChangeDetectorRef} changeDetector - The change detector reference.
   */
  constructor(
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef
  ) {}

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Subscribes to the next event in the authentication service.
   */
  ngOnInit() {
    this.authService.next.subscribe((val) => {
      if (val) {
        this.wizard.navigation.goToNextStep();
        this.authService.setNextBtn(false);
      }
    });
  }

  /**
   * Lifecycle hook that is called after every check of a component's or directive's content.
   * Detects changes to the view.
   */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
