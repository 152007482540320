import { Component, OnInit } from '@angular/core';
import { InvoicesService } from '../invoices/invoices.service';
import { Router } from '@angular/router';
import {
  ICustomerInvoice,
  PaymentService,
} from '../../../payments/payment.service';
import { SnotifyService } from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

@Component({
  selector: 'app-all-invoices',
  templateUrl: './all-invoices.component.html',
  styleUrls: ['./all-invoices.component.scss'],
})
export class AllInvoicesComponent implements OnInit {
  paidInvoices: any[];
  unPaidInvoices: any[];
  invoices:any = [];
  dir: string;
  lang: string;
  defaultInvoiceType: string;
  active = 1;
  isInvocesReturned: boolean = true;

  constructor(
    private readonly invoiceService: InvoicesService,
    private readonly router: Router,
    private readonly paymentService: PaymentService,
    private readonly snotifyService: SnotifyService,
    private translate: TranslateService,
    private spinner: SpinnerService
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    // if (!this.invoiceService.invoiceData.length) {
    //   this.router.navigate(['/e-services/invoice']);
    // }
    // this.invoiceService.invoiceDataChange.subscribe(val => {
    //   this.paidInvoices = val.filter(item => item.invoiceStatus === 1);
    //   this.unPaidInvoices = val.filter(item => item.invoiceStatus === 0);
    // });
    // this.defaultInvoiceType = '0';
    // this.invoices = this.unPaidInvoices;
    this.getAllInvoices('0');
  }

  // paidOrNotPaid(value: string) {
  //   if (value === '0') {
  //     this.invoices = this.unPaidInvoices;
  //   } else if (value === '1') {
  //     this.invoices = this.paidInvoices;
  //   }
  // }

  // Call the invoiceService to get invoice details based on the transaction count
  getInvoice(transCount: any) {
    this.invoiceService.getInvoiceDetails(transCount).subscribe(
      (data) => {
        if (data) {
          console.log(data);
          window.open(`payments/invoices/${transCount}`, '_self');
          this.isInvocesReturned = true;
        } else {
          console.error('Error');
        }
      },
      (error) => {
        this.spinner.hide();
        const err = this.translate.instant('invoices.errors.invoiceNotFound');
        this.snotifyService.error(err, '', {
          position: 'rightTop',
        });
      }
    );
  }

  // Create an ICustomerInvoice object using relevant properties from the invoice
  payInvoice(invoice: any) {
    const order: ICustomerInvoice = {
      customerIdNumber: invoice.idNumber,
      customerMobileNumber: invoice.mobileNumber,
      customerProfileNumber: invoice.profileNumber,
      transCount: invoice.transCount,
    };
    this.paymentService.getTelrPaymentPage(order).subscribe((newOrder) => {
      if (newOrder && newOrder.order) {
        window.location.href = newOrder.order.url;
      }
    });
  }

  // Call the invoice service to get all invoices based on the specified status
  getAllInvoices(status) {
    this.spinner.show();
    this.invoiceService.getAllInvoices(status).subscribe((val: any) => {
      if (val) {
        this.invoices = (val.items as any[]).sort(
          (a, b) => b.bex_orders_main_no - a.bex_orders_main_no
        );
        this.isInvocesReturned = true;
        this.spinner.hide();
      } else {
        console.error('Error');
        this.isInvocesReturned = false;
        this.spinner.hide();
      }
    },(error) => {
      this.spinner.hide();
      const err = this.translate.instant('invoices.errors.invoiceNotFound');
    });
  }

  // Set the 'active' property to the specified value
  setActiveBtn(active) {
    this.active = active;
  }
}
