// Import necessary Angular modules and services
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { decode } from 'app/utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss'],
})
export class PaymentErrorComponent implements OnInit {
  transCount: any;
  invoiceData: any;
  params: any;
  userId: any;

  /**
   * Constructor to inject necessary services and subscribe to query parameters.
   * @param {ActivatedRoute} route - The current route snapshot.
   * @param {Router} router - The router for navigation.
   * @param {TranslateService} translate - The translation service.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private apiHandler: ApiHandlerService
  ) {
    // Subscribe to query parameters to check for transCount
    this.route.queryParams.subscribe(
      (params) => {
        if (params) {
          const params = this.route.snapshot.paramMap;
          let numberValue: number | null = null;
          params.keys.forEach((key) => {
            const value = +params.get(key);
            if (!isNaN(value)) {
              numberValue = value;
              return;
            }
          });
          // If a numeric value is found, call telrPaymentCallback and get transaction count
          if (numberValue !== null) {
            this.apiHandler
              .telrPaymentCallback(numberValue)
              .subscribe((data: any) => {
                if (data) {
                  this.transCount = data.transCount;
                  this.getInvoiceDetails();
                }
              });
          } else {
            this.router.navigate(['e-services']);
          }
        }
      },
      (error) => {
        console.log('Error=========>', error);
        this.router.navigate(['e-services']);
      }
    );
  }

  /**
   * OnInit lifecycle hook to check if the user is logged in and get user ID.
   */
  ngOnInit() {
    // Check if the user is logged in and get user ID
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  /**
   * Get invoice details using the transaction count.
   */
  getInvoiceDetails() {
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.transCount }).subscribe(
      (invoice) => {
        // Process invoice data and replace part of the customer ID for security
        this.invoiceData = invoice;
        const idNumber: string = this.invoiceData.customer_id;
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      },
      (err) => {
        // Display error message using SweetAlert2 in case of an invalid invoice
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  /**
   * Navigate to the home page.
   */
  goToHome() {
    this.router.navigate(['/e-services']);
  }

  /**
   * Navigate to payment checkout details.
   */
  goToDetails() {
    this.router.navigate([`payments/checkout/${this.transCount}`]);
  }

  /**
   * Navigate to payment invoices.
   */
  placeOrder() {
    this.router.navigate([`payments/invoices/${this.transCount}`]);
  }
}
