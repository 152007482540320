// Importing necessary modules and components from Angular and third-party libraries
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { decode } from 'app/utils';

/**
 * Service for interacting with the API.
 * @service
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * HTTP options for GET requests.
   * @type {Object}
   */
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  /**
   * HTTP options for POST requests.
   * @type {Object}
   */
  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  /**
   * Constructor to inject HttpClient.
   * @param {HttpClient} http - The HTTP client service.
   */
  constructor(private http: HttpClient) {}

  /**
   * Fetches patient data.
   * @param {string} patCode - Patient code.
   * @param {string} mobileNumber - Mobile number.
   * @returns {Observable<any>} Observable containing patient data.
   */
  getPatientData(patCode: string, mobileNumber: string) {
    return this.http.get(
      `${environment.apiUrl}/pat/${patCode}/${mobileNumber}`,
      this.httpOptions
    );
  }

  /**
   * Fetches private radio reports.
   * @returns {Observable<any>} Observable containing radio reports.
   */
  getRadioReports() {
    return this.http.get(`${environment.apiUrl}/report/private/xray-report`, {
      headers: new HttpHeaders({
        Authorization: `ak ${environment.key}`,
        'Content-Type': 'application/json',
        'jwt-token': decode('token'),
      }),
    });
  }

  /**
   * Fetches public radiology reports.
   * @param {string} patCode - Patient code.
   * @param {string} idCard - ID card number.
   * @returns {Observable<any>} Observable containing public radiology reports.
   */
  geRadiologyPublic(patCode: string, idCard: string) {
    return this.http.get(
      `${environment.apiUrl}/report/public/xray-report?patCode=${patCode}&idCard=${idCard}`
    );
  }

  /**
   * Fetches private X-ray details.
   * @param {string} drCode - Doctor code.
   * @param {string} servCode - Service code.
   * @param {string} transCount - Transaction count.
   * @returns {Observable<any>} Observable containing X-ray details.
   */
  getXRayDetails(drCode: string, servCode: string, transCount: string) {
    return this.http.get(
      `${environment.apiUrl}/report/private/xray-report?drCode=${drCode}&servCode=${servCode}&transCount=${transCount}`,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  /**
   * Fetches public X-ray details.
   * @param {string} idCard - ID card number.
   * @param {string} patCode - Patient code.
   * @param {string} drCode - Doctor code.
   * @param {string} servCode - Service code.
   * @param {string} transCount - Transaction count.
   * @returns {Observable<any>} Observable containing public X-ray details.
   */
  getXRayDetailsPublic(idCard: string, patCode: string, drCode: string, servCode: string, transCount: string) {
    return this.http.get(
      `${environment.apiUrl}/report/public/xray-report?idCard=${idCard}&patCode=${patCode}&drCode=${drCode}&servCode=${servCode}&transCount=${transCount}`
    );
  }

  /**
   * Fetches X-ray details for printing in PDF format.
   * @param {string} drCode - Doctor code.
   * @param {string} servCode - Service code.
   * @param {string} transCount - Transaction count.
   * @returns {Observable<any>} Observable containing X-ray details in PDF format.
   */
  getXRayDetailsPrint(drCode: string, servCode: string, transCount: string) {
    return this.http.get(
      `${environment.apiUrl}/report/private/xray-report-pdf?drCode=${drCode}&servCode=${servCode}&transCount=${transCount}`,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }
}
