// Importing necessary modules and components from Angular and third-party libraries
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as chartsData from '../../../shared/data/chartjs';
import { BaseChartDirective } from 'ng2-charts';
import { UserInfoService } from './user-info.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { decode } from 'app/utils';
import { InvoicesService } from '../invoices/invoices.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { DatePipe } from '@angular/common';

/**
 * Component for displaying user information including vital signs, blood sugar levels, and other health metrics.
 * @component
 */
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  /**
   * Language setting.
   * @type {string}
   */
  @Input() lang: string;

  /**
   * User data.
   * @type {any}
   */
  @Input() user: any;

  /**
   * Flag to show or hide no data message.
   * @type {boolean}
   */
  noDataShow: boolean = false;

  /**
   * Datepicker configuration.
   * @type {Partial<BsDatepickerConfig>}
   */
  bsConfig: Partial<BsDatepickerConfig>;

  /**
   * Selected index for the tab.
   * @type {number}
   */
  selectedIndex = 0;

  /**
   * Current user information.
   * @type {any}
   */
  currentUser: any;

  /**
   * List of unpaid invoices.
   * @type {any[]}
   */
  unpaidInvoices: any[];

  /**
   * User's vital signs data.
   * @type {any}
   */
  vitalSigns: any;

  /**
   * List of user appointments.
   * @type {any}
   */
  appointmentList: any;

  /**
   * Line chart data.
   * @type {any}
   */
  public lineChartData = chartsData.lineChartData;

  /**
   * Line chart labels.
   * @type {any}
   */
  public lineChartLabels = chartsData.lineChartLabels;

  /**
   * Line chart options.
   * @type {any}
   */
  public lineChartOptions = chartsData.lineChartOptions;

  /**
   * Line chart colors.
   * @type {any}
   */
  public lineChartColors = chartsData.lineChartColors;

  /**
   * Line chart legend.
   * @type {any}
   */
  public lineChartLegend = chartsData.lineChartLegend;

  /**
   * Line chart type.
   * @type {any}
   */
  public lineChartType = chartsData.lineChartType;

  // Health metrics objects
  randomBloodSugar = {
    name: 'user.randomBloodSugar',
    className: '',
    icon: '/assets/img/svg/blood_pre.svg',
    result: 0,
    titleEnd: '',
  };

  fastingBloodSugar = {
    name: 'user.fastingBloodSugar',
    className: 'fasting',
    icon: '/assets/img/svg/blood.svg',
    result: 0,
    titleEnd: '',
  };

  cholesterol = {
    name: 'user.cholesterol',
    className: 'cholesterol',
    icon: '/assets/img/svg/water_point.svg',
    result: 0,
    titleEnd: '',
  };

  vitaminD = {
    name: 'user.vitamin',
    className: 'vitamin',
    icon: '/assets/img/svg/vitamin.svg',
    result: 0,
    titleEnd: '',
  };

  triglycerides = {
    name: 'user.triglycerides',
    className: 'triglycerides',
    icon: '/assets/img/svg/water_point.svg',
    result: 0,
    titleEnd: '',
  };

  fullRandomBloodSugar = {};
  fullFastingBloodSugar = {};
  fullCholesterol = {};
  fullVitaminD = {};
  fullTriglycerides = {};

  /**
   * ViewChild for the chart directive.
   * @type {BaseChartDirective}
   */
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  /**
   * Selected index for the radio button.
   * @type {number}
   */
  selectedRadioIndex = 0;

  /**
   * Analysis metrics for the user.
   * @type {Array}
   */
  analysis = [
    { name: 'user.randomBloodSugar' },
    { name: 'user.fastingBloodSugar' },
    { name: 'user.cholesterol' },
    { name: 'user.vitamin' },
    { name: 'user.triglycerides' },
  ];

  constructor(
    private userService: UserInfoService,
    private translate: TranslateService,
    private invoiceService: InvoicesService,
    private spinnerService: SpinnerService,
    private datePipe: DatePipe
  ) {
    this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit(): void {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = decode('currentUser');
      console.log(this.currentUser);
    } else {
      this.currentUser = null;
    }

    const currentDate = new Date();
    this.bsConfig = Object.assign({}, {
      minDate: new Date(),
      maxDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 31),
      showWeekNumbers: false,
      minViewMode: 'days',
      maxViewMode: 'days',
    });

    if (!this.userService.getRandomData()) {
      this.userService.getRandomBloodSugar(this.user.mrn);
    }
    if (!this.userService.getFastingData()) {
      this.userService.getFastingBloodSugar(this.user.mrn);
    }
    if (!this.userService.getCholesterolData()) {
      this.userService.getCholesterol(this.user.mrn);
    }
    if (!this.userService.getVitaminDData()) {
      this.userService.getVitaminD(this.user.mrn);
    }
    if (!this.userService.getTriglyceridesData()) {
      this.userService.getTriglycerides(this.user.mrn);
    }

    this.userService.randomBloodSugar.subscribe((val) => {
      if (val) {
        if (Object.keys(val).length) {
          this.lineChartLabels = Object.keys(val).map((item) => item);
          this.fullRandomBloodSugar = val;
          this.getMonthData(this.fullRandomBloodSugar);
          const elem = Object.keys(val)[Object.keys(val).length - 1];
          this.randomBloodSugar.result = val[elem][val[elem].length - 1].RESULT;
          this.randomBloodSugar.titleEnd = val[elem][val[elem].length - 1].TITLE_END;
        } else {
          this.noDataShow = true;
        }
      }
    });

    this.userService.fastingBloodSugar.subscribe((val) => {
      if (val) {
        if (Object.keys(val).length) {
          this.fullFastingBloodSugar = val;
          const elem = Object.keys(val)[Object.keys(val).length - 1];
          this.fastingBloodSugar.result = val[elem][val[elem].length - 1].RESULT;
          this.fastingBloodSugar.titleEnd = val[elem][val[elem].length - 1].TITLE_END;
        } else {
          this.noDataShow = true;
        }
      }
    });

    this.userService.cholesterol.subscribe((val) => {
      if (val) {
        if (Object.keys(val).length) {
          this.fullCholesterol = val;
          const elem = Object.keys(val)[Object.keys(val).length - 1];
          this.cholesterol.result = val[elem][val[elem].length - 1].RESULT;
          this.cholesterol.titleEnd = val[elem][val[elem].length - 1].TITLE_END;
        } else {
          this.noDataShow = true;
        }
      }
    });

    this.userService.vitaminD.subscribe((val) => {
      if (val) {
        if (Object.keys(val).length) {
          this.fullVitaminD = val;
          const elem = Object.keys(val)[Object.keys(val).length - 1];
          this.vitaminD.result = val[elem][val[elem].length - 1].RESULT;
          this.vitaminD.titleEnd = val[elem][val[elem].length - 1].TITLE_END;
        } else {
          this.noDataShow = true;
        }
      }
    });

    this.userService.triglycerides.subscribe((val) => {
      if (val) {
        if (Object.keys(val).length) {
          this.fullTriglycerides = val;
          const elem = Object.keys(val)[Object.keys(val).length - 1];
          this.triglycerides.result = val[elem][val[elem].length - 1].RESULT;
          this.triglycerides.titleEnd = val[elem][val[elem].length - 1].TITLE_END;
        } else {
          this.noDataShow = true;
        }
      }
    });

    this.getUnpaidInvoices();
    this.getLastVitalSigns();
  //  this.getPatientAppointments('');
    this.getPatientAppointments(undefined);

  }

  /**
   * Handles the chart click event.
   * @param {any} e - The chart click event.
   */
  public chartClicked(e: any): void {
    console.log(e);
  }

  /**
   * Handles the chart hover event.
   * @param {any} e - The chart hover event.
   */
  public chartHovered(e: any): void {
    console.log(e);
  }

  /**
   * Sets the index for the selected tab.
   * @param {number} i - The index to set.
   */
  setIndex(i: number): void {
    this.selectedIndex = i;
    switch (i) {
      case 0:
        this.lineChartLabels = [moment()];
        break;
      case 1:
        this.lineChartLabels = [moment().subtract(2, 'month'), moment()];
        break;
      case 2:
        this.lineChartLabels = [moment().subtract(5, 'month'), moment()];
        break;
      case 3:
        this.lineChartLabels = [moment().subtract(11, 'month'), moment()];
        break;
    }

    this.setRadioIndex(this.selectedRadioIndex);
  }

  /**
   * Sets the index for the selected radio button.
   * @param {number} i - The index to set.
   */
  setRadioIndex(i: number): void {
    this.selectedRadioIndex = i;
    switch (i) {
      case 0:
        this.getMonthData(this.fullRandomBloodSugar);
        break;
      case 1:
        this.getMonthData(this.fullFastingBloodSugar);
        break;
      case 2:
        this.getMonthData(this.fullCholesterol);
        break;
      case 3:
        this.getMonthData(this.fullVitaminD);
        break;
      case 4:
        this.getMonthData(this.fullTriglycerides);
        break;
    }
  }

  /**
   * Gets data for the selected month and updates the chart.
   * @private
   * @param {any} element - The data element.
   */
  private getMonthData(element: any): void {
    this.lineChartLabels = [];
    this.lineChartData[0].data = [];
    this.lineChartData[2].data = [];
    if (Object.keys(element).length > 1) {
      this.noDataShow = false;
      const data = Object.keys(element)
        .sort()
        .map((item, index) => {
          this.lineChartLabels.push(item);
          return element[item].find((el) => {
            if (this.user.gender === 'Male') {
              this.lineChartData[0].data[index] = el.MALE_RANGE.start;
              this.lineChartData[2].data[index] = el.MALE_RANGE.end;
              this.lineChartOptions.scales.yAxes[0].ticks = {
                suggestedMin: el.MALE_RANGE.start - 20,
                suggestedMax: el.MALE_RANGE.end + 20,
              };
            }
            if (this.user.gender === 'Female') {
              this.lineChartData[0].data[index] = el.FEMALE_RANGE.start;
              this.lineChartData[2].data[index] = el.FEMALE_RANGE.end;
              this.lineChartOptions.scales.yAxes[0].ticks = {
                suggestedMin: el.FEMALE_RANGE.start - 20,
                suggestedMax: el.FEMALE_RANGE.end + 20,
              };
            }

            return el;
          }).RESULT;
        });
      this.lineChartData[1].data = data;

      const startEl = this.lineChartData[0].data[0];
      const endEl = this.lineChartData[2].data[0];
      let start = true;
      let end = true;
      this.lineChartData[0].data.map((item) => {
        if (startEl !== item) {
          start = false;
        }
      });
      this.lineChartData[2].data.map((item) => {
        if (endEl !== item) {
          end = false;
        }
      });

      if (!end) {
        this.lineChartColors[2].pointRadius = 4;
      } else {
        this.lineChartColors[2].pointRadius = 0;
      }
      if (!start) {
        this.lineChartColors[0].pointRadius = 4;
      } else {
        this.lineChartColors[0].pointRadius = 0;
      }
      this.chart.chart.update();
    } else {
      this.noDataShow = true;
    }
  }

  /**
   * Fetches unpaid invoices.
   */
  getUnpaidInvoices(): void {
    this.invoiceService.getAllInvoices('0').subscribe(
      (invoices: any[]) => {
        if (invoices) {
          this.unpaidInvoices = invoices;
        } else {
          this.unpaidInvoices = null;
        }
      },
      (err) => {
        console.error('Error', err);
      }
    );
  }

  /**
   * Fetches the last vital signs of the user.
   */
  getLastVitalSigns(): void {
    this.userService.getVitalSigns().subscribe(
      (data) => {
        if (data) {
          this.vitalSigns = data[0];
        } else {
          this.vitalSigns = null;
        }
        this.calculateBMI(this.vitalSigns)
      },
      (err) => {
        console.error('Error', err);
      }
    );
  }

  /**
   * Checks if there is an appointment on the given date.
   * @param {Date} date - The date to check.
   * @returns {boolean} True if there is an appointment, false otherwise.
   */
  isAppointment(date: Date): boolean {
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.appointmentList.some((appointment) => {
      const appointmentDate = this.datePipe.transform(appointment.date, 'yyyy-MM-dd');
      return appointmentDate === formattedDate;
    });
  }

  /**
   * Fetches patient appointments for the given date range.
   * @param {Date} date - The start date for fetching appointments.
   */
  getPatientAppointments(date: Date): void {
    const startDate = date ? date : new Date();
    const currentDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    const nextDate = new Date(startDate);
    nextDate.setDate(date ? nextDate.getDate() + 1 : nextDate.getDate() + 8);
    const endDate = this.datePipe.transform(nextDate, 'yyyy-MM-dd');
    const daysClass = document.querySelectorAll('.bs-datepicker-body');
    this.userService.getPatientAppointments(currentDate, endDate).subscribe(
      (data: any) => {
        if (data) {
          this.appointmentList = data.data
            ? data.data.sort((a, b) => new Date(a.RES_DATE).getTime() - new Date(b.RES_DATE).getTime())
            : null;
          this.appointmentList = this.appointmentList && this.appointmentList.length > 2
            ? this.appointmentList.slice(0, 2)
            : this.appointmentList;
        } else {
          this.appointmentList = null;
        }
      },
      (err) => {
        console.error('Error', err);
      }
    );
  }

  /**
   * Calculates BMI based on the user's vital signs.
   * @param {any} data - The user's vital signs data.
   * @returns {number} The calculated BMI.
   */
  calculateBMI(data: any): number {
    if (data && data.WIGHT && data.LENGTH) {
      data.bmi = (data.WIGHT / Math.pow((data.LENGTH / 100), 2)).toFixed(1);

      data.bmiResult = ''
      if (data.bmi < 18.5) {
        data.bmiResult = this.lang == 'en' ? 'Under weight' : 'تحت الوزن';
        data.bmiColor = '#00b3ed';
      } else if (data.bmi > 18.6 && data.bmi < 24.9) {
        data.bmiResult = this.lang == 'en' ? 'Healthy range' : 'نطاق صحي';
        data.bmiColor = '#87d25d';
      } else if (data.bmi > 25 && data.bmi < 29.9) {
        data.bmiResult = this.lang == 'en' ? 'Overweight' : 'زيادة الوزن';
        data.bmiColor = '#ffbf34';
      } else if (data.bmi > 30 && data.bmi < 39.9) {
        data.bmiResult = this.lang == 'en' ? 'Obesity' : 'بدانة';
        data.bmiColor = '#ff0015';
      } else if (data.bmi > 40) {
        data.bmiResult = this.lang == 'en' ? 'Severe obesity' : 'السمنة الشديدة';
        data.bmiColor = '#ff0015';
      }
    }
    return data.bmi;
  }
}
