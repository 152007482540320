import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as SecureStorage from 'secure-web-storage';

/**
 * Secret key used for encryption and hashing.
 * @constant {string}
 */
const SECRET_KEY = 'IdEzGVRJ2NvQeVSImQrda0d4ucwpolRa4o';

@Injectable()
export class StorageService {
  /**
   * SecureStorage instance configured with encryption and hashing.
   * @type {SecureStorage}
   */
  public secureStorage = new SecureStorage(localStorage, {
    /**
     * Hash function for the keys.
     * @function
     * @param {string} key - The key to be hashed.
     * @returns {string} The hashed key.
     */
    hash: function hash(key) {
      key = CryptoJS.SHA256(key, SECRET_KEY);
      return key.toString();
    },
    /**
     * Encrypt function for the data.
     * @function
     * @param {string} data - The data to be encrypted.
     * @returns {string} The encrypted data.
     */
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      return data.toString();
    },
    /**
     * Decrypt function for the encrypted data.
     * @function
     * @param {string} data - The data to be decrypted.
     * @returns {string} The decrypted data.
     */
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      return data.toString(CryptoJS.enc.Utf8);
    },
  });

  /**
   * Constructor for the StorageService.
   */
  constructor() {}
}