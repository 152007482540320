// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from './api.service';
import { observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../../../pages/content-pages/login/auth.service';
import { decode, encode } from '../../../utils';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { TrackingService } from 'app/core/services/tracking.service';

/**
 * Component for handling radiology-related functionality.
 * @component
 */
@Component({
  selector: 'app-radiology',
  templateUrl: './radiology.component.html',
  styleUrls: ['./radiology.component.scss'],
})
export class RadiologyComponent implements OnInit {
  // recap = environment.recaptchaSite;

  /**
   * Form group for patient information.
   * @type {FormGroup}
   */
  patInfoForm: FormGroup;

  /**
   * Mobile number pattern for validation.
   * @type {RegExp}
   */
  mobilePattern =
    /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;

  /**
   * Flag indicating whether there is an error.
   * @type {number}
   */
  isError = 0;

  /**
   * Flag indicating whether to show the form.
   * @type {number}
   */
  showForm = 1;

  /**
   * Flag indicating whether to show the data.
   * @type {number}
   */
  showData = 0;

  /**
   * Array to store lab data.
   * @type {any[]}
   */
  labsData: any[] = [];

  /**
   * Patient code.
   * @type {string}
   */
  patCode: string;

  /**
   * Mobile number.
   * @type {string}
   */
  mobileNumber: string;

  /**
   * Flag indicating whether there are no results.
   * @type {number}
   */
  noResult: number;

  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Text direction based on language.
   * @type {string}
   */
  dir: string;

  /**
   * ID card number.
   * @type {string}
   */
  idCard: string;

  /**
   * User information.
   * @type {any}
   */
  private user: any;

  /**
   * Constructor to inject services and initialize component properties.
   * @param {ApiService} data - The API service for data fetching.
   * @param {AuthService} authService - The authentication service.
   * @param {SpinnerService} spinner - The spinner service.
   * @param {TrackingService} _trackingService - The tracking service.
   */
  constructor(
    private data: ApiService,
    private authService: AuthService,
    private spinner: SpinnerService,
    private _trackingService: TrackingService
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ar';
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';
    this.patInfoForm = new FormGroup(
      {
        profileNo: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        mobileNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          // Validators.pattern(this.mobilePattern),
        ]),
        // recaptcha: new FormControl(null, [Validators.required]),
      },
      { updateOn: 'blur' }
    );
  }

  /**
   * Callback function for reCAPTCHA resolution.
   * @param {string} captchaResponse - The response from reCAPTCHA.
   */
  resolved(captchaResponse: string) {
    if (captchaResponse != null && captchaResponse != undefined) {
    }
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.user = decode('currentUser');

    this.authService.user.subscribe((val) => {
      if (val) {
        this.user = val;
      }
    });
    if (this.user) {
      this.getLabsDataWithUserLogin();
      this.patchValue(this.user.udhId, this.user.mobile);
      this.spinner.show();
      this.addTrackingLog();
    }
  }

  /**
   * Adds a tracking log for radiology result access.
   */
  addTrackingLog() {
    this._trackingService
      .addTrackingLog(
        this._trackingService.tracking_type.RADIOLOGY_RESULT,
        this.user.id ? this.user.id : ''
      )
      .subscribe((data) => {
        return data;
      });
  }

  /**
   * Fetches labs data using the patient information from the form.
   */
  getLabsData() {
    if (this.patInfoForm.valid) {
      this.patCode = this.patInfoForm.get('profileNo').value;
      this.idCard = this.patInfoForm.get('mobileNumber').value;
      encode('patInfo', this.patInfoForm.value);
      this.data.geRadiologyPublic(this.patCode, this.idCard).subscribe(
        (r) => {
          // this.data.getLabs('263574', '0554952250').subscribe(r => {
          if (r['status'] == 'success') {
            this.labsData = [...r['data']];
            this.labsData.sort((a: any, b: any): number => {
              let firstDate: any = new Date(b.TRS_DATE);
              let secondDate: any = new Date(a.TRS_DATE);
              let result = firstDate - secondDate;
              return result;
            });
            this.showData = 1;
            this.showForm = 0;
            this.isError = 0;
            this.noResult = 0;
          } else {
            this.showForm = 1;
            this.noResult = 1;
            this.isError = 0;
            this.showData = 0;
          }
        },
        (error) => {
          this.isError = 1;
          this.showForm = 1;
          this.showData = 0;
          this.noResult = 0;
        }
      );
    }
    this.addTrackingLog();
  }

  /**
   * Fetches labs data for authenticated users.
   */
  getLabsDataWithUserLogin() {
    this.data.getRadioReports().subscribe(
      (r) => {
        // this.data.getLabs('263574', '0554952250').subscribe(r => {
        if (r['status'] == 'success') {
          this.labsData = [...r['data']];
          this.labsData.sort((a: any, b: any): number => {
            let firstDate: any = new Date(b.TRS_DATE);
            let secondDate: any = new Date(a.TRS_DATE);
            let result = firstDate - secondDate;
            return result;
          });
          this.showData = 1;
          this.showForm = 0;
          this.isError = 0;
          this.noResult = 0;
        } else {
          this.showForm = 0;
          this.noResult = 0;
          this.isError = 0;
          this.showData = 1;
        }

        this.spinner.hide();
      },
      (error) => {
        this.isError = 1;
        this.showForm = 1;
        this.showData = 0;
        this.noResult = 0;

        this.spinner.hide();
      }
    );
  }

  /**
   * Sets initial form values with user information.
   * @param {string} udhId - User ID.
   * @param {string} mobile - Mobile number.
   */
  private patchValue(udhId: string, mobile: string) {
    this.patInfoForm.patchValue({
      profileNo: udhId,
      mobileNumber: mobile,
    });
  }
}
