// Import necessary Angular modules and services
import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { decode } from 'app/utils';
import { environment } from 'environments/environment';

const images = [
  '/assets/img/photos/mada.png',
  '/assets/img/photos/visa.png',
  '/assets/img/photos/american express.png',
  '/assets/img/photos/apple-pay.png',
  '/assets/img/photos/hyper.jpg',
  '/assets/img/photos/stc pay.png',
  // Add other image paths here
];

/**
 * Component for handling payment methods.
 * @component
 */
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  /**
   * Flag to indicate if payment is loading.
   * @type {boolean}
   */
  isLoadingPayment: boolean = false;

  /**
   * The selected payment type.
   * @type {any}
   */
  paymentType: any;

  /**
   * Transaction count.
   * @type {any}
   */
  transCount: any;

  /**
   * Current language setting.
   * @type {any}
   */
  lang: any;

  /**
   * Hyperlink for the payment URL.
   * @type {string}
   */
  hyperlink: string = '';

  /**
   * Flag to show or hide the HyperPay form.
   * @type {boolean}
   */
  showHyperForm: boolean = false;

  /**
   * Flag to indicate if the script is appended.
   * @type {boolean}
   */
  isScriptAppended: boolean = false;

  /**
   * User ID.
   * @type {any}
   */
  userId: any;

  /**
   * Flag to indicate if HyperPay is enabled.
   * @type {boolean}
   */
  isHyperPay: boolean = false;

  /**
   * URL for HyperPay.
   * @type {string}
   */
  heperpayURL = environment.heperpayURL;

  /**
   * Flag to indicate if the page is loaded.
   * @type {boolean}
   */
  pageloaded = false;

  /**
   * Flag to indicate if the device is iOS.
   * @type {boolean}
   */
  isIOS = false;

  /**
   * Constructor with dependency injection.
   * @param {ActivatedRoute} route - The activated route service.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   * @param {SpinnerService} spinnerService - The spinner service.
   * @param {Renderer2} _renderer2 - The renderer service.
   * @param {Router} router - The router service.
   * @param {Location} location - The location service.
   * @param {NgbModal} modalService - The modal service.
   * @param {ChangeDetectorRef} cdr - The change detector reference service.
   * @param {Document} _document - The document object.
   */
  constructor(
    private route: ActivatedRoute,
    private apiHandler: ApiHandlerService,
    private spinnerService: SpinnerService,
    private _renderer2: Renderer2,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    // Subscribe to query parameters to check for transCount
    this.route.queryParams.subscribe((params) => {
      if (params) {
        const params = this.route.snapshot.paramMap;
        params.keys.forEach((key) => {
          const value = +params.get(key);
          if (!isNaN(value)) {
            this.transCount = value;
            return;
          }
        });
      }
    });
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @returns {void}
   */
  ngOnInit(): void {
    // Check if a user is logged in
    this.isIOS = this.detectIOS();
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
    // Preload images and hide spinner after a timeout
    this.preloadImages();
    this.spinnerService.show();
    setTimeout(() => {
      this.pageloaded = true;
      this.spinnerService.hide();
    }, 500);
  }

  /**
   * Lifecycle hook that is called after the view has been checked.
   * @returns {void}
   */
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  /**
   * Initiates the payment process based on the selected payment type.
   * @returns {void}
   */
  payNow(): void {
    // this.modalService.dismissAll();
    this.isLoadingPayment = true;
    if (this.paymentType && this.paymentType == 1 && this.transCount) {
      const params = {
        trs_count: this.transCount.toString(),
        payment_page_lang: this.lang,
      };

      // Call API UR way payment
      this.apiHandler.makeUrwayPayment(params).subscribe((data: any) => {
        if (data && data.status && data.paymentUrl) {
          // let paymentScreen = window.open(data.paymentUrl, '_blank');
          window.location.href = data.paymentUrl;
          // this.isLoadingPayment = false;
        }
      });
    }
    if (
      this.paymentType &&
      (this.paymentType == 2 || this.paymentType == 3) &&
      this.transCount
    ) {
      const params = {
        trs_count: this.transCount.toString(),
        payment_page_lang: this.lang,
      };

      // Call API Telr payment
      this.apiHandler.makeTelrPayment(params).subscribe((data: any) => {
        if (data && data.status && data.paymentUrl) {
          // let paymentScreen = window.open(data.paymentUrl, '_blank');
          window.location.href = data.paymentUrl;
          // this.isLoadingPayment = false;
        }
      });
    }
    if (this.paymentType && this.paymentType == 4 && this.transCount) {
      this.isLoadingPayment = true;
      this.isHyperPay = true;
      const params = {
        trs_count: this.transCount.toString(),
        payment_page_lang: this.lang,
      };
      this.apiHandler.makeHyperPayment(params).subscribe((data: any) => {
        if (data && data.status == true) {
          this.isHyperPay = false;
          this.hyperlink = data.paymentUrl;
          this.registerScript();
          this.showHyperForm = true;
        }
        this.isHyperPay = false;
      });
    }
  }

  /**
   * Registers and appends scripts for HyperPay integration.
   * @returns {void}
   */
  registerScript(): void {
    const script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${this.hyperlink}`;
    this._renderer2.appendChild(this._document.body, script);
    const script1 = this._renderer2.createElement('script');
    script1.type = 'text/javascript';
    let fn = '';
    // Customize script options based on the selected language
    if (this.lang == 'ar') {
      fn = `onReady: function() {
        $(".wpwl-control.wpwl-control-brand option[value='AMEX']").html('أمريكان إكسبرس');
        $(".wpwl-control.wpwl-control-brand option[value='MADA']").html('مدى');
        $(".wpwl-control.wpwl-control-brand option[value='MASTER']").html('ماستر كارد');
        $(".wpwl-control.wpwl-control-brand option[value='VISA']").html('فيزا');
      },`
    }

    let iosStr = '';
    if (this.isIOS) {
      iosStr = `onReady: function() {
        $(input:hidden).prop('type', 'text');
      },`
      iosStr += ` applePay: {
        displayName: "Apple Pay",
        total: { label: "COMPANY INC." }
      },`
    }
    script1.text = ` var wpwlOptions = {
      style:"plain", 
      locale: '${this.lang}',
      ${fn}
      ${iosStr}
        brandDetection: true, 
        brandDetectionType: "binlist",
        brandDetectionPriority: ["MADA","VISA","MASTER","AMEX"],
        showCVVHint: true,
        imageStyle: "svg",
}`;
    this._renderer2.appendChild(this._document.body, script1);
    this.isScriptAppended = true;
  }

  /**
   * Navigates to the previous page.
   * @returns {void}
   */
  goToHome(): void {
    this.location.back();
  }

  /**
   * Preloads images.
   * @returns {void}
   */
  preloadImages(): void {
    for (const image of images) {
      const img = new Image();
      img.src = image;
    }
  }

  /**
   * Detects if the device is running iOS.
   * @private
   * @returns {boolean} True if the device is iOS, otherwise false.
   */
  private detectIOS(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    const platform = window.navigator.platform;

    // Check if the user agent contains 'iPhone' or 'iPad' to identify iOS
    if (/iPad|iPhone|iPod/.test(userAgent) || platform.toLowerCase() == 'macintel') {
      console.log("This is an iOS device.", userAgent, '==========>', platform);
      return true;
    } else {
      console.log("This is not an iOS device.", userAgent, '==========>', platform);
      return false;
    }
  }
}
