// Importing necessary modules and components from Angular and third-party libraries
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

/**
 * Guard to protect routes and modules based on user authentication status.
 * @@Injectable Decorator indicating that this service can be injected into other components or services.
 */
@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  /**
   * Creates an instance of AuthGuard.
   * @param {AuthService} authService - The authentication service to check user authentication status.
   */
  constructor(private authService: AuthService) { }

  /**
   * Checks if the route can be activated.
   * @param {ActivatedRouteSnapshot} route - Contains the information about a route at a particular moment in time.
   * @param {RouterStateSnapshot} state - Represents the state of the router at a particular moment in time.
   * @returns {boolean} True if the user is authenticated, otherwise false.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authService.isAuthenticated();
  }

  /**
   * Checks if the module can be loaded.
   * @returns {boolean} True if the user is authenticated, otherwise false.
   */
  canLoad(): boolean {
    return this.authService.isAuthenticated();
  }
}
