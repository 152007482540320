import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeRoutingModule } from './home-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { TranslateModule } from '@ngx-translate/core';

import { EServicesComponent } from './e-services/e-services.component';
import { ReservationsComponent } from './e-services/reservations/reservations.component';
import { LabsComponent } from './e-services/labs/labs.component';
import { LabDetailsComponent } from './e-services/lab-details/lab-details.component';
import { RadiologyComponent } from './e-services/radiology/radiology.component';
import { RadiologyDetailsComponent } from './e-services/radiology-details/radiology-details.component';
import { AppointmentsComponent } from './e-services/appointments/appointments.component';
import { AppointmentsNewComponent } from './e-services/appointments-new/appointments-new.component';
import {
  NgxUiHeroModule,
  InputDropdownSearchComponent,
  NgxUiHeroInputFormsModule,
} from 'ngx-ui-hero';
import { FlatpickrModule } from 'angularx-flatpickr';
import { inputFormsConfig } from './ui-hero.config';
import { DoctorFilter } from './doctorFilter.pipe';
import { PatientAppointmentComponent } from './online-sessions/patient-appointment/patient-appointment.component';
import { DoctorLoginComponent } from './online-sessions/doctor-login/doctor-login.component';
import { DoctorRoomComponent } from './online-sessions/doctor-room/doctor-room.component';
import { AuthService } from 'app/shared/auth/auth.service';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { DragulaService } from 'ng2-dragula';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { environment } from 'environments/environment';
import { NgxAgoraModule } from 'ngx-agora';
import { AngularDraggableModule } from 'angular2-draggable';
import { CdTimerModule } from 'angular-cd-timer';
import { LocalService } from 'app/local.service';
import { StorageService } from 'app/storage.service';
import { SessionRateComponent } from './online-sessions/sesson-rate/sesson-rate.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { EligibilityComponent } from './e-services/eligibility/eligibility.component';
import { InvoicesComponent } from './e-services/invoices/invoices.component';
import { AllInvoicesComponent } from './e-services/all-invoices/all-invoices.component';
import { LoginPageComponent } from '../pages/content-pages/login/login-page.component';
import { WizardWrapperComponent } from '../pages/content-pages/wizard/wizard.component';
import { InformationComponent } from '../pages/content-pages/wizard/information/information.component';
import { VerificationComponent } from '../pages/content-pages/wizard/verification/verification.component';
import { SetPasswordComponent } from '../pages/content-pages/wizard/set-password/set-password.component';
import { CongratulationComponent } from '../pages/content-pages/wizard/congratulation/congratulation.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { EcommerceComponent } from '../dashboard/eCommerce/eCommerce.component';
import { ChartistModule } from 'ng-chartist';
import { UserInfoComponent } from './e-services/user-info/user-info.component';
import { PasswordResetComponent } from '../pages/content-pages/password-reset/password-reset.component';
import { AnalysisCardComponent } from './e-services/analysis-card/analysis-card.component';
import { ChartsModule } from 'ng2-charts';
import { UserModule } from '../admin/user/user.module';
import { MedicalRecordComponent } from './e-services/medical-record/medical-record.component';
import { RequestRefillComponent } from './e-services/request-refill/request-refill.component';
import { AllergiesComponent } from './e-services/allergies/allergies.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { VitalSignsComponent } from './e-services/vital-signs/vital-signs.component';
import { QRCodeModule } from 'angularx-qrcode';
import { IdNumberComponent } from 'app/pages/content-pages/wizard/id-number/id-number.component';
import { AgmCoreModule } from '@agm/core';
import { PublicLabDetailsComponent } from './e-public-services/public-lab-details/public-lab-details.component';
import { PublicReadiologyComponent } from './e-public-services/public-readiology/public-readiology.component';
import { PublicReadiologyDetailsComponent } from './e-public-services/public-readiology-details/public-readiology-details.component';
import { EPublicServicesComponent } from './e-public-services/e-public-services.component';
import { ServiceBookingComponent } from './e-services/service-booking/service-booking.component';
import { FilterPipe } from 'app/shared/custom-pipes/filterData.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { DoctorHomeVisitBookingComponent } from './e-services/doctor-home-visit-booking/doctor-home-visit-booking.component';
import { PublicOrdersComponent } from './e-public-services/public-orders/public-orders.component';
import { MyAppointmentsComponent } from './e-services/my-appointments/my-appointments.component';
import { MedicalFilesComponent } from './e-services/medical-files/medical-files.component';
import { UploadMedicalFileComponent } from './e-services/upload-medical-file/upload-medical-file.component';
import { HijriCalenderComponent } from '../pages/content-pages/wizard/hijri-calender/hijri-calender.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPaginationModule } from 'ngx-pagination';
// import { EPublicServicesComponent } from './e-public-services/e-public-services.component';

const language = localStorage.getItem('lang');

@NgModule({
  declarations: [
    EServicesComponent,
    ReservationsComponent,
    LabsComponent,
    LabDetailsComponent,
    RadiologyComponent,
    RadiologyDetailsComponent,
    AppointmentsComponent,
    DoctorFilter,
    AppointmentsNewComponent,
    PatientAppointmentComponent,
    DoctorLoginComponent,
    DoctorRoomComponent,
    SessionRateComponent,
    TermsAndConditionsComponent,
    EligibilityComponent,
    InvoicesComponent,
    AllInvoicesComponent,
    LoginPageComponent,
    WizardWrapperComponent,
    InformationComponent,
    VerificationComponent,
    SetPasswordComponent,
    CongratulationComponent,
    UserInfoComponent,
    PasswordResetComponent,
    AnalysisCardComponent,
    MedicalRecordComponent,
    RequestRefillComponent,
    AllergiesComponent,
    VitalSignsComponent,
    IdNumberComponent,
    ServiceBookingComponent,
    DoctorHomeVisitBookingComponent,
    MyAppointmentsComponent,
    MedicalFilesComponent,
    UploadMedicalFileComponent,
    HijriCalenderComponent
    // PublicOrdersComponent,
    // PublicLabDetailsComponent,
    // PublicReadiologyComponent,
    // PublicReadiologyDetailsComponent,
    // EPublicServicesComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ArchwizardModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule,
    NgxUiHeroModule,
    NgxUiHeroInputFormsModule.forRoot(inputFormsConfig),
    FlatpickrModule.forRoot(),
    HttpModule,
    HttpClientModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
    AngularDraggableModule,
    CdTimerModule,
    ReactiveFormsModule,
    DashboardModule,
    ChartistModule,
    ChartsModule,
    UserModule,
    JwPaginationModule,
    QRCodeModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey,
      libraries: ['places'],
    }),
    SharedModule,
    NgxDropzoneModule,
    NgxPaginationModule
  ],
  exports: [
    TranslateModule,
    LabsComponent,
    LabDetailsComponent,
    RadiologyComponent,
    RadiologyDetailsComponent,
    AppointmentsComponent,
    MyAppointmentsComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: language || 'en',
    },
    // { provide: LOCALE_ID, useValue: 'ar-SA' },
    AuthGuard,
    AuthService,
    LocalService,
    StorageService,
    FilterPipe,
    DatePipe,
  ],
})
export class HomeModule {}