// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit } from "@angular/core";
import { IinvoicesTotalAmount } from "../../core/interfaces/IDashboard";

/**
 * Component for displaying dashboard cards with various statistics.
 * @component
 */
@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  /**
   * User count to be displayed on the dashboard card.
   * @type {number}
   */
  @Input() user: number;

  /**
   * Appointment count to be displayed on the dashboard card.
   * @type {number}
   */
  @Input() appointmentCount: number;

  /**
   * Total amount of invoices to be displayed on the dashboard card.
   * @type {IinvoicesTotalAmount}
   */
  @Input() invoicesTotalAmount: IinvoicesTotalAmount;

  /**
   * Overall satisfaction rating to be displayed on the dashboard card.
   * @type {any}
   */
  @Input() overallSatisfaction: any;

  constructor() {
  }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   */
  ngOnInit() {
  }
}
