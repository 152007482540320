import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface Clinics {
  CLIN_CODE: string;
  CLIN_ANAME: string;
  CLIN_ENAME: string;
}

export class UserChoice {
  public selectedClinic: Clinics;
  public selectedProvider: Providers;
  public membershipNo: string;
  public policyNo: string;
  public patientID: string;
}

export interface Providers {
   PROVIDER_CODE: string;
   PROVIDER_ENAME: string;
   PROVIDER_ANAME: string;
}

export class ServiceResponse {
  public HasError: boolean;
  public StatusDescription: string;
}



