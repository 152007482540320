import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../payment.service';
import { ApiHandlerService } from 'app/core/services/api-handler.service';

@Component({
  selector: 'app-holder',
  templateUrl: './holder.component.html',
  styleUrls: ['./holder.component.scss'],
})
export class HolderComponent implements OnInit {
  checkoutId: string;
  invoiceData: any;
  params: any;

  /**
   * Constructor to inject necessary services and initialize component.
   * @param {ActivatedRoute} route - The current route snapshot.
   * @param {Router} router - The router for navigation.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiHandler: ApiHandlerService
  ) {
    // this.checkoutId = this.route.queryParamMap.params;
    // if (!this.checkoutId || this.checkoutId.trim() === null) {
    //   this.router.navigate(['e-services']);
    // }
  }

  ngOnInit() {
    // Subscribe to query parameters to check for params
    this.route.queryParams.subscribe(
      (params) => {
        if (params) {
          this.params = params;
          this.apiHandler
            .paymentCallback(this.params)
            .subscribe((data: any) => {
              if (data && data.status) {
                this.router.navigate(['payments/done/' + data.transCount]);
              } else {
                this.router.navigate([`payments/failed/${data.transCount}`]);
                localStorage.setItem('payment_error_status', data.responseCode);
              }
            });
        }
      },
      (error) => {
        console.log('Error=========>', error);
        this.router.navigate(['e-services']);
      }
    );
  }
}
