import { Component, Input, OnInit } from "@angular/core";

/**
 * Component for displaying customer satisfaction metrics.
 * @component
 */
@Component({
  selector: 'app-customer-satisfaction',
  templateUrl: './customer-satisfaction.component.html',
  styleUrls: ['./customer-satisfaction.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class CustomerSatisfactionComponent implements OnInit {
  /**
   * The percentage of hot angry ratings.
   * @type {number}
   */
  @Input() hotAngryRatePercent: number;

  /**
   * The count of hot angry ratings.
   * @type {number}
   */
  @Input() hotAngryRate: number;

  /**
   * The percentage of angry ratings.
   * @type {number}
   */
  @Input() angryRatePercent: number;

  /**
   * The count of angry ratings.
   * @type {number}
   */
  @Input() angryRate: number;

  /**
   * The percentage of negative ratings.
   * @type {number}
   */
  @Input() negativeRatePercent: number;

  /**
   * The count of negative ratings.
   * @type {number}
   */
  @Input() negativeRate: number;

  /**
   * The percentage of neutral ratings.
   * @type {number}
   */
  @Input() neutralRatePercent: number;

  /**
   * The count of neutral ratings.
   * @type {number}
   */
  @Input() neutralRate: number;

  /**
   * The percentage of positive ratings.
   * @type {number}
   */
  @Input() positiveRatePercent: number;

  /**
   * The count of positive ratings.
   * @type {number}
   */
  @Input() positiveRate: number;

  /**
   * Constructor for CustomerSatisfactionComponent.
   */
  constructor() { }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   */
  ngOnInit() { }
}
