import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UserRoutingModule } from './user-routing.module';
import { AddUserComponent } from './add-user/add-user.component';
import { UserComponent } from './all-user/user.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditInformationComponent } from './edit-information/edit-information.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';

@NgModule({
  declarations: [
    AddUserComponent,
    UserComponent,
    ChangePasswordComponent,
    EditInformationComponent,
    UserProfileComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgSelectModule,
    UserRoutingModule,
    NgxDatatableModule,
    UiSwitchModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey,
      libraries: ['places'],
    }),
  ],
  exports: [ChangePasswordComponent],
  providers: [],
})
export class UserModule {}
