// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ITags } from "../../core/interfaces/ITags";
import { GiftStoreService } from "../../core/services/gift-store.service";
import { ORDER_TYPE } from "../../core/enums/order-type.enum";
import Swal from "sweetalert2";

/**
 * Component to display and manage a list of tags in a table format.
 * @component
 */
@Component({
  selector: "app-tags-list-table",
  templateUrl: './tags-list-table.component.html',
  styleUrls: ['./tags-list-table.component.scss']
})
export class TagsListTableComponent implements OnInit {

  /**
   * Reference to the DatatableComponent.
   * @type {DatatableComponent}
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;
  
  /**
   * Enum for column mode to configure datatable behavior.
   * @type {ColumnMode}
   */
  ColumnMode = ColumnMode;

  /**
   * Array of tag items received from the parent component.
   * @type {ITags[]}
   */
  @Input() items: ITags[];

  /**
   * Order type received from the parent component.
   * @type {ORDER_TYPE}
   */
  @Input() type: ORDER_TYPE;

  /**
   * Object to track the editing status of table cells.
   * @type {Object}
   */
  editing = {};

  /**
   * Language setting retrieved from local storage.
   * @type {string}
   */
  private lang: string;

  /**
   * Constructor to inject the GiftStoreService and set the language variable.
   * @param {GiftStoreService} tagsService - The service to manage tag operations.
   */
  constructor(private tagsService: GiftStoreService) {
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit(): void {}

  /**
   * Method to handle cell value updates in the datatable.
   * @param {Event | boolean} event - The event object or boolean value.
   * @param {string} cell - The cell identifier.
   * @param {number} rowIndex - The index of the row.
   * @param {string} id - The identifier of the tag item.
   */
  updateValue(event: Event | boolean, cell: string, rowIndex: number, id: string): void {
    let body;
    if (typeof event === "boolean") {
      body = {
        [cell]: event
      };
    } else {
      // Update the editing status and item value based on the event type
      this.editing[rowIndex + '-' + cell] = false;
      this.items[rowIndex][cell] = (event.target as HTMLInputElement).value;
      this.items = [...this.items];

      body = {
        [cell]: (event.target as HTMLInputElement).value
      };
    }
    // Call the service to update tags information
    this.tagsService.putTagsInfo(body, id, this.type);
  }

  /**
   * Method to handle tag deletion with a confirmation dialog.
   * @param {string} id - The identifier of the tag item to be deleted.
   */
  deleteTag(id: string): void {
    Swal({
      title: this.lang == 'en' ? 'Are you sure?' : 'تأكيد العملية',
      text:
        this.lang == 'en'
          ? 'You won\'t be able to revert this!'
          : '!لن يكون بإستطاعتك التراجع',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText:
        this.lang == 'en' ? 'Yes, delete it!' : 'نعم ، احذفها',
      cancelButtonText: this.lang == 'en' ? 'No' : 'لا',
    }).then((result) => {
      if (result.value) {
        this.tagsService.deleteTags(id, this.type);
      }
    });
  }
}
