// Importing necessary Angular modules and services
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../radiology/api.service';
import * as moment from 'moment-timezone';
import { observable } from 'rxjs';
import { decode } from 'app/utils';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { environment } from 'environments/environment';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
@Component({
  selector: 'app-radiology-details',
  templateUrl: './radiology-details.component.html',
  styleUrls: ['./radiology-details.component.scss'],
})
export class RadiologyDetailsComponent implements OnInit {
  mobileNumber: string;
  patCode: string;
  transCode: string;
  patName: string;
  servCode: string;
  drCode: string;
  startDate: any;
  endDate: any;
  xrayResult: any[];
  xrayInfo: Object;
  isError = 0;
  today = new Date();
  userId: any;
  lang: string;
  idCard: any;
  patInfo: any;
  getXRayDetail: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private data: ApiService,
    private spinner: SpinnerService,
    private apiHandler: ApiHandlerService,
  ) {
    // Setting the default language to 'ar' if not present in localStorage
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ar';
  }

  ngOnInit() {
    // Show a spinner while data is being fetched
    this.spinner.show();

    // Check if patient information is stored in localStorage
    if (localStorage.getItem('patInfo')) {
      this.patInfo = decode('patInfo');
    }
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
    
    // Subscribe to query parameters in the route
    this.route.queryParamMap.subscribe((queryParams) => {
      this.transCode = queryParams.get('transCount');
      this.servCode = queryParams.get('servCode').trim();
      this.drCode = queryParams.get('drCode');
    });
    // console.log(this.transCode, this.servCode);
    if (localStorage.getItem('currentUser')) {
      this.data
        .getXRayDetails(this.drCode, this.servCode, this.transCode)
        .subscribe(
          (r) => {
            if (r['status'] == 'success') {
              this.xrayResult = r['data'][0];
              this.spinner.hide();
              if (r['data']) {
                this.xrayInfo = r['data'][0];
                this.xrayInfo['DATE_BIRTH'] = moment(
                  this.xrayInfo['DATE_BIRTH']
                ).from(moment(), true);
              }
            }
          },
          (error) => {
            this.isError = 1;
            this.spinner.hide();
          },
          () => {
            let linkString: any[];
            linkString = this.xrayResult['XRAY_RESULT'].split('http://');
            let temp: any[];
            temp = this.xrayResult['XRAY_RESULT'].split(' ');
            let word = '';
            for (let i = 0; i < temp.length; i++) {
              word = temp[i];
              if (word[word.length - 1] == ':') {
                temp[i] = `${word}<br/>`;
              }
              if (word[0] == '-' || word[1] == '-' || word == '-') {
                temp[i] = `<br/>${word}`;
              }
              // if (word.includes('.')) {
              //   temp[i] = word.replace('.', '.<br/>');
              // }
              if (word.includes(':')) {
                temp[i] = word.replace(':', ':<br/>');
              }
              if (
                word.substring(0, word.length - 1).toUpperCase() == 'IMPRESSION'
              ) {
                temp[i] = `<br/><br/><b class='text-primary'>${word}</b><br/>`;
              }
              if (word.includes('IMPRESSION:')) {
                temp[i] = word.replace(
                  'IMPRESSION:',
                  `<br/><br/><b class='text-primary'>${word}</b><br/>`
                );
              }
              if (
                word.toUpperCase().includes('DATA:') &&
                temp[i - 1].toUpperCase().includes('CLINICAL')
              ) {
                temp[i - 1] = temp[i - 1].replace(
                  'Clinical',
                  `<br/><br/><b class='text-primary'>Clinical`
                );
                temp[i] = word.replace('Data:', `${word}</b><br/>`);
              }
            }
            this.xrayResult['XRAY_RESULT'] =
              temp.join(' ').replace(`http://${linkString[1]}`, '') +
              `${linkString[1] != undefined
                ? `<br><a href="http://${linkString[1]}" target="_blank">http://${linkString[1]}</a>`
                : ''
              }`;
          }
        );
    } else {
      // If user is not logged in, fetch X-ray details using public access
      this.data
        .getXRayDetailsPublic(
          this.patInfo.mobileNumber,
          this.patInfo.profileNo,
          this.drCode,
          this.servCode,
          this.transCode
        )
        .subscribe(
          (r) => {
            if (r['status'] == 'success') {
              this.xrayResult = r['data'][0];
              this.spinner.hide();
              if (r['data']) {
                this.xrayInfo = r['data'][0];
                this.xrayInfo['DATE_BIRTH'] = moment(
                  this.xrayInfo['DATE_BIRTH']
                ).from(moment(), true);
              }
            }
          },
          (error) => {
            this.isError = 1;
            this.spinner.hide();
          },
          () => {
            let linkString: any[];
            linkString = this.xrayResult['XRAY_RESULT'].split('http://');
            let temp: any[];
            temp = this.xrayResult['XRAY_RESULT'].split(' ');
            let word = '';
            for (let i = 0; i < temp.length; i++) {
              word = temp[i];
              if (word[word.length - 1] == ':') {
                temp[i] = `${word}<br/>`;
              }
              if (word[0] == '-' || word[1] == '-' || word == '-') {
                temp[i] = `<br/>${word}`;
              }
              // if (word.includes('.')) {
              //   temp[i] = word.replace('.', '.<br/>');
              // }
              if (word.includes(':')) {
                temp[i] = word.replace(':', ':<br/>');
              }
              if (
                word.substring(0, word.length - 1).toUpperCase() == 'IMPRESSION'
              ) {
                temp[i] = `<br/><br/><b class='text-primary'>${word}</b><br/>`;
              }
              if (word.includes('IMPRESSION:')) {
                temp[i] = word.replace(
                  'IMPRESSION:',
                  `<br/><br/><b class='text-primary'>${word}</b><br/>`
                );
              }
              if (
                word.toUpperCase().includes('DATA:') &&
                temp[i - 1].toUpperCase().includes('CLINICAL')
              ) {
                temp[i - 1] = temp[i - 1].replace(
                  'Clinical',
                  `<br/><br/><b class='text-primary'>Clinical`
                );
                temp[i] = word.replace('Data:', `${word}</b><br/>`);
              }
            }
            this.xrayResult['XRAY_RESULT'] =
              temp.join(' ').replace(`http://${linkString[1]}`, '') +
              `${linkString[1] != undefined
                ? `<br><a href="http://${linkString[1]}" target="_blank">http://${linkString[1]}</a>`
                : ''
              }`;
          }
        );
    }
  }

  // Open WhatsApp and share the current page link with a predefined message
  openWhatsApp() {
    const link = window.location.href;
    const message = `*عناية متجددة للعناية المنزلية*\nيمكنكم معاينة تقرير الأشعة من خلال الرابط أدناه:\n${link}`;

    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    window.location.href = url;
  }

  // Function to retrieve and open an X-ray report PDF for printing.
  getPrintXRayDetails() {
    this.getXRayDetail = true;
    let data = {
      drCode: this.drCode,
      servCode: this.servCode,
      transCount: this.transCode,
      lang: this.lang ? this.lang : 'ar'
    }
    this.apiHandler.getReportsPrint('xray-report-pdf', data).subscribe((r: any) => {
      if (r.data) {
        window.open(`${environment.apiUrl.replace('/api/v1','')}/${r.data}`, '_blank');
      }
      this.getXRayDetail = false;
    }, (error) => {
      this.getXRayDetail = false;
      console.log("error", error);
    },
      () => { })
  }
}
