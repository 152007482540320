// Importing necessary modules and components from Angular and third-party libraries
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GiftStoreService } from "../../core/services/gift-store.service";
import { ProductsModalComponent } from "../products-modal/products-modal.component";
import { ProductsSetupService } from "../../core/services/products-setup.service";
import { decode } from "app/utils";

/**
 * Component for displaying and managing a table of products.
 * @component
 */
@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent implements OnInit {
  /**
   * Items to be displayed in the table.
   * @type {any[]}
   */
  @Input() items: any[];

  /**
   * Type of the items.
   * @type {any}
   */
  @Input() type: any;

  /**
   * Reference to the datatable component.
   * @type {DatatableComponent}
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * Temporary array to hold filtered items.
   * @type {any[]}
   */
  temp = [];

  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Enumeration for column mode in the datatable.
   * @type {ColumnMode}
   */
  ColumnMode = ColumnMode;

  /**
   * Current user information.
   * @type {any}
   */
  user: any;

  /**
   * Creates an instance of ProductsTableComponent.
   * @param {NgbModal} modalService - Service to handle modals.
   * @param {ProductsSetupService} productsSetupService - Service for product setup.
   * @param {GiftStoreService} giftStoreService - Service for gift store.
   */
  constructor(
    private modalService: NgbModal,
    private productsSetupService: ProductsSetupService,
    private giftStoreService: GiftStoreService
  ) {
    // Initialize language from local storage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    // Decode and store current user information
    this.user = decode('currentUser');

    // Set a timeout to populate the temporary array with items after 2 seconds
    setTimeout(() => {
      this.temp = this.items;
    }, 2000);
  }

  /**
   * Method to open the products modal for a selected item.
   * @param {Event} event - The DOM event that triggered the method.
   * @param {number} id - The ID of the selected item.
   * @param {any} firstChild - The first child element of the selected item.
   */
  openProductsModal(event: Event, id: number, firstChild: any) {
    if (this.user.userType === 3) {
      return;
    }

    // Stop the event propagation
    event.stopPropagation();

    // Blur the parent element to prevent focus issues
    firstChild && firstChild.parentElement && firstChild.parentElement.parentElement &&
      firstChild.parentElement.parentElement.blur();

    // Open the products modal using NgbModal
    let modalRef = this.modalService.open(ProductsModalComponent, {
      centered: true, size: 'lg', windowClass: 'product-view-modal'
    });

    // Pass the type and call a method in productsSetupService to select the products item
    modalRef.componentInstance.type = this.type;

    this.productsSetupService.selectProductsItem(id, this.type);
  }

  /**
   * Method to update the datatable with a filter value.
   * @param {Event} $event - The DOM event that triggered the method.
   */
  updateFilter($event: Event) {
    const val = ($event.target as HTMLInputElement).value.toLowerCase();

    // Filter the items based on the provided value
    const temp = [];
    temp.push(...this.temp.filter(function (d) {
      return (d.serviceCode && d.serviceCode.toLowerCase().indexOf(val) !== -1) ||
        (d.serviceNameEn && d.serviceNameEn.toLowerCase().indexOf(val) !== -1) ||
        (d.serviceNameAr && d.serviceNameAr.toLowerCase().indexOf(val) !== -1) ||
        (d.isActiveText && d.isActiveText.toLowerCase().indexOf(val) !== -1) ||
        (d.onlineActiveText && d.onlineActiveText.toLowerCase().indexOf(val) !== -1) || !val;
    }));
    this.items = temp;
    this.table.offset = 0;
  }
}
