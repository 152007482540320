// Import necessary Angular modules and services
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  lang: string;
  dir: string;
  hide: boolean;
  loginForm: FormGroup;
  resetPassword: boolean;
  confirmPage: boolean;
  setNewPasswordPage: boolean;
  lastPage: boolean;
  showPassword: boolean = false;
  isSignupModal: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    !localStorage.getItem('lang') && localStorage.setItem('lang', 'ar');
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
    this.hide = false;
    // this.config.backdrop = 'static';
    // this.config.keyboard = false;
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      udhId: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      channel: new FormControl('1'),
    });

    // Subscribe to the authService's resetPassword event to update the 'resetPassword' property.
    this.authService.resetPassword.subscribe((val) => {
      this.resetPassword = val;
    });

    // Subscribe to the authService's verifyPage event to update the 'confirmPage' property.
    this.authService.verifyPage.subscribe((val) => {
      this.confirmPage = val;
    });

    // Subscribe to the authService's setNewPasswordPage event to update the 'setNewPasswordPage' property.
    this.authService.setNewPasswordPage.subscribe((val) => {
      this.setNewPasswordPage = val;
    });

    // Subscribe to the authService's lastPage event to update the 'lastPage' property.
    this.authService.lastPage.subscribe((val) => {
      this.lastPage = val;
    });
  }
  
  // On submit button click
  onSubmit() {
    this.loginForm.reset();
    console.log(this.loginForm.value);
  }

  // On Forgot password link click
  onForgotPassword() {
    this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
  }

  // On registration link click
  onRegister() {
    this.router.navigate(['register'], { relativeTo: this.route.parent });
  }

  // Function to opens the sign-up modal, setting appropriate modal properties and configurations.
  openSignUpModal(content) {
    this.isSignupModal = true;
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'customModal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  // Function to opens the forgot password modal, setting appropriate modal properties and configurations.
  openForgotPassModal(content) {
    this.isSignupModal = false;
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'customModal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  // Function to initiates the login process by sending the user credentials to the authService.
  login() {
    const body = {
      channel: '1',
      udhId: this.loginForm.controls.udhId.value,
      password: this.loginForm.controls.password.value,
    };

    this.authService.login(body);
    // console.log(body);
  }

  // Function to opens the forgot password modal, updating the modal properties and triggering a password reset event.
  openForgotPasswordModal(content) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'customModal',
    });
    this.resetPassword = true;
    this.authService.resetPassword.next(true);
  }

  // Function to changes the language of the application and performs a full page reload for the changes to take effect.
  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url], { skipLocationChange: true });
    window.location.reload();
  }

  // Function to navigates to the 'e-public' page when booking an appointment.
  bookAppointment() {
    this.router.navigate(['e-public']).then(nav => {
      console.log(nav);
    }, err => {
      console.log(err);
    });
  }

  // Function to navigates to the 'e-public' page with a query parameter indicating a home doctor visit.
  goToDoctorHomeVisitPage() {
    this.router.navigate(['e-public'], { queryParams: { homeDoctorVisit: true } }).then(nav => {
      console.log(nav);
    }, err => {
      console.log(err);
    });
  }
}
