// Import necessary modules and components from Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

/**
 * Service to handle payment-related operations.
 * @@Injectable Decorator indicating that this service can be injected into other components or services.
 */
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  /**
   * HTTP options for GET requests.
   * @type {Object}
   */
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
      'access-control-allow-origin': '*',
    }),
  };

  /**
   * HTTP options for POST requests.
   * @type {Object}
   */
  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
      'access-control-allow-origin': '*',
    }),
  };

  /**
   * Constructor to inject HttpClient.
   * @param {HttpClient} _http - The HTTP client service.
   */
  constructor(private _http: HttpClient) { }

  /**
   * Gets invoice details by ID using a GET request.
   * @param {string} invoiceId - The ID of the invoice.
   * @returns {Observable<any>} - An observable containing the invoice details.
   */
  public getInvoiceDetailsById(invoiceId: string) {
    return this._http.get(
      `${environment.paymentApiUrl}/payments/invoices/${invoiceId}`,
      this.httpOptions
    );
  }

  /**
   * Gets invoice ID by transaction count using a GET request.
   * @param {number} transCount - The transaction count.
   * @returns {Observable<{ id: string }>} - An observable containing the invoice ID.
   */
  public getInvoiceIdByTransCount(transCount: number) {
    return this._http.get<{ id: string }>(
      `${environment.paymentApiUrl}/payments/invoices/id/${transCount}`,
      this.httpOptions
    );
  }

  /**
   * Gets Telr payment page using a POST request.
   * @param {ICustomerInvoice} invoiceData - The customer invoice data.
   * @returns {Observable<ITelrCreateOrderResponse>} - An observable containing the Telr create order response.
   */
  public getTelrPaymentPage(invoiceData: ICustomerInvoice) {
    return this._http.post<ITelrCreateOrderResponse>(
      `${environment.paymentApiUrl}/telr/orders`,
      {
        ...invoiceData,
        request_source: 2
      },
      this.httpOptionsPost
    );
  }

  /**
   * Gets HyperPay payment page using a POST request.
   * @param {ICustomerInvoice} invoiceData - The customer invoice data.
   * @returns {Observable<{ checkoutId: string }>} - An observable containing the HyperPay checkout ID.
   */
  public getHyperPayPaymentPage(invoiceData: ICustomerInvoice) {
    return this._http.post<{ checkoutId: string }>(
      `${environment.paymentApiUrl}/hyperpay/orders`,
      invoiceData,
      this.httpOptionsPost
    );
  }

  /**
   * Gets HyperPay payment status using a GET request.
   * @param {string} checkoutId - The HyperPay checkout ID.
   * @returns {Observable<{ result: { code: string; description: string }; transCount: number }>} - An observable containing the HyperPay payment status.
   */
  public getHyperpayPaymentStatus(checkoutId: string) {
    return this._http.get<{
      result: { code: string; description: string };
      transCount: number;
    }>(
      `${environment.paymentApiUrl}/hyperpay/orders/${checkoutId}`,
      this.httpOptions
    );
  }
}

/**
 * Interface for customer invoice details.
 * @interface
 */
export interface ICustomerInvoice {
  /**
   * The customer's ID number.
   * @type {string}
   */
  customerIdNumber: string;

  /**
   * The customer's mobile number.
   * @type {string}
   */
  customerMobileNumber: string;

  /**
   * The customer's profile number.
   * @type {string}
   */
  customerProfileNumber: string;

  /**
   * The transaction count.
   * @type {number}
   */
  transCount: number;
}

/**
 * Interface for Telr create order response.
 * @interface
 */
export interface ITelrCreateOrderResponse {
  /**
   * The payment method.
   * @type {string}
   */
  method: string;

  /**
   * The order details.
   * @type {Object}
   * @property {string} ref - The order reference.
   * @property {string} url - The order URL.
   */
  order?: {
    ref: string;
    url: string;
  };

  /**
   * The error details.
   * @type {Object}
   * @property {string} message - The error message.
   * @property {string} note - The error note.
   */
  error?: {
    message: string;
    note: string;
  };
}

/**
 * Interface for Telr order status.
 * @interface
 */
export interface ITelrOrderStatus {
  /**
   * The transaction count.
   * @type {number}
   */
  transCount: number;

  /**
   * The status details.
   * @type {Object}
   * @property {number} code - The status code.
   * @property {string} text - The status text.
   */
  status: {
    code: number;
    text: string;
  };
}
