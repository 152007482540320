// Import necessary modules from Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  IChangePassword,
  ICreateUser,
  IEditInformation,
  IUser,
} from '../interfaces/IUser';
import { environment } from '../../../environments/environment';
import { decode } from '../../utils';
import { SnotifyService } from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      Authorization: `ak ${environment.key}`,
      'jwt-token': decode('token'),
    }),
  };

  users: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>(null);
  user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  patient: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly snotifyService: SnotifyService,
    private readonly translate: TranslateService
  ) {}

  getUser(): IUser[] {
    return this.users.value;
  }

  /**
   * @description Get Users List
   * */
  getAllUsers() {
    this.httpClient
      .get(`${environment.paymentApiUrl}/user`, this.httpOptions)
      .subscribe(
        (data: any) => {
          if (data) {
            this.users.next(data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * @description Create User
   * @param body: IUser
   * @param reset: Function
   * */
  createUser(body: ICreateUser, reset: Function): void {
    this.httpClient
      .post(`${environment.paymentApiUrl}/user`, body, this.httpOptions)
      .subscribe(
        (data: any) => {
          this.snotifyService.success(
            this.translate.instant(`message.${data.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
          reset();
        },
        (error) => {
          error.error.errors.map((item) => {
            this.snotifyService.error(
              this.translate.instant(`message.${item.message}`),
              '',
              {
                position: 'rightTop',
              }
            );
          });
        }
      );
  }

  /**
   * @description Update User Info
   * @param body: IUser
   * @param id: number
   * */
  putInfo(body, id) {
    this.httpClient
      .put(`${environment.paymentApiUrl}/user/${id}`, body, this.httpOptions)
      .subscribe(
        (data: any) => {
          this.snotifyService.success(
            this.translate.instant(`message.${data.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
        },
        (error) => {
          error.error.errors.map((item) => {
            this.snotifyService.error(
              this.translate.instant(`message.${item.message}`),
              '',
              {
                position: 'rightTop',
              }
            );
          });
        }
      );
  }
  /**
   * @description Reset User Password
   * @param id: number
   * */
  resetPassword(id) {
    this.httpClient
      .get(
        `${environment.paymentApiUrl}/user/reset-password/${id}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          this.snotifyService.success(
            this.translate.instant(`message.${data.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
        },
        (error) => {
          error.error.errors.map((item) => {
            this.snotifyService.error(
              this.translate.instant(`message.${item.message}`),
              '',
              {
                position: 'rightTop',
              }
            );
          });
        }
      );
  }

  /**
   * @description Change Password
   * @param body: IChangePassword
   * */
  changePassword(body: IChangePassword) {
    this.httpClient
      .patch(
        `${environment.paymentApiUrl}/user/password`,
        body,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          this.snotifyService.success(
            this.translate.instant(`message.${data.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
        },
        (error) => {
          console.log(error);
          this.snotifyService.error(
            this.translate.instant(`message.${error.error.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
        }
      );
  }

  /**
   * @description Edit User Information
   * @param body: IEditInformation
   * @param reset: Funcrtion
   * */
  editInformation(body: IEditInformation, reset: Function) {
    this.httpClient
      .put(`${environment.paymentApiUrl}/user`, body, this.httpOptions)
      .subscribe(
        (data: any) => {
          this.snotifyService.success(
            this.translate.instant(`message.${data.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
          // reset();
        },
        (error) => {
          console.log(error);
          this.snotifyService.error(
            this.translate.instant(`message.${error.error.message}`),
            '',
            {
              position: 'rightTop',
            }
          );
        }
      );
  }

  /**
   * @description Get Current User Information
   * */
  getCurrentUserInformation() {
    this.httpClient
      .get(`${environment.paymentApiUrl}/user/my-info`, this.httpOptions)
      .subscribe(
        (data: any) => {
          this.user.next(data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getPatientInfo() {
    return this.httpClient
      .get(
        `${environment.paymentApiUrl}/enaya/patientInformation`,
        this.httpOptions
      )
      .subscribe(
        (data) => {
          this.patient.next(data);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  updatePatientInfo(body: any) {
    return this.httpClient.put(
      `${environment.paymentApiUrl}/enaya/updatePatientInformation/1?ADDRESS=${
        body.ADDRESS
      }&MOBILE=${body.MOBILE}&CITY=${body.CITY}&DIST=${body.DIST}&KIN_NAME=${
        body.KIN_NAME
      }${body.PHONE1 === null ? '' : `&PHONE1=${body.PHONE1}`}${
        body.PHONE2 === null ? '' : `&PHONE2=${body.PHONE2}`
      }&KIN_TEL=${body.KIN_TEL}&KIN_REL=${body.KIN_REL}${
        body.SMS_AE === null ? '&SMS_AE="2"' : `&SMS_AE=${body.SMS_AE}`
      }${
        !body.WHATSAPP_OP
          ? '&WHATSAPP_OP="1"'
          : `&WHATSAPP_OP=${body.WHATSAPP_OP}`
      }${body.EMAIL === null ? '' : `&EMAIL=${body.EMAIL}`}&GPS_LINK=${
        body.GPS_LINK
      }${
        body.FORMATTED_ADDRESS === null
          ? ''
          : `&FORMATTED_ADDRESS=${body.FORMATTED_ADDRESS}`
      }&GPS_LAT=${body.GPS_LAT}&GPS_LNG=${body.GPS_LNG}`,
      null,
      this.httpOptions
    );
  }

  getAllergies() {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/enaya/patientInformation`,
      this.httpOptions
    );
  }
}
