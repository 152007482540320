// Import necessary modules from Angular
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileUpload } from '../models/file-upload.model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private basePath;
  fileUrl: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }

  // Set the base path for file storage
  setBasePath(path) {
    this.basePath = path;
  }

  // Upload file to storage and update database with file details
  pushFileToStorage(fileUpload: FileUpload): void {
    const filePath = `${this.basePath}/${this.getRandomFileName(fileUpload.file.name)}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    // Use snapshotChanges to track the upload progress and finalize when complete
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        // Retrieve the download URL after upload completion
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          this.saveFileData(fileUpload);
          this.saveDownloadUrl(downloadURL);
        });
      })
    ).subscribe(val => {
      console.log(val, 111111111111111111111111111)
    });
  }

  // Generate a random file name based on the current timestamp
  getRandomFileName(name) {
    let timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    return timestamp + '_' + name;
  }

  // Save file details to the database
  private saveFileData(fileUpload: FileUpload): void {
    this.db.list(this.basePath).push(fileUpload);
  }

  // Retrieve a specified number of files from the database
  getFiles(numberItems): AngularFireList<FileUpload> {
    return this.db.list(this.basePath, ref =>
      ref.limitToLast(numberItems));
  }

  // Delete file from both database and storage
  deleteFile(fileUpload: FileUpload): void {
    this.deleteFileDatabase(fileUpload.key)
      .then(() => {
        this.deleteFileStorage(fileUpload.name);
      })
      .catch(error => console.log(error));
  }

  // Delete file details from the database
  private deleteFileDatabase(key: string): Promise<void> {
    return this.db.list(this.basePath).remove(key);
  }

  // Delete file from storage
  private deleteFileStorage(name: string): void {
    const storageRef = this.storage.ref(this.basePath);
    storageRef.child(name).delete();
  }

  // Update the download URL in the BehaviorSubject
  private saveDownloadUrl(downloadURL: any) {
    this.fileUrl.next(downloadURL)
  }
}
