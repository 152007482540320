// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { SnotifyService } from "ng-snotify";
import { TranslateService } from "@ngx-translate/core";
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-upload-medical-file',
  templateUrl: './upload-medical-file.component.html',
  styleUrls: ['./upload-medical-file.component.scss']
})
export class UploadMedicalFileComponent implements OnInit {
  @ViewChild('modalSuccessUpload') modalSuccessUpload: TemplateRef<any>;
  @ViewChild('modalErrorUpload') modalErrorUpload: TemplateRef<any>;

  files: File[] = [];
  lang: string;
  filePath: string;
  form: FormGroup;
  today: NgbDateStruct;
  tdate = new Date();
  type: string;
  orderId: string;
  resNo: string;
  orderNumber: string;
  fileDetails = {};
  fileUploadErrorMsg: string;
  fileUploading = false;
  fileTypes = [
    { enName: "Medical Report", arName: "تقرير طبي", value: 1 },
    { enName: "Radiology", arName: "أشعة", value: 2 },
    { enName: "Lab Results", arName: "تحاليل مختبر", value: 3 },
    { enName: "Image", arName: "صورة", value: 4 },
    { enName: "Video", arName: "فيديو", value: 5 },
    { enName: "Audio", arName: "ملف صوتي", value: 6 },
    { enName: "Other", arName: "أخرى", value: 7 }
  ]

  constructor(private translate: TranslateService,
    private snotifyService: SnotifyService,
    private ApiHandlerService: ApiHandlerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private readonly router: Router,
    private location: Location,
    private route: ActivatedRoute,

  ) {
    // Subscribe to query parameters to check for order information
    this.lang = localStorage.getItem('lang')
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
      this.orderId = params['orderId']
      this.resNo = params['resNo']
      this.orderNumber = params['orderNumber'];
    });
  }

  ngOnInit() {
    this.today = {
      year: this.tdate.getFullYear(),
      month: this.tdate.getMonth() + 1,
      day: this.tdate.getDate(),
    };

    // Initialize the form with form controls and validators
    this.form = this.fb.group({
      fileName: ['', [Validators.required, Validators.maxLength(80)]],
      fileCategory: ['', [Validators.required, Validators.maxLength(80)]],
      fileDate: ['', [Validators.required]],
      notes: ['', []],
    })
  }

  get fileName() { return this.form.get('fileName') }
  get fileCategory() { return this.form.get('fileCategory') }
  get fileDate() { return this.form.get('fileDate') }
  get notes() { return this.form.get('notes') }

  // Event handler for file upload using ngx-dropzone
  onFileUploadSelect(event: NgxDropzoneChangeEvent) {

    // Validate the file type and size before processing
    if (this.validateFile(event.addedFiles[0]) && event.addedFiles[0].size < 250000 * 1000) {
      this.files.push(...event.addedFiles);

      // If more than one file is added, replace the first one
      if (this.files.length > 1) {
        this.replaceFile();
      }
      this.fileUploading = true;
      // Set the fileUploading flag to true and initiate file upload to the server
      this.ApiHandlerService.patientFileUpload(event.addedFiles[0]).subscribe((data: any) => {
        if (data && data.data && data.data.url) {
          this.filePath = data.data.url;
        }
        this.fileUploading = false;
      }, (error) => {
        this.fileUploading = false;
        this.filePath = '';
        console.log(error)
      })
    } else {
      // Notify the user if the file size exceeds the limit
      this.snotifyService.error(this.translate.instant(`message.fileSizeNotMax25MB`), '', {
        position: 'rightTop'
      })
    }
  }

  private replaceFile() {
    this.files.splice(0, 1);
  }

  // Validate the file type based on its extension
  validateFile(file: any) {
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1);

    if (['png', 'jpeg', 'jpg', 'mp4', 'mkv', 'avi', 'webm', 'gif', 'pdf'].indexOf(ext.toLowerCase()) > -1) {
      return true
    }
    return false;

  }

  // Open modal to validate the uploaded file and reset the form
  validateUploadedFile(modal) {
    this.modalService.open(modal, {
      centered: true,
      size: 'lg',
      windowClass: "customModalClass",
      backdrop: 'static',
    })
    this.form.reset();
    this.filePath = '';
  }

  closePopup() {
    this.modalService.dismissAll();
    this.router.navigate(['/e-services/medical-files']);
  }

  // This method is called when the form is submitted.
  onSubmit() {
    // Check for form validity and existence of file path
    if (this.form.invalid && !this.filePath) {
      // Display an error message if form is invalid or file path is missing
      this.snotifyService.error(this.translate.instant(`message.data.message`), '', { position: 'rightTop' });
      return;
    }
    this.fileUploadErrorMsg = '';

    // Format the date values to ensure two-digit representation for day and month
    let date = this.form.value.fileDate.day.toString();
    if (date.length < 2) {
      this.form.value.fileDate.day = '0' + date;
    }

    let month = this.form.value.fileDate.month.toString();
    if (month.length < 2) {
      this.form.value.fileDate.month = '0' + month;
    }

    // Construct the date in the required format
    let formFileDate = `${this.form.value.fileDate.year}-${this.form.value.fileDate.month}-${this.form.value.fileDate.day}`
    let body = {
      fileName: this.form.get('fileName').value,
      fileCategory: this.form.get('fileCategory').value,
      fileDate: formFileDate,
      resNo: this.resNo || '',
      orderId: this.orderId || '',
      orderNumber: this.orderNumber || '',
      notes: this.form.get('notes').value,
      filePath: this.filePath,
    };
    this.fileDetails = JSON.parse(JSON.stringify(body));

    // Call the API to upload patient file data
    this.ApiHandlerService.uploadPatientFileData(body).subscribe((data: any) => {
      if (data) {
        console.log(data)
        this.validateUploadedFile(this.modalSuccessUpload);
      }
      // Display success message
      this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
        position: 'rightTop'
      });
    }, (err) => {
      // Trigger error modal and display error message
      this.validateUploadedFile(this.modalErrorUpload);
      this.fileUploadErrorMsg = err.error.message[0];
      console.log(err)
    });
  }

  // This method is called when the cancel button is clicked.
  onCancel() {
    // Reset the form and navigate back to the previous location
    this.form.reset();
    this.location.back();
  }

  // This method is called to reset the form and clear the file path.
  callReupload() {
    this.modalService.dismissAll();
    this.form.reset();
    this.filePath = ''
  }

  // This method is called to open the file path in a new window.
  openFilePath(file) {
    window.open(file.filePath);
  }

  // This method is called to open a details popup.
  openDetailsPopup(content, item) {
    this.modalService.dismissAll();
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      // backdrop: 'static',
    });
  }

  // This method is called to close a modal.
  closeModal(content) {
    this.modalService.dismissAll(content);
  }
}
