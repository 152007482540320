import * as SecureLS from 'secure-ls';

/**
 * SecureLS instance with AES encoding.
 * @type {SecureLS}
 */
let ls = new SecureLS({ encodingType: 'aes' });

/**
 * Encodes and stores data with a specified key.
 * @function encode
 * @param {string} key - The key under which the data will be stored.
 * @param {*} data - The data to be encoded and stored.
 */
export function encode(key: string, data: any): void {
  ls.set(key, data);
}

/**
 * Decodes and retrieves data stored under a specified key.
 * @function decode
 * @param {string} key - The key under which the data is stored.
 * @returns {*} The decoded data.
 */
export function decode(key: string): any {
  return ls.get(key);
}

/**
 * Removes the data stored under a specified key.
 * @function remove
 * @param {string} key - The key under which the data is stored.
 */
export function remove(key: string): void {
  ls.remove(key);
}

/**
 * Retrieves all keys from the storage.
 * @function getAllKeys
 * @returns {Array<string>} An array of all keys.
 */
export function getAllKeys(): Array<string> {
  return ls.getAllKeys();
}
