// Import necessary Angular modules and services
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../login/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Component for handling password setting functionality.
 * @component
 */
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Direction setting (ltr/rtl).
   * @type {string}
   */
  dir: string;

  /**
   * User data.
   * @type {any}
   */
  user: any;

  /**
   * Flag to check if passwords are equal.
   * @type {boolean}
   */
  equal: boolean;

  /**
   * Flag to show/hide password.
   * @type {boolean}
   */
  showPassword: boolean = false;

  /**
   * Flag to check if password meets minimum length requirement.
   * @type {boolean}
   */
  minLength: boolean = false;

  /**
   * Form group for setting password.
   * @type {FormGroup}
   */
  setPasswordForm: FormGroup;

  /**
   * Input property to receive modal data.
   * @type {any}
   */
  @Input() modal: any;

  /**
   * Constructor to initialize dependencies and set language and direction.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(private authService: AuthService) {
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
    this.equal = true;
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.authService.user.subscribe((val) => {
      if (val) {
        this.user = val;
      }
    });

    this.setPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  /**
   * Method to handle setting the password.
   */
  setPassword() {
    const body = {
      password: this.setPasswordForm.controls.password.value,
      passwordConfirm: this.setPasswordForm.controls.confirmPassword.value,
    };

    this.authService.setPassword(body);
  }

  /**
   * Method to check if passwords match.
   */
  matchPassword() {
    const password = this.setPasswordForm.get('password').value;
    const confirmPassword = this.setPasswordForm.get('confirmPassword').value;
    this.equal = password === confirmPassword && password.length >= 6;
  }

  /**
   * Method to check if password meets minimum length requirement.
   */
  isMinLength() {
    this.minLength = this.setPasswordForm.get('password').value.length >= 6;
  }
}
