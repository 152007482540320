import { Component, Input, OnInit } from "@angular/core";

/**
 * Component to display top-rated items.
 * @component
 */
@Component({
  selector: 'app-top-rated',
  templateUrl: './top-rated.component.html',
  styleUrls: ['./top-rated.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class TopRatedComponent implements OnInit {
  /**
   * The item to be displayed as top-rated.
   * @type {any}
   */
  @Input() item: any;

  /**
   * Constructor for the TopRatedComponent.
   */
  constructor() {}

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initialize any additional logic here.
   */
  ngOnInit() {}
}
