// Import necessary Angular modules and services
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../login/auth.service';
import transliterate from '@sindresorhus/transliterate';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HijriDate from 'hijri-date/lib/safe';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';

/**
 * Component for handling user information.
 * @component
 */
@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit {
  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Direction setting (ltr/rtl).
   * @type {string}
   */
  dir: string;

  /**
   * Input property to receive modal data.
   * @type {any}
   */
  @Input() modal: any;

  /**
   * Today's date.
   * @type {NgbDateStruct}
   */
  today: NgbDateStruct;

  /**
   * Current date.
   * @type {Date}
   */
  tdate = new Date();

  /**
   * ID number.
   * @type {number}
   */
  idNumber: number;

  /**
   * Form group for signup.
   * @type {FormGroup}
   */
  signupForm: FormGroup;

  /**
   * Flag for form submit attempt.
   * @type {boolean}
   */
  formSubmitAttempt: boolean;

  /**
   * Mobile number pattern for validation.
   * @type {RegExp}
   */
  mobilePattern =
    /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;

  /**
   * Minimum date for date picker.
   * @type {NgbDateStruct}
   */
  minDate = { year: 1923, month: 1, day: 1 };

  /**
   * Constructor to initialize dependencies and set language and direction.
   * @param {AuthService} authService - The authentication service.
   * @param {NgbModal} modalService - The modal service.
   */
  constructor(
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    // Create a form group with form controls for various user details
    this.signupForm = new FormGroup({
      aFirstName: new FormControl(''),
      aLastName: new FormControl(''),
      aMiddleName: new FormControl(''),
      eMiddleName: new FormControl(''),
      eFirstName: new FormControl('', [Validators.required]),
      eLastName: new FormControl('', [Validators.required]),
      id_code: new FormControl('', [Validators.required]),
      id_number: new FormControl('', [Validators.required]),
      dateType: new FormControl('G', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [
        Validators.required,
        Validators.pattern(this.mobilePattern),
      ]),
      gender: new FormControl('M', [Validators.required]),
      terms: new FormControl('', [Validators.required]),
    });
    // Set today's date for later use
    this.today = {
      year: this.tdate.getFullYear(),
      month: this.tdate.getMonth() + 1,
      day: this.tdate.getDate(),
    };
    // Perform some initializations, e.g., setting the ID type to '01'
    this.checkedIdType('01');
  }

  /**
   * Method to handle signup.
   */
  signup() {
    this.formSubmitAttempt = true;
    if (this.signupForm.valid) {
      let dob;
      let dobHijri;

      // Check the dateType to determine the date format
      if (this.signupForm.value.dateType === 'H') {
        // If dateType is Hijri, convert to Gregorian
        const day_eid_adha = new HijriDate(
          this.signupForm.value.dob.year,
          this.signupForm.value.dob.month,
          this.signupForm.value.dob.day
        );
        let dayGreg = day_eid_adha.toGregorian();
        dayGreg = dayGreg.setDate(dayGreg.getDate() + 1);

        // Get current timestamp
        const currentTimeStamp = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0,
          0,
          0,
          0
        ).getTime();

        // Check if the selected date is greater than or equal to the current date
        if (dayGreg >= currentTimeStamp) {
          // Display an error message and return false if the date is invalid
          Swal({
            title: this.lang === 'en' ? 'Date Error' : 'خطأ في التاريخ',
            text:
              this.lang === 'en'
                ? 'Date of birth should not be greater than current date.'
                : 'يجب ألا يكون تاريخ الميلاد أكبر من التاريخ الحالي',
            confirmButtonText: this.lang === 'en' ? 'Cancel' : 'إلغاء',
            type: 'error',
          });
          return false;
        }
        // dob = new Date(dayGreg).toISOString().split('T')[0] || null;
        if (this.signupForm.value.dob.month.toString().length === 1) {
          this.signupForm.value.dob.month =
            '0' + this.signupForm.value.dob.month.toString();
        }

        if (this.signupForm.value.dob.day.toString().length === 1) {
          this.signupForm.value.dob.day =
            '0' + this.signupForm.value.dob.day.toString();
        }
        // Format the date in 'YYYY-MM-DD' and set it to dob
        dob = moment(new Date(dayGreg)).format('YYYY-MM-DD').toString();

        // Format the Hijri date and set it to dobHijri
        dobHijri = `${this.signupForm.value.dob.day}-${this.signupForm.value.dob.month}-${this.signupForm.value.dob.year}`;
      } else {
        // If dateType is Gregorian, format the date and set it to dob
        dob =
          new Date(
            `${this.signupForm.value.dob.year}-${this.signupForm.value.dob.month}-${this.signupForm.value.dob.day}`
          )
            .toISOString()
            .split('T')[0] || null;
      }

      // Call the authService.signup method with the user data
      this.authService.signup({
        aFirstName: this.signupForm.value.aFirstName,
        aMiddleName: this.signupForm.value.aMiddleName,
        aLastName: this.signupForm.value.aLastName,
        eFirstName: this.signupForm.value.eFirstName,
        eMiddleName: this.signupForm.value.eMiddleName,
        eLastName: this.signupForm.value.eLastName,
        id_code: this.signupForm.value.id_code,
        id_number: this.signupForm.value.id_number,
        mobile: this.signupForm.value.mobile,
        gender: this.signupForm.value.gender,
        terms: this.signupForm.value.terms,
        dob: dob,
        dobHijri: dobHijri || undefined,
      });
    }
  }

  /**
   * Handle changes in the selected ID type.
   * @param {string} idType - The selected ID type.
   */
  checkedIdType(idType: string) {
    // Update form controls based on the selected ID type
    this.signupForm.controls.id_code.setValue(idType);
    switch (idType) {
      case '01':
        this.signupForm.controls.id_number.setValue('');
        this.signupForm.controls.id_number.setValidators([
          Validators.required,
          Validators.pattern(/^(1)\d{9}$/),
        ]);
        this.idNumber = 1;
        break;
      case '02':
        this.signupForm.controls.id_number.setValue('');
        this.signupForm.controls.id_number.setValidators([
          Validators.required,
          Validators.pattern(/^(2)\d{9}$/),
        ]);
        this.idNumber = 2;
        break;
      case '03':
        this.signupForm.controls.id_number.setValue('');
        this.signupForm.controls.id_number.setValidators([
          Validators.required,
          Validators.maxLength(12),
        ]);
        this.idNumber = 3;
        break;
      case '04':
        this.signupForm.controls.id_number.setValue('');
        this.signupForm.controls.id_number.setValidators([
          Validators.required,
          Validators.maxLength(12),
        ]);
        this.idNumber = 4;
        break;
      default:
        break;
    }
  }

  /**
   * Transliterate Arabic input to English in specific form fields.
   * @param {Event} event - The input event.
   */
  transliteration(event: Event) {
    // Set input value to inputs
    const switchInputs = {
      firstNameArabic: 'eFirstName',
      lastNameArabic: 'eLastName',
      middleNameArabic: 'eMiddleName',
    };
    const target = event.target as HTMLInputElement;
    this.signupForm.controls[switchInputs[target.id]].setValue(
      transliterate(target.value).toUpperCase()
    );
  }

  /**
   * Close the modal.
   */
  close() {
    this.modalService.dismissAll();
  }

  /**
   * Check if a form field is valid and has been touched or submitted.
   * @param {string} field - The form field name.
   * @returns {boolean} True if the field is invalid, false otherwise.
   */
  isFieldValid(field: string) {
    return (
      !this.signupForm.get(field).valid &&
      (this.signupForm.get(field).touched ||
        (this.signupForm.get(field).untouched && this.formSubmitAttempt))
    );
  }

  /**
   * Update the value of the 'dob' field in the form.
   * @param {any} item - The selected date item.
   */
  isFieldValidHijri(item: any) {
    this.signupForm.patchValue({ dob: this.signupForm.get('dob').value });
  }

  /**
   * Validate terms and set the corresponding form control.
   * @param {Event} event - The change event.
   */
  validateTerms(event: Event) {
    const target = event.target as HTMLInputElement;
    this.signupForm.controls.terms.setValue(target.checked ? 'Y' : null);
  }

  /**
   * Change the calendar type (Gregorian or Hijri).
   * @param {string} item - The selected calendar type.
   */
  changCalendar(item: string) {
    if (item === 'H') {
      this.signupForm.patchValue({ dob: this.signupForm.get('dob').value });
      this.signupForm.controls.dateType.setValue('H');
      this.signupForm.controls.dob.setValue('');
      this.minDate = { year: 1300, month: 1, day: 1 };
    } else {
      this.signupForm.controls.dateType.setValue('G');
      this.signupForm.controls.dob.setValue('');
      this.minDate = { year: 1923, month: 1, day: 1 };
    }
  }
}
