// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { ServiceTypeEnum } from "../../core/enums/service-type.enum";

/**
 * Component for displaying closed requests in a datatable.
 * @component
 */
@Component({
  selector: 'app-closed-requests',
  templateUrl: './closed-requests.component.html',
  styleUrls: ['./closed-requests.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class ClosedRequestsComponent implements OnInit {
  /**
   * Reference to the DatatableComponent.
   * @type {DatatableComponent}
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * Enum for column mode in ngx-datatable.
   * @type {ColumnMode}
   */
  ColumnMode = ColumnMode;

  /**
   * Input property for rows of data to be displayed in the datatable.
   * @type {any[]}
   */
  @Input() rows: any[];

  /**
   * Enum for service types.
   * @type {ServiceTypeEnum}
   */
  ServiceType = ServiceTypeEnum;

  /**
   * Constructor for ClosedRequestsComponent.
   */
  constructor() {}

  /**
   * Angular lifecycle hook for initialization logic.
   */
  ngOnInit() {}
}
