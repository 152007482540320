import { Directive, HostListener } from '@angular/core';
import * as screenfull from 'screenfull';

/**
 * Directive to toggle fullscreen mode when an element is clicked.
 * 
 * @example
 * <button appToggleFullscreen>Toggle Fullscreen</button>
 */
@Directive({
  selector: '[appToggleFullscreen]'
})
export class ToggleFullscreenDirective {

  /**
   * Handles the click event on the element to toggle fullscreen mode.
   * 
   * @returns {void}
   */
  @HostListener('click') onClick(): void {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
}
