import { Component, Input, OnInit } from "@angular/core";
import { ORDER_TYPE } from "../../core/enums/order-type.enum";
import { IOrdersCount } from "../../core/interfaces/IDashboard";

/**
 * Component for displaying service orders.
 * @component
 */
@Component({
  selector: 'app-services-orders',
  templateUrl: './services-orders.component.html',
  styleUrls: ['./services-orders.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class ServicesOrdersComponent implements OnInit {
  /**
   * Orders count data for services.
   * @type {IOrdersCount}
   */
  @Input() serviceOrders: IOrdersCount;

  /**
   * Enum for order types.
   * @type {ORDER_TYPE}
   */
  ORDER_TYPE = ORDER_TYPE;

  /**
   * Creates an instance of ServicesOrdersComponent.
   */
  constructor() {}

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {}
}
