import {
    Directive, ElementRef, AfterViewInit,
    Input, HostListener, NgModule
} from '@angular/core';

/**
 * Directive to match the height of child elements within a parent element.
 */
@Directive({
    selector: '[matchHeight]'
})
export class MatchHeightDirective implements AfterViewInit {
    /**
     * Class name of the child elements to match height.
     */
    @Input()
    matchHeight: string;

    /**
     * Constructor to inject ElementRef.
     * @param el ElementRef to access the DOM element.
     */
    constructor(private el: ElementRef) {
    }

    /**
     * Lifecycle hook that is called after the view has been initialized.
     * Calls the matchHeights function after a delay.
     */
    ngAfterViewInit() {
        setTimeout(() => { this.matchHeights(this.el.nativeElement, this.matchHeight) }, 300);
    }

    /**
     * Matches the heights of all child elements with the specified class name within the parent element.
     * @param parent Parent HTML element.
     * @param className Class name of the child elements to match height.
     */
    matchHeights(parent: HTMLElement, className: string) {
        if (!parent) return;

        // Find all the child elements with the selected class name
        const children = parent.getElementsByClassName(className);

        if (!children) return;

        // Reset the height of all child elements
        Array.from(children).forEach((x: HTMLElement) => {
            x.style.height = 'initial';
        });

        // Get all the child elements heights
        const itemHeights = Array.from(children)
            .map(x => x.getBoundingClientRect().height);

        // Find the tallest height
        const maxHeight = itemHeights.reduce((prev, curr) => {
            return curr > prev ? curr : prev;
        }, 0);

        // Update all the child elements to the tallest height
        Array.from(children)
            .forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
    }

    /**
     * HostListener for window resize event.
     * Calls the matchHeights function to adjust heights on window resize.
     */
    @HostListener('window:resize')
    onResize() {
        this.matchHeights(this.el.nativeElement, this.matchHeight);
    }
}

/**
 * NgModule to declare and export MatchHeightDirective.
 */
@NgModule({
    declarations: [MatchHeightDirective],
    exports: [MatchHeightDirective]
})
export class MatchHeightModule { }
