// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GiftStoreService } from "../../core/services/gift-store.service";
import { IGiftStore } from "../../core/interfaces/IGiftStore";

/**
 * Component for displaying order details in a datatable and managing modals.
 * 
 * @example
 * <app-order-details></app-order-details>
 */
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  /**
   * Reference to the DatatableComponent instance.
   * 
   * @type {DatatableComponent}
   */
  @ViewChild(DatatableComponent) detailTable: DatatableComponent;

  /**
   * The current rating value.
   * 
   * @type {number}
   */
  currentRate = 0;

  /**
   * Enum for column mode settings in the datatable.
   * 
   * @type {ColumnMode}
   */
  ColumnMode = ColumnMode;

  /**
   * The currently selected item from the gift store.
   * 
   * @type {IGiftStore}
   */
  item: IGiftStore;

  /**
   * The language setting retrieved from localStorage.
   * 
   * @type {string}
   */
  lang: string;

  /**
   * Constructs the OrderDetailsComponent and initializes services.
   * 
   * @param {NgbModal} modalService - The service to manage modals.
   * @param {GiftStoreService} giftStoreService - The service to interact with the gift store.
   */
  constructor(
    private modalService: NgbModal,
    private giftStoreService: GiftStoreService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Subscribes to changes in the selected item from the gift store service.
   * 
   * @returns {void}
   */
  ngOnInit(): void {
    // Subscribe to changes in the selected item from the gift store service
    this.giftStoreService.item.subscribe(value => {
      if (value) {
        this.item = value;
      }
    });
  }

  /**
   * Method to close the modal.
   * 
   * @returns {void}
   */
  closeModal(): void {
    this.modalService.dismissAll();
  }
}
