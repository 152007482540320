// Importing necessary modules and components from Angular
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-labs',
  templateUrl: './public-labs.component.html',
  styleUrls: ['./public-labs.component.scss'],
})
export class PublicLabsComponent implements OnInit {
  lang: string;
  dir: string;
  sidebarClose: Boolean = false;
  constructor() {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {}

  countChangedHandler(count) {
    this.sidebarClose = count;
  }
}
