// Import necessary modules and dependencies
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Langs } from '../../language.interface';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { MedicalRecordService } from './medical-record.service';
import { decode } from 'app/utils';
import { TrackingService } from 'app/core/services/tracking.service';
import { Invoice } from '@axenda/zatca';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
@Component({
  selector: 'app-medical-record',
  templateUrl: './medical-record.component.html',
  styleUrls: ['./medical-record.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class MedicalRecordComponent implements OnInit {
  // Define component properties and variables
  modalRef?: BsModalRef;
  isAr: boolean;
  lang: string;
  isActiveBtn: boolean = true;
  dateGreaterError: boolean = false;
  dateGreaterThanYear: boolean = false;
  last90DaysData: any;
  pageOfItems: Array<any>;
  historicalData: any;
  recordDetails: any;
  visitDetails: any;
  fromDate: Date;
  toDate: Date;
  visitDate: Date = new Date();
  feedbackData: any;
  req_type = 'تعليق على زيارة للعيادات الخارجية';
  platform = 'الموقع الإلكتروني';
  userId: any;
  invoiceDetails: any;
  imageData: any;
  qrdata: any;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    public _medicalRecordService: MedicalRecordService,
    private _trackingService: TrackingService,
    private spinner: SpinnerService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;
  }

  ngOnInit() {
    this.spinner.show();

    // Initialize component by fetching last 90 days data
    this.getLast90DaysData();

    // Get user ID from decoded token in local storage
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  // Method to generate QR code for an invoice
  async getQrCode() {
    const invoice = new Invoice({
      sellerName: 'Enaya for Home Medical Care',
      vatRegistrationNumber: '300185964100003',
      invoiceTimestamp: this.invoiceDetails.TRS_DATE,
      invoiceTotal: this.invoiceDetails.T_AMOUNT,
      invoiceVatTotal: this.invoiceDetails.VAT_PAT_PAY,
    });
    invoice.toTlv();
    invoice.toBase64();
    this.qrdata = invoice.toBase64();
    this.imageData = await invoice.render();
  }

  // Method to call tracking log
  addTrackingLog() {
    this._trackingService
      .addTrackingLog(this._trackingService.tracking_type.DRAGS, this.userId.id)
      .subscribe((data) => {
        return data;
      });
  }

  // Method to fetch last 90 days data
  getLast90DaysData() {
    this.spinner.show();
    this._medicalRecordService.getLast90DaysData().subscribe(
      (data) => {
        if (data) {
          this.last90DaysData = data;
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  // Method to get historical data for a specific date range
  getHistoricalData(fromDate: any, toDate: any) {
    this.spinner.show();
    this.isActiveBtn = false;
    this.fromDate = fromDate;
    this.toDate = toDate;
    if (toDate.getTime() < fromDate.getTime()) {
      this.dateGreaterError = true;
    } else {
      this.dateGreaterError = false;
    }

    // Check if the date range is greater than 3 years
    if (
      (toDate.getTime() - fromDate.getTime()) / 1000 / 60 / 60 / 24 >
      367 * 3
    ) {
      this.dateGreaterThanYear = true;
    } else {
      this.dateGreaterThanYear = false;
    }

    // Fetch historical data based on date range
    this._medicalRecordService
      .getHistoricalData(
        this.fromDate
          .toLocaleDateString('en-GB')
          .split('/')
          .reverse()
          .join('-'),
        this.toDate.toLocaleDateString('en-GB').split('/').reverse().join('-')
      ).subscribe((data) => {
        if (!this.dateGreaterError && !this.dateGreaterThanYear) {
          this.last90DaysData = data;
          this.spinner.hide();
        } else {
          this.last90DaysData = [];
          this.spinner.hide();
        }
      }, (error) => {
        console.error('Error: ' + error);
        this.spinner.hide();
      });
  }

  // Method to open details modal for a medical record
  openDetailsModal(content: any, id: any) {
    this.modalService.open(content, {
      windowClass: 'details-modal',
    });
    this.recordDetails = this.last90DaysData.find(
      (item) => item.TRS_COUNT === id
    );
    this._medicalRecordService.getVisitDetails(id).subscribe((data) => {
      if (data) {
        this.visitDetails = data;
      }
    });
    window.scrollTo(0, 0);
  }

  // Method to open invoice modal for a medical record
  openInvoiceModal(content1: any, id: any) {
    this.modalService.open(content1, {
      windowClass: 'invoice-modal',
    });
    window.scrollTo(0, 0);
    this._medicalRecordService.getInvoiceDetails(id).subscribe((data) => {
      if (data) {
        this.invoiceDetails = data[0];
        this.getQrCode();
      }
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    window.scrollTo(0, 0);
  }
}
