// Import necessary modules and dependencies
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { decode } from 'app/utils';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // Define common HTTP options for GET requests
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };
  // Define common HTTP options for POST requests
  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };
  constructor(private http: HttpClient) { }
  getPatientData(patCode, mobileNumber) {
    return this.http.get(
      `${environment.apiUrl}/pat/${patCode}/${mobileNumber}`,
      this.httpOptions
    );
  }
  // Method to get private lab reports
  getLabs() {
    return this.http.get(`${environment.apiUrl}/report/private/lab-report`, {
      headers: new HttpHeaders({
        Authorization: `ak ${environment.key}`,
        'Content-Type': 'application/json',
        'jwt-token': decode('token'),
      }),
    });
  }

    /**
  * @description Method to get public lab reports based on patient code and ID card
  * @param patCode
  * @param idCard
  * */
  getLabsPublic(patCode, idCard) {
    return this.http.get(
      `${environment.apiUrl}/report/public/lab-report?patCode=${patCode}&idCard=${idCard}`
    );
  }

  /**
 * @description Method to get private lab details based on various parameters
 * @param drCode
 * @param startDate
 * @param endDate
 * @param servCode
 * */
  getLabDetails(drCode, startDate, endDate, servCode) {
    return this.http.get(
      `${environment.apiUrl}/report/private/lab-report?drCode=${drCode}&startDate=${startDate}&endDate=${endDate}&servCode=${servCode}`,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  /**
 * @description Method to get public lab details based on various parameters
 * @param idCard
 * @param patCode
 * @param drCode
 * @param startDate
 * @param endDate
 * @param servCode
 * */
  getLabDetailsPublic(idCard, patCode, drCode, startDate, endDate, servCode) {
    return this.http.get(
      `${environment.apiUrl}/report/public/lab-report?idCard=${idCard}&patCode=${patCode}&drCode=${drCode}&startDate=${startDate}&endDate=${endDate}&servCode=${servCode}`
    );
  }

  getLabDetailsPrint(drCode, servCode) {
    return this.http.get(
      `${environment.apiUrl}/report/private/lab-report-pdf?drCode=${drCode}&servCode=${servCode}`,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }
}
