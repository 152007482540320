// Import necessary modules from Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { decode } from '../../utils';
import { SnotifyService } from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from "rxjs";
import { FileUploadService } from "./file-upload.service";
import { IGiftStore } from "../interfaces/IGiftStore";
import { ORDER_TYPE } from "../enums/order-type.enum";
import { ITags } from "../interfaces/ITags";

@Injectable({
  providedIn: 'root'
})
export class GiftStoreService {
  // HTTP options with authorization headers
  httpOptions = {
    'method': 'GET',
    headers: new HttpHeaders({
      'Authorization': `ak ${environment.key}`,
      'jwt-token': decode('token')
    })
  };

  // BehaviorSubjects to hold and notify changes for various data
  giftStores: BehaviorSubject<IGiftStore[]> = new BehaviorSubject<IGiftStore[]>(null);
  restaurants: BehaviorSubject<IGiftStore[]> = new BehaviorSubject<IGiftStore[]>(null);
  giftTags: BehaviorSubject<ITags[]> = new BehaviorSubject<ITags[]>(null);
  restaurantTags: BehaviorSubject<ITags[]> = new BehaviorSubject<ITags[]>(null);
  type: ORDER_TYPE;
  item: BehaviorSubject<IGiftStore> = new BehaviorSubject<IGiftStore>(null);

  constructor(private readonly httpClient: HttpClient,
    private readonly snotifyService: SnotifyService,
    private readonly translate: TranslateService,
    private readonly fileUploadService: FileUploadService) {
  }

  /**
 * @description Get the list of gift stores.
 * @returns An array of IGiftStore objects.
 */
  getGiftStores(): IGiftStore[] {
    return this.giftStores.value;
  }

  /**
 * @description Get the list of restaurants.
 * @returns An array of IGiftStore objects representing restaurants.
 */
  getRestaurants(): IGiftStore[] {
    return this.restaurants.value;
  }

  /**
   * @description Get all services orders
   * @param type
   * */
  getAllServicesOrders(type) {
    this.type = type;
    this.httpClient.get(`${environment.paymentApiUrl}/services/order?type=${type}`, this.httpOptions).subscribe(
      (data: any) => {
        if (type === ORDER_TYPE.GIFT_STORE) {
          this.giftStores.next(data.data)
        }
        if (type === ORDER_TYPE.RESTAURANT) {
          this.restaurants.next(data.data)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
   * @description Create tags
   * @param type
   * @param body
   * @param reset
   * */
  addTags(type, body, reset) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.addGiftStoreTags(body, reset)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.addRestaurantTags(body, reset)
    }
  }
  /**
   * @description Selects an item based on its ID, updates the 'item' subject based on the order type.
   * @param {string} id
   * @returns {void}
   */
  selectItem(id) {
    if (this.type === ORDER_TYPE.GIFT_STORE) {
      this.item.next(this.giftStores.value.find(el => el.id === id));
    }
    if (this.type === ORDER_TYPE.RESTAURANT) {
      this.item.next(this.restaurants.value.find(el => el.id === id));
    }
  }

  /**
   * @description Adds tags to a gift store by making a POST request to the corresponding API endpoint.
   * @param {any} body
   * @param {Function} reset
   * @returns {void}
   */
  private addGiftStoreTags(body, reset) {
    this.httpClient.post(`${environment.paymentApiUrl}/gift-store/tag`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        reset();
      },
      (error) => {
        error.error.errors.map(item => {
          this.snotifyService.error(this.translate.instant(`message.${item.message}`), '', {
            position: 'rightTop'
          })
        })
      }
    )
  }

  /**
 * @description Adds tags to a restaurant by making a POST request to the corresponding API endpoint.
 * @param {any} body
 * @param {Function} reset
 * @returns {void}
 */
  private addRestaurantTags(body, reset) {
    this.httpClient.post(`${environment.paymentApiUrl}/restaurant/tag`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        reset();
      },
      (error) => {
        error.error.errors.map(item => {
          this.snotifyService.error(this.translate.instant(`message.${item.message}`), '', {
            position: 'rightTop'
          })
        })
      }
    )
  }

  /**
 * @description Retrieves all tags based on the order type (Gift Store or Restaurant).
 * @param {ORDER_TYPE} type
 * @returns {void}
 */
  getAllTags(type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.getGiftStoreTags();
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.getRestaurantTags();
    }
  }

  /**
 * @description Retrieves tags for a gift store by making a GET request to the corresponding API endpoint.
 * @returns {void}
 */
  private getGiftStoreTags() {
    this.httpClient.get(`${environment.paymentApiUrl}/gift-store/tag`, this.httpOptions).subscribe(
      (data: any) => {
        this.giftTags.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
 * @description Retrieves tags for a restaurant by making a GET request to the corresponding API endpoint.
 * @returns {void}
 */
  private getRestaurantTags() {
    this.httpClient.get(`${environment.paymentApiUrl}/restaurant/tag`, this.httpOptions).subscribe(
      (data: any) => {
        this.restaurantTags.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
 * @description Updates tags information based on the order type (Gift Store or Restaurant).
 * @param {any} body
 * @param {string} id
 * @param {ORDER_TYPE} type
 * @returns {void}
 */
  putTagsInfo(body, id, type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.updateGiftTags(body, id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.updateRestaurantTags(body, id)
    }
  }

  /**
 * @description Updates tags information for a gift store by making a PUT request to the corresponding API endpoint.
 * @param {any} body
 * @param {string} id
 * @returns {void}
 */
  private updateGiftTags(body, id) {
    this.httpClient.put(`${environment.paymentApiUrl}/gift-store/tag/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
 * @description Updates tags information for a restaurant by making a PUT request to the corresponding API endpoint.
 * @param {any} body
 * @param {string} id
 * @returns {void}
 */
  private updateRestaurantTags(body, id) {
    this.httpClient.put(`${environment.paymentApiUrl}/restaurant/tag/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
 * @description Deletes tags based on the order type (Gift Store or Restaurant).
 * @param {string} id - The ID of the tag to be deleted.
 * @param {ORDER_TYPE} type
 * @returns {void}
 */
  deleteTags(id, type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.deleteGiftTags(id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.deleteRestaurantTags(id)
    }
  }

  /**
 * @description Deletes a tag for a gift store by making a DELETE request to the corresponding API endpoint.
 * @param {string} id - The ID of the tag to be deleted.
 * @returns {void}
 */
  private deleteGiftTags(id) {
    this.httpClient.delete(`${environment.paymentApiUrl}/gift-store/tag/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        let tags = this.giftTags.value.filter(item => item.id !== id);
        this.giftTags.next(tags);
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        this.snotifyService.error(this.translate.instant(`message.thisTags`), '', {
          position: 'rightTop'
        });
      }
    )
  }

  /**
 * @description Deletes a tag for a restaurant by making a DELETE request to the corresponding API endpoint.
 * @param {string} id - The ID of the tag to be deleted.
 * @returns {void}
 */
  private deleteRestaurantTags(id) {
    this.httpClient.delete(`${environment.paymentApiUrl}/restaurant/tag/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        let tags = this.restaurantTags.value.filter(item => item.id !== id);
        this.restaurantTags.next(tags);
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        this.snotifyService.error(this.translate.instant(`message.thisTags`), '', {
          position: 'rightTop'
        });
      }
    )
  }

  /**
* @description Creates an image for an item (Gift Store or Restaurant) by making a POST request to the corresponding API endpoint.
* @param {File} file
* @param {ORDER_TYPE} type
* @param {string} id
* @returns {void}
*/
  createImg(file: File, type: ORDER_TYPE, id: string) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.createGiftStoreImg(file, id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.createRestaurantImg(file, id)
    }
  }

  private createGiftStoreImg(file: File, id) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('giftStoreProductId', id);

    this.httpClient.post(`${environment.paymentApiUrl}/gift-store`, formData, this.httpOptions).subscribe(
      (data) => {
        console.log(data)
      },
      (error) => {
        console.log(error)
      })
  }

  /**
 * @description Uploads an image for a restaurant menu to the server.
 * @param file - The File object representing the image to be uploaded.
 * @param id - The unique identifier for the restaurant menu.
 */
  private createRestaurantImg(file: File, id: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurantMenuId', id);

    // Send a POST request to the server to upload the image
    this.httpClient.post(`${environment.paymentApiUrl}/restaurant`, formData, this.httpOptions).subscribe(
      (data) => {
        console.log(data)
      },
      (error) => {
        console.log(error)
      })
  }


  /**
 * @description Deletes an image based on the provided id and type.
 * @param id
 * @param type
 */
  deleteImg(id, type) {
    // Check the type and call the corresponding delete function
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.deleteGiftStoreImg(id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.deleteRestaurantImg(id)
    }
  }

  /**
 * @description Deletes an image associated with a gift store from the server.
 * @param id
 */
  private deleteGiftStoreImg(id) {
    // Send a DELETE request to the server to delete the gift store image
    this.httpClient.delete(`${environment.paymentApiUrl}/gift-store/image/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
 * @description Deletes an image associated with a restaurant from the server.
 * @param id
 */
  private deleteRestaurantImg(id) {
    // Send a DELETE request to the server to delete the restaurant image
    this.httpClient.delete(`${environment.paymentApiUrl}/restaurant/image/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }
}
