import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Langs } from 'app/home/language.interface';
import { LocalService } from 'app/local.service';
import { AgoraService } from '../agora.service';

@Component({
  selector: 'app-patient-appointment',
  templateUrl: './patient-appointment.component.html',
  styleUrls: ['./patient-appointment.component.scss'],
})
export class PatientAppointmentComponent implements OnInit {
  patientAuth = {
    mobile: null,
    doctorCode: null,
    transNo: null,
    profileNo: null,
    arabicName: null,
    englishName: null,
    idNo: null,
  };

  patInfoForm: FormGroup;
  mobilePattern = /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;
  isError = 0;
  labsData: any[];
  patCode: string;
  mobileNumber: string;
  noResult: number;
  lang: string;
  authSuccess: boolean;
  done: boolean;
  requested: boolean;
  isAr: boolean;

  constructor(
    private localService: LocalService,
    private _dataService: AgoraService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;

    this.patInfoForm = new FormGroup({
      idNo: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
      mobileNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(this.mobilePattern),
      ]),
    });

    // Subscribe to query parameters to check for Id & mobile
    this.route.queryParams.subscribe((params) => {
      if (params['Id'] && params['Id'].trim() !== null) {
        this.patInfoForm.controls.idNo.setValue(params['Id']);
      }
      if (params['mobile'] && params['mobile'].trim() !== null) {
        this.patInfoForm.controls.mobileNumber.setValue(params['mobile']);
      }
    });
  }

  ngOnInit() {
    this.localService.removeData('srd');
    this.localService.removeData('srp');
  }

  // Function to retrieves patient data using the entered ID number and mobile number from the patient information form.
  getPatientData() {
    let idNo = this.toEnglishDigits(
      this.patInfoForm.controls['idNo'].value.trim()
    );
    let mobileNumber = this.toEnglishDigits(
      this.patInfoForm.controls['mobileNumber'].value.trim()
    );
    this._dataService
      .patientAuth({
        idNo: idNo,
        mobile: mobileNumber,
      })
      .subscribe(
        (r) => {
          if (r) {
            this.patientAuth.mobile = this.patInfoForm.controls[
              'mobileNumber'
            ].value
              .toString()
              .trim();
            this.patientAuth.idNo = this.patInfoForm.controls['idNo'].value
              .toString()
              .trim();
            this.patientAuth.doctorCode = r.DR_CODE.toString().trim();
            this.patientAuth.arabicName = r.PAT_ANAME.toString().trim();
            this.patientAuth.englishName = r.PAT_ENAME.toString().trim();
            this.patientAuth.profileNo = r.PAT_CODE.toString().trim();
            this.patientAuth.transNo = r.TRS_COUNT.toString().trim();
            this.localService.setJsonValue('srp', this.patientAuth);
            this.authSuccess = true;
            this.requested = true;
            this.done = true;
            setTimeout(() => {
              this.router.navigate(['e-services/UDHLive/doctor-room']);
            }, 3000);
          } else {
            this.authSuccess = false;
            this.done = false;
            this.requested = true;
          }
        },
        (err) => {
          this.authSuccess = false;
          this.done = false;
          this.requested = true;
        }
      );
  }

  // Function to converts Arabic-Indic digits in a string to English-Indic digits.
  toEnglishDigits(str) {
    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    let e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  }
}
