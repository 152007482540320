// Importing necessary Angular modules and services
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { decode } from 'app/utils';
import swal from 'sweetalert2';

@Component({
  selector: 'app-hyper-complete',
  templateUrl: './hyper-complete.component.html',
  styleUrls: ['./hyper-complete.component.scss'],
})
export class HyperCompleteComponent implements OnInit {
  transCount: any;
  invoiceData: any;
  params: any;
  userId: any;
  cardType: any;
  cardSerial: any;
  transId: any;
  lang: any;
  hyperData: any;
  paymentDate: any;

  /**
   * Constructor to initialize the HyperCompleteComponent.
   * @param apiHandler - ApiHandlerService for handling API requests.
   * @param router - Router for navigation.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param spinner - SpinnerService for showing and hiding spinner.
   * @param translate - TranslateService for language translation.
   */
  constructor(
    private apiHandler: ApiHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) {
    // Subscribe to route query parameters
    this.route.queryParams.subscribe((params) => {
      if (params) {
        // Extracting transaction count from query parameters
        const params = this.route.snapshot.paramMap;
        params.keys.forEach((key) => {
          const value = +params.get(key);
          if (!isNaN(value)) {
            this.transCount = value;
            return;
          }
        });
        console.log(this.transCount);
        this.getInvoiceDetails();
      } else {
        this.router.navigate(['e-services']);
      }
    });
    // Checking if hyper-response is present in localStorage
    if (localStorage.getItem('hyper-response')) {
      // Parsing hyper-response from localStorage
      this.hyperData = JSON.parse(localStorage.getItem('hyper-response'));
      console.log(this.hyperData);
      this.cardType = this.hyperData.cardBrand;
      this.cardSerial = this.hyperData.cardSerial;
      this.paymentDate = this.hyperData.transactionDate;
      this.transId = this.hyperData.transId;
      this.transId = this.transId.substring(0, this.transId.indexOf('.') + 0);
    } else {
      this.hyperData = null;
    }
  }

  ngOnInit() {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  /**
   * Method to fetch invoice details based on transaction count.
   */
  getInvoiceDetails() {
    this.spinner.show();
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.transCount }).subscribe(
      (invoice) => {
        this.invoiceData = invoice;
        const idNumber: string = this.invoiceData.customer_id;
        // Masking part of customer ID for privacy
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
        this.spinner.hide();
      },
      (err) => {
        // Error handling if invoice details retrieval fails
        this.spinner.hide();
        swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  /**
   * Navigate to the home page.
   */
  goToHome() {
    this.router.navigate(['/e-services']);
  }

  /**
   * Method to print the current page.
   */
  printPage() {
    window.print();
  }

  /**
   * Method to share the invoice on WhatsApp.
   */
  shareWhatsapp() {
    const link = window.location.href;
    const message = `*عناية متجددة للعناية المنزلية*\nيمكنكم معاينة الفاتورة من الرابط أدناه:\n${link}`;

    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    window.location.href = url;
  }

  /**
   * HostListener to remove hyper-response from localStorage before unloading the page.
   * @param event - The beforeunload event.
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    localStorage.removeItem('hyper-response');
  }
}
