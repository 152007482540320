import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Langs } from 'app/home/language.interface';
import { LocalService } from 'app/local.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sesson-rate',
  templateUrl: './sesson-rate.component.html',
  styleUrls: ['./sesson-rate.component.scss'],
})
export class SessionRateComponent implements OnInit, OnDestroy {
  doctorRate = 1;
  connectionRate = 5;
  lang: string;
  isAr: boolean;
  saved: boolean;

  constructor(private localService: LocalService, private router: Router) {
    if (!this.localService.getJsonValue('srp')) {
      this.router.navigate(['e-services/UDHLive']);
    }
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;
  }

  ngOnInit() {}

  // Remove 'srp' data from local storage before unloading the page
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.localService.removeData('srp');
  }

  // Remove 'srp' data from local storage when the component is destroyed
  ngOnDestroy() {
    this.localService.removeData('srp');
  }

  // Save the rate and remove 'srp' data from local storage
  saveRate() {
    this.localService.removeData('srp');
    this.saved = true;
    setTimeout(() => {
      window.location.href = 'https://enaya.med.sa/';
    }, 2000);
  }
}
