import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { SnotifyService } from 'ng-snotify';
import { StorageService } from '../../../storage.service';
import { decode, encode, remove } from '../../../utils';
import { UserType } from '../../../core/enums/user-type.enum';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  next: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  regUser: any;
  forgotPassUdhId: any;
  forgotPassSmsCode: any;
  user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  lastPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  verifyPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  setNewPasswordPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private isUserAuthenticated = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly snotifyService: SnotifyService,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private translate: TranslateService
  ) {
    this.currentUser = new BehaviorSubject<any>(decode('currentUser'));
  }

  public get currentUserValue() {
    return this.currentUser.value;
  }

  signup(body, val?) {
    this.regUser = body;
    this.httpClient
      .post(`${environment.paymentApiUrl}/auth/register-patient`, body, {
        headers: new HttpHeaders({
          // 'Authorization': `ak ${environment.key}`,
          'Content-Type': 'application/json',
        }),
      })
      .subscribe(
        (data) => {
          this.next.next(true);
        },
        (error) => {
          if (
            error.error.statusCode === 409 &&
            error.error.message !== 'User already exists'
          ) {
            this.snotifyService.error(
              this.translate.instant('auth.idLengthError'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
          }
          if (
            error.error.statusCode === 409 &&
            error.error.message === 'User already exists'
          ) {
            this.snotifyService.error(
              this.translate.instant('auth.userFoundError'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
          }
          if (
            error.error.statusCode === 403 &&
            error.error.message === 'User found but not confirmed'
          ) {
            this.snotifyService.error(
              this.translate.instant('auth.userFoundError'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
          }
          if (
            error.error.statusCode === 403 &&
            error.error.message !== 'User found but not confirmed'
          ) {
            this.verifyPage.next(true);
            this.snotifyService.error(
              this.translate.instant('auth.userFoundError'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
            if (!val) {
              this.next.next(true);
            }
          }
          if (error.error.statusCode === 404) {
            this.snotifyService.error(
              this.translate.instant('auth.incorrectCredentials'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
          }
        }
      );
  }

  setNextBtn(val) {
    this.next.next(val);
  }

  resend(val) {
    this.forgotPassword({ udhId: this.regUser.id_number });
  }

  verification(body) {
    body = {
      ...body,
      udhId: this.regUser.id_number
        ? this.regUser.id_number
        : this.forgotPassUdhId,
    };
    this.regUser = {
      ...this.regUser,
      passcode: body.passcode,
    };
    this.forgotPassSmsCode = body.passcode;
    this.httpClient
      .post(`${environment.paymentApiUrl}/auth/verify-passcode`, body)
      .subscribe(
        (data: any) => {
          if (data) {
            this.snotifyService.success(
              this.translate.instant('auth.passcodeSuccess'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
            this.user.next(data.data);
            this.next.next(true);
            this.verifyPage.next(false);
            this.setNewPasswordPage.next(true);
          }
        },
        (error) => {
          this.snotifyService.error(
            this.translate.instant('auth.passcodeError'),
            '',
            {
              position: 'rightTop',
              timeout: 4000,
              pauseOnHover: true,
            }
          );
        }
      );
  }

  setPassword(body) {
    body = {
      ...body,
      udhId: this.regUser.id_number
        ? this.regUser.id_number
        : this.forgotPassUdhId,
      passcode: this.regUser.passcode
        ? this.regUser.passcode
        : this.forgotPassSmsCode,
    };

    this.httpClient
      .patch(`${environment.paymentApiUrl}/auth/set-password`, body)
      .subscribe(
        (data: any) => {
          this.snotifyService.success(data.message, '', {
            position: 'rightTop',
            timeout: 4000,
            pauseOnHover: true,
          });
          this.next.next(true);
          this.setNewPasswordPage.next(false);
          this.lastPage.next(true);
        },
        (error) => {
          this.snotifyService.error(error.error.message, '', {
            position: 'rightTop',
            timeout: 4000,
            pauseOnHover: true,
          });
        }
      );
  }

  login(body) {
    this.httpClient
      .post(`${environment.paymentApiUrl}/auth/login`, body, {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
        }),
      })
      .subscribe(
        (data: any) => {
          if (data.user) {
            encode('currentUser', data.user);
            if (data.access_token) {
              encode('token', data.access_token);
            }
            this.currentUser.next(data.user);
            this.checkUserRole(data.user);
          }

          this.snotifyService.success(
            this.translate.instant('auth.loggedIn'),
            '',
            {
              position: 'rightTop',
              timeout: 4000,
              pauseOnHover: true,
            }
          );
          this.isUserAuthenticated.next(true);
          window.location.reload();
        },
        (error) => {
          this.snotifyService.error(
            this.translate.instant('auth.incorrectCredentials'),
            '',
            {
              position: 'rightTop',
              timeout: 4000,
              pauseOnHover: true,
            }
          );
        }
      );
  }

  logout() {
    remove('currentUser');
    remove('token');
    this.currentUser.next(null);
    this.isUserAuthenticated.next(false);
    this.router.navigate(['/']).then(() => window.location.reload());
  }
  get isUserAuthenticated$() {
    return this.isUserAuthenticated.asObservable();
  }
  forgotPassword(body: any, isForgotPassModal: boolean = false) {
    this.regUser = body;
    this.httpClient
      .post(`${environment.paymentApiUrl}/auth/forgot-password`, body, {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
        }),
      })
      .subscribe(
        (data: any) => {
          this.snotifyService.success(
            this.translate.instant('auth.smsSent'),
            '',
            {
              position: 'rightTop',
              timeout: 4000,
              pauseOnHover: true,
            }
          );

          this.resetPassword.next(false);
          this.verifyPage.next(true);
          if (isForgotPassModal === true) {
            this.next.next(true);
            this.forgotPassUdhId = body.udhId;
          }
        },
        (error) => {
          if (error.error.statusCode === 404) {
            this.snotifyService.error(
              this.translate.instant('auth.userNotFoundError'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
          } else {
            this.snotifyService.error(
              this.translate.instant('auth.smsError'),
              '',
              {
                position: 'rightTop',
                timeout: 4000,
                pauseOnHover: true,
              }
            );
          }
        }
      );
  }

  private checkUserRole(user) {
    if (user) {
      const role = user.userType || UserType.Patient;
      if (
        role === UserType.Admin ||
        role === UserType.CustomerServices ||
        role === UserType.DepartmentsManagers
      ) {
        this.router.navigate(['/admin/dashboard']);
      } else if (role === UserType.EnayaStoreManagement) {
        this.router.navigate(['/admin/gift-store-management/orders']);
      } else if (role === UserType.RestaurantManagement) {
        this.router.navigate(['/admin/restaurant-management/orders']);
      } else if (role === UserType.GalleryManagement) {
        this.router.navigate(['/admin/gallery-management/all']);
      }
    }
  }
}
