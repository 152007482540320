import { Component, OnInit } from '@angular/core';
import { Langs } from '../../language.interface';
import { UserService } from 'app/core/services/user.service';
import { TrackingService } from 'app/core/services/tracking.service';
import { decode } from 'app/utils';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss'],
})
export class AllergiesComponent implements OnInit {
  isAr: boolean;
  lang: string;
  allergies: any;
  userId: any;
  allergiesList: any;
  constructor(
    private _userService: UserService,
    private _trackingService: TrackingService,
    private spinner: SpinnerService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;
  }

  ngOnInit() {
    this.spinner.show();
    // this._userService.getPatientInfo()
    //  this.allergies = this._userService.patient.subscribe(data =>{
    //   this.allergies = data
    //  })

    // Fetch allergies data from the user service
    this._userService.getAllergies().subscribe(
      (data: any) => {
        if (data) {
          this.allergies = data
          this.allergiesList = [];
          if (data.length > 0 && data[0].D_ALLERGY) {
            this.allergiesList = data[0].D_ALLERGY.split(', ')
          }
        }
        this.spinner.hide()
      }, (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
    this.addTrackingLog();
  }

  // Call the Tracking Service to add a tracking log for allergies
  addTrackingLog() {
    this._trackingService
      .addTrackingLog(
        this._trackingService.tracking_type.ALLERGIES,
        this.userId.id
      )
      .subscribe((data) => {
        return data;
      });
  }
}
