import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, ICustomerInvoice } from '../payment.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Invoice } from '@axenda/zatca';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  invoiceId: any;
  invoiceData: any;
  payLoader: boolean;
  qrData: string;
  lang: string;
  imageData: any;
  payOptionsModel: any;

  /**
   * Constructor to initialize the CheckoutComponent.
   * @param modalService - NgbModal for opening modals.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param router - Router for navigation.
   * @param translate - TranslateService for language translation.
   * @param paymentService - PaymentService for handling payment-related API calls.
   * @param apiHandler - ApiHandlerService for handling API requests.
   * @param location - Location for accessing browser location.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService,
    private apiHandler: ApiHandlerService,
    private location: Location
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    /* Extracts the invoiceId from route parameters and fetches invoice details.
    If no valid invoiceId is found, it navigates to 'e-services'.*/
    this.route.params.subscribe(
      (params) => {
        this.invoiceId = params.invoiceId;
        if (!this.invoiceId || this.invoiceId.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceDetails();
        }
      },
      (err) => {
        this.router.navigate(['e-services']);
      }
    );
  }

  ngOnInit() {
    this.getQrCode();
  }

  /**
   * Fetches QR code for the invoice.
   */
  async getQrCode() {
    const invoice = new Invoice({
      sellerName: 'Enaya for Home Medical Care',
      vatRegistrationNumber: '300185964100003',
      invoiceTimestamp: this.invoiceData.trs_date,
      invoiceTotal: this.invoiceData.totals.totalinvoice,
      invoiceVatTotal: this.invoiceData.totals.totalvat,
    });
    invoice.toTlv();
    invoice.toBase64();
    this.imageData = await invoice.render();
  }

  /**
   * Fetches invoice details.
   */
  getInvoiceDetails() {
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.invoiceId }).subscribe(
      (invoice) => {
        this.invoiceData = invoice;
        this.getQrCode();
        const idNumber: string = this.invoiceData.customer_id;
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      },
      (err) => {
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  /**
   * Navigates to invoice details.
   */
  goToDetails() {
    this.router.navigate([`payments/invoices/${this.invoiceId}`]);
  }

  /**
   * Opens a modal for payment options.
   * @param content - The modal content.
   */
  placeOrder(content) {
    if (this.invoiceId && this.invoiceData) {
      this.payOptionsModel = this.modalService.open(content, {
        centered: true,
        size: 'lg',
        backdrop: false,
        windowClass: 'payment-window-cls',
      });
    }
  }

  /**
   * Methods to call Telr payment page API.
   */
  getTelrPaymentPage() {
    const order: ICustomerInvoice = {
      customerIdNumber: this.invoiceData.idNumber,
      customerMobileNumber: this.invoiceData.mobileNumber,
      customerProfileNumber: this.invoiceData.profileNumber,
      transCount: this.invoiceData.transCount,
    };
    this.paymentService.getTelrPaymentPage(order).subscribe((newOrder) => {
      if (newOrder && newOrder.order) {
        window.location.href = newOrder.order.url;
      }
    });
  }

  /**
   * Methods to call HyperPay payment page API.
   */
  getHyperpayPaymentPage() {
    const order: ICustomerInvoice = {
      customerIdNumber: this.invoiceData.idNumber,
      customerMobileNumber: this.invoiceData.mobileNumber,
      customerProfileNumber: this.invoiceData.profileNumber,
      transCount: this.invoiceData.transCount,
    };
    this.paymentService.getHyperPayPaymentPage(order).subscribe((newOrder) => {
      if (newOrder && newOrder.checkoutId) {
        this.router.navigate(['payments/pay/' + newOrder.checkoutId]);
      }
    });
  }

  /**
   * Navigates back to the previous page.
   */
  goBack() {
    this.location.back();
    this.router.navigate(['payments/invoices/' + this.invoiceId]);
  }
}
