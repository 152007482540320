import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { decode } from 'app/utils';
import swal from 'sweetalert2';

/**
 * Component to handle the completion of a payment process.
 * @component
 */
@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent implements OnInit {
  /**
   * Transaction count.
   * @type {any}
   */
  transCount: any;

  /**
   * Invoice data.
   * @type {any}
   */
  invoiceData: any;

  /**
   * Parameters from the route.
   * @type {any}
   */
  params: any;

  /**
   * User ID.
   * @type {any}
   */
  userId: any;

  /**
   * Card type used in the transaction.
   * @type {any}
   */
  cardType: any;

  /**
   * Card serial number used in the transaction.
   * @type {any}
   */
  cardSerial: any;

  /**
   * Transaction ID.
   * @type {any}
   */
  transId: any;

  /**
   * Current language setting.
   * @type {any}
   */
  lang: any;

  /**
   * Constructor to inject necessary services and initialize the component.
   * @param {ActivatedRoute} route - The activated route service to access route parameters.
   * @param {Router} router - The router service for navigation.
   * @param {TranslateService} translate - The translation service.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   * @param {SpinnerService} spinner - The spinner service.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private apiHandler: ApiHandlerService,
    private spinner: SpinnerService
  ) {
    // Subscribe to query parameters to check for numberValue
    this.route.queryParams.subscribe(
      (params) => {
        if (params) {
          // Extract numberValue from query parameters
          const params = this.route.snapshot.paramMap;
          let numberValue: number | null = null;
          params.keys.forEach((key) => {
            const value = +params.get(key);
            if (!isNaN(value)) {
              numberValue = value;
              return;
            }
          });
          // If valid numberValue, make API call
          if (numberValue !== null) {
            this.apiHandler
              .telrPaymentCallback(numberValue)
              .subscribe((data: any) => {
                if (data) {
                  this.transCount = data.transCount;
                  this.cardSerial = data.cardSerial;
                  // Mask the card serial for security
                  this.cardSerial
                    ? (this.cardSerial = this.cardSerial.replace(
                      this.cardSerial.substring(0, 12),
                      '**** **** **** '
                    ))
                    : (this.cardSerial = null);
                  this.cardType = data.cardBrand;
                  this.transId = data.transId;
                  this.getInvoiceDetails();
                }
              });
          } else {
            this.router.navigate(['e-services']);
          }
        }
      },
      (error) => {
        // Handle error during subscription
        console.log('Error=========>', error);
        this.router.navigate(['e-services']);
      }
    );
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Sets the language and user ID from localStorage.
   * @returns {void}
   */
  ngOnInit(): void {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  /**
   * Fetches invoice details using the transaction count.
   * @returns {void}
   */
  getInvoiceDetails(): void {
    this.spinner.show();
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.transCount }).subscribe(
      (invoice) => {
        // Process invoice data
        this.invoiceData = invoice;
        const idNumber: string = this.invoiceData.customer_id;
        // Mask part of the customer ID for security
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
        this.spinner.hide();
      },
      (err) => {
        // Handle error during invoice retrieval
        this.spinner.hide();
        swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  /**
   * Navigates to the home page.
   * @returns {void}
   */
  goToHome(): void {
    this.router.navigate(['/e-services']);
  }

  /**
   * Navigates to the payment details page.
   * @returns {void}
   */
  goToDetails(): void {
    this.router.navigate([`payments/checkout/${this.transCount}`]);
  }

  /**
   * Prints the current page.
   * @returns {void}
   */
  printPage(): void {
    window.print();
  }

  /**
   * Shares the current page link via WhatsApp.
   * @returns {void}
   */
  shareWhatsapp(): void {
    // Prepare WhatsApp message
    const link = window.location.href;
    const message = `*عناية متجددة للعناية المنزلية*\nيمكنكم معاينة الفاتورة من الرابط أدناه:\n${link}`;
    // Create WhatsApp URL and trigger navigation
    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    window.location.href = url;
  }
}
