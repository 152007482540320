import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { decode } from 'app/utils';
import Swal from 'sweetalert2';

/**
 * Component to handle errors during the payment process.
 * @component
 */
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  /**
   * Transaction count.
   * @type {any}
   */
  transCount: any;

  /**
   * Invoice data.
   * @type {any}
   */
  invoiceData: any;

  /**
   * User ID.
   * @type {any}
   */
  userId: any;

  /**
   * Payment status.
   * @type {any}
   */
  paymentStatus: any;

  /**
   * Current language setting.
   * @type {any}
   */
  lang: any;

  /**
   * Constructor to inject necessary services and initialize the component.
   * @param {ActivatedRoute} route - The activated route service to access route parameters.
   * @param {Router} router - The router service for navigation.
   * @param {TranslateService} translate - The translation service.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private apiHandler: ApiHandlerService
  ) {
    // Subscribe to route parameters to get transaction count
    this.route.params.subscribe(
      (params) => {
        this.transCount = params.transCount;
        if (!this.transCount || this.transCount.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceDetails();
        }
      },
      (err) => {
        this.router.navigate(['e-services']);
      }
    );
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Sets the language and user ID from localStorage.
   * @returns {void}
   */
  ngOnInit(): void {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
    if (localStorage.getItem('payment_error_status')) {
      this.paymentStatus = localStorage.getItem('payment_error_status');
    }
  }

  /**
   * Retrieves invoice details using the API handler.
   * @returns {void}
   */
  getInvoiceDetails(): void {
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.transCount }).subscribe(
      (invoice) => {
        this.invoiceData = invoice;
        const idNumber: string = this.invoiceData.customer_id;
        // Mask part of the customer ID for security
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      },
      (err) => {
        // If update is unsuccessful, show error message using SweetAlert
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  /**
   * Navigates to the home page.
   * @returns {void}
   */
  goToHome(): void {
    this.router.navigate(['/e-services']);
  }

  /**
   * Navigates to the payment details page.
   * @returns {void}
   */
  goToDetails(): void {
    this.router.navigate([`payments/checkout/${this.transCount}`]);
  }

  /**
   * Navigates to the place order page.
   * @returns {void}
   */
  placeOrder(): void {
    this.router.navigate([`payments/invoices/${this.transCount}`]);
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Removes the 'payment_error_status' key from the localStorage.
   * @returns {void}
   */
  ngOnDestroy(): void {
    localStorage.removeItem('payment_error_status');
  }
}
