import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Interface representing a clinic.
 */
export interface Clinics {
  /**
   * Clinic code.
   * @type {string}
   */
  CLIN_CODE: string;

  /**
   * Clinic name in Arabic.
   * @type {string}
   */
  CLIN_ANAME: string;

  /**
   * Clinic name in English.
   * @type {string}
   */
  CLIN_ENAME: string;
}

/**
 * Interface representing a doctor.
 */
export interface Doctors {
  /**
   * Doctor code.
   * @type {string}
   */
  DOC_CODE: string;

  /**
   * Doctor name in Arabic.
   * @type {string}
   */
  DOC_ANAME: string;

  /**
   * Doctor name in English.
   * @type {string}
   */
  DOC_ENAME: string;

  /**
   * Web service identifier.
   * @type {string}
   */
  WEB_SER: string;
}

/**
 * Class representing a user's choice.
 */
export class UserChoice {
  /**
   * Selected clinic.
   * @type {Clinics}
   */
  public selectedClinic: Clinics;

  /**
   * Selected doctor.
   * @type {Doctors}
   */
  public selectedDoctor: Doctors;

  /**
   * Selected date.
   * @type {NgbDateStruct}
   */
  public selectedDate: NgbDateStruct;

  /**
   * Selected time.
   * @type {string}
   */
  public selectedTime: string;

  /**
   * Reservation type.
   * @type {string}
   */
  public reservationType: string;
}
