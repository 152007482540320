// Import necessary Angular modules and services
import { Component, Injectable, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  NgbDateStruct, NgbModal, NgbCalendar,
  NgbDatepickerI18n,
  NgbCalendarIslamicUmalqura
} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

// Retrieve the language setting from localStorage
let lang = localStorage.getItem('lang');

// Define arrays for weekdays and months
let WEEKDAYS = [];
let MONTHS = [];

// Set weekdays and months based on the selected language
if (lang === 'en') {
  WEEKDAYS = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  MONTHS = [
    "Muharram",
    "Safar",
    "Rabi al-Awwal",
    "Rabi al-Thani",
    "Jumada al-Awwal",
    "Jumada al-Thani",
    "Rajab",
    "Shaban",
    "Ramadan",
    "Shawwal",
    "Dhul Qadah",
    "Dhul Hijjah",
  ];
} else {
  WEEKDAYS = ["الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت", "الأحد"];
  MONTHS = [
    "محرم",
    "صفر",
    "ربيع الأول",
    "ربيع الآخر",
    "جمادى الأولى",
    "جمادى الآخرة",
    "رجب",
    "شعبان",
    "رمضان",
    "شوال",
    "ذو القعدة",
    "ذو الحجة"
  ];
}

/**
 * Custom internationalization service for the NgbDatepicker
 * @extends NgbDatepickerI18n
 */
@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  constructor() {
    super();
  }

  /**
   * Gets the short name for the weekday.
   * @param {number} weekday - The weekday number.
   * @returns {string} The short name of the weekday.
   */
  getWeekdayShortName(weekday: number): string {
    return WEEKDAYS[weekday - 1];
  }

  /**
   * Gets the short name for the month.
   * @param {number} month - The month number.
   * @returns {string} The short name of the month.
   */
  getMonthShortName(month: number): string {
    return MONTHS[month - 1];
  }

  /**
   * Gets the full name for the month.
   * @param {number} month - The month number.
   * @returns {string} The full name of the month.
   */
  getMonthFullName(month: number): string {
    return MONTHS[month - 1];
  }

  /**
   * Gets the aria label for the day.
   * @param {NgbDateStruct} date - The date structure.
   * @returns {string} The aria label for the day.
   */
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

/**
 * Component for the Hijri calendar.
 * @component
 */
@Component({
  selector: 'app-hijri-calender',
  templateUrl: './hijri-calender.component.html',
  styleUrls: ['./hijri-calender.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n },
  ]
})
export class HijriCalenderComponent implements OnInit {
  /**
   * The inner value of the component.
   * @type {string}
   */
  innerValue: string;

  /**
   * Language setting.
   * @type {string}
   */
  @Input() lang: string;

  /**
   * Form group for the sign-up form.
   * @type {FormGroup}
   */
  @Input() public signupForm: FormGroup;

  /**
   * Event emitter for validating the Hijri field.
   * @type {EventEmitter<any>}
   */
  @Output() isFieldValidHijri = new EventEmitter();

  constructor() { }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @returns {void}
   */
  ngOnInit(): void { }

  /**
   * Validates the Hijri field and emits the value.
   * @param {string} field - The field to validate.
   * @returns {void}
   */
  validFieldHijri(field: string): void {
    if (this.signupForm.get('dob') && this.signupForm.get('dob').value) {
      this.isFieldValidHijri.emit(this.signupForm.get('dob').value);
    }
  }
}
