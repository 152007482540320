// Importing necessary modules and components from Angular and third-party libraries
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { decode } from 'app/utils';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  // HTTP options with authorization header
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  /** BehaviorSubject to hold random blood sugar data */
  randomBloodSugar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /** BehaviorSubject to hold fasting blood sugar data */
  fastingBloodSugar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /** BehaviorSubject to hold cholesterol data */
  cholesterol: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /** BehaviorSubject to hold vitamin D data */
  vitaminD: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /** BehaviorSubject to hold triglycerides data */
  triglycerides: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient) {}

  // Getter methods for accessing the latest values of health data
  getRandomData() {
    return this.randomBloodSugar.value;
  }
  getFastingData() {
    return this.fastingBloodSugar.value;
  }
  getCholesterolData() {
    return this.cholesterol.value;
  }
  getVitaminDData() {
    return this.vitaminD.value;
  }
  getTriglyceridesData() {
    return this.triglycerides.value;
  }

  /**
   * Fetches random blood sugar data for a specific patient.
   * @param {string} patient - The ID or unique identifier of the patient.
   */
  getRandomBloodSugar(patient) {
    this.httpClient
      .get(
        `https://app.enaya.med.sa/api/v1/report?service=${72044}&patient=${patient}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.randomBloodSugar.next(data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Fetches fasting blood sugar data for a specific patient.
   * @param {string} patient - The ID or unique identifier of the patient.
   */
  getFastingBloodSugar(patient) {
    this.httpClient
      .get(
        `https://app.enaya.med.sa/api/v1/report?service=${72042}&patient=${patient}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.fastingBloodSugar.next(data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Fetches Cholesterol data for a specific patient.
   * @param {string} patient - The ID or unique identifier of the patient.
   */
  getCholesterol(patient) {
    this.httpClient
      .get(
        `https://app.enaya.med.sa/api/v1/report?service=${72029}&patient=${patient}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.cholesterol.next(data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Fetches Vitamin D data for a specific patient.
   * @param {string} patient - The ID or unique identifier of the patient.
   */
  getVitaminD(patient) {
    this.httpClient
      .get(
        `https://app.enaya.med.sa/api/v1/report?service=${93539}&patient=${patient}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.vitaminD.next(data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Fetches Triglycerides data for a specific patient.
   * @param {string} patient - The ID or unique identifier of the patient.
   */
  getTriglycerides(patient) {
    this.httpClient
      .get(
        `https://app.enaya.med.sa/api/v1/report?service=${72066}&patient=${patient}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.triglycerides.next(data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Retrieves vital signs data from the server.
   * @returns {Observable} Observable containing vital signs data.
   */
  getVitalSigns() {
    return this.httpClient.get(`${environment.paymentApiUrl}/enaya/visits`, {
      headers: new HttpHeaders({
        Authorization: `ak ${environment.key}`,
        'Content-Type': 'application/json',
        'jwt-token': decode('token'),
      }),
    });
  }

  /**
   * Retrieves patient appointments within a specified date range.
   * @param {string} startDate 
   * @param {string} endDate
   * @returns {Observable} Observable containing patient appointments data.
   */
  getPatientAppointments(startDate, endDate) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/book/appointments/specific-patient?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }
}
