import { RouteInfo } from './sidebar.metadata';
import { AuthService } from '../../pages/content-pages/login/auth.service';
import { routes } from '../routes';
import { decode } from 'app/utils';

/**
 * Current user information decoded from token.
 * @type {any}
 */
const currentUser = decode('currentUser');

/**
 * Array of route information objects for the sidebar menu.
 * @constant {RouteInfo[]}
 */
export const ROUTES: RouteInfo[] = [
  {
    path: currentUser ? '/e-services' : '/e-public',
    title: !currentUser ? 'الخدمات الإلكترونية' : 'user.dashboard',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    role: [0, 3],
    type: '',
  },
  /*{
      path: '/e-services/UDHLive',
      title: 'onlineSessions.title',
      icon: 'icon-camcorder',
      class: '',
      badge: 'جديد',
      badgeClass: 'badge badge-pill badge-danger mt-1',
      isExternalLink: false,
      submenu: [],
      type: ''
    },*/

  // {
  //   path: '',
  //   title: 'المواعيد',
  //   icon: 'icon-clock',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   type: '',
  //   submenu: [],
  // },
  {
    path: '/e-services/reservations',
    title: 'حجز جديد',
    icon: 'icon-plus',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  {
    path: currentUser ? '/e-services/orders' : '/e-public/orders',
    title: 'upcomingAppointments',
    icon: 'icon-calendar',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3],
  },
  {
    path: '/e-services/my-reservations',
    title: 'إدارة حجوزاتي',
    icon: 'icon-calendar',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  {
    path: '/e-services/invoices',
    title: 'invoices.',
    icon: 'icon-credit-card',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  {
    path: '/e-services/medical-record',
    title: 'medicalRecord',
    icon: 'icon-folder-alt',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  {
    path: '/e-services/allergies',
    title: 'allergies.allergies',
    icon: 'icon-magnifier-remove',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  {
    path: '/e-services/vital-signs',
    title: 'medicalRecords.vitalSignsIndicatorsHistory',
    icon: 'icon-folder-alt',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  // {
  //   path: '',
  //   title: 'التقارير الطبية',
  //   icon: 'icon-docs',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   type: '',
  //   submenu: [
  //   ],
  // },
  {
    path: currentUser ? '/e-services/labs' : '/e-public/labs',
    title: 'نتائج المختبر',
    icon: 'icon-docs',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3],
  },
  {
    path: currentUser ? '/e-services/radiology' : '/e-public/radiology',
    title: 'تقارير الأشعة',
    icon: 'icon-doc',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3],
  },
  {
    path: '/e-services/medical-files',
    title: 'public.fileCenter',
    icon: 'icon-docs',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [3],
  },
  {
    path: '/e-services/user-management/edit-information',
    title: 'sidenav.editInformation',
    icon: 'icon-note',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [3],
    type: '',
    submenu: [],
  },
  {
    path: '/e-services/user-management/change-password',
    title: 'sidenav.changePassword',
    icon: 'icon-lock-open',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [3],
    type: '',
    submenu: [],
  },
  // Admin Modules
  {
    path: routes.ADMIN.DASHBOARD,
    title: 'sidenav.dashboard',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 6, 7],
    type: '',
    submenu: [],
  },
  {
    path: routes.ADMIN.SERVICES_LIST,
    title: 'sidenav.servicesList',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [6, 7],
    type: '',
    submenu: [],
  },
  {
    path: routes.ADMIN.ADVANCED_REPORTS,
    title: 'sidenav.advancedReports',
    icon: 'icon-folder-alt',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 6, 7],
    type: '',
    submenu: [],
  },
  {
    path: routes.ADMIN.PATIENTS_REQUESTS,
    title: 'sidenav.patientRequests',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [],
    type: '',
    submenu: [],
  },
  {
    path: routes.ADMIN.USER_MANAGEMENT,
    title: 'sidenav.userManagement',
    icon: 'icon-users',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 2, 4, 5, 6, 7],
    type: '',
    submenu: [
      {
        path: routes.ADMIN.USER_MANAGEMENT_ADD,
        title: 'sidenav.addNewUser',
        icon: 'icon-user-follow',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1],
        type: '',
        submenu: [],
      },
      {
        path: routes.ADMIN.USER_MANAGEMENT_ALL,
        title: 'sidenav.usersList',
        icon: 'icon-user-follow',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1],
        type: '',
        submenu: [],
      },
      {
        path: routes.ADMIN.CHANGE_PASSWORD,
        title: 'sidenav.changePassword',
        icon: 'icon-lock-open',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2, 4, 5, 6, 7],
        type: '',
        submenu: [],
      },
      {
        path: routes.ADMIN.EDIT_INFORMATION,
        title: 'sidenav.editInformation',
        icon: 'icon-note',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2, 4, 5, 6, 7],
        type: '',
        submenu: [],
      },
    ],
  },
  // {
  //   path: routes.ADMIN.GALLERY_MANAGEMENT,
  //   title: 'sidenav.galleryManagement',
  //   icon: 'icon-layers',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   role: [1, 5],
  //   type: '',
  //   submenu: [
  //     {
  //       path: routes.ADMIN.GALLERY_MANAGEMENT_ADD,
  //       title: 'sidenav.addPicture',
  //       icon: 'icon-puzzle',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       role: [1, 5],
  //       type: '',
  //       submenu: []
  //     },
  //     {
  //       path: routes.ADMIN.GALLERY_MANAGEMENT_ALL,
  //       title: 'sidenav.pictureList',
  //       icon: 'icon-picture',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       role: [1, 5],
  //       type: '',
  //       submenu: []
  //     },
  //   ],
  // },

  {
    path: routes.ADMIN.ENAYA_STORE_MANAGEMENT,
    title: 'sidenav.enayaStoreManagement',
    icon: 'icon-present',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 2, 6],
    type: '',
    submenu: [
      // {
      //   path: routes.ADMIN.GIFT_ORDERS,
      //   title: 'sidenav.orders',
      //   icon: 'icon-puzzle',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   role: [1, 2, 6],
      //   type: '',
      //   submenu: []
      // },
      {
        path: routes.ADMIN.ENAYA_PRODUCTS_SETUP,
        title: 'sidenav.productsSetup',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2],
        type: '',
        submenu: [],
      },
      {
        path: routes.ADMIN.GIFT_TAGS_SETUP,
        title: 'sidenav.tags',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [],
        type: '',
        submenu: [],
      },
      {
        path: routes.ADMIN.GIFT_TAGS_LIST,
        title: 'sidenav.tags_list',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [],
        type: '',
        submenu: [],
      },
    ],
  },
  {
    path: routes.ADMIN.DOCTORS_MANAGEMENT,
    title: 'sidenav.doctorManagement',
    icon: 'icon-users',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 2, 6],
    type: '',
    submenu: [
      {
        path: routes.ADMIN.DOCTOR_SETUP,
        title: 'sidenav.doctorSetup',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1],
        type: '',
        submenu: [],
      },
    ],
  },
  // {
  //   path: routes.ADMIN.RESTAURANT_MANAGEMENT,
  //   title: 'sidenav.restaurantManagement',
  //   icon: 'icon-cup',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   role: [1, 4, 6],
  //   type: '',
  //   submenu: [
  //     {
  //       path: routes.ADMIN.RESTAURANT_ORDERS,
  //       title: 'sidenav.orders',
  //       icon: 'icon-puzzle',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       role: [1, 4, 6],
  //       type: '',
  //       submenu: []
  //     },
  //     {
  //       path: routes.ADMIN.RESTAURANT_PRODUCTS_SETUP,
  //       title: 'sidenav.productsSetup',
  //       icon: 'icon-puzzle',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       role: [1, 4],
  //       type: '',
  //       submenu: []
  //     },
  //     {
  //       path: routes.ADMIN.RESTAURANT_TAGS_SETUP,
  //       title: 'sidenav.tags',
  //       icon: 'icon-puzzle',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       role: [1, 4],
  //       type: '',
  //       submenu: []
  //     },
  //     {
  //       path: routes.ADMIN.RESTAURANT_TAGS_LIST,
  //       title: 'sidenav.tags_list',
  //       icon: 'icon-puzzle',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       role: [1, 4],
  //       type: '',
  //       submenu: []
  //     },
  //   ],
  // },

  {
    path: '',
    title: 'auth.logout',
    icon: 'icon-logout',
    action: {
      type: 'logout',
      action: (auth: AuthService) => {
        auth.logout();
      },
    },
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: 'logout',
    role: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    path: '/e-services',
    title: 'auth.login',
    icon: 'icon-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0],
  },
];
