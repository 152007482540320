import { Component, Input, OnInit } from "@angular/core";

/**
 * Component to display the latest client comments.
 * 
 * @example
 * <app-latest-client-comments [comments]="clientComments"></app-latest-client-comments>
 */
@Component({
  selector: 'app-latest-client-comments',
  templateUrl: './latestClientComments.component.html',
  styleUrls: ['./latestClientComments.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class LatestClientCommentsComponent implements OnInit {
  /**
   * Array of comments to be displayed.
   * 
   * @type {any[]}
   */
  @Input() comments: any[];

  /**
   * The language setting retrieved from localStorage.
   * 
   * @type {string}
   */
  lang: string;

  /**
   * Constructs the LatestClientCommentsComponent and sets the language.
   */
  constructor() {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * 
   * @returns {void}
   */
  ngOnInit(): void {
  }
}
