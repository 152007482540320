// Import necessary Angular modules and services
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../login/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component for handling ID number related functionality.
 * @component
 */
@Component({
  selector: 'app-id-number',
  templateUrl: './id-number.component.html',
  styleUrls: ['./id-number.component.scss'],
})
export class IdNumberComponent implements OnInit {
  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Direction setting (ltr/rtl).
   * @type {string}
   */
  dir: string;

  /**
   * Form group for ID number.
   * @type {FormGroup}
   */
  idForm: FormGroup;

  /**
   * Constructor to initialize dependencies and set language and direction.
   * @param {AuthService} authService - The authentication service.
   * @param {NgbModal} modalService - The modal service.
   */
  constructor(
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    // Initialize the form group with form controls
    this.idForm = new FormGroup({
      udhId: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Method to handle the "Forgot Password" functionality.
   */
  forgotPassword() {
    const body = {
      udhId: this.idForm.controls.udhId.value,
    };
    // Call the authService's forgotPassword method with the request body
    this.authService.forgotPassword(body, true);
  }

  /**
   * Method to close the modal.
   */
  close() {
    this.modalService.dismissAll();
  }
}
