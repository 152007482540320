// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderDetailsComponent } from "../order-details/order-datails.component";
import { GiftStoreService } from "../../core/services/gift-store.service";

/**
 * Component to display and manage a table of orders.
 */
@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
})
export class OrdersTableComponent implements OnInit {
  /**
   * Input property to receive the list of items to display in the table.
   */
  @Input() items: any;

  /**
   * ViewChild decorator to get a reference to the DatatableComponent in the template.
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * Enum-like object for setting column modes.
   */
  ColumnMode = ColumnMode;

  /**
   * Constructor to inject necessary services.
   * @param {NgbModal} modalService - Service to manage modals.
   * @param {GiftStoreService} giftStoreService - Service to manage gift store data.
   */
  constructor(private modalService: NgbModal, private giftStoreService: GiftStoreService) {}

  /**
   * Lifecycle hook called after the component's view has been initialized.
   */
  ngOnInit() {}

  /**
   * Opens the order detail modal.
   * @param {Event} event - The triggering event.
   * @param {string} id - The ID of the order to display details for.
   * @param {any} firstChild - The first child element, used for blurring parent elements.
   */
  openOrderDetailModal(event: Event, id: string, firstChild: any) {
    // Prevent event propagation to avoid unwanted side effects
    event.stopPropagation();

    // If the firstChild is present, blur its parent elements
    if (firstChild && firstChild.parentElement && firstChild.parentElement.parentElement) {
      firstChild.parentElement.parentElement.blur();
    }

    // Open the OrderDetailsComponent modal, centered and with a large size
    this.modalService.open(OrderDetailsComponent, { centered: true, size: 'lg' });

    // Call the selectItem method of the GiftStoreService with the order ID
    this.giftStoreService.selectItem(id);
  }
}
