import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor() {}

  // Function to adds an event to the user's calendar with the specified details.
  addEventToCalendar(
    title: string,
    startDate: Date,
    endDate: Date,
    description: string,
    location: string
  ) {
    const calendarEvent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `DTSTART:${startDate.toISOString().replace(/-|:|\.\d+/g, '')}`,
      `DTEND:${endDate.toISOString().replace(/-|:|\.\d+/g, '')}`,
      `SUMMARY:${title}`,
      `DESCRIPTION:${description}`,
      `LOCATION:${location}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');

    const calendarUrl =
      'data:text/calendar;charset=utf-8,' + encodeURIComponent(calendarEvent);

    const downloadLink = document.createElement('a');
    downloadLink.href = calendarUrl;
    downloadLink.setAttribute('download', 'event.ics');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
