import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../login/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  lang: string;
  resetPasswordForm: FormGroup;

  constructor(
    private readonly authService: AuthService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  ngOnInit() {
    // Initialize the form group with 'id' form control and validation
    this.resetPasswordForm = new FormGroup({
      id: new FormControl('', [Validators.required])
    });
  }

  // Method to handle sending ID for password reset
  sendId() {
    const body = {
      udhId: this.resetPasswordForm.get('id').value
    };
    this.authService.forgotPassword(body);
  }
}
