import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { ChartistModule } from 'ng-chartist';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchHeightModule } from "../shared/directives/match-height.directive";

import { EcommerceComponent } from "./eCommerce/eCommerce.component";
import { AnalyticsComponent } from "./analytics/analytics.component";


@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartistModule,
    NgbModule,
    MatchHeightModule
  ],
  declarations: [
    EcommerceComponent,
    AnalyticsComponent
  ],
  providers: [],
  exports: [
    EcommerceComponent
  ]
})
export class DashboardModule { }
