// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { decode } from '../../utils';
import { AuthService } from '../../pages/content-pages/login/auth.service';

/**
 * Component for e-services functionality.
 * @class
 * @implements {OnInit}
 */
@Component({
  selector: 'app-e-services',
  templateUrl: './e-services.component.html',
  styleUrls: ['./e-services.component.scss']
})
export class EServicesComponent implements OnInit {
  /** Language setting (e.g., 'en' for English). */
  lang: string;
  
  /** Direction setting ('ltr' for left-to-right or 'rtl' for right-to-left). */
  dir: string;

  /** Current user information. */
  user: any;

  /**
   * Constructor to initialize services.
   * @param {TranslateService} translate - Service for translation functionality.
   * @param {AuthService} authService - Service for authentication functionality.
   */
  constructor(public translate: TranslateService, private authService: AuthService) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = (this.lang == "en") ? 'ltr' : 'rtl';
    this.authService.currentUser.subscribe(val => {
      this.user = val;
    });

    // Decode and set user information from the stored token
    this.user = decode('currentUser');
  }

  /**
   * OnInit lifecycle hook to perform component initialization.
   */
  ngOnInit() {
    // Dynamically load a JavaScript file (utils.js) from the assets directory
    $.getScript('./assets/js/utils.js');

    // Adjust styling based on the language (adding or removing a class)
    if (this.lang == 'en') {
      $('.media-body i').addClass('smallf');
    } else {
      $('.media-body i').removeClass('smallf');
    }
  }
}
