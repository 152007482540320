// Importing necessary Angular modules and services
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../labs/api.service';
import * as moment from 'moment-timezone';
import { observable } from 'rxjs';
import { decode } from 'app/utils';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { environment } from 'environments/environment';
import { ApiHandlerService } from 'app/core/services/api-handler.service';

@Component({
  selector: 'app-lab-details',
  templateUrl: './lab-details.component.html',
  styleUrls: ['./lab-details.component.scss'],
})
export class LabDetailsComponent implements OnInit {
  mobileNumber: string;
  patCode: string;
  labCode: string;
  patName: string;
  servCode: string;
  drCode: string;
  startDate: any;
  endDate: any;
  labResults: any[];
  labinfo: Object;
  isError = 0;
  today = new Date();
  userId: any;
  lang: string;
  idCard: any;
  patInfo: any;
  getLabDetail: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private data: ApiService,
    private spinner: SpinnerService,
    private apiHandler: ApiHandlerService
  ) {
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ar';
  }
  ngOnInit() {
    // Displaying a spinner while data is being fetched
    this.spinner.show();

    // Checking if patient information is available in localStorage and decoding it
    if (localStorage.getItem('patInfo')) {
      this.patInfo = decode('patInfo');
    }
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }

    // Extracting query parameters from the route
    this.route.queryParamMap.subscribe((queryParams) => {
      this.drCode = queryParams.get('drCode');
      this.startDate = queryParams.get('startDate');
      this.endDate = queryParams.get('endDate');
      this.servCode = queryParams.get('servCode');
    });
    if (localStorage.getItem('currentUser')) {
      this.data
        .getLabDetails(this.drCode, this.startDate, this.endDate, this.servCode)
        .subscribe(
          (r) => {
            if (r['status'] == 'success') {
              this.isError = 0;
              this.spinner.hide();
              this.labResults = [...r['data']].sort((a, b) => a.NROW - b.NROW);
              let temp: any[];
              if (r['data']) {
                temp = [...r['data']];
                for (let i = 0; i < temp.length; i++) {
                  if (
                    temp[i]['SERV_CODE'].toString().trim() ==
                    this.servCode.trim()
                  ) {
                    this.labinfo = temp[i];
                    this.labinfo['DATE_BIRTH'] = moment(
                      this.labinfo['DATE_BIRTH']
                    ).from(moment(), true);
                    this.labinfo['DENOTED_DATE'] = moment(
                      this.labinfo['DENOTED_DATE']
                    ).format('YYYY-MM-DD');
                  }
                }
              }
            }
          },
          (error) => {
            this.isError = 1;
            this.spinner.hide();
          },
          () => { }
        );
    } else {
      // Fetching lab details for public users
      this.data
        .getLabDetailsPublic(
          this.patInfo.mobileNumber,
          this.patInfo.profileNo,
          this.drCode,
          this.startDate,
          this.endDate,
          this.servCode
        )
        .subscribe(
          (r) => {
            if (r['status'] == 'success') {
              this.spinner.hide();
              this.isError = 0;
              this.labResults = [...r['data']].sort((a, b) => a.NROW - b.NROW);
              let temp: any[];
              if (r['data']) {
                temp = [...r['data']];
                for (let i = 0; i < temp.length; i++) {
                  if (
                    temp[i]['SERV_CODE'].toString().trim() ==
                    this.servCode.trim()
                  ) {
                    this.labinfo = temp[i];
                    this.labinfo['DATE_BIRTH'] = moment(
                      this.labinfo['DATE_BIRTH']
                    ).from(moment(), true);
                    this.labinfo['DENOTED_DATE'] = moment(
                      this.labinfo['DENOTED_DATE']
                    ).format('YYYY-MM-DD');
                  }
                }
              }
            }
          },
          (error) => {
            this.isError = 1;
            this.spinner.hide();
          },
          () => { }
        );
    }
  }

  // Method to open WhatsApp with a predefined message containing the lab result link
  openWhatsApp() {
    const link = window.location.href;
    const message = `*عناية متجددة للعناية المنزلية*\nيمكنكم معاينة نتيجة المختبر من خلال الرابط أدناه:\n${link}`;

    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    window.location.href = url;
  }

  // Function to retrieve and open a lab report PDF for printing.
  getPrintLabDetails() {
    this.getLabDetail = true;
    let data = {
      drCode: this.drCode,
      servCode: this.servCode,
      lang: this.lang ? this.lang : 'ar'
    }

    this.apiHandler.getReportsPrint('lab-report-pdf', data).subscribe((r: any) => {
      if (r.data) {
        window.open(`${environment.apiUrl.replace('/api/v1','')}/${r.data}`, '_blank');
      }
      this.getLabDetail = false;
    }, (error) => {
      this.getLabDetail = false;
      console.log("error", error);
    },
      () => { })
  }
}
