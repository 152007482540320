// Importing necessary modules and components from Angular and third-party libraries
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { decode } from 'app/utils';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  // Define HTTP options for API requests
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      Authorization: `ak ${environment.key}`,
      'jwt-token': decode('token'),
    }),
  };

  // BehaviorSubject to notify subscribers about changes in invoice data
  invoiceDataChange: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  invoice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private translate: TranslateService,
    private readonly snotifyService: SnotifyService
  ) { }

  get invoiceData() {
    return this.invoiceDataChange.value;
  }

  // Method to fetch invoice data based on various parameters
  getInvoiceData(invoiceNo?: any, mrn?: any, idOrIqam?: any) {
    this.isLoading.next(true);
    let url = '';
    // Build URL based on provided parameters
    if (invoiceNo) {
      url +=
        url === '' ? `transCount=${invoiceNo}` : `&transCount=${invoiceNo}`;
    }
    if (mrn) {
      url += url === '' ? `profileNumber=${mrn}` : `&profileNumber=${mrn}`;
    }
    if (idOrIqam) {
      url += url === '' ? `idNumber=${idOrIqam}` : `&idNumber=${idOrIqam}`;
    }

    // Make API request to fetch invoice data
    this.httpClient
      .get(
        `${environment.paymentApiUrl}/payments/invoice?${url}`,
        this.httpOptions
      )
      .subscribe(
        (data: any) => {
          this.isLoading.next(false);
          if (data.length === 1) {
            // If only one invoice is returned, open it in a new window
            this.invoice.next(data[0]);
            let invoiceIdString = data[0].qrCode.substring(
              data[0].qrCode.indexOf('QrCode/') + 7,
              data[0].qrCode.indexOf('.png')
            );
            // window.open(`/p/${data[0].transCount}`, '_blank')
            window.open(`payments/invoices/${invoiceIdString}`, '_blank');
          } else {
            this.invoiceDataChange.next(data);
            this.router.navigate(['/e-services/invoices']);
          }
        },
        (error) => {
          this.isLoading.next(false);
          const err = this.translate.instant('invoices.errors.invoiceNotFound');

          // Show error notification using SnotifyService
          this.snotifyService.error(err, '', {
            position: 'rightTop',
          });
        }
      );
  }

  // Method to get all invoices with optional status parameter
  getAllInvoices(status) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/invoices/get-all-invoice${status ? `?PAID_STATUS=${status}` : ''
      }`,
      this.httpOptions
    );
  }

  // Method to get details of a specific invoice using transaction count
  getInvoiceDetails(trsCount) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/invoices/invoice-trs?trsCount=${trsCount}`,
      this.httpOptions
    );
  }
}
