// Import Angular core and other necessary modules
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalService } from '../../../local.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Langs } from '../../language.interface';
import { TranslateService } from '@ngx-translate/core';
import { InvoicesService } from './invoices.service';
import { AuthService } from '../../../pages/content-pages/login/auth.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { decode } from '../../../utils';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  // Initialize form group and other variables
  invoiceForm: FormGroup;
  isError = 0;
  patCode: string;
  mobileNumber: string;
  noResult: number;
  lang: string;
  done: boolean;
  isAr: boolean;
  isLoading: boolean;
  user: any;

  // Constructor to initialize services and set initial values
  constructor(
    private localService: LocalService,
    private _invoiceService: InvoicesService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private authService: AuthService,
    private spinner: SpinnerService
  ) {
    // Initialize language and other flags
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;
    this.isLoading = false;

    // Initialize the form group with form controls
    this.invoiceForm = new FormGroup({
      invoiceNo: new FormControl(null),
      mrn: new FormControl(null),
      idOrIqam: new FormControl(null, [Validators.required, Validators.minLength(1), this.noWhitespaceValidator]),
    }, this.requireOneControl());

    // Decode user information from token and fetch invoice data if user is logged in
    this.user = decode('currentUser');
    if (this.user) {
      this.getInvoiceData('', this.user.mrn);
    }
  }

  // Custom validator to ensure at least one form control has a value
  private requireOneControl() {
    return formGroup => {
      if (
        (formGroup.get('invoiceNo').value === '' && formGroup.get('mrn').value === null) ||
        (formGroup.get('mrn').value === '' && formGroup.get('invoiceNo').value === null) ||
        (formGroup.get('mrn').value === '' && formGroup.get('invoiceNo').value === '')
      ) {
        return { required: this.translate.instant('invoices.required') };
      }
      return null;
    };
  }

  // OnInit lifecycle hook
  ngOnInit() {
    // Remove unnecessary data from local service
    this.localService.removeData('srd');
    this.localService.removeData('srp');

    // Subscribe to isLoading property changes in the InvoicesService
    this._invoiceService.isLoading.subscribe(val => {
      this.isLoading = val;
    });
  }

  // Method to fetch invoice data based on form input
  getInvoiceData(no?, mrNumber?) {
    const invoiceNo = this.invoiceForm.controls['invoiceNo'].value;
    const mrn = this.invoiceForm.controls['mrn'].value;
    const idOrIqam = this.invoiceForm.controls['idOrIqam'].value;

    if (no || mrNumber) {
      this._invoiceService.getInvoiceData(no, mrNumber);
    } else {
      this._invoiceService.getInvoiceData(invoiceNo, mrn, idOrIqam);
    }
  }

  // Custom validator to check for whitespace in a form control
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
