import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";
import {ServiceTypeEnum} from "../../core/enums/service-type.enum";

/**
 * Component for displaying in-patient data in a datatable.
 * @component
 */
@Component({
  selector: 'app-in-patient',
  templateUrl: './inPatient.component.html',
  styleUrls: ['./inPatient.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class InPatientComponent implements OnInit {
  /**
   * Reference to the DatatableComponent.
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * Column mode for the datatable.
   */
  ColumnMode = ColumnMode;

  /**
   * Rows of data to be displayed in the datatable.
   */
  @Input() rows: any[];

  /**
   * Total number of in-patient requests.
   */
  @Input() totalRequest: number;

  /**
   * Overall satisfaction rating for in-patient services.
   */
  @Input() overallSatisfaction: number;

  /**
   * Enumeration for service types.
   */
  ServiceType = ServiceTypeEnum;

  /**
   * Language setting from localStorage.
   */
  lang: string;

  /**
   * Constructor to set the language from localStorage.
   */
  constructor() {
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
  }
}
