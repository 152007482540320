// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../core/services/user.service';
import { IEditInformation } from '../../../core/interfaces/IUser';
import Swal from 'sweetalert2';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { environment } from 'environments/environment';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

/**
 * Component for editing user information.
 * @component
 */
@Component({
  selector: 'app-edit-information',
  templateUrl: './edit-information.component.html',
  styleUrls: ['./edit-information.component.scss'],
})
export class EditInformationComponent implements OnInit {
  /** @type {string} */
  lang: string;
  /** @type {FormGroup} */
  form: FormGroup;
  /** @type {RegExp} */
  mobilePattern = /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;
  /** @type {any} */
  patientInfo: any;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  /** @type {Object} */
  options = {
    types: [],
    componentRestrictions: { country: 'SA' },
  };
  /** @type {any} */
  latitude: any;
  /** @type {any} */
  longitude: any;
  /** @type {any} */
  title_address: any;
  /** @type {any} */
  addressFormat: any;
  /** @type {any} */
  addressLink: any;
  /** @type {number} */
  zoom = 17;
  /** @type {any} */
  geoData: any;

  /**
   * Constructor to initialize services.
   * @param {FormBuilder} fb - FormBuilder service to create forms.
   * @param {UserService} userService - UserService to interact with user-related data.
   * @param {SpinnerService} spinner - SpinnerService to show and hide loading spinner.
   */
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private spinner: SpinnerService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Initializes the component, sets up the form, and subscribes to user data.
   */
  ngOnInit() {
    this.spinner.show();
    this.form = this.fb.group({
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
        ],
      ],
      email: ['', [Validators.email]],
      formattedAddress: [''],
      city: [
        '',
        [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ],
      ],
      district: [
        '',
        [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ],
      ],
      address: [
        '',
        [
          Validators.maxLength(200),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ],
      ],
      phone1: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        ],
      ],
      phone2: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        ],
      ],
      guardianName: [
        '',
        [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ],
      ],
      guardianMobile: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ],
      ],
      guardianRelation: [
        '',
        [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ],
      ],
      smsPref: ['2'],
      whatsappPref: ['1'],
    });

    this.userService.getPatientInfo();

    // Subscribe to the patient observable.
    this.userService.patient.subscribe(
      (val) => {
        if (val) {
          this.form.patchValue({
            mobile: val[0].MOBILE !== (null || 'null') ? val[0].MOBILE : '',
            email: val[0].EMAIL !== (null || 'null') ? val[0].EMAIL : '',
            formattedAddress:
              val[0].FORMATTED_ADDRESS !== (null || 'null')
                ? val[0].FORMATTED_ADDRESS
                : '',
            city: val[0].CITY !== (null || 'null') ? val[0].CITY : '',
            district: val[0].DIST !== (null || 'null') ? val[0].DIST : '',
            address: val[0].ADDRESS !== (null || 'null') ? val[0].ADDRESS : '',
            phone1: val[0].PHONE1 !== (null || 'null') ? val[0].PHONE1 : '',
            phone2: val[0].PHONE2 !== (null || 'null') ? val[0].PHONE2 : '',
            guardianName:
              val[0].KIN_NAME !== (null || 'null') ? val[0].KIN_NAME : '',
            guardianMobile:
              val[0].KIN_TEL !== (null || 'null') ? val[0].KIN_TEL : '',
            guardianRelation:
              val[0].KIN_REL !== (null || 'null') ? val[0].KIN_REL : '',
            smsPref: val[0].SMS_AE !== (null || 'null') ? val[0].SMS_AE : '2',
            whatsappPref:
              val[0].WHATSAPP_OP !== (null || 'null')
                ? val[0].WHATSAPP_OP
                : '1',
          });
          this.patientInfo = val[0];
          if (this.patientInfo) {
            this.latitude = parseFloat(this.patientInfo.GPS_LAT);
            this.longitude = parseFloat(this.patientInfo.GPS_LNG);
            this.addressFormat = this.patientInfo.FORMATTED_ADDRESS;
            this.addressLink = this.patientInfo.GPS_LINK;
          }
          this.spinner.hide();
        }
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  /**
   * Function to handle the change event when the user selects or modifies an address.
   * @param {Address} address - The address selected or modified by the user.
   */
  public handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.addressFormat = address.formatted_address;
    this.addressLink =
      `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.replace(
        /\s/g,
        '+'
      );
    console.log(this.addressLink);
  }

  /**
   * Function to handle location coordinates.
   * @param {any} x - The location object containing latitude and longitude.
   */
  location(x) {
    this.latitude = x.coords.lat;
    this.longitude = x.coords.lng;
    this.addressFormat = '';
  }

  /**
   * Function to set the current device location.
   */
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.addressLink =
          `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.toLowerCase();
        this.addressFormat = '';
        this.formattedAddress.reset();
      });
    }
  }

  get mobile() {
    return this.form.get('mobile');
  }
  get email() {
    return this.form.get('email');
  }
  get formattedAddress() {
    return this.form.get('formattedAddress');
  }
  get city() {
    return this.form.get('city');
  }
  get district() {
    return this.form.get('district');
  }
  get address() {
    return this.form.get('address');
  }
  get phone1() {
    return this.form.get('phone1');
  }
  get phone2() {
    return this.form.get('phone2');
  }
  get guardianName() {
    return this.form.get('guardianName');
  }
  get guardianMobile() {
    return this.form.get('guardianMobile');
  }
  get guardianRelation() {
    return this.form.get('guardianRelation');
  }
  get smsPref() {
    return this.form.get('smsPref');
  }
  get whatsappPref() {
    return this.form.get('whatsappPref');
  }

  /**
   * Clears the form by resetting all fields.
   */
  clear = () => {
    this.form.reset();
  };

  /**
   * Handles the form submission.
   */
  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const body: any = {
      MOBILE: this.mobile.value,
      EMAIL: this.email.value && this.email.value,
      FORMATTED_ADDRESS: this.addressFormat,
      CITY: this.city.value,
      DIST: this.district.value,
      ADDRESS: this.address.value,
      PHONE1: this.phone1.value,
      PHONE2: this.phone2.value,
      KIN_NAME: this.guardianName.value,
      KIN_TEL: this.guardianMobile.value,
      KIN_REL: this.guardianRelation.value,
      SMS_AE: this.smsPref.value ? this.smsPref.value : '2',
      WHATSAPP_OP: this.whatsappPref.value ? this.whatsappPref.value : '1',
      GPS_LINK: this.addressLink,
      GPS_LAT: this.latitude,
      GPS_LNG: this.longitude,
    };

    try {
      // Attempt to update patient information using userService
      this.userService.updatePatientInfo(body).subscribe((data) => {
        if (data) {
          // If update is successful, show success message using SweetAlert
          Swal({
            title: this.lang == 'en' ? 'Edited Successfully' : 'تم التعديل بنجاح',
            text: this.lang == 'en'
              ? 'Profile information has been modified successfully'
              : 'تم تعديل معلومات الملف الشخصي بنجاح',
            confirmButtonText: this.lang == 'en' ? 'Done' : 'تم',
            type: 'success',
          });

          // After successful update, retrieve and refresh patient information
          this.userService.getPatientInfo();
        } else {
          // If update is unsuccessful, show error message using SweetAlert
          Swal({
            title: this.lang == 'en' ? 'Error' : 'خطأ',
            text: this.lang == 'en'
              ? 'Something went wrong, please try again later'
              : 'حدث خطأ, يرجى المحاولة فى وقت لاحق',
            confirmButtonText: this.lang == 'en' ? 'Done' : 'تم',
            type: 'warning',
          });
        }
      });
    } catch (error) {
      // Catch any errors that occur during the update process and show a generic error message
      Swal({
        title: this.lang == 'en' ? 'Error' : 'خطأ',
        text: this.lang == 'en'
          ? 'Something went wrong, please try again later'
          : 'حدث خطأ, يرجى المحاولة فى وقت لاحق',
        confirmButtonText: this.lang == 'en' ? 'Done' : 'تم',
        type: 'warning',
      });
    }
  }

  /**
   * Function to handle key presses for alphanumeric characters.
   * @param {KeyboardEvent} event - The keyboard event.
   * @returns {boolean} - Whether the key press is allowed.
   */
  keyPressAlphanumeric(event: KeyboardEvent): boolean {
    // Get the character corresponding to the key code of the pressed key
    const input = String.fromCharCode(event.keyCode);

    // Check if the input character is a valid alphanumeric character or Arabic letters
    if (/[a-zA-Z-ا-ي-ؤ-ئ-ء]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}