// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit } from "@angular/core";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { TranslateService } from "@ngx-translate/core";
import { SnotifyService } from "ng-snotify";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ORDER_TYPE } from "../../core/enums/order-type.enum";
import { GiftStoreService } from "../../core/services/gift-store.service";
import { ITags } from "../../core/interfaces/ITags";
import { IProducts, IPutProducts } from "../../core/interfaces/IProducts";
import { ProductsSetupService } from "../../core/services/products-setup.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiHandlerService } from "app/core/services/api-handler.service";

/**
 * Component for handling product modal operations.
 * @component
 */
@Component({
  selector: 'app-products-modal',
  templateUrl: './products-modal.component.html',
  styleUrls: ['./products-modal.component.scss']
})
export class ProductsModalComponent implements OnInit {
  /**
   * Input property to receive the type of the product.
   * @type {ORDER_TYPE}
   */
  @Input() public type;

  files: File[] = [];
  tagItems: ITags[] = [];
  selectedTagItems = [];
  lang: string;
  item: IProducts;

  form: FormGroup;
  ORDER_TYPE = ORDER_TYPE;
  visitsNumberIncludedOradb = false;
  requireMidOrDoctOradb = false;
  isActiveOradb = false;
  isEserviceOradb = false;
  acceptOffersOradb = false;
  acceptCouponsOradb = false;
  popUpName = 'Text area';
  inputText = '';
  editorOpenedModel: any

  // Configuration options for the rich text editor.
  config = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'style', 'height', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color', 'ul', 'ol', 'paragraph']],
      ['insert', ['table', 'link', 'hr', 'codeview', 'undo', 'redo']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '18', '24', '36', '44', '56', '64', '76', '84', '96'],
    fontNames: ['Arial', 'Times New Roman', 'Inter', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'MangCau', 'BayBuomHep', 'BaiSau', 'BaiHoc', 'CoDien', 'BucThu', 'KeChuyen', 'MayChu', 'ThoiDai', 'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    codeviewFilter: true,
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  }

  /**
   * Constructor with injected services and dependencies.
   * @param {TranslateService} translate - Service for translation.
   * @param {SnotifyService} snotifyService - Service for notifications.
   * @param {NgbModal} modalService - Service for handling modals.
   * @param {GiftStoreService} giftService - Service for gift store operations.
   * @param {ApiHandlerService} ApiHandlerService - Service for API handling.
   * @param {ProductsSetupService} productsSetupService - Service for product setup operations.
   * @param {FormBuilder} fb - Service for building reactive forms.
   */
  constructor(private translate: TranslateService,
    private snotifyService: SnotifyService,
    private modalService: NgbModal,
    private giftService: GiftStoreService,
    private ApiHandlerService: ApiHandlerService,
    private productsSetupService: ProductsSetupService,
    private fb: FormBuilder) {
    this.lang = localStorage.getItem('lang')
  }

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    // Initialize the reactive form group with form controls.
    this.form = this.fb.group({
      serviceMktAr: ['', [Validators.maxLength(80)]],
      serviceMktEn: ['', [Validators.maxLength(80)]],
      serviceMktExcerptAr: ['', []],
      serviceMktExcerptEn: ['', []],
      serviceWpEnName: ['', []],
      serviceWpArName: ['', []],
      serviceWpEnRow: ['', []],
      serviceWpArRow: ['', []],
      serviceMktDescAr: ['', []],
      serviceMktDescEn: ['', []],
      serviceMktNoteAr: ['', []],
      serviceMktNoteEn: ['', []],
      serviceVisitsDescAr: ['', []],
      serviceVisitsDescEn: ['', []],
      serviceMktIcon: ['', [Validators.maxLength(300)]],
      serviceMktImage: ['', [Validators.maxLength(300)]],
      showOnline: ['', [Validators.maxLength(300)]],
      scheduledType: [false, []]
    })

    // Subscribe to gift tags or restaurant tags based on the product type.
    if (this.type === ORDER_TYPE.GIFT_STORE) {
      this.giftService.giftTags.subscribe(val => {
        if (val) {
          this.tagItems = val;
        }
      })
    }
    if (this.type === ORDER_TYPE.RESTAURANT) {
      this.giftService.restaurantTags.subscribe(val => {
        if (val) {
          this.tagItems = val;
        }
      })
    }

    // Subscribe to product item changes.
    this.productsSetupService.productsItem.subscribe(val => {
      if (val && val.serviceCodeOradb) {
        // Fetch additional information for the product.
        this.productsSetupService.getEnayaserviceProduct(val.serviceCodeOradb).subscribe((data: any) => {
          if (data && data.length > 0) {
            // Extract the first element (assuming it's the only one).
            this.item = data[0];

            // Trim image URLs if they exist.
            if (this.item.serviceMktImage) {
              this.item.serviceMktImage = this.item.serviceMktImage.trim();
            }
            if (this.item.serviceMktIcon) {
              this.item.serviceMktIcon = this.item.serviceMktIcon.trim();
            }

            // Populate the form with fetched data.
            this.form.patchValue({
              serviceMktAr: this.item.serviceMktAr,
              serviceMktEn: this.item.serviceMktEn,
              serviceMktExcerptAr: this.item.serviceMktExcerptAr,
              serviceMktExcerptEn: this.item.serviceMktExcerptEn,
              serviceMktDescAr: this.item.serviceMktDescAr,
              serviceMktDescEn: this.item.serviceMktDescEn,
              serviceMktNoteAr: this.item.serviceMktNoteAr,
              serviceMktNoteEn: this.item.serviceMktNoteEn,
              serviceVisitsDescAr: this.item.serviceVisitsDescAr,
              serviceVisitsDescEn: this.item.serviceVisitsDescEn,
              // serviceMktIcon: this.item.serviceMktIcon,
              // serviceMktImage: this.item.serviceMktImage,
              showOnline: this.item.showOnline,
              scheduledType: this.item.scheduledType,
              serviceWpEnName: this.item.serviceWpEnName,
              serviceWpArName: this.item.serviceWpArName,
              serviceWpEnRow: this.item.serviceWpEnRow,
              serviceWpArRow: this.item.serviceWpArRow,
            });

            // Update boolean flags based on product properties.
            this.visitsNumberIncludedOradb = this.item.visitsNumberIncludedOradb ? true : false;
            this.requireMidOrDoctOradb = this.item.requireMidOrDoctOradb ? true : false;
            this.isActiveOradb = this.item.isActiveOradb ? true : false;
            this.isEserviceOradb = this.item.isEserviceOradb ? true : false;
            this.acceptOffersOradb = this.item.acceptOffersOradb ? true : false;
            this.acceptCouponsOradb = this.item.acceptCouponsOradb ? true : false;
          } else {
            // this.item = val;
          }
        }, (error) => {
          console.log(error)
        });
      }
    });
  }

  /**
   * Method to open a text editor modal popup.
   * @param {any} content - The content to display in the modal.
   * @param {any} item - The item being edited.
   * @param {string} id - The identifier for the text field.
   */
  openTextEditorPopup(content: any, item: any, id: string) {
    this.editorOpenedModel = this.modalService.open(content, {
      centered: true, size: 'lg',
      backdrop: false
    })
    this.popUpName = id;
    this.inputText = item[id] ? item[id] : '';
  }

  /**
   * Method to handle editor modal form submission.
   * @param {string} item - The identifier for the text field.
   */
  onSubmitEditor(item: string) {
    this.item[item] = this.inputText;
    this.form.patchValue(this.item)
    this.editorOpenedModel.close();
  }

  /**
   * Method to close the editor modal.
   */
  closeEditorModal = () => {
    this.editorOpenedModel.close();
  }

  // Getter methods for form controls.
  get serviceMktAr() { return this.form.get('serviceMktAr') }
  get serviceMktEn() { return this.form.get('serviceMktEn') }
  get serviceMktExcerptAr() { return this.form.get('serviceMktExcerptAr') }
  get serviceMktExcerptEn() { return this.form.get('serviceMktExcerptEn') }
  get serviceMktDescAr() { return this.form.get('serviceMktDescAr') }
  get serviceMktDescEn() { return this.form.get('serviceMktDescEn') }
  get serviceMktNoteAr() { return this.form.get('serviceMktNoteAr') }
  get serviceMktNoteEn() { return this.form.get('serviceMktNoteEn') }
  get serviceVisitsDescAr() { return this.form.get('serviceVisitsDescAr') }
  get serviceVisitsDescEn() { return this.form.get('serviceVisitsDescEn') }
  // get serviceMktIcon() { return this.form.get('serviceMktIcon') }
  // get serviceMktImage() { return this.form.get('serviceMktImage') }
  get showOnline() { return this.form.get('showOnline') }
  get scheduledType() { return this.form.get('scheduledType') }
  get serviceWpEnName() { return this.form.get('serviceWpEnName') }
  get serviceWpArName() { return this.form.get('serviceWpArName') }
  get serviceWpEnRow() { return this.form.get('serviceWpEnRow') }
  get serviceWpArRow() { return this.form.get('serviceWpArRow') }

  /**
   * Method triggered when a file is selected for the serviceMktIcon.
   * @param {NgxDropzoneChangeEvent} event - The file selection event.
   */
  onServiceMktIconSelect(event: NgxDropzoneChangeEvent) {
    // Validate the selected file and upload it.
    if (this.validateFile(event.addedFiles[0]) && event.addedFiles[0].size < 250000) {
      this.files.push(...event.addedFiles); // Add the selected file to the files array.

      // Handle the case when more than one file is selected.
      if (this.files.length > 1) {
        this.replaceFile();
      }
      // Upload the image and update the serviceMktIcon property.
      this.ApiHandlerService.commenImageUpload(event.addedFiles[0]).subscribe((data: any) => {
        if (data && data.data && data.data.url) {
          this.item.serviceMktIcon = data.data.url;
        }
      }, (error) => {
        console.log(error)
      })
    } else {
      // Display an error message if the file is invalid or exceeds the size limit.
      this.snotifyService.error(this.translate.instant(`message.imageSizeNotMax250`), '', {
        position: 'rightTop'
      })
    }
  }

  /**
   * Handles the event when an image is selected in the NgxDropzone.
   * @param {NgxDropzoneChangeEvent} event - The file selection event.
   */
  onServiceMktImageSelect(event: NgxDropzoneChangeEvent) {
    // Validate the selected file and its size before processing.
    if (this.validateFile(event.addedFiles[0]) && event.addedFiles[0].size < 250000) {
      this.files.push(...event.addedFiles); // Add the selected file to the files array.

      // Replace the file if more than one file is selected.
      if (this.files.length > 1) {
        this.replaceFile();
      }
      // Upload the image using the common image upload service.
      this.ApiHandlerService.commenImageUpload(event.addedFiles[0]).subscribe((data: any) => {
        if (data && data.data && data.data.url) {
          this.item.serviceMktImage = data.data.url;
        }
      }, (error) => {
        console.log(error)
      })
    } else {
      // Display an error message if the file is invalid or exceeds the size limit.
      this.snotifyService.error(this.translate.instant(`message.imageSizeNotMax250`), '', {
        position: 'rightTop'
      })
    }
  }

  /**
   * Validates the file format based on its extension.
   * @param {File} file - The file to validate.
   * @returns {boolean} - True if the file format is valid, otherwise false.
   */
  validateFile(file: File): boolean {
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    switch (ext.toLowerCase()) {
      case 'png': {
        return true;
      }
      case 'jpeg': {
        return true;
      }
      case 'jpg': {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  /**
   * Removes the first file from the files array.
   */
  private replaceFile() {
    this.files.splice(0, 1);
  }

  /**
   * Handles the removal of a file by setting the corresponding item property to an empty string.
   * @param {string} id - The identifier for the image field.
   */
  onRemove(id: string) {
    // this.files.splice(this.files.indexOf(f), 1);
    if (id) {
      this.item[id] = ''
      this.form.patchValue(this.item)
    }
  }

  /**
   * Closes the modal and triggers a service call to refresh the product setup list.
   */
  closeModal = () => {
    this.modalService.dismissAll();
    this.productsSetupService.getEnayaserviceProductsSetup({
      cat_code_oradb: "",
      service_code_oradb: "",
      show_online: "",
      require_mid_or_doct_oradb: "",
      is_active_oradb: "",
      is_eservice_oradb: "",
      accept_coupons_oradb: "",
      accept_offers_oradb: "",
      sort: "created_at",
      sort_by: "DESC"
    });
  }

  /**
   * Clears the form values and resets certain properties.
   */
  clearForm() {
    this.form.patchValue({
      serviceMktAr: '',
      serviceMktEn: '',
      serviceMktExcerptAr: '',
      serviceMktExcerptEn: '',
      serviceMktDescAr: '',
      serviceMktDescEn: '',
      serviceMktNoteAr: '',
      serviceMktNoteEn: '',
      serviceVisitsDescAr: '',
      serviceVisitsDescEn: '',
      showOnline: '',
      scheduledType: false,
      serviceWpEnName: '',
      serviceWpArName: '',
      serviceWpEnRow: '',
      serviceWpArRow: '',
    });
    this.item.serviceMktIcon = '';
    this.item.serviceMktImage = '';
  }

  /**
   * Handles the form submission, validates the form, and sends a request to update service information.
   */
  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    // Prepare the request body for updating service information.
    let body: IPutProducts = {
      serviceMktAr: this.form.get('serviceMktAr').value,
      serviceMktEn: this.form.get('serviceMktEn').value,
      serviceMktExcerptAr: this.form.get('serviceMktExcerptAr').value,
      serviceMktExcerptEn: this.form.get('serviceMktExcerptEn').value,
      serviceMktDescAr: this.form.get('serviceMktDescAr').value,
      serviceMktDescEn: this.form.get('serviceMktDescEn').value,
      serviceMktNoteAr: this.form.get('serviceMktNoteAr').value,
      serviceMktNoteEn: this.form.get('serviceMktNoteEn').value,
      serviceVisitsDescAr: this.form.get('serviceVisitsDescAr').value,
      serviceVisitsDescEn: this.form.get('serviceVisitsDescEn').value,
      showOnline: this.form.get('showOnline').value ? '1' : '0',
      serviceMktIcon: this.item.serviceMktIcon,
      serviceMktImage: this.item.serviceMktImage,
      scheduledType: this.form.get('scheduledType').value,
      serviceWpEnName: this.form.get('serviceWpEnName').value,
      serviceWpArName: this.form.get('serviceWpArName').value,
      serviceWpEnRow: this.form.get('serviceWpEnRow').value,
      serviceWpArRow: this.form.get('serviceWpArRow').value,
    }

    // Send the request to update service information.
    this.productsSetupService.putEnayaServiceInfo(this.item.serviceCodeOradb, body).subscribe(
      (data: any) => {
        // Display success message and close the modal on successful update.
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        this.closeModal()
      },
      (error) => {
        console.log(error)
        this.closeModal()
      }
    );
  }

  /**
   * Checks the status of the form to determine if any required fields are empty.
   * @returns {boolean} - True if there are empty fields, otherwise false.
   */
  checkStatus(): boolean {
    // Extracting form values for checking.
    let data = {
      serviceMktAr: this.form.get('serviceMktAr').value,
      serviceMktEn: this.form.get('serviceMktEn').value,
      serviceMktExcerptAr: this.form.get('serviceMktExcerptAr').value,
      serviceMktExcerptEn: this.form.get('serviceMktExcerptEn').value,
      serviceMktDescAr: this.form.get('serviceMktDescAr').value,
      serviceMktDescEn: this.form.get('serviceMktDescEn').value,
      serviceVisitsDescAr: this.form.get('serviceVisitsDescAr').value,
      serviceVisitsDescEn: this.form.get('serviceVisitsDescEn').value,
    }
    let showOnline = this.form.get('showOnline').value;

    // Iterate through form data to check for any empty or null values.
    for (let prop in data) {
      if (data[prop] == null || data[prop] == '') {
        if (showOnline) {
          this.form.patchValue({ 'showOnline': 0 })
        }
        return true; // Indicates that there are empty fields.
      }
    }
    return false; // Indicates that all required fields are filled.
  }

  /**
   * Handles the deletion of an image by setting the corresponding item property to an empty string.
   * @param {string} id - The identifier for the image field.
   */
  deleteImg(id: string) {
    if (id) {
      this.item['serviceMktIcon'] = ''
      this.form.patchValue(this.item)
    }
  }
}
