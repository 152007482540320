import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { decode } from 'app/utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss'],
})
export class DoneComponent implements OnInit {
  transCount: any;
  invoiceData: any;
  userId: any;

  /**
   * Constructor function to initialize the DoneComponent.
   * @param {ActivatedRoute} route - ActivatedRoute for accessing route parameters.
   * @param {Router} router - Router for navigation.
   * @param {TranslateService} translate - TranslateService for language translation.
   * @param {ApiHandlerService} apiHandler - ApiHandlerService for handling API requests.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private apiHandler: ApiHandlerService
  ) {
    // Subscribe to route parameters to get transaction count
    this.route.params.subscribe(
      (params) => {
        this.transCount = params.transCount;
        if (!this.transCount || this.transCount.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceDetails();
        }
      },
      (err) => {
        this.router.navigate(['e-services']);
      }
    );
  }

  /**
   * Function to retrieve invoice details using the API handler.
   */
  getInvoiceDetails() {
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.transCount }).subscribe(
      (invoice) => {
        this.invoiceData = invoice;
        const idNumber: string = this.invoiceData.customer_id;
        // Mask part of the customer ID for security
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      },
      (err) => {
        // If update is unsuccessful, show error message using SweetAlert
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  ngOnInit() {
    // Check if a user is logged in and retrieve the user ID
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  goToHome() {
    this.router.navigate(['/e-services']);
  }

  goToDetails() {
    this.router.navigate([`payments/checkout/${this.transCount}`]);
  }
}
