// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';

/**
 * Component for customizing the application layout.
 * @component
 */
@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   */
  ngOnInit() {
    // Customizer JS File
    $.getScript('./assets/js/customizer.js');
  }

  /**
   * Function triggered when the value of the 'e' parameter changes.
   * @param {boolean} e - The new value of the parameter.
   */
  onChange(e: boolean) {
    if (e === true) {
      // Set the display property of the sidebar background element to "block".
      (document.getElementsByClassName("sidebar-background")[0] as HTMLElement).style.display = "block";
    }
    else {
      // Set the display property of the sidebar background element to "none".
      (document.getElementsByClassName("sidebar-background")[0] as HTMLElement).style.display = "none";
    }
  }
}
