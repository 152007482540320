// Importing necessary modules and components from Angular and third-party libraries
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';

import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { DragulaService } from 'ng2-dragula';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import * as $ from 'jquery';

import { LOCATION_INITIALIZED, PathLocationStrategy } from '@angular/common';

import { LocationStrategy } from '@angular/common';
import { LocalService } from './local.service';
import { StorageService } from './storage.service';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { decode, encode } from './utils';
import { Langs } from './home/language.interface';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgCircleProgressModule } from 'ng-circle-progress';
// registerLocaleData(arSa, 'ar-SA', localeArExtra);
import { InvoicesComponent } from '././payments/invoices/invoices.component';
import { CheckoutComponent } from './payments/checkout/checkout.component';
import { DoneComponent } from './payments/done/done.component';
import { ErrorComponent } from './payments/error/error.component';
import { CanceledComponent } from './payments/canceled/canceled.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { EPublicServicesComponent } from './home/e-public-services/e-public-services.component';
import { ArchwizardModule } from 'angular-archwizard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoctorHomeVisitComponent } from './home/e-public-services/doctor-home-visit/doctor-home-visit.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { PublicLabsComponent } from './home/e-public-services/public-labs/public-labs.component';
import { LabsComponent } from './home/e-services/labs/labs.component';
import { LabDetailsComponent } from './home/e-services/lab-details/lab-details.component';
import { PublicLabDetailsComponent } from './home/e-public-services/public-lab-details/public-lab-details.component';
import { RadiologyComponent } from './home/e-services/radiology/radiology.component';
import { RadiologyDetailsComponent } from './home/e-services/radiology-details/radiology-details.component';
import { PublicReadiologyComponent } from './home/e-public-services/public-readiology/public-readiology.component';
import { PublicReadiologyDetailsComponent } from './home/e-public-services/public-readiology-details/public-readiology-details.component';
import { HomeModule } from './home/home.module';
import { HolderComponent } from './payments/holder/holder.component';
import { CompletedComponent } from './payments/completed/completed.component';
import { PaymentErrorComponent } from './payments/payment-error/payment-error.component';
import { PublicOrdersComponent } from './home/e-public-services/public-orders/public-orders.component';
import { HyperHolderComponent } from './payments/hyper-holder/hyper-holder.component';
import { HyperErrorComponent } from './payments/hyper-error/hyper-error.component';
import { HyperCompleteComponent } from './payments/hyper-complete/hyper-complete.component';
import { PaymentMethodComponent } from './payments/payment-method/payment-method.component';
import { NgxPaginationModule } from 'ngx-pagination';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  http: HttpClient
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        translate.currentLoader = new TranslateHttpLoader(
          http,
          'assets/i18n/',
          '.json'
        );
        let langToSet;

        // Set language from localStorage
        if (localStorage.getItem('lang')) {
          langToSet = localStorage.getItem('lang') || Langs.AR;
        } else {
          langToSet = Langs.AR;
        }
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(
          () => {
            // console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    PageLoaderComponent,
    InvoicesComponent,
    CheckoutComponent,
    DoneComponent,
    ErrorComponent,
    CanceledComponent,
    EPublicServicesComponent,
    DoctorHomeVisitComponent,
    PublicLabsComponent,
    PublicLabDetailsComponent,
    PublicReadiologyComponent,
    PublicReadiologyDetailsComponent,
    HolderComponent,
    CompletedComponent,
    PaymentErrorComponent,
    PublicOrdersComponent,
    HyperHolderComponent,
    HyperErrorComponent,
    HyperCompleteComponent,
    PaymentMethodComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    SnotifyModule.forRoot(),
    HttpClientModule,
    NgbModule.forRoot(),
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey, // 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo',
      libraries: ['places'],
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    JwPaginationModule,
    GooglePlaceModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    HomeModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    // { provide: LOCALE_ID, useValue: 'ar-SA' },
    SnotifyService,
    AuthService,
    AuthGuard,
    DragulaService,
    LocalService,
    StorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, HttpClient],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
