import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doctorFilter',
  pure: false
})
export class DoctorFilter implements PipeTransform {
  transform(items: any[], code: string): any {
    return items.filter(item => item['doctorId'] === code);
  }
}
