// Importing necessary modules and services from Angular and custom files
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../pages/content-pages/login/auth.service';
import { Observable } from 'rxjs';
import { UserType } from '../enums/user-type.enum';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  // Implementation of CanActivate interface
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Checking if a user is logged in
    if (this.authService.currentUserValue) {
      // Getting the user's role or defaulting to UserType.Patient
      const userRole = this.authService.currentUserValue.userType || UserType.Patient;
      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        // Redirecting to '/e-services' if the user does not have the required role
        this.router.navigate(['/e-services']);
        return false;
      }
      // All conditions met, allowing access
      return true;
    }

    this.router.navigate(['/e-services']);
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.currentUserValue) {
      // Getting the user's role or defaulting to UserType.Patient
      const userRole = this.authService.currentUserValue.userType || UserType.Patient;

      // Checking if the user has the required role for the route
      if ((userRole !== route.data.role) && (route.data.role.indexOf(userRole) === -1)) {
        let role = '';
        if (userRole === 1) { role = 'admin'; }
        if (userRole === 2) { role = 'enaya-store-management'; }
        if (userRole === 4) { role = 'restaurant-management'; }
        if (userRole === 5) { role = 'gallery-management'; }
        if (userRole === 6) { role = 'customer-services'; }
        if (userRole === 7) { role = 'departments-managers'; }

        this.router.navigate([`/admin/dashboard`]);
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReverseAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  // Checking if a user is logged in
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      // Getting the user's role or defaulting to UserType.Patient
      const userRole = this.authService.currentUserValue.userType || UserType.Patient;

      // Checking if the user has one of the specified roles for which access is denied
      if (userRole === UserType.Admin || userRole === UserType.EnayaStoreManagement || userRole === UserType.RestaurantManagement || userRole === UserType.GalleryManagement
        || userRole === UserType.DepartmentsManagers || userRole === UserType.CustomerServices) {

        // Redirecting to '/admin/dashboard' if access is denied
        this.router.navigate(['/admin/dashboard']);
        return false;
      }
    }
    return true;
  }
}
