// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { Langs } from '../../language.interface';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalRecordService } from '../medical-record/medical-record.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { UserService } from 'app/core/services/user.service';

@Component({
  selector: 'app-request-refill',
  templateUrl: './request-refill.component.html',
  styleUrls: ['./request-refill.component.scss'],
})
export class RequestRefillComponent implements OnInit {
  isAr: boolean;
  lang: string;
  checked: boolean;
  selectedMedecines: Set<number> = new Set<number>();
  visitNumber: any;
  medicinesList: any;
  dragsForSent: any;
  messageDate: any;
  doctorCode: any;
  patientFile: any;
  messageVisitNumber: any;
  medicineCode: any;
  quantity: any;
  req_type = 'إعادة صرف وصفة علاجية';
  platform = 'الموقع الإلكتروني';
  patientInfo: any;
  isLoadingData: boolean = false;

  constructor(
    public router: Router,
    private _medicalRecordService: MedicalRecordService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private userService: UserService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;
  }

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.spinner.show(); // Display loading spinner
    this.route.params.subscribe(
      (params) => (this.visitNumber = params.visitNumber)
    );
    this.getMedicinesList(); // Fetch medicines list
    this.userService.getPatientInfo(); // Fetch patient information
    this.userService.patient.subscribe(
      (val) => {
        if (val) {
          this.patientInfo = val[0];
          this.spinner.hide(); // Hide loading spinner
        }
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  /**
   * Angular lifecycle hook that is called when any data-bound property of a directive changes.
   */
  ngOnChanges() {
    if (!this.selectedMedecines) {
      this.checked = false;
    }
  }

  /**
   * Fetch medicines list for a specific visit
   */
  getMedicinesList() {
    this._medicalRecordService.getVisitDetails(this.visitNumber).subscribe(
      (data) => {
        if (data) {
          this.medicinesList = data;
          this.spinner.hide();
        }
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  /**
   * Handle row click to select or deselect a medicine
   * @param {any} code - The code of the medicine
   */
  onRowClick(code: any) {
    if (this.selectedMedecines.has(code)) {
      this.selectedMedecines.delete(code);
    } else {
      this.selectedMedecines.add(code);
    }
  }

  /**
   * Check if a row (medicine) is selected
   * @param {any} code - The code of the medicine
   * @returns {boolean} - True if the row is selected, false otherwise
   */
  rowIsSelected(code: any) {
    return this.selectedMedecines.has(code);
  }

  /**
   * Get the selected medicines
   * @returns {Array} - The list of selected medicines
   */
  getSelectedRows() {
    return this.medicinesList.filter((x) =>
      this.selectedMedecines.has(x.SRV_CODE)
    );
  }

  /**
   * Send refill request
   */
  sendRequest() {
    if (this.getSelectedRows().length) {
      this.isLoadingData = true; // Set loading flag
      this.dragsForSent = this.getSelectedRows(); // Get selected medicines for sending
      const body = {
        received_order_type_id: 2,
        client_name:
          this.lang == 'ar'
            ? this.patientInfo.PAT_ANAME
            : this.patientInfo.PAT_ENAME,
        client_mobile: this.patientInfo.MOBILE,
        client_city: this.patientInfo.CITY ? this.patientInfo.CITY : '',
        service_cat_code_id: 14,
        client_mrn: this.patientInfo.PAT_CODE,
        formatted_address: this.patientInfo.FORMATTED_ADDRESS
          ? this.patientInfo.FORMATTED_ADDRESS
          : '',
        url: this.patientInfo.GPS_LINK ? this.patientInfo.GPS_LINK : '',
        geometry: {
          location: {
            lat: this.patientInfo.GPS_LAT ? this.patientInfo.GPS_LAT : '',
            lng: this.patientInfo.GPS_LNG ? this.patientInfo.GPS_LNG : '',
          },
        },
        order_items: [
          {
            service_code: 0,
            suggested_date_time: '',
            client_message: `⇨ خدمة التواصل مع المرضى \n
            تم استقبال طلب جديد من العميل أدناه: \n 
            رقم الملف الطبي: \n
            *${this.patientInfo.PAT_CODE}* \n
            نوع الطلب: \n
            *${this.req_type}* \n
            قناة الإرسال:\n
            *${this.platform}* \n
            رقم الزيارة: \n
            *${this.medicinesList[0].VISIT_NO}* \n
            كود الطبيب: \n
            *${this.medicinesList[0].DOC_CODE.replace(/ +/g, '')}* \n
            بتاريخ : \n
            *${new Date(this.medicinesList[0].REQ_DATE).toDateString()}* \n
            ------------------ *نص الرسالة* ----------------- \n
             
            ${this.dragsForSent.map((item) => [
              `كود الدواء: \n
               *${item.SRV_CODE}* \n
               اسم الدواء بالإنجليزي: \n
               *${item.SERV_ENAME}* \n
               الكمية: \n
               *${item.QTY}*\n
               =========================== \n
              `,
            ])}
            
            ---------- *نهاية الرسالة*  ----------`,
          },
        ],
      };

      // Call service to send request to WhatsApp
      this._medicalRecordService.sendWhatsapp(body).subscribe(
        (data) => {
          if (data) {
            this.isLoadingData = false; // Reset loading flag

            // Show success notification and navigate to the medical record page
            Swal({
              title: this.lang == 'en' ? 'Thank you' : 'شكراً لكم',
              text:
                this.lang == 'en'
                  ? 'We received your request and will contact you soon.'
                  : 'لقد استلمنا طلبكم وسنقوم بالتواصل معكم قريباً.',
              confirmButtonText: this.lang == 'en' ? 'Done' : 'تم',
              type: 'success',
            });
            this.router.navigate(['/e-services/medical-record']);
          } else {
            this.isLoadingData = false;
            // Show error notification if the request fails
            Swal({
              title: this.lang == 'en' ? 'Error' : 'خطأ',
              showCancelButton: true,
              showConfirmButton: false,
              text:
                this.lang == 'en'
                  ? 'You must choose at least one type of medicines'
                  : 'يجب عليك اختيار نوع واحد على الأقل من الأدوية',
              cancelButtonText:
                this.lang == 'en' ? 'Try again' : 'إعادة المحاولة',
              cancelButtonColor: '#d02828',
              type: 'error',
            });
          }
        },
        (err) => {
          this.isLoadingData = false;

          // Handle error if the request to send WhatsApp message fails
          Swal({
            title: this.lang == 'en' ? 'Error' : 'خطأ',
            showCancelButton: true,
            showConfirmButton: false,
            text:
              this.lang == 'en'
                ? 'An unexpected error occurred while completing your request, please try again later.'
                : 'حصل خطأ غير متوقع خلال استكمال طلبكم, يرجى المحاولة مرة أخرى لاحقاً',
            cancelButtonText:
              this.lang == 'en' ? 'Try again' : 'إعادة المحاولة',
            cancelButtonColor: '#d02828',
            type: 'error',
          });
        }
      );
    }
  }
}
