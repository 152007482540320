import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { decode } from '../../utils';
import { ORDER_TYPE } from "../enums/order-type.enum";
import { BehaviorSubject } from "rxjs";
import { IProducts, IPutProducts } from "../interfaces/IProducts";
import { SnotifyService } from "ng-snotify";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ProductsSetupService {
  // HTTP options for requests
  httpOptions = {
    'method': 'GET',
    headers: new HttpHeaders({
      'Authorization': `ak ${environment.key}`,
      'jwt-token': decode('token')
    })
  };

  // BehaviorSubjects for different product types
  giftProducts: BehaviorSubject<IProducts[]> = new BehaviorSubject<IProducts[]>(null);
  enayaServiceProducts: BehaviorSubject<IProducts[]> = new BehaviorSubject<IProducts[]>(null);
  restaurantsProducts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  productsItem: BehaviorSubject<IProducts> = new BehaviorSubject<IProducts>(null);

  constructor(private readonly httpClient: HttpClient,
    private readonly snotifyService: SnotifyService,
    private readonly translate: TranslateService,) {
  }

  /**
  * @description Get the setup of gift products.
  * @returns {IProducts[]} - Array of gift products.
  */
  getGiftProductsSetup(): IProducts[] {
    return this.giftProducts.value;
  }

  /**
   * @description Get the setup of restaurant products.
   * @returns {IProducts[]} - Array of restaurant products.
   */
  getRestaurantProductsSetup(): IProducts[] {
    return this.restaurantsProducts.value;
  }


  /**
   * @description Get the setup of enaya service products.
   * @returns {IProducts[]} - Array of enaya service products.
   */
  getEnayaProductsSetup(): IProducts[] {
    return this.enayaServiceProducts.value;
  }

  /**
 * @description Get all services orders
 * @param {any} data
 * */
  getEnayaserviceProductsSetup(data) {
    this.EnayaserviceProducts(data);
  }

   /**
   * @description Private method to fetch enaya service products based on the provided data.
   * @param {any} data
   */
  private EnayaserviceProducts(data) {
    this.httpClient.post(`${environment.apiUrl}/enaya/private-enaya-all-ervices`, data, {
      headers: new HttpHeaders({
        'Authorization': `ak ${environment.key}`,
        'Content-Type': 'application/json',
        'jwt-token': decode('token')
      })
    }).subscribe((data: any) => {
      this.enayaServiceProducts.next(data);
    }, (error) => {
      console.log(error)
    })
  }

    /**
   * Get enaya service product based on the provided code.
   * @param {string} code
   * @returns {Observable<any>} 
   */
  getEnayaserviceProduct(code) {
    return this.httpClient.post(`${environment.apiUrl}/enaya/private-enaya-service?service_code_oradb=${code}`, {}, {
      headers: new HttpHeaders({
        'Authorization': `ak ${environment.key}`,
        'Content-Type': 'application/json',
        'jwt-token': decode('token')
      })
    })
  }


    /**
   * @description Update enaya service information.
   * @param {string} id
   * @param {any} body 
   * @returns {Observable<any>} 
   */
  public putEnayaServiceInfo(id, body) {

    let query = new URLSearchParams(body).toString()
    // var query = "";
    // for (var key in body) {
    //   if (query != "") {
    //     query += "&";
    //   }
    //   query += key + "=" + encodeURIComponent(body[key]);
    // }
    return this.httpClient.put(`${environment.apiUrl}/enaya/enaya-service/${id}?${query}`, {}, this.httpOptions)
  }

    /**
   * @description Get the list of service categories.
   * @returns {Observable<any>} 
   */
  public categoryList() {
    return this.httpClient.post(`${environment.apiUrl}/enaya/public-enaya-service?sort=created_at&sort_by=DESC`, this.httpOptions)
  }

  /**
   * @description Get all services orders
   * */
  getAllProductsSetup(type) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this._getGiftProducts();
    } else {
      this._getRestaurantProducts();
    }
  }


  /**
   * @description Private method to fetch gift products.
   */
  private _getGiftProducts() {
    this.httpClient.get(`${environment.paymentApiUrl}/gift-store`, this.httpOptions).subscribe(
      (data: any) => {
        console.log(data)
        this.giftProducts.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

    /**
   * @description Private method to fetch restaurant products.
   */
  private _getRestaurantProducts() {
    this.httpClient.get(`${environment.paymentApiUrl}/restaurant`, this.httpOptions).subscribe(
      (data: any) => {
        this.restaurantsProducts.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
   * @description Select a specific product item based on the provided id and type.
   * @param {string} id
   * @param {ORDER_TYPE} type
   */  
  selectProductsItem(id, type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.productsItem.next(this.enayaServiceProducts.value.find(item => item.id === id));
      // this.productsItem.next(this.giftProducts.value.find(item => item.id === id));
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.productsItem.next(this.restaurantsProducts.value.find(item => item.id === id));
    }
  }


  /**
   * @description Update information for a specific product.
   * @param {string} id
   * @param {IPutProducts} body 
   * @param {ORDER_TYPE} type
   * @param {Function} closeModal
   */
  putInfo(id: string, body: IPutProducts, type, closeModal) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.putGiftInfo(id, body, closeModal)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.putRestaurantInfo(id, body, closeModal)
    }
  }

  /**
   * @description Private method to update information for a gift product.
   * @param {string} id
   * @param {IPutProducts} body 
   * @param {Function} closeModal
   */
  private putGiftInfo(id, body, closeModal) {
    this.httpClient.put(`${environment.paymentApiUrl}/gift-store/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        this.getAllProductsSetup(ORDER_TYPE.GIFT_STORE);
        closeModal();
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private putRestaurantInfo(id: string, body: IPutProducts, closeModal) {
    this.httpClient.put(`${environment.paymentApiUrl}/restaurant/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        this.getAllProductsSetup(ORDER_TYPE.RESTAURANT);
        closeModal();
      },
      (error) => {
        this.snotifyService.error(this.translate.instant(`message.${error.error.message}`), '', {
          position: 'rightTop'
        });
      }
    )
  }
}
