import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from 'app/pages/content-pages/login/auth.service';

/**
 * Function to fire a 'resize' event on the window.
 * Used to trigger a window resize event after a certain action.
 */
var fireRefreshEventOnWindow = function () {
  var evt = document.createEvent('HTMLEvents');
  evt.initEvent('resize', true, false);
  window.dispatchEvent(evt);
};

/**
 * FullLayoutComponent
 * Component for managing the full layout of the application
 */
@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit {
  isUserAuthenticated: boolean = false;
  userLoggedIn: Boolean = false;
  lang: string;
  sidebarClose: Boolean = false;

  /**
   * Constructor to inject dependencies
   * @param elementRef - Reference to the element
   * @param authService - AuthService for authentication handling
   */
  constructor(
    private elementRef: ElementRef,
    private authService: AuthService
  ) {}

  /**
   * OnInit lifecycle hook
   */
  ngOnInit() {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');

    // Check if a user is logged in
    if (localStorage.getItem('currentUser')) {
      this.userLoggedIn = true;
    }
  
    // Subscribe to the authentication status changes
    this.authService.isUserAuthenticated$.subscribe((isUserAuthenticated) => {
      this.isUserAuthenticated = isUserAuthenticated;
    });

    // //sidebar toggle event listener
    // this.elementRef.nativeElement.querySelector('#sidebarToggle')
    //     .addEventListener('click', this.onClick.bind(this));
    // //customizer events
    // this.elementRef.nativeElement.querySelector('#cz-compact-menu')
    //     .addEventListener('click', this.onClick.bind(this));
    // this.elementRef.nativeElement.querySelector('#cz-sidebar-width')
    //     .addEventListener('click', this.onClick.bind(this));
  }

  /**
   * Handle click event and trigger a window resize event
   * @param event - Click event
   */
  onClick(event) {
    // Initialize window resizer event on sidebar toggle click event
    setTimeout(() => {
      fireRefreshEventOnWindow();
    }, 300);
  }

  /**
   * Handle count change for sidebar close
   * @param count - New count value
   */
  countChangedHandler(count) {
    this.sidebarClose = count;
  }
}
