import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../shared/spinner/spinner.service';

/**
 * PageLoaderComponent
 * Component for displaying a page loader spinner.
 */
@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {
  loading: boolean;

  /**
   * Constructor to inject the SpinnerService dependency
   * @param spinner - The spinner service to control the loading state
   */
  constructor(private spinner: SpinnerService) { }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Subscribes to the spinner service to receive loading state changes
   */
  ngOnInit() {
    this.spinner.spinner.subscribe(val => {
      this.loading = val;
    });
  }
}
