// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { Langs } from '../../language.interface';
import { MedicalRecordService } from '../medical-record/medical-record.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { decode } from 'app/utils';

/**
 * Component for displaying vital signs.
 * @class
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vital-signs',
  templateUrl: './vital-signs.component.html',
  styleUrls: ['./vital-signs.component.scss'],
})
export class VitalSignsComponent implements OnInit {
  /** Array to hold the items to be displayed. */
  items: any;

  /** Array to hold the items for the current page. */
  pageOfItems: Array<any>;

  /** Boolean indicating if the current language is Arabic. */
  isAr: boolean;

  /** Current language. */
  lang: string;

  /** Today's date. */
  todayDate: Date = new Date();

  /** Date representing 365 days before today. */
  before365DaysDate: Date = new Date();

  /** Formatted today's date. */
  todayDateFixed: any;

  /** Formatted date for 365 days before today. */
  before365DaysDateFixed: any;

  /** Current user information. */
  user: any;

  /**
   * Constructor to initialize services.
   * @param {MedicalRecordService} _medicalRecordService - Service for medical records.
   * @param {SpinnerService} spinner - Service for showing/hiding the spinner.
   */
  constructor(
    private _medicalRecordService: MedicalRecordService,
    private spinner: SpinnerService
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;
  }

  /**
   * OnInit lifecycle hook to perform component initialization.
   */
  ngOnInit() {
    this.user = decode('currentUser');

    // Fetch initial data and display spinner
    this.getData();
    this.spinner.show();
  }

  /**
   * Callback function for pagination change.
   * @param {Array<any>} pageOfItems - The items for the current page.
   */
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    window.scrollTo(0, 0); // Scroll to the top of the page when changing the page
  }

  /**
   * Fetch historical data from the medical record service.
   */
  getData() {
    // Format today's date and date 365 days ago for API requests
    this.todayDateFixed = this.todayDate
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-');
    this.before365DaysDate = new Date(
      this.todayDate.setMonth(this.todayDate.getMonth() - 12)
    );
    this.before365DaysDateFixed = this.before365DaysDate
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-');

    // Call the medical record service to get historical data
    this._medicalRecordService
      .getHistoricalData(this.before365DaysDateFixed, this.todayDateFixed)
      .subscribe(
        (data) => {
          this.items = data;
          this.spinner.hide();
        },
        (error) => {
          // Hide the spinner in case of an error
          this.spinner.hide();
        }
      );
  }
}
