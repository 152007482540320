import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../core/services/user.service";
import {IChangePassword} from "../../../core/interfaces/IUser";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  lang: string;
  form: FormGroup;
  constructor(private fb: FormBuilder,
              private userService: UserService) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  ngOnInit() {
    this.form = this.fb.group({
      oldPassword: ['',  [Validators.required] ],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]], //Validators.pattern(/^.*(?=.{2,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/)
    })
  }

  get oldPassword() { return this.form.get('oldPassword'); }
  get newPassword() { return this.form.get('newPassword'); }

  // Function to handle form submission for changing the password.
  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const body: IChangePassword = {
      oldPassword: this.oldPassword.value,
      password: this.newPassword.value
    }

    this.userService.changePassword(body);
  }
}
