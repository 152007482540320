// Import necessary modules and dependencies
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medical-files',
  templateUrl: './medical-files.component.html',
  styleUrls: ['./medical-files.component.scss'],
})
export class MedicalFilesComponent implements OnInit {
  lang: any;
  dir: any;
  filesList: any[];
  fileDetails: any;
  objFilter: any = {
    sortBy: 'DESC',
    sort: 'createdAt'
  };
  pageOfItems: Array<any>;
  fileTypes = [
    { enName: "Medical Report", arName: "تقرير طبي", value: 1 },
    { enName: "Radiology", arName: "أشعة", value: 2 },
    { enName: "Lab Results", arName: "تحاليل مختبر", value: 3 },
    { enName: "Image", arName: "صورة", value: 4 },
    { enName: "Video", arName: "فيديو", value: 5 },
    { enName: "Audio", arName: "ملف صوتي", value: 6 },
    { enName: "Other", arName: "أخرى", value: 7 }
  ]
  constructor(
    private apiHandler: ApiHandlerService,
    private spinner: SpinnerService,
    private modalService: NgbModal
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {
    // Initialize component by fetching all files
    this.getAllFiles();
  }

  // Method to get all medical files
  getAllFiles() {
    this.spinner.show();
    this.apiHandler.getMedicalFilesList(this.objFilter).subscribe((data: any) => {
      if (data) {
        this.filesList = data;
        if (this.filesList && this.filesList.length > 0) {
          // Map file categories to their corresponding names based on language
          this.filesList.forEach(el => {
            if (el && el.fileCategory && this.fileTypes[+el.fileCategory]) {
              el.fileCategory = this.lang == 'en' ? this.fileTypes[+el.fileCategory - 1].enName : this.fileTypes[+el.fileCategory - 1].arName
            } else {
              el.fileCategory = this.lang == 'en' ? this.fileTypes[0].enName : this.fileTypes[0].arName
            }
          });
        }
        this.spinner.hide();
      } else {
        this.filesList = null;
        this.spinner.hide();
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error)
    });
  }

  // Method to reset filter values and fetch all files
  resetFilter() {
    this.objFilter = {
      sort: "createdAt",
      sortBy: "DESC"
    }
    this.getAllFiles();
  }

  // Method to open details popup for a file
  openDetailsPopup(content, item) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      // backdrop: 'static',
    });
    this.fileDetails = item;
    console.log(this.fileDetails);
  }

  closeModal(content) {
    this.modalService.dismissAll(content);
  }

  // Method to open the file path in a new windowc
  openFilePath(file) {
    window.open(file.filePath);
  }

  // Method to delete a file
  deleteFile(file) {
    Swal({
      title: this.lang == 'en' ? 'Are you sure?' : 'تأكيد العملية',
      text:
        this.lang == 'en'
          ? "You won't be able to revert this!"
          : '!لن يكون بإستطاعتك التراجع',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText:
        this.lang == 'en' ? 'Yes, delete it!' : 'نعم قم بحذف الملف',
      cancelButtonText: this.lang == 'en' ? 'No, return' : 'إلغاء العملية',
    }).then((result) => {
      if (result.value) {
        this.apiHandler.deleteFile(file.id);
        // Show success message
        Swal(
          this.lang == 'en' ? 'Deleted!' : '!تم الحذف',
          this.lang == 'en'
            ? 'Your file has been deleted successfully.'
            : 'تم حذف الملف بنجاح',
          'success'
        );
        // Refresh the files list
        this.getAllFiles();

        // Close the modal
        this.modalService.dismissAll();
      }
    });
  }

  // Method to handle date range change
  onDateRangeChange(event, type) {
    if (event) {
      this.objFilter[type] = event;
    }
  }

  // Method to handle page change
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    window.scrollTo(0, 0);
  }
}
