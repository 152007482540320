// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { SideBarAction } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { decode } from '../../utils';
import { AuthService } from '../../pages/content-pages/login/auth.service';
import { UserType } from '../../core/enums/user-type.enum';
import Swal from 'sweetalert2';

declare var $: any;

/**
 * Component to manage and display the sidebar.
 * @component
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Array of menu items to be displayed in the sidebar.
   * @type {any[]}
   */
  public menuItems: any[];

  /**
   * Current user information.
   * @type {any}
   */
  private user: any;

  /**
   * User type enumeration.
   * @type {number}
   */
  userType: number = UserType.All;

  /**
   * Array of routes for the sidebar.
   * @type {any[]}
   */
  routes: any[];

  /**
   * Sidebar close state.
   * @type {boolean}
   */
  sidebarClose: boolean = false;

  /**
   * Event emitter to notify parent component about sidebar state change.
   * @type {EventEmitter<any>}
   */
  @Output() countChanged = new EventEmitter<any>();

  /**
   * Constructor to inject the necessary services and set the language variable.
   * @param {Router} router - The router service.
   * @param {ActivatedRoute} route - The activated route service.
   * @param {TranslateService} translate - The translation service.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private authService: AuthService
  ) {
    // Subscribe to changes in the current user
    this.authService.currentUser.subscribe((val) => {
      this.user = val;
      this.routes = ROUTES;

      // Determine the user type based on the current user's role
      if (this.authService.currentUserValue) {
        const userRole = this.authService.currentUserValue.userType;

        // Map user roles to UserType enumeration
        if (userRole === UserType.Admin) {
          this.userType = UserType.Admin;
        } else if (userRole === UserType.EnayaStoreManagement) {
          this.userType = UserType.EnayaStoreManagement;
        } else if (userRole === UserType.RestaurantManagement) {
          this.userType = UserType.RestaurantManagement;
        } else if (userRole === UserType.GalleryManagement) {
          this.userType = UserType.GalleryManagement;
        } else if (userRole === UserType.Patient) {
          this.userType = UserType.Patient;
        } else if (userRole === UserType.CustomerServices) {
          this.userType = UserType.CustomerServices;
        } else if (userRole === UserType.DepartmentsManagers) {
          this.userType = UserType.DepartmentsManagers;
        }
      } else {
        this.userType = UserType.All; // Default user type
      }

      // Render sidebar items based on user type
      this.renderSidebarItem();
    });

    // Decode user information from token (assuming 'decode' is a utility function)
    this.user = decode('currentUser');
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @returns {void}
   */
  ngOnInit(): void {
    // Load external scripts for perfect-scrollbar and app-sidebar
    $.getScript('./assets/js/perfect-scrollbar.jquery.min.js').then((r) => {
      $.getScript('./assets/js/app-sidebar.js');
    });

    // Initial rendering of sidebar items
    this.renderSidebarItem();
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Function to filter and render sidebar items based on user type.
   * @param {any[]} [submenu] - Optional submenu items to be filtered.
   * @returns {any[]} The filtered sidebar items.
   */
  renderSidebarItem(submenu?: any[]): any[] {
    if (submenu) {
      // Filter submenu items based on user type
      return submenu.filter((item) => {
        if (item.role.indexOf(this.userType) !== -1) {
          return item;
        }
        return null;
      });
    } else {
      // Filter main menu items based on user type
      this.menuItems = this.routes.filter((menuItem) => {
        if (menuItem.type === 'logout' && !this.user) {
          return null;
        } else {
          if (menuItem.role.indexOf(this.userType) !== -1) {
            if (menuItem.submenu.length) {
              menuItem.submenu = this.renderSidebarItem(menuItem.submenu);
            }
            return menuItem;
          }
          return null;
        }
      });
      return this.menuItems;
    }
  }

  /**
   * Function to handle actions when a level 1 item is toggled.
   * @param {any} $event - The event object.
   * @param {SideBarAction} [action] - Optional action to be performed.
   */
  callLevel1Toggle($event: any, action?: SideBarAction): void {
    if (action && action.type === 'logout') {
      action.action(this.authService); // Perform logout action
      this.renderSidebarItem(); // Re-render sidebar items after logout
    }
  }

  /**
   * Function to handle sidebar click events.
   * @returns {void}
   */
  clickedOnSidebar(): void {
    if (!this.sidebarClose) {
      this.sidebarClose = true;
      this.countChanged.emit(this.sidebarClose);

      // Emit event to notify parent component about sidebar state change
      setTimeout(() => {
        this.countChanged.emit(!this.sidebarClose);
      }, 1000);
    } else {
      this.sidebarClose = false;
      this.countChanged.emit(this.sidebarClose);
    }
  }

  /**
   * Function to navigate to the e-services page.
   * @returns {void}
   */
  navigateHome(): void {
    this.router.navigate(['e-services']);
  }
}
