import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../reservations/api.service';
import { environment } from 'environments/environment';
import * as moment from 'moment-timezone';
import * as mom from 'moment';
import { isError } from 'util';
import Swal from 'sweetalert2';
import { UserChoice } from '../reservations/userChoice';
import { AuthService } from '../../../pages/content-pages/login/auth.service';
import { decode } from '../../../utils';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { TrackingService } from 'app/core/services/tracking.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit {
  recap = environment.recaptchaSite;
  patInfoForm: FormGroup;
  mobilePattern =
    /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;
  isError = 0;
  showForm = 1;
  showData = 0;
  reservationsData: any[] = [];
  transNo: number;
  mobileNumber: string;
  noResult = 0;
  lang: string;
  dir: string;
  user: any;
  dataList: any;
  pageOfItems: Array<any>;
  statusList: any;
  filterForm: FormGroup;
  categoryList: any;
  orderDetails: any;
  loadingData: boolean = false;
  latitude: any;
  longitude: any;
  clickedIndex: number | null = null;
  clientMessage: any;
  adjustedMessage: any;
  orderStatus:any;

  constructor(
    private data: ApiService,
    private authService: AuthService,
    private spinner: SpinnerService,
    private _trackingService: TrackingService,
    private modalService: NgbModal,
    private readonly router: Router,
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';
    this.patInfoForm = new FormGroup(
      {
        mobileNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
        ]),
        orderNumber: new FormControl(null, [Validators.required]),
        // recaptcha: new FormControl(null, [Validators.required]),
      },
      { updateOn: 'blur' }
    );
    this.filterForm = new FormGroup({
      orderStatus: new FormControl(''),
      orderNumber: new FormControl(''),
      serviceCategory: new FormControl(''),
    });
  }
  resolved(captchaResponse: string) {
    if (captchaResponse != null && captchaResponse != undefined) {
    }
  }
  ngOnInit() {
    this.user = decode('currentUser');

    if (this.user) {
      this.getActiveBookWithUserLogin({});
      this.patchValue(this.user.mobile);
      this.spinner.show();
      this.addTrackingLog();
      this.getOrderStatusList();
      this.getServiceCategoryList();
      this.filterForm.reset();
    }
  }

  // Call the Tracking Service to add a tracking log for booking an appointment
  addTrackingLog() {
    this._trackingService
      .addTrackingLog(
        this._trackingService.tracking_type.BOOK_APPOINTMENT,
        this.user.id ? this.user.id : ''
      )
      .subscribe((data) => {
        return data;
      });
  }

  // Call the data service to get all books associated with the mobile number
  getActiveBook() {
    if (this.patInfoForm.valid) {
      this.addTrackingLog();
      this.mobileNumber = this.patInfoForm.get('mobileNumber').value;
      this.data.getAllBooks(this.mobileNumber).subscribe(
        (r) => {
          if (r['Success'] == true) {
            this.reservationsData = [];
            this.showForm = 0;
            moment.tz.setDefault('UTC');
            r['Result'].forEach((reserv) => {
              reserv['RES_TIME'] = moment(reserv['RES_TIME']).format(
                'hh:mm:ss a'
              );
              reserv['RES_DATE_ORIGINAL'] = new Date(reserv['RES_DATE']);
              reserv['RES_DATE'] = moment(reserv['RES_DATE']).format(
                'YYYY-MM-DD'
              );
              if (mom().isAfter(reserv['RES_DATE'])) {
                reserv['hide'] = true;
              }
              this.reservationsData.push(reserv);
            });
            this.reservationsData.sort(
              (a, b) => b.RES_DATE_ORIGINAL - a.RES_DATE_ORIGINAL
            );
            this.showData = 1;
            this.showForm = 0;
            this.noResult = 0;
            this.isError = 0;
          } else {
            this.showForm = 1;
            this.showData = 0;
            this.noResult = 1;
          }
        },
        (error) => {
          this.isError = 1;
          this.showForm = 1;
          this.showData = 0;
          this.noResult = 0;
        }
      );
    }
  }

  // Call the data service to get all books based on the provided filters
  getActiveBookWithUserLogin(filters) {
    this.data.getAllBooks(filters).subscribe(
      (r) => {
        if (r) {
          this.dataList = (r as any[]).sort(
            (a, b) =>
              Number(new Date(b.BEX_ORDERS_MAIN.ORDER_NUMBER)) -
              Number(new Date(a.BEX_ORDERS_MAIN.ORDER_NUMBER))
          );
          this.showData = 1;
          this.showForm = 0;
          this.noResult = 0;
          this.isError = 0;
        } else {
          this.showForm = 1;
          this.showData = 1;
          this.noResult = 0;
        }
        this.spinner.hide();
      },
      (error) => {
        this.isError = 1;
        this.showForm = 1;
        this.showData = 0;
        this.noResult = 2;
        this.spinner.hide();
      }
    );
  }

  // Cancel an appointment and update the reservations data
  cancelApp(app, i) {
    if (app) {
      if (app.RES_NO && this.mobileNumber) {
        this.data.cancelBook(app.RES_NO, this.mobileNumber).subscribe(
          (r) => { },
          (error) => {
            this.isError = 1;
            this.showForm = 1;
            this.showData = 0;
            this.noResult = 0;
          },
          () => {
            this.reservationsData.splice(i, 1);
          }
        );
      }
    }
  }

  // Shows a confirmation dialog before canceling an appointment
  confirmDelete(app, i) {
    Swal({
      title: this.lang == 'en' ? 'Are you sure?' : 'تأكيد العملية',
      text:
        this.lang == 'en'
          ? "You won't be able to revert this!"
          : '!لن يكون بإستطاعتك التراجع',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText:
        this.lang == 'en' ? 'Yes, cancel it!' : 'نعم قم بإلغاء الموعد',
      cancelButtonText: this.lang == 'en' ? 'No, return' : 'إلغاء العملية',
    }).then((result) => {
      if (result.value) {
        this.cancelApp(app, i);
        Swal(
          this.lang == 'en' ? 'Cancel!' : 'إلغاء',
          this.lang == 'en'
            ? 'Your appointment has been canceled.'
            : 'تمت عملية إلغاء الحجز بنجاح',
          'success'
        );
      }
    });
  }

  // Update the mobileNumber form control in the patient information form
  private patchValue(mobile) {
    this.patInfoForm.patchValue({
      mobileNumber: mobile,
    });
  }

  // Handles the change in the displayed page of items
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    window.scrollTo(0, 0);
  }

  // Call the data service to get the order status list
  getOrderStatusList() {
    this.data.getOrderStatusList().subscribe(
      (items) => {
        if (items['data']) {
          this.statusList = items['data'].filter(
            (status) => status.SHOW_ONLINE == 1
          );
          this.showData = 1;
          this.showForm = 0;
          this.noResult = 0;
          this.isError = 0;
        } else {
          this.showForm = 1;
          this.showData = 1;
          this.noResult = 0;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // Call the data service to get the service categories
  getServiceCategoryList() {
    this.data.getServiceCategories().subscribe(
      (items) => {
        if (items) {
          this.categoryList = items;
          this.showData = 1;
          this.showForm = 0;
          this.noResult = 0;
          this.isError = 0;
        } else {
          this.showForm = 1;
          this.showData = 1;
          this.noResult = 0;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // Call the data service to get and displaying details of a specific order
  openDetailsModal(content, item, index) {
    this.loadingData = true;
    this.clickedIndex = index;
    this.data.getOrderDetails(item.toString()).subscribe(
      (order: any) => {
        if (order) {
          this.orderDetails = order;
          this.latitude = order.BEX_ORDERS_MAIN.GPS_LAT
            ? parseFloat(order.BEX_ORDERS_MAIN.GPS_LAT)
            : 21.4925;
          this.longitude = order.BEX_ORDERS_MAIN.GPS_LNG
            ? parseFloat(order.BEX_ORDERS_MAIN.GPS_LNG)
            : 39.17757;
          this.clientMessage = order.BEX_ORDERS_MAIN_ITEMS[0].CLIENT_MESSAGE;

          if (order && order.BEX_ORDERS_MAIN && order.BEX_ORDERS_MAIN.ORDER_STATUS_ID) {
            this.orderStatus = order.BEX_ORDERS_MAIN.ORDER_STATUS_ID;
          }

          if (this.clientMessage) {
            let messageStartIndex = this.clientMessage.indexOf('نص الرسالة');
            let messageBody = this.clientMessage.substring(messageStartIndex);
            messageBody = messageBody.replace(/^\s+/gm, '');
            let textSections = messageBody.split('===========================');
            let adjustedTextSections = textSections.map((section) => {
              return section
                .replace(/\*/g, '')
                .replace(/([A-Za-z\s]+)/g, (match) => match.toUpperCase());
            });
            let adjusted = adjustedTextSections.join(
              '\n===========================\n'
            );
            this.adjustedMessage = adjusted.replace(/\n/g, '<br>');
          }
          this.modalService.open(content, {
            centered: true,
            size: 'lg',
            windowClass: 'order-modal'
            // backdrop: 'static',
          });
          this.loadingData = false;
        } else {
          this.orderDetails = null;
          this.loadingData = false;
          this.clickedIndex = null;
        }
      },
      (err) => {
        console.error('Error', err);
        this.loadingData = false;
        this.clickedIndex = null;
      }
    );
  }

  // Call the data service to get and displaying details of the public order
  openPublicDetailsModal(content) {
    const body = {
      orderNumber: this.patInfoForm.get('orderNumber').value,
      mobileNumber: this.patInfoForm.get('mobileNumber').value,
    };
    this.loadingData = true;
    this.data.getPublicOrderDetails(body).subscribe(
      (order: any) => {
        if (order) {
          this.orderDetails = order;
          this.latitude = order.BEX_ORDERS_MAIN.GPS_LAT
            ? parseFloat(order.BEX_ORDERS_MAIN.GPS_LAT)
            : 21.4925;
          this.longitude = order.BEX_ORDERS_MAIN.GPS_LNG
            ? parseFloat(order.BEX_ORDERS_MAIN.GPS_LNG)
            : 39.17757;
          this.clientMessage = order.BEX_ORDERS_MAIN_ITEMS[0].CLIENT_MESSAGE;
          if (this.clientMessage) {
            let messageStartIndex = this.clientMessage.indexOf('نص الرسالة');
            let messageBody = this.clientMessage.substring(messageStartIndex);
            messageBody = messageBody.replace(/^\s+/gm, '');
            let textSections = messageBody.split('===========================');
            let adjustedTextSections = textSections.map((section) => {
              return section
                .replace(/\*/g, '')
                .replace(/([A-Za-z\s]+)/g, (match) => match.toUpperCase());
            });
            let adjusted = adjustedTextSections.join(
              '\n===========================\n'
            );
            this.adjustedMessage = adjusted.replace(/\n/g, '<br>');
          }
          this.modalService.open(content, {
            centered: true,
            size: 'lg',
            // backdrop: 'static',
          });
        } else {
          this.orderDetails = null;
          this.loadingData = false;
          Swal(
            this.lang == 'en' ? 'Cancel!' : 'إلغاء',
            this.lang == 'en'
              ? 'There is no request data for the information entered. Please verify the information entered and try again'
              : 'لا توجد بيانات طلب للمعلومات التي تم إدخالها، يرجى التأكد من المعلومات المدخلة والمحاولة مجدداً',
            'error'
          );
        }
      },
      (err) => {
        this.loadingData = false;
        Swal(
          this.lang == 'en' ? 'Error!' : 'خطأ',
          this.lang == 'en'
            ? 'There is no request data for the information entered. Please verify the information entered and try again'
            : 'لا توجد بيانات طلب للمعلومات التي تم إدخالها، يرجى التأكد من المعلومات المدخلة والمحاولة مجدداً',
          'error'
        );
      }
    );
    console.log(body);
  }

  // Closes all currently open modals
  closePopup() {
    this.modalService.dismissAll();
  }

  // Navigates to the file upload route with specific query parameters based on the item
  uploadFiles(item) {
    if (item) {
      this.modalService.dismissAll();
      this.router.navigate(['/e-services/medical-files/upload'],
        {
          queryParams: {
            type: "order",
            resNo: item.BEX_ORDERS_MAIN.RES_NO || undefined,
            orderId: item.BEX_ORDERS_MAIN.ID,
            orderNo: item.BEX_ORDERS_MAIN.ORDER_NUMBER
          }
        })
    }
  }
}
