// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ORDER_TYPE } from "../../core/enums/order-type.enum";
import { GiftStoreService } from "../../core/services/gift-store.service";
import { ICreateTags } from "../../core/interfaces/ITags";

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnInit {
  form: FormGroup;
  lang: string;
  @Input() type: number;

  /**
   * Constructor with injected services and dependencies.
   * @param {FormBuilder} fb - The form builder service for creating reactive forms.
   * @param {GiftStoreService} tagsService - The service for handling tags operations.
   */
  constructor(private fb: FormBuilder,
    private tagsService: GiftStoreService) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Angular lifecycle hook that initializes the component.
   */
  ngOnInit() {
    // Initialize the form with form controls and validators
    this.form = this.fb.group({
      nameAr: ['', [Validators.required, Validators.maxLength(25)]],
      nameEn: ['', [Validators.required, Validators.maxLength(25)]],
      onlineActive: [false],
      orderNumber: ['']
    });
  }

  // Getter methods for form controls to simplify template binding
  get nameAr() { return this.form.get('nameAr'); }
  get nameEn() { return this.form.get('nameEn'); }
  get orderNumber() { return this.form.get('orderNumber'); }
  get isActive() { return this.form.get('onlineActive'); }

  /**
   * Method to clear form values.
   */
  clearForm = () => {
    this.form.reset();
    this.form.patchValue({
      nameAr: '',
      nameEn: '',
      onlineActive: false,
      orderNumber: ''
    });
  }

  /**
   * Method to handle form submission for creating tags.
   * @param {number} type - The type of the tag (e.g., gift store or restaurant).
   */
  onSubmit(type: number) {
    if (this.form.invalid) {
      return;
    }

    const body: ICreateTags = {
      order: +this.orderNumber.value,
      nameEn: this.nameEn.value,
      nameAr: this.nameAr.value,
      isActive: this.isActive.value,
    };

    this.tagsService.addTags(type, body, this.clearForm);
  }

  /**
   * Method to allow only alphanumeric characters and specific Arabic characters in input fields.
   * @param {KeyboardEvent} event - The keyboard event triggered on keypress.
   * @returns {boolean} - Returns true if the input character is valid, otherwise false.
   */
  keyPressAlphanumeric(event: KeyboardEvent): boolean {
    // Get the input character from the event
    var input = String.fromCharCode(event.keyCode);

    // Check if the input character is alphanumeric or Arabic characters
    if (/[a-zA-Z-ا-ي-ؤ-ئ-ء]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false; // Prevent the input
    }
  }
}
