// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit, Input } from '@angular/core';
import { UserChoice, Clinics, Providers, ServiceResponse } from './userChoice';
import { ApiService } from './api.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { observable, Observable, timer } from 'rxjs';
import * as moment from 'moment-timezone';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'url';
import { ActivatedRoute } from '@angular/router';
import { providersArray, WasselDepartments, DHSDepartments } from './data';

@Component({
  selector: 'app-eligibility',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.scss'],
})
export class EligibilityComponent implements OnInit {
  showSpiner: Observable<number>;
  // recap = environment.recaptchaSite;
  currentRating = 1;
  isError = 0;
  model: UserChoice;
  eligibilityForm: FormGroup;
  done = 0;
  providers: Providers[] = providersArray;
  departments: Clinics[];
  company: string;
  eligibilityResult: ServiceResponse;

  constructor(
    private data: ApiService,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';
    this.eligibilityForm = new FormGroup(
      {
        patientID: new FormControl(null, [
          // Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10),
          // Validators.pattern(this.mobilePattern),
        ]),
        membershipNo: new FormControl(null, [
          Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10),
          // Validators.pattern(this.mobilePattern),
        ]),
        policyNo: new FormControl(null, [
          Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10),
          // Validators.pattern(this.mobilePattern),
        ]),
        // recaptcha: new FormControl(null, [
        //   Validators.required
        // ]),
      },
      { updateOn: 'blur' }
    );

    this.model = new UserChoice();
  }

  lang: string;
  dir: string;
  ngOnInit() {
    if (this.lang == 'en') {
      $('aw-wizard').attr('dir', 'ltr');
      $('ng-select').attr('dir', 'ltr');
      $('.ng-option-label').addClass('text-left');
    } else {
      $('aw-wizard').removeAttr('dir');
      $('ng-select').removeAttr('dir');
      $('.ng-option-label').removeClass('dir');
    }
  }

  // Function to handle the resolved CAPTCHA response.
  resolved(captchaResponse: string) {
    if (captchaResponse != null && captchaResponse != undefined) {
    }
  }

  // Function triggered when the provider selection is changed.
  providerChanged(event) {
    this.model.selectedClinic = null;
    if (event.PROVIDER_CODE === '20147') this.departments = DHSDepartments;
    else this.departments = WasselDepartments;
  }

  // Function to submit eligibility information.
  submitEligibility() {
    try {
      let eligibilityInfo = {
        provider_code: this.model.selectedProvider['PROVIDER_CODE'],
        comp_id_code: this.eligibilityForm.get('membershipNo').value,
        ins_no: this.eligibilityForm.get('policyNo').value,
        wsl_dept_code: this.model.selectedClinic['CLIN_CODE'],
        id_card: this.eligibilityForm.get('patientID').value,
      };

      this.data.getEligibility(eligibilityInfo).subscribe(
        (e) => {
          this.eligibilityResult = <ServiceResponse>e;
        },
        (error) => {
          this.isError = 1;
          console.log(error);
        },
        () => {
          //this.eligibilityForm.reset(),
          this.isError = 0;
          this.done = 1;
        }
      );
    } catch (error) {
      this.isError = 1;
    }
  }

  // Function to reset the wizard state.
  resetWizard() {
    this.done = 0;
    this.isError = 0;
    console.log('resetWizard resetWizard resetWizard');
  }
}
