// Import necessary Angular modules and dependencies
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { decode } from 'app/utils';

/**
 * Component to handle errors during HyperPay payment process.
 * @component
 */
@Component({
  selector: 'app-hyper-error',
  templateUrl: './hyper-error.component.html',
  styleUrls: ['./hyper-error.component.scss'],
})
export class HyperErrorComponent implements OnInit {
  /**
   * The user ID.
   * @type {any}
   */
  userId: any;

  /**
   * The transaction count.
   * @type {any}
   */
  transCount: any;

  /**
   * The current language setting.
   * @type {any}
   */
  lang: any;

  /**
   * Constructor to inject the router and activated route services.
   * @param {Router} router - The router service for navigation.
   * @param {ActivatedRoute} route - The activated route service to access route parameters.
   */
  constructor(private router: Router, private route: ActivatedRoute) {
    // Subscribe to query parameters to check for transCount
    this.route.queryParams.subscribe((params) => {
      if (params) {
        // Use snapshot to get the current route's paramMap
        const params = this.route.snapshot.paramMap;
        params.keys.forEach((key) => {
          const value = +params.get(key);
          if (!isNaN(value)) {
            this.transCount = value;
            return;
          }
        });
      }
    });
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Sets the language and user ID from localStorage.
   * @returns {void}
   */
  ngOnInit(): void {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  /**
   * Navigates to the home page based on the presence of userId.
   * @returns {void}
   */
  goToHome(): void {
    this.userId
      ? this.router.navigate(['/e-services'])
      : this.router.navigate(['/e-public']);
  }

  /**
   * Navigates to the order placement page based on the transaction count.
   * @returns {void}
   */
  placeOrder(): void {
    this.router.navigate([`payments/invoices/${this.transCount}`]);
  }
}
