import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

/**
 * LocalService
 * A service to handle local storage operations securely.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalService {
  /**
   * Constructor to inject the StorageService dependency
   * @param storageService - The storage service to handle secure storage operations
   */
  constructor(private storageService: StorageService) {}

  /**
   * Set the JSON data to local storage
   * @param key - The key to store the value under
   * @param value - The value to store
   */
  setJsonValue(key: string, value: any) {
    this.storageService.secureStorage.setItem(key, value);
  }

  /**
   * Get the JSON value from local storage
   * @param key - The key to retrieve the value for
   * @returns The value stored under the provided key
   */
  getJsonValue(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }

  /**
   * Clear the local storage
   * @returns void
   */
  clearToken() {
    return this.storageService.secureStorage.clear();
  }

  /**
   * Remove a specific item from local storage
   * @param key - The key to remove from storage
   */
  removeData(key: string) {
    this.storageService.secureStorage.removeItem(key);
  }
}
