import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EServicesComponent } from './e-services/e-services.component';
import { ReservationsComponent } from './e-services/reservations/reservations.component';
import { LabsComponent } from './e-services/labs/labs.component';
import { LabDetailsComponent } from './e-services/lab-details/lab-details.component';
import { RadiologyComponent } from './e-services/radiology/radiology.component';
import { RadiologyDetailsComponent } from './e-services/radiology-details/radiology-details.component';
import { AppointmentsComponent } from './e-services/appointments/appointments.component';
import { AppointmentsNewComponent } from './e-services/appointments-new/appointments-new.component';
import { PatientAppointmentComponent } from './online-sessions/patient-appointment/patient-appointment.component';
import { DoctorLoginComponent } from './online-sessions/doctor-login/doctor-login.component';
import { DoctorRoomComponent } from './online-sessions/doctor-room/doctor-room.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { SessionRateComponent } from './online-sessions/sesson-rate/sesson-rate.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { EligibilityComponent } from './e-services/eligibility/eligibility.component';
import { InvoicesComponent } from './e-services/invoices/invoices.component';
import { AllInvoicesComponent } from './e-services/all-invoices/all-invoices.component';
import { ChangePasswordComponent } from '../admin/user/change-password/change-password.component';
import { EditInformationComponent } from '../admin/user/edit-information/edit-information.component';
import { MedicalRecordComponent } from './e-services/medical-record/medical-record.component';
import { RequestRefillComponent } from './e-services/request-refill/request-refill.component';
import { AllergiesComponent } from './e-services/allergies/allergies.component';
import { VitalSignsComponent } from './e-services/vital-signs/vital-signs.component';
import { ServiceBookingComponent } from './e-services/service-booking/service-booking.component';
import { MyAppointmentsComponent } from './e-services/my-appointments/my-appointments.component';
import { MedicalFilesComponent } from './e-services/medical-files/medical-files.component';
import { UploadMedicalFileComponent } from './e-services/upload-medical-file/upload-medical-file.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: EServicesComponent,
        data: {
          title: 'الخدمات الإلكترونية',
        },
      },
      {
        path: 'reservations',
        component: ServiceBookingComponent,
        data: {
          title: 'الحجوزات و المواعيد',
        },
      },
      {
        path: 'my-reservations',
        component: MyAppointmentsComponent,
        data: {
          title: 'My sAppointments',
        },
      },
      {
        path: 'reservations-new',
        component: AppointmentsNewComponent,
        data: {
          title: 'الحجوزات و المواعيد',
        },
      },
      {
        path: 'labs',
        component: LabsComponent,
        data: {
          title: 'تقارير المختبر',
        },
      },
      {
        path: 'medical-record',
        component: MedicalRecordComponent,
        data: {
          title: 'السجل الطبي',
        },
      },
      {
        path: 'allergies',
        component: AllergiesComponent,
        data: {
          title: 'الحساسية',
        },
      },
      {
        path: 'vital-signs',
        component: VitalSignsComponent,
        data: {
          title: 'المؤشرات الحيوية',
        },
      },
      {
        path: 'request-refill/:visitNumber',
        component: RequestRefillComponent,
        data: {
          title: 'طلب إعادة التعبئة',
        },
      },
      {
        path: 'lab-details',
        component: LabDetailsComponent,
        data: {
          title: 'تقارير المختبر',
        },
      },
      {
        path: 'radiology',
        component: RadiologyComponent,
        data: {
          title: 'تقارير الأشعة',
        },
      },
      {
        path: 'radiology-details',
        component: RadiologyDetailsComponent,
        data: {
          title: 'تقارير الأشعة',
        },
      },
      {
        path: 'orders',
        component: AppointmentsComponent,
        data: {
          title: 'إدارة الحجوزات',
        },
      },
      {
        path: 'user-management/change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Change Password',
        },
      },
      {
        path: 'user-management/edit-information',
        component: EditInformationComponent,
        data: {
          title: 'Edit Information',
        },
      },
      /*      {
        path: 'UDHLive',
        component: PatientAppointmentComponent,
        data: {
          title: 'الطلب الإتصالي',
        },
      },
      {
        path: 'UDHLive/doctor-login',
        component: DoctorLoginComponent,
        data: {
          title: 'الطلب الإتصالي',
        },
      },
      {
        path: 'UDHLive/doctor-room',
        component: DoctorRoomComponent,
        data: {
          title: 'الطلب الإتصالي',
        },
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },
      {
        path: 'UDHLive/session-rate',
        component: SessionRateComponent,
        data: {
          title: 'تقييم الطب الإتصالي',
        },
        // canLoad: [AuthGuard],
        // canActivate: [AuthGuard],
      },*/
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'eligibility',
        component: EligibilityComponent,
        data: {
          title: 'أهلية التأمين',
        },
      },
      {
        path: 'invoice',
        component: InvoicesComponent,
        data: {
          title: 'إظهار الفاتورة',
        },
      },
      {
        path: 'invoices',
        component: AllInvoicesComponent,
        data: {
          title: 'إظهار الفاتورة',
        },
      },
      {
        path: 'medical-files',
        component: MedicalFilesComponent,
        data: {
          title: 'الملفات الطبية',
        },
      },
      {
        path: 'medical-files/upload',
        component: UploadMedicalFileComponent,
        data: {
          title: 'الملفات الطبية',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
