import { Component, Input, OnInit } from "@angular/core";
import { ORDER_TYPE } from "../../core/enums/order-type.enum";

/**
 * Component for displaying order reviews.
 * @component
 */
@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class OrderReviewComponent implements OnInit {
  /**
   * List of the latest order reviews.
   * @type {any[]}
   */
  @Input() latestOrdersReview: any[];

  /**
   * Enum for order types.
   * @type {ORDER_TYPE}
   */
  ORDER_TYPE = ORDER_TYPE;

  /**
   * Creates an instance of OrderReviewComponent.
   */
  constructor() {}

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {}
}
