// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

/**
 * NavbarComponent is responsible for the navigation bar functionality,
 * including language change and UI direction adjustments.
 */
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  /**
   * Current language of the application.
   */
  public currentLang = 'ar';

  /**
   * CSS class for toggling the navigation menu.
   */
  toggleClass = 'ft-maximize';

  /**
   * Flag for collapsing the navigation menu.
   */
  public isCollapsed = true;

  /**
   * Language preference from local storage.
   */
  lang = localStorage.getItem('lang');

  /**
   * Constructor to initialize TranslateService and Router.
   * @param {TranslateService} translate - Service to manage translations.
   * @param {Router} router - Angular router to handle navigation.
   */
  constructor(public translate: TranslateService, private router: Router) { }

  /**
   * Lifecycle hook called after the component's view has been initialized.
   * Sets the language preference and direction of the UI.
   */
  ngOnInit() {
    if (this.lang) {
      this.translate.use(this.lang);
      this.changeDirection(this.lang);
    } else {
      this.translate.use(this.currentLang);
      localStorage.setItem('lang', this.currentLang);
    }
  }

  /**
   * Change the language and update UI direction accordingly.
   * @param {string} language - Language code to switch to.
   */
  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
    this.changeDirection(language);

    // Reload the route to apply language changes
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url], { skipLocationChange: true });
    window.location.reload();
  }

  /**
   * Update UI direction based on the selected language.
   * @param {string} lang - Language code to set the UI direction.
   */
  changeDirection(lang: string) {
    if (lang === 'en') {
      // Update CSS classes for English language direction
      $('.navbar-toggle').removeClass('float-left').addClass('float-right');
      $('.customizer').addClass('customizer-l').removeClass('customizer');
      $('.app-sidebar').addClass('app-sidebar-l').removeClass('app-sidebar');
      $('.off-canvas-sidebar').addClass('off-canvas-sidebar-l').removeClass('off-canvas-sidebar');
      $('.wrapper').addClass('wrapper-l').removeClass('wrapper');
      $('.spHover').addClass('ltr').removeClass('rtl');
      $('.mb').addClass('text-left').removeClass('text-right');
      $('.main-panel').addClass('main-panel-l').removeClass('main-panel');
      $('.bootstrap-navbar').addClass('bootstrap-navbar-l').removeClass('bootstrap-navbar');
      $('.navbar-header').addClass('navbar-header-l').removeClass('navbar-header');
      $('.navbar .container-fluid').addClass('container-fluid-l');
      $('.navbar-header-l > a.ml-2.display-inline-block').removeClass('ml-2');
      $('.navbar-header-l > a.display-inline-block').addClass('mr-2');
      $('.langimg').addClass('langimg-l').removeClass('langimg');
      $('.copyright').addClass('text-center');
      $('.dropdown-menu').addClass('text-left');
      $('.media-body i').addClass('smallf');
      $('aw-wizard-navigation-bar').attr('dir', 'ltr');
    } else if (lang === 'ar') {
      // Update CSS classes for Arabic language direction
      $('.navbar-toggle').removeClass('float-right').addClass('float-left');
      $('.customizer-l').addClass('customizer').removeClass('customizer-l');
      $('.app-sidebar-l').addClass('app-sidebar').removeClass('app-sidebar-l');
      $('.off-canvas-sidebar-l').addClass('off-canvas-sidebar').removeClass('off-canvas-sidebar-l');
      $('.wrapper-l').addClass('wrapper').removeClass('wrapper-l');
      $('.main-panel-l').addClass('main-panel').removeClass('main-panel-l');
      $('.bootstrap-navbar-l').addClass('bootstrap-navbar').removeClass('bootstrap-navbar-l');
      $('.navbar .container-fluid').removeClass('container-fluid-l');
      $('.navbar .navbar-container').removeClass('navbar-container-l');
      $('.navbar-header-l').addClass('navbar-header').removeClass('navbar-header-l');
      $('.navbar-header-l > a.mr-2.display-inline-block').removeClass('mr-2');
      $('.navbar-header-l > a.display-inline-block').addClass('ml-2');
      $('.langimg-l').addClass('langimg').removeClass('langimg-l');
      $('.dropdown-menu').removeClass('text-left');
      $('.copyright').removeClass('text-center');
      $('.media-body i').removeClass('smallf');
      $('.spHover').removeClass('ltr').addClass('rtl');
      $('.mb').removeClass('text-left').addClass('text-right');
    }
  }

  /**
   * Toggle navigation menu between maximized and minimized states.
   */
  ToggleClass() {
    this.toggleClass = this.toggleClass === 'ft-maximize' ? 'ft-minimize' : 'ft-maximize';
  }
}
