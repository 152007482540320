import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgoraService {
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Fetch RTM token for a doctor
   * @param body - contains the mobile and code
   * @returns Observable with RTM token details
   */
  getRTMTokenDoctor(body) {
    return this.http.post<{
      rtmToken: string;
      uid: string;
      channelName: string;
      doctorCode: string;
      rtcToken: string;
    }>(
      `${environment.apiUrl}/online-sessions/rtm-doctor-token`,
      { mobile: body['mobile'], code: body['code'] },
      this.httpOptionsPost
    );
  }

  /**
   * Fetch RTM token for a patient
   * @param body - contains patient details
   * @returns Observable with RTM token details
   */
  getRTMTokenPatient(body) {
    return this.http.post<{
      rtmToken: string;
      uid: string;
      channelName: string;
      profileNo: string;
      doctorCode: string;
      rtcToken: string;
    }>(
      `${environment.apiUrl}/online-sessions/rtm-patient-token`,
      body,
      this.httpOptionsPost
    );
  }

  /**
   * Authenticate patient information
   * @param patientInfo - contains patient information
   * @returns Observable with patient authentication details
   */
  patientAuth(patientInfo) {
    return this.http.post<{
      DR_CODE: string;
      PAT_CODE: string;
      PAT_ENAME: string;
      PAT_ANAME: string;
      MOBILE: string;
      TRS_DATE: string;
      TRS_COUNT: string;
    }>(
      `${environment.apiUrl}/online-sessions/visitors`,
      patientInfo,
      this.httpOptionsPost
    );
  }

  /**
   * Authenticate doctor information
   * @param docInfo - contains doctor information
   * @returns Observable with doctor authentication details
   */
  doctorAuth(docInfo) {
    return this.http.post<{
      USR_CODE: string;
      TEL_SMS: string;
      DOC_ANAME: string;
      DOC_ENAME: string;
    }>(
      `${environment.apiUrl}/online-sessions/doctors`,
      docInfo,
      this.httpOptionsPost
    );
  }

  /**
   * Verify the doctor's code
   * @param body - contains verification code details
   * @returns Observable with verification status
   */
  verifyCode(body) {
    return this.http.post<{ auth: boolean }>(
      `${environment.apiUrl}/online-sessions/doctor-verify-code`,
      body,
      this.httpOptionsPost
    );
  }
}
