import { Component, OnInit } from '@angular/core';
import { LocalService } from 'app/local.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AgoraService } from '../agora.service';
import { Router } from '@angular/router';
import { Langs } from 'app/home/language.interface';

@Component({
  selector: 'app-doctor-login',
  templateUrl: './doctor-login.component.html',
  styleUrls: ['./doctor-login.component.scss'],
})
export class DoctorLoginComponent implements OnInit {
  doctorAuth = {
    mobile: null,
    code: null,
    isDoctor: true,
    arabicName: null,
    englishName: null,
  };
  
  doctorInfoForm: FormGroup;
  acForm: FormGroup;
  mobilePattern = /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;
  isError = 0;
  lang: string;
  dir: string;
  isAr: boolean;

  loginSuccess: boolean;
  done: boolean;
  verified: boolean;
  stepTwo = false;

  constructor(
    private localService: LocalService,
    private _dataService: AgoraService,
    private router: Router
  ) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    this.isAr = this.lang === Langs.AR;

    // Initialize form groups
    this.doctorInfoForm = new FormGroup({
      code: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      mobileNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(this.mobilePattern),
      ]),
    });

    this.acForm = new FormGroup({
      activationCode: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.localService.removeData('srd');
    this.localService.removeData('srp');
  }

  // Fetch doctor data for authentication
  getDoctorData() {
    let code = this.toEnglishDigits(this.doctorInfoForm.controls['code'].value);
    let mobileNumber = this.toEnglishDigits(
      this.doctorInfoForm.controls['mobileNumber'].value
    );
    
    this._dataService
      .doctorAuth({
        code: code.toString().toUpperCase().trim(),
        mobile: mobileNumber.toString().toUpperCase().trim(),
      })
      .subscribe(
        (r) => {
          if (r) {
            this.doctorAuth.mobile = this.doctorInfoForm.controls['mobileNumber'].value.toString().trim();
            this.doctorAuth.code = this.doctorInfoForm.controls['code'].value.toString().toUpperCase().trim();
            this.doctorAuth.arabicName = r.DOC_ANAME.trim().replace('د', '').replace('.', '');
            this.doctorAuth.englishName = r.DOC_ENAME.trim().replace('DR', '').replace('.', '');
            this.loginSuccess = true;
            this.done = true;
            this.localService.setJsonValue('srd', this.doctorAuth);
            setTimeout(() => {
              this.router.navigate(['e-services/UDHLive/doctor-room']);
            }, 3000);
          } else {
            this.loginSuccess = false;
            this.done = true;
          }
        },
        (err) => {
          this.loginSuccess = false;
        }
      );
  }

  // Convert Arabic-Indic digits to their equivalent English digits
  toEnglishDigits(str: string) {
    let e = '٠'.charCodeAt(0);
    return str.replace(/[٠-٩]/g, (t) => String(t.charCodeAt(0) - e));
  }

  verify() {
    // if (this.acForm.valid && this.loginSuccess) {
    //   this._dataService
    //     .verifyCode({
    //       mobile: this.doctorAuth.mobile,
    //       vCode: this.acForm.controls['activationCode'].value,
    //     })
    //     .subscribe(
    //       (result) => {
    //         if (result && result.auth) {
    //           this.verified = true;
    //           this.stepTwo = true;
    //           this.localService.setJsonValue('srd', this.doctorAuth);
    //           setTimeout(() => {
    //             this.router.navigate(['e-services/doctor-room']);
    //           }, 3000);
    //         } else {
    //           this.verified = false;
    //           this.stepTwo = true;
    //         }
    //       },
    //       (err) => {
    //         this.verified = false;
    //       }
    //     );
    // }
  }
}