import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to control the visibility of a loading spinner.
 * @@Injectable
 */
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  /**
   * Observable boolean to represent the spinner state.
   * @type {BehaviorSubject<boolean>}
   */
  spinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Constructor for the SpinnerService.
   */
  constructor() {}

  /**
   * Show the spinner by setting the spinner state to true.
   */
  show(): void {
    this.spinner.next(true);
  }

  /**
   * Hide the spinner by setting the spinner state to false.
   */
  hide(): void {
    this.spinner.next(false);
  }
}
