// Importing necessary modules and components from Angular and third-party libraries
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { UserType } from './core/enums/user-type.enum';
import {
  AuthGuard,
  ReverseAuthGuard,
  RoleGuard,
} from './core/guards/auth.guard';
import { InvoicesComponent } from './payments/invoices/invoices.component';
import { CheckoutComponent } from './payments/checkout/checkout.component';
import { DoneComponent } from './payments/done/done.component';
import { ErrorComponent } from './payments/error/error.component';
import { CanceledComponent } from './payments/canceled/canceled.component';
import { EPublicServicesComponent } from './home/e-public-services/e-public-services.component';
import { PublicLabsComponent } from './home/e-public-services/public-labs/public-labs.component';
import { PublicLabDetailsComponent } from './home/e-public-services/public-lab-details/public-lab-details.component';
import { PublicReadiologyComponent } from './home/e-public-services/public-readiology/public-readiology.component';
import { PublicReadiologyDetailsComponent } from './home/e-public-services/public-readiology-details/public-readiology-details.component';
import { HolderComponent } from './payments/holder/holder.component';
import { CompletedComponent } from './payments/completed/completed.component';
import { PaymentErrorComponent } from './payments/payment-error/payment-error.component';
import { PublicOrdersComponent } from './home/e-public-services/public-orders/public-orders.component';
import { HyperHolderComponent } from './payments/hyper-holder/hyper-holder.component';
import { HyperErrorComponent } from './payments/hyper-error/hyper-error.component';
import { HyperCompleteComponent } from './payments/hyper-complete/hyper-complete.component';
import { PaymentMethodComponent } from './payments/payment-method/payment-method.component';
const appRoutes: Routes = [
  {
    path: 'e-public',
    component: EPublicServicesComponent,
  },
  {
    path: 'e-public/labs',
    component: PublicLabsComponent,
  },
  {
    path: 'e-public/lab-details',
    component: PublicLabDetailsComponent,
  },
  {
    path: 'e-public/radiology',
    component: PublicReadiologyComponent,
  },
  {
    path: 'e-public/radiology-details',
    component: PublicReadiologyDetailsComponent,
  },
  {
    path: 'e-public/orders',
    component: PublicOrdersComponent,
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/e-services', pathMatch: 'full' },
      {
        path: 'admin',
        data: {
          role: [
            UserType.Admin,
            UserType.EnayaStoreManagement,
            UserType.RestaurantManagement,
            UserType.GalleryManagement,
            UserType.CustomerServices,
            UserType.DepartmentsManagers,
          ],
        },
        canActivate: [RoleGuard],
        loadChildren: './admin/admin.module#AdminModule',
      },
      {
        path: 'payments',
        component: FullLayoutComponent,
        data: { title: 'المدفوعات الإلكترونية' },
        loadChildren: './payments/payments.module#PaymentsModule',
      },
    ],
  },
  {
    path: 'p/:invoiceId',
    redirectTo: 'payments/invoices/:invoiceId',
    pathMatch: 'full',
  },

  {
    path: 'payments',
    component: FullLayoutComponent,
    children: [
      {
        path: 'invoices/:invoiceId',
        component: InvoicesComponent,
        data: {
          title: 'الفواتير',
        },
      },
      {
        path: 'done/:transCount',
        component: DoneComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'failed/:transCount',
        component: ErrorComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'canceled/:transCount',
        component: CanceledComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'redirect',
        component: HolderComponent,
        data: {
          title: 'صفحة الدفع',
        },
      },
      {
        path: 'completed/:transCount',
        component: CompletedComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'payment-error/:transCount',
        component: PaymentErrorComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'hyper-holder',
        component: HyperHolderComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'hyper-error/:transCount',
        component: HyperErrorComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'hyper-complete/:transCount',
        component: HyperCompleteComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
      {
        path: 'payment-method/:transCount',
        component: PaymentMethodComponent,
        data: {
          title: 'المدفوعات الإلكترونية',
        },
      },
    ],
  },

  {
    path: 'payments',
    component: FullLayoutComponent,
    children: [
      {
        path: 'checkout/:invoiceId',
        component: CheckoutComponent,
        data: {
          title: 'ملخص الفاتورة',
        },
      },
    ],
  },

  {
    path: 'e-services',
    component: FullLayoutComponent,
    canActivate: [ReverseAuthGuard],
    data: { title: 'عناية متجددة للرعاية الطبية المنزلية' },
    children: Full_ROUTES,
  },

  {
    path: '**',
    redirectTo: 'e-services',
    pathMatch: 'full',
  },
  // {
  //   path: '',
  //   component: ContentLayoutComponent,
  //   data: { title: 'content Views' },
  //   children: CONTENT_ROUTES,
  //   canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
