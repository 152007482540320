// Import necessary Angular modules and services
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService, ICustomerInvoice } from '../payment.service';
import Swal from 'sweetalert2';
import { Invoice } from '@axenda/zatca';
import { retry } from 'rxjs/operators';
import { decode } from 'app/utils';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

/**
 * Component for handling and displaying invoices.
 * @component
 */
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  /**
   * The ID of the invoice.
   * @type {any}
   */
  invoiceId: any;

  /**
   * Data related to the invoice.
   * @type {any}
   */
  invoiceData: any = {};

  /**
   * Flag to indicate if the payment loader is active.
   * @type {boolean}
   */
  payLoader: boolean;

  /**
   * Current language setting.
   * @type {any}
   */
  lang: any;

  /**
   * Image data for the QR code.
   * @type {any}
   */
  imageData: any;

  /**
   * User ID of the current user.
   * @type {any}
   */
  userId: any;

  /**
   * Model for payment options.
   * @type {any}
   */
  payOptionsModel: any;

  /**
   * The selected payment type.
   * @type {any}
   */
  paymentType: any;

  /**
   * Flag to indicate if payment is loading.
   * @type {boolean}
   */
  isLoadingPayment: boolean = false;

  /**
   * Hyperlink for the payment URL.
   * @type {string}
   */
  hyperlink: string = '';

  /**
   * Flag to show or hide the HyperPay form.
   * @type {boolean}
   */
  showHyperForm: boolean = false;

  /**
   * Flag to indicate if the script is appended.
   * @type {boolean}
   */
  isScriptAppended: boolean = false;

  /**
   * Constructor with dependency injection.
   * @param {NgbModal} modalService - The modal service.
   * @param {ActivatedRoute} route - The activated route service.
   * @param {Router} router - The router service.
   * @param {TranslateService} translate - The translation service.
   * @param {PaymentService} paymentService - The payment service.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   * @param {Renderer2} _renderer2 - The renderer service.
   * @param {Document} _document - The document object.
   */
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private paymentService: PaymentService,
    private apiHandler: ApiHandlerService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    // Subscribe to route params to get the invoiceId and navigate accordingly
    this.route.params.subscribe(
      (params) => {
        this.invoiceId = params.invoiceId;
        if (!this.invoiceId || this.invoiceId.trim() === null) {
          this.router.navigate(['e-services']);
        } else {
          this.getInvoiceDetails();
        }
      },
      (err) => {
        this.router.navigate(['e-services']);
      }
    );

    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @returns {void}
   */
  ngOnInit(): void {
    // Check if currentUser is in localStorage and decode it
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
    // this.getQrCode();
  }

  /**
   * Asynchronously generates a QR code for the invoice.
   * @returns {Promise<void>}
   */
  async getQrCode(): Promise<void> {
    const invoice = new Invoice({
      sellerName: 'Enaya for Home Medical Care',
      vatRegistrationNumber: '300185964100003',
      invoiceTimestamp: this.invoiceData.trs_date,
      invoiceTotal: this.invoiceData.totals.totalinvoice,
      invoiceVatTotal: this.invoiceData.totals.totalvat,
    });
    // Convert invoice details to TLV format and then to Base64
    invoice.toTlv();
    invoice.toBase64();

    // Render the QR code and store the image data
    this.imageData = await invoice.render();
  }

  /**
   * Fetches invoice details using the API.
   * @returns {void}
   */
  getInvoiceDetails(): void {
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.invoiceId }).subscribe(
      (invoice) => {
        this.invoiceData = invoice;
        this.getQrCode();
        // Mask part of the customer_id for privacy
        const idNumber: string = this.invoiceData.customer_id;
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      },
      (err) => {
        // Handle error using SweetAlert2 library
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            // Navigate based on user authentication status
            this.userId ? this.router.navigate(['/e-services']) : this.router.navigate(['/e-public']);
          }
        });
      }
    );
  }

  /**
   * Navigates to the invoice details page.
   * @returns {void}
   */
  goToDetails(): void {
    this.router.navigate([`payments/checkout/${this.invoiceId}`]);
  }

  /**
   * Navigates home based on user authentication status.
   * @returns {void}
   */
  goToHome(): void {
    this.modalService.dismissAll();
    this.userId
      ? this.router.navigate(['/e-services/invoices'])
      : this.router.navigate(['/e-public']);
  }

  /**
   * Places an order and navigates to the payment method page.
   * @returns {void}
   */
  placeOrder(): void {
    this.router.navigate([`/payments/payment-method/${this.invoiceId}`]);
  }

  /**
   * Initiates the payment process based on the selected payment type.
   * @returns {void}
   */
  payNow(): void {
    this.isLoadingPayment = true;
    if (
      this.paymentType &&
      this.paymentType == 1 &&
      this.invoiceData.trs_count
    ) {
      // Prepare parameters for Urway payment
      const params = {
        trs_count: this.invoiceData.trs_count.toString(),
        payment_page_lang: this.lang,
      };

      // Make a request to the Urway payment API
      this.apiHandler.makeUrwayPayment(params).subscribe((data: any) => {
        if (data && data.status && data.paymentUrl) {
          window.location.href = data.paymentUrl;
          this.isLoadingPayment = false;
        }
      });
    }

    // Check if the paymentType is 2 or 3 (Telr payment)
    if (
      this.paymentType &&
      (this.paymentType == 2 || this.paymentType == 3) &&
      this.invoiceData.trs_count
    ) {
      const params = {
        trs_count: this.invoiceData.trs_count.toString(),
        payment_page_lang: this.lang,
      };
      // Make a request to the Telr payment API
      this.apiHandler.makeTelrPayment(params).subscribe((data: any) => {
        if (data && data.status && data.paymentUrl) {
          window.location.href = data.paymentUrl;
          this.isLoadingPayment = false;
        }
      });
    }

    // Check if the paymentType is 4 (Hyper payment)
    if (
      this.paymentType &&
      this.paymentType == 4 &&
      this.invoiceData.trs_count
    ) {
      this.isLoadingPayment = true;
      const params = {
        trs_count: this.invoiceData.trs_count.toString(),
        payment_page_lang: this.lang,
      };
      // Make a request to the Hyper payment API
      this.apiHandler.makeHyperPayment(params).subscribe((data: any) => {
        if (data && data.status == true) {
          this.hyperlink = data.paymentUrl;
          this.registerScript();
          this.showHyperForm = true;
        }
      });
    }
  }

  /**
   * Gets the Telr payment page.
   * @returns {void}
   */
  getTelrPaymentPage(): void {
    const order: ICustomerInvoice = {
      customerIdNumber: this.invoiceData.customer_id,
      customerMobileNumber: this.invoiceData.mobileNumber,
      customerProfileNumber: this.invoiceData.customerid,
      transCount: this.invoiceData.trs_count,
    };
    this.paymentService
      .getTelrPaymentPage(order)
      .pipe(retry())
      .subscribe((newOrder) => {
        if (newOrder && newOrder.order) {
          window.location.href = newOrder.order.url;
        }
      });
  }

  /**
   * Gets the Hyperpay payment page by making a request to the payment service.
   * @returns {void}
   */
  getHyperpayPaymentPage(): void {
    const order: ICustomerInvoice = {
      customerIdNumber: this.invoiceData.customer_id,
      customerMobileNumber: this.invoiceData.mobileNumber,
      customerProfileNumber: this.invoiceData.customerid,
      transCount: this.invoiceData.trs_count,
    };
    // Make a request to get Hyperpay payment page    
    this.paymentService
      .getHyperPayPaymentPage(order)
      .pipe(retry())
      .subscribe((newOrder) => {
        if (newOrder && newOrder.checkoutId) {
          this.router.navigate(['payments/pay/' + newOrder.checkoutId]);
        }
      });
  }

  /**
   * Registers scripts for Hyper payment.
   * @returns {void}
   */
  registerScript(): void {
    // Create and append script for Hyper payment
    const script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${this.hyperlink}`;
    this._renderer2.appendChild(this._document.body, script);

    // Create and append additional script for Hyper payment options
    const script1 = this._renderer2.createElement('script');
    script1.type = 'text/javascript';
    script1.text = ` var wpwlOptions = {
      style:"plain", 
      locale: '${this.lang}',
        brandDetection: true, 
        brandDetectionType: "binlist",
        brandDetectionPriority: ["MADA","VISA","MASTER","AMEX", "APPLEPAY"],
        showCVVHint: true,
         imageStyle: "svg",
}`;
    this._renderer2.appendChild(this._document.body, script1);
    this.isScriptAppended = true;
  }
}
