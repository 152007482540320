// Importing necessary modules and components from Angular and third-party libraries
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';

/**
 * Component for tracking the status of orders using a wizard interface.
 * 
 * @example
 * <app-order-tracking [orderStatus]="status"></app-order-tracking>
 */
@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {
  /**
   * The status of the current order.
   * 
   * @type {any}
   */
  @Input() orderStatus: any;

  /**
   * Array of wizard steps for the order tracking process.
   * 
   * @type {Array<Object>}
   */
  wizardSteps: Array<Object>;

  /**
   * Reference to the WizardComponent instance.
   * 
   * @type {WizardComponent}
   */
  @ViewChild(WizardComponent) public wizard: WizardComponent;

  /**
   * The mode for wizard navigation.
   * 
   * @type {string}
   */
  navigationMode = "free";

  /**
   * The current language setting.
   * 
   * @type {string}
   */
  lang: string;

  /**
   * The direction of text based on the language setting.
   * 
   * @type {string}
   */
  dir: string;

  /**
   * Indicates whether data is currently loading.
   * 
   * @type {boolean}
   */
  isLoading: boolean;

  /**
   * The status text to be displayed.
   * 
   * @type {string}
   */
  statusText = '';

  /**
   * Indicates whether the current page is the "My Reservation" page.
   * 
   * @type {boolean}
   */
  isMyReservationPage = false;

  /**
   * Constructs the OrderTrackingComponent and initializes services.
   * 
   * @param {Router} router - The Angular router for navigation.
   */
  constructor(private router: Router) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Sets up the wizard and subscribes to changes in the order status.
   * 
   * @returns {void}
   */
  ngOnInit(): void {
    this.isLoading = true;

    // Set additional styles based on language direction
    if (this.lang === 'en') {
      $('aw-wizard').attr('dir', 'ltr');
      $('ng-select').attr('dir', 'ltr');
      $('.ng-option-label').addClass('text-left');
    } else {
      $('aw-wizard').removeAttr('dir');
      $('ng-select').removeAttr('dir');
      $('.ng-option-label').removeClass('dir');
    }

    // Check if the page is for My Reservation
    if (this.router.url.indexOf('my-reservation') > -1) {
      this.isMyReservationPage = true;
    }

    // Simulate loading for 2 seconds
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);

    // Simulate setting up the wizard after 2 seconds
    setTimeout(() => {
      this.wizard.navigationMode = 'free';
      if (this.orderStatus) {
        // Check different CLOSE_TYPE values and update wizard accordingly
        // Navigate to specific steps based on CLOSE_TYPE
        if (this.orderStatus.CLOSE_TYPE === 1) {
          this.wizard.wizardSteps.forEach((el, id) => {
            if (id < this.orderStatus.CLOSE_TYPE - 1) {
              el.completed = true;
            }
          });
          this.statusText = this.lang === 'en' ? this.orderStatus.STATUS_EN : this.orderStatus.STATUS_AR;

        } else if (this.orderStatus.CLOSE_TYPE === 2) {
          this.statusText = this.lang === 'en' ? this.orderStatus.STATUS_EN : this.orderStatus.STATUS_AR;

          this.wizard.wizardSteps.forEach((el, id) => {
            if (id < this.orderStatus.CLOSE_TYPE - 1) {
              el.completed = true;
            }
          });
          this.wizard.model.navigationMode.goToStep(1);
        } else if (this.orderStatus.CLOSE_TYPE === 3) {
          this.statusText = this.lang === 'en' ? this.orderStatus.STATUS_EN : this.orderStatus.STATUS_AR;

          this.wizard.wizardSteps.forEach((el, id) => {
            if (id < this.orderStatus.CLOSE_TYPE - 1) {
              el.completed = true;
            }
          });
          this.wizard.model.navigationMode.goToStep(1);
          this.wizard.model.navigationMode.goToStep(2);

        } else if (this.orderStatus.CLOSE_TYPE === 4) {
          this.statusText = this.lang === 'en' ? this.orderStatus.STATUS_EN : this.orderStatus.STATUS_AR;

          this.wizard.wizardSteps.forEach((el, id) => {
            if (id < this.orderStatus.CLOSE_TYPE) {
              el.completed = true;
            }
          });
          this.wizard.model.navigationMode.goToStep(3);
        } else if (this.orderStatus.CLOSE_TYPE === 5) {
          this.statusText = this.lang === 'en' ? this.orderStatus.STATUS_EN : this.orderStatus.STATUS_AR;

          this.wizard.model.wizardSteps.forEach(el => {
            el.completed = false;
          });

          $("li").removeClass("done navigable");
          $("li").removeClass("current");
          $("li").addClass("editing");
        }
      }
    }, 2000);
  }

  /**
   * Array to hold order status information for different steps.
   * 
   * @type {Array<Object>}
   */
  orderStatusArr = [
    { value: 1, class: "status-bl", enStatus: "New order", arStatus: "طلب جديد" },
    { value: 2, class: "status-or", enStatus: "Review the request", arStatus: "مراجعة الطلب" },
    { value: 3, class: "status-or", enStatus: "Providing the service", arStatus: "تقديم الخدمة" },
    { value: 4, class: "status-gr", enStatus: "Close Order", arStatus: "إغلاق الطلب" },
    { value: 5, class: "status-rd", enStatus: "Order Canceled", arStatus: "الطلب ملغي" }
  ];
}
