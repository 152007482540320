import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { environment } from 'environments/environment';
import {
  AgoraClient,
  ClientEvent,
  NgxAgoraService,
  Stream,
  StreamEvent,
} from 'ngx-agora';
import * as AgoraRTM from 'agora-rtm-sdk';

import { BaseComponent } from 'app/home/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  SnotifyService,
  SnotifyPosition,
  SnotifyToastConfig,
} from 'ng-snotify';

import { AgoraService } from '../agora.service';
import { interval, from } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import swal from 'sweetalert2';
import { CdTimerComponent } from 'angular-cd-timer';
import { LocalService } from 'app/local.service';
$.getScript('./assets/js/dr-room.js');
@Component({
  selector: 'app-doctor-room',
  templateUrl: './doctor-room.component.html',
  styleUrls: ['./doctor-room.component.scss'],
})
export class DoctorRoomComponent extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  audioRemoteName: string;
  @ViewChild('basicTimer') timer: CdTimerComponent;
  sessionTime: '00:00';
  Toast = swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
  });
  doctorAuth = {
    mobile: null,
    code: null,
    isDoctor: null,
    arabicName: null,
    englishName: null,
  };
  patientAuth = {
    mobile: null,
    doctorCode: null,
    transNo: null,
    profileNo: null,
    arabicName: null,
    englishName: null,
  };
  factors: IRTMC = {
    appId: environment.agora.appId,
    channelName: null,
    doctorId: null,
    doctorRtmToken: null,
    doctorCode: null,
    doctorRtcToken: null,
    patientRtcToken: null,
  };

  doctorRtmChannel;
  patientRtmChannel: any;

  private doctorRtmClient;
  private patientRtmClient;
  private doctorStream: Stream;
  private patientStream: Stream;
  patientsList: any[] = [];
  settings = {
    sessionStart: false,
    audioVolume: 50,
    streaming: false,
    isMuted: false,
    cameraOn: true,
    micOn: true,
  };
  currentUserIsDoctor = false;
  onlinePatientsNumber = 0;
  rtcClient: AgoraClient;
  isDoctorOnline = false;
  alive = true;
  private outgoingCallAudio = new Audio('./assets/sounds/duo_outgoing.mp3');
  private incomingCallAudio = new Audio('./assets/sounds/duo_incoming.mp3');
  patientCallnvitation: any;
  localInvitation = null;
  PatientInvitation = null;
  startTimer: boolean;
  doctorHasSession = false;
  mediaAllowed: boolean;
  playOutDevices: MediaDeviceInfo[] = [];
  recordingDevices: MediaDeviceInfo[] = [];
  cameras: MediaDeviceInfo[] = [];
  remoteStream: Stream;
  loading = true;
  currentPatient: any;
  /*
    Change global configuration
     */
  getConfig(): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: true,
        maxAtPosition: 2,
        maxOnScreen: 2,
      },
    });
    return {
      bodyMaxLength: 10,
      titleMaxLength: 10,
      backdrop: -1,
      position: SnotifyPosition.centerTop,
      timeout: 1000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    };
  }
  constructor(
    private ngxAgoraService: NgxAgoraService,
    public translate: TranslateService,
    private snotifyService: SnotifyService,
    private location: Location,
    private router: Router,
    private agoraService: AgoraService,
    private localService: LocalService
  ) {
    super();

    // $.getScript('./assets/js/perfect-scrollbar.jquery.min.js').then((f) => {
    // });
    if (this.localService.getJsonValue('srd')) {
      this.doctorAuth = this.localService.getJsonValue('srd');
      if (this.doctorAuth.code && this.doctorAuth.mobile) {
        this.currentUserIsDoctor = this.doctorAuth.isDoctor;
      }
    } else if (this.localService.getJsonValue('srp')) {
      this.patientAuth = this.localService.getJsonValue('srp');
      this.currentUserIsDoctor = false;
    } else {
      this.router.navigate(['/e-services']);
    }
    this.ngxAgoraService.AgoraRTC.Logger.setLogLevel(
      this.ngxAgoraService.AgoraRTC.Logger.NONE
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event) {
    this.unloadPage();
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler($event) {
    this.unloadPage();
  }
  ngOnDestroy() {
    this.unloadPage();
  }
  ngOnInit() {
    this.createClientRTM();
    interval(300000)
      .pipe(takeWhile((a) => this.alive))
      .subscribe((x) => {
        // something
        if (this.patientsList.length > 0) {
          this.playBeep();
          // this.snotifyService.warning('There are patients in waiting room', {
          //   bodyMaxLength: 100,
          //   timeout: 2000,
          //   showProgressBar: false,
          //   position: SnotifyPosition.leftTop,
          // });
          this.Toast({
            titleText: 'There are patients in waiting room',
            type: 'warning',
          });
        }
      });
  }
  ngAfterViewInit() {}

  // Function to perform cleanup and logout actions when unloading the page.
  unloadPage() {
    if (this.currentUserIsDoctor) {
      this.localService.removeData('srd');
      if (this.doctorRtmChannel) {
        this.doctorRtmChannel.leave();
      }
      if (this.doctorRtmClient) {
        this.doctorRtmClient.logout();
      }
    } else {
      // this.localService.removeData('srp');
      if (this.patientRtmChannel) {
        this.patientRtmChannel.leave();
      }
      if (this.patientRtmClient) {
        this.patientRtmClient.logout();
      }
    }
    if (this.rtcClient) {
      if (this.currentUserIsDoctor) {
        this.rtcClient.unpublish(this.doctorStream);
        this.rtcClient.leave(() => {
          this.doctorStream.stopAllEffects(() => {});
          this.doctorStream.stop();
          this.doctorStream.close();
        });
      } else {
        this.rtcClient.unpublish(this.patientStream);
        this.rtcClient.leave(() => {
          this.patientStream.stopAllEffects(() => {});
          this.patientStream.stop();
          this.patientStream.close();
        });
      }
    }
    this.alive = false;
    this.patientsList = [];
    this.doctorHasSession = false;
  }
  /**
   * RTM Section
   */
  // Function to create and configure the Agora RTM (Real-Time Messaging) client.
  createClientRTM() {
    if (this.currentUserIsDoctor) {
      this.agoraService.getRTMTokenDoctor(this.doctorAuth).subscribe((rtm) => {
        if (rtm) {
          this.factors.doctorRtmToken = rtm.rtmToken;
          this.factors.doctorId = rtm.uid;
          this.factors.channelName = rtm.channelName;
          this.factors.doctorCode = rtm.doctorCode;
          this.factors.doctorRtcToken = rtm.rtmToken;
          this.doctorRtmClient = AgoraRTM.createInstance(this.factors.appId, {
            logFilter: {
              debug: false,
              error: false,
              info: false,
              track: false,
              warn: false,
            },
          });
          this.doctorRtmClient.on(
            'ConnectionStateChanged',
            (newState, reason) => {
              // console.log(
              //   'on connection state changed to ' +
              //     newState +
              //     ' reason: ' +
              //     reason
              // );
            }
          );

          this.doctorRtmClient
            .login({
              uid: this.factors.doctorId,
              token: this.factors.doctorRtmToken,
            })
            .then(
              () => {
                // console.log('AgoraRTM client login success');
                this.doctorRtmClient.setLocalUserAttributes({
                  ['doctorArabicName']: this.doctorAuth.arabicName.toString(),
                  ['doctorEnglishName']: this.doctorAuth.englishName.toString(),
                });
                this.doctorRtmChannel = this.doctorRtmClient.createChannel(
                  this.factors.doctorCode
                );
                this.doctorRtmChannel.on('MemberJoined', (id) => {
                  if (
                    this.patientsList.findIndex((p) => p.patientId === id) ===
                    -1
                  ) {
                    this.doctorRtmClient.getUserAttributes(id).then((attr) => {
                      this.patientsList.push({
                        patientId: attr.patientId,
                        englishName: attr.englishName,
                        arabicName: attr.arabicName,
                        profile: attr.profile,
                      });
                    });
                    this.playBeep();
                    this.Toast({
                      titleText: 'New Patient Joined',
                      type: 'warning',
                      timer: 2000,
                    });
                  }
                });
                this.doctorRtmChannel.on('MemberLeft', (id) => {
                  const i = this.patientsList.findIndex((f) => f === id);
                  this.patientsList.splice(i, 1);
                });
                from(this.doctorRtmChannel.join()).subscribe(
                  () => {
                    this.doctorRtmChannel
                      .getMembers()
                      .then((patients: string[]) => {
                        this.patientsList = [];
                        this.patientsList.push(
                          ...patients.filter((f) => f !== this.factors.doctorId)
                        );
                        this.patientsList = this.patientsList.filter(
                          (elem, index, self) => {
                            return index === self.indexOf(elem);
                          }
                        );
                        const tempPatients: any[] = [];
                        for (const patient of this.patientsList) {
                          this.doctorRtmClient
                            .getUserAttributes(patient)
                            .then((attr) => {
                              tempPatients.push({
                                patientId: patient,
                                englishName: attr.englishName,
                                arabicName: attr.arabicName,
                                profile: attr.profile,
                              });
                            });
                        }
                        this.patientsList = tempPatients;
                      });
                    this.creatClientRTC();
                  },
                  (err) => {},
                  () => {}
                );
              },
              (err) => {
                console.log(`Can't join channel : ${err}`);
              }
            );
        }
      });
    } else {
      this.agoraService
        .getRTMTokenPatient(this.patientAuth)
        .subscribe((rtm) => {
          if (rtm) {
            this.factors.patientRtmToken = rtm.rtmToken;
            this.factors.patientId = rtm.uid;
            this.factors.channelName = rtm.channelName;
            this.factors.doctorCode = rtm.doctorCode;
            this.factors.patientRtcToken = rtm.rtcToken;
            this.patientRtmClient = AgoraRTM.createInstance(
              this.factors.appId,
              {
                logFilter: {
                  debug: false,
                  error: false,
                  info: false,
                  track: false,
                  warn: false,
                },
              }
            );

            this.patientRtmClient.on(
              'ConnectionStateChanged',
              (newState, reason) => {
                // console.log(
                //   'on connection state changed to ' +
                //     newState +
                //     ' reason: ' +
                //     reason
                // );
              }
            );

            this.patientRtmClient.on('RemoteInvitationReceived', (ri) => {
              this.patientCallnvitation = ri;
              this.showPatientCallDialog();
              this.playIncomingCallAudio();
              this.patientCallnvitation.on('RemoteInvitationCanceled', () => {
                this.patientCallnvitation = null;
                swal.close(() => {
                  swal(
                    `<i class="ft-phone-missed f-18 mt-1 mr-1 text-danger"></i>`,
                    this.translate.instant('onlineSessions.canceled'),
                    'error'
                  );
                });
                this.stopIncominggCallAudio();
              });
            });
            this.patientRtmClient.on('MessageFromPeer', (message, peerId) => {
              if (message.text === 'STP') {
                // this.rtcClient.unpublish(this.patientStream);
                // this.rtcClient.leave(() => {
                //   this.patientStream.stop();
                // });
                // this.patientRtmClient.logout();
                this.timer.stop();
                this.router.navigate(['e-services/UDHLive/session-rate']);
              }
            });

            this.patientRtmClient
              .login({
                uid: this.factors.patientId,
                token: this.factors.patientRtmToken,
              })
              .then(() => {
                // console.log('AgoraRTM client login success');
                this.patientRtmClient.setLocalUserAttributes({
                  ['arabicName']: this.patientAuth.arabicName,
                  ['englishName']: this.patientAuth.englishName,
                  ['profile']: this.patientAuth.profileNo,
                  ['patientId']: this.factors.patientId,
                });
                this.patientRtmChannel = this.patientRtmClient.createChannel(
                  this.factors.channelName
                );
                this.patientRtmChannel
                  .join()
                  .then(() => {
                    this.creatClientRTC();
                    /* Your code for handling events, such as a channel message-send success. */
                    this.patientRtmClient
                      .getChannelMemberCount([this.factors.channelName])
                      .then((patientsCount) => {
                        if (patientsCount[this.factors.channelName] >= 1) {
                          this.onlinePatientsNumber =
                            patientsCount[this.factors.channelName] - 1;
                        }
                      });
                    this.patientRtmClient
                      .subscribePeersOnlineStatus([this.factors.doctorCode])
                      .then(() => {
                        this.patientRtmClient.on(
                          'PeersOnlineStatusChanged',
                          (evt) => {
                            if (evt[this.factors.doctorCode] === 'ONLINE') {
                              this.patientRtmClient
                                .getUserAttributes(this.factors.doctorCode)
                                .then((attr) => {
                                  this.doctorAuth.arabicName =
                                    attr.doctorArabicName;
                                  this.doctorAuth.englishName = attr.doctorEnglishName
                                    .toString()
                                    .toLowerCase();
                                  this.isDoctorOnline = true;
                                });
                            }
                          }
                        );
                      });
                  })
                  .catch((err) => {
                    console.log('AgoraRTM client login failure', err);
                  });
              });
          }
        });
    }
  }

  // Function to play a notification beep sound.
  playBeep() {
    new Audio('./assets/sounds/duo_notification.mp3').play();
  }

  // Function to play outgoing call audio.
  playOutgoingCallAudio() {
    if (this.outgoingCallAudio) {
      this.outgoingCallAudio.loop = true;
      this.outgoingCallAudio.play();
    }
  }

  // Function to stop outgoing call audio.
  stopOutgoingCallAudio() {
    this.outgoingCallAudio.pause();
    this.outgoingCallAudio.currentTime = 0;
  }

  // Function to play incoming call audio.
  playIncomingCallAudio() {
    if (this.incomingCallAudio) {
      this.incomingCallAudio.loop = true;
      this.incomingCallAudio.play();
    }
  }

  // Function to stop incoming call audio.
  stopIncominggCallAudio() {
    this.incomingCallAudio.pause();
    this.incomingCallAudio.currentTime = 0;
  }
  /**
   * RTC Section
   */

  // Function to create Agora RTC Client.
  creatClientRTC() {
    /**************************** */
    this.rtcClient = this.ngxAgoraService.createClient({
      mode: 'rtc',
      codec: 'h264',
    });
    this.rtcClient.getPlayoutDevices((devices) => {
      this.playOutDevices = devices;
      // console.table(this.playOutDevices);
    });
    this.rtcClient.getRecordingDevices((devices) => {
      this.recordingDevices = devices;
      // console.table(this.recordingDevices);
    });

    this.rtcClient.enableAudioVolumeIndicator();
    this.assignClientHandlers();
    this.createStramRTC();
  }

  // Function to create Agora RTC Stream for the current user (doctor or patient).
  createStramRTC() {
    if (this.currentUserIsDoctor) {
      this.doctorStream = this.ngxAgoraService.createStream({
        streamID: this.factors.doctorId,
        audio: true,
        video: true,
        screen: false,
        mirror: false,
        microphoneId:
          this.recordingDevices.length > 0
            ? this.recordingDevices.findIndex((f) => f.deviceId === 'default') >
              -1
              ? 'default'
              : this.recordingDevices[0].deviceId
            : null,
      });
      // this.doctorStream.setAudioVolume(50);
      this.doctorStream.setVideoProfile('720p_3');
    } else {
      this.patientStream = this.ngxAgoraService.createStream({
        streamID: this.factors.patientId,
        audio: true,
        video: true,
        screen: false,
        mirror: true,
        microphoneId:
          this.recordingDevices.length > 0
            ? this.recordingDevices.findIndex((f) => f.deviceId === 'default') >
              -1
              ? 'default'
              : this.recordingDevices[0].deviceId
            : null,
      });
      // this.patientStream.setAudioVolume(50);
      this.patientStream.setVideoProfile('720p_3');
    }
    this.assignLocalStreamHandlers();
    if (this.currentUserIsDoctor) {
      this.initLocalStream(() => {
        this.join(
          (uid) => {},
          (error) => console.error(error)
        );
      });
    }
  }

  // Function to join the Agora RTC channel.
  join(
    onSuccess?: (uid: number | string) => void,
    onFailure?: (error: Error) => void
  ): void {
    this.rtcClient.join(
      this.currentUserIsDoctor
        ? this.factors.doctorRtcToken
        : this.factors.patientRtcToken,
      this.factors.channelName,
      this.currentUserIsDoctor ? this.factors.doctorId : this.factors.patientId,
      onSuccess,
      onFailure
    );
  }
  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    if (this.currentUserIsDoctor) {
      this.rtcClient.publish(this.doctorStream, (err) => {
        console.log('Publish local stream error: ' + err);
      });
    } else {
      this.rtcClient.publish(this.patientStream, (err) => {
        console.log('Publish local stream error: ' + err);
      });
    }
  }

  // Function assigns handlers to various events related to the Agora RTC client.
  public assignClientHandlers(): void {
    this.rtcClient.on(ClientEvent.VolumeIndicator, function (evt) {
      evt.attr.forEach(function (volume, index) {
        // console.log(`${index} UID ${volume.uid} Level ${volume.level}`);
      });
    });
    this.rtcClient.on(ClientEvent.LocalStreamPublished, (evt) => {
      // console.log('Publish local stream successfully');
    });

    this.rtcClient.on(ClientEvent.Error, (error) => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.rtcClient.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          (renewError) =>
            console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.rtcClient.on(ClientEvent.RemoteStreamAdded, (evt) => {
      const stream = evt.stream as Stream;
      this.rtcClient.subscribe(stream, { audio: true, video: true }, (err) => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.rtcClient.on(ClientEvent.RemoteStreamSubscribed, (evt) => {
      const stream = evt.stream as Stream;
      this.remoteStream = stream;

      stream.setAudioVolume(50);
      const id = this.getRemoteId(stream);
      this.audioRemoteName = 'audio' + id;
      stream.play('remote');
      $('#player_' + id).css('height', 'inherit');
      // $('#player_' + id).css('border-radius', '5px');
      $('#video' + id).removeAttr('style');
      $('#video' + id).attr(
        'style',
        'width:100%;height:100%;display:block;object-fit: cover;'
      );
    });

    this.rtcClient.on(ClientEvent.RemoteStreamRemoved, (evt) => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        stream.close();
        if (this.currentUserIsDoctor) {
          this.doctorHasSession = false;
          this.currentPatient = null;
          this.factors.patientId = null;
        }
        this.timer.stop();
        // if (this.currentUserIsDoctor) {
        //   this.rtcClient.unpublish(this.doctorStream);
        //   swal(
        //     `<i class="ft-phone-missed f-18 mt-1 mr-1 text-danger"></i>`,
        //     this.translate.instant('onlineSessions.patientLeave'),
        //     'error'
        //   );
        // } else {
        //   this.rtcClient.unpublish(this.patientStream);
        //   swal(
        //     `<i class="ft-phone-missed f-18 mt-1 mr-1 text-danger"></i>`,
        //     this.translate.instant('onlineSessions.doctorLeave'),
        //     'error'
        //   );
        // }
        // this.doctorHasSession = false;

        // this.remoteCalls = [];
        // console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });
    this.rtcClient.on(ClientEvent.PeerOnline, (evt) => {
      // console.log(evt);
    });
    this.rtcClient.on(ClientEvent.PeerLeave, (evt) => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        // this.remoteCalls = this.remoteCalls.filter(
        //   call => call !== `${this.getRemoteId(stream)}`
        // );
      }
      // console.log(`${evt.uid} left from this channel`);
    });
  }

  // Function assigns handlers to various events related to the local stream.
  private assignLocalStreamHandlers(): void {
    if (this.currentUserIsDoctor) {
      this.doctorStream.on(StreamEvent.MediaAccessAllowed, (evt) => {
        // console.log('accessAllowed');
        this.mediaAllowed = true;
      });

      // The user has denied access to the camera and mic.
      this.doctorStream.on(StreamEvent.MediaAccessDenied, () => {
        // console.log('accessDenied');
        this.mediaAllowed = false;
      });
    } else {
      this.patientStream.on(StreamEvent.MediaAccessAllowed, () => {
        // console.log('accessAllowed');
        this.mediaAllowed = true;
      });

      // The user has denied access to the camera and mic.
      this.patientStream.on(StreamEvent.MediaAccessDenied, () => {
        // console.log('accessDenied');
        this.mediaAllowed = false;
      });
    }
  }

  // Function for initializes the local stream.
  private initLocalStream(onSuccess?: () => any): void {
    if (this.currentUserIsDoctor) {
      this.doctorStream.init(
        () => {
          // The user has granted access to the camera and mic.
          this.settings.micOn
            ? this.doctorStream.unmuteAudio()
            : this.doctorStream.muteAudio();
          this.settings.cameraOn
            ? this.doctorStream.unmuteVideo()
            : this.doctorStream.muteVideo();
          this.doctorStream.play('local');

          $('#player_' + this.factors.doctorId).css('height', 'inherit');
          // $('#player_' + this.factors.doctorId).css('border-radius', '5px');
          $('#video' + this.factors.doctorId).removeAttr('style');
          $('#video' + this.factors.doctorId).attr(
            'style',
            'width:100%;height:100%;display:block;object-fit: cover;'
          );
          if (onSuccess) {
            onSuccess();
          }
        },
        (err) => console.error('getUserMedia failed', err)
      );
    } else {
      this.patientStream.init(
        () => {
          // The user has granted access to the camera and mic.
          this.settings.micOn
            ? this.patientStream.unmuteAudio()
            : this.patientStream.muteAudio();
          this.settings.cameraOn
            ? this.patientStream.unmuteVideo()
            : this.patientStream.muteVideo();
          this.patientStream.play('local');
          $('#player_' + this.factors.patientId).css('height', 'inherit');
          // $('#player_' + this.factors.patientId).css('border-radius', '5px');
          $('#video' + this.factors.patientId).removeAttr('style');
          $('#video' + this.factors.patientId).attr(
            'style',
            'width:100%;height:100%;display:block;object-fit: cover;'
          );
          if (onSuccess) {
            onSuccess();
          }
        },
        (err) => console.error('getUserMedia failed', err)
      );
    }
  }

  private getRemoteId(stream: Stream): string {
    return `${stream.getId()}`;
  }

  // Function for increases the audio volume of the remote stream by 10 units and displays a toast notification.
  volumeUp() {
    if (this.settings.audioVolume < 100) {
      this.settings.audioVolume += 10;
      this.remoteStream.setAudioVolume(this.settings.audioVolume);
      this.Toast({
        titleText: this.settings.audioVolume.toString(),
        type: 'info',
      });
    }
    if (this.settings.audioVolume > 0 && this.settings.isMuted) {
      this.remoteStream.unmuteAudio();
      this.settings.isMuted = false;
      this.Toast({
        titleText: 'Unmuted',
        type: 'info',
      });
    }
  }

  // Function for decreases the audio volume of the remote stream by 10 units and displays a toast notification.
  volumeDown() {
    if (this.settings.audioVolume > 0) {
      this.settings.audioVolume -= 10;
      this.remoteStream.setAudioVolume(this.settings.audioVolume);
      this.Toast({
        titleText: this.settings.audioVolume.toString(),
        type: 'info',
      });
    }
    if (this.settings.audioVolume === 0) {
      this.settings.isMuted = true;
      this.remoteStream.muteAudio();
      this.Toast({
        titleText: 'Muted',
        type: 'warning',
      });
    }
    // } else if (this.settings.audioVolume > 0) {
    //   this.settings.isMuted = false;
    //   this.Toast({
    //     titleText: this.settings.audioVolume.toString(),
    //     type: 'info',
    //   });
    // }
  }

  // Function to toggles the mute state of the remote stream's audio.
  swichMuteAudio() {
    if (this.settings.isMuted) {
      this.remoteStream.unmuteAudio();
      this.settings.isMuted = false;

      this.Toast({
        titleText: 'Unmuted',
        type: 'info',
      });
      if (this.settings.audioVolume <= 0) {
        this.remoteStream.setAudioVolume(50);
      }
    } else {
      this.remoteStream.muteAudio();
      this.settings.isMuted = true;
      this.Toast({
        titleText: 'Muted',
        type: 'warning',
      });
    }
  }

  // Function to mutes the microphone of the current user (doctor or patient) in the call session.
  disableMic() {
    if (this.currentUserIsDoctor) {
      this.doctorStream.muteAudio();
      this.settings.micOn = false;
    } else {
      this.patientStream.muteAudio();
      this.settings.micOn = false;
    }
  }

  // Function to unmutes the microphone of the current user (doctor or patient) in the call session.
  enableMice() {
    if (this.currentUserIsDoctor) {
      this.doctorStream.unmuteAudio();
      this.settings.micOn = true;
    } else {
      this.patientStream.unmuteAudio();
      this.settings.micOn = true;
    }
  }

  // Function to toggles the camera state of the current user (doctor or patient) in the call session.
  cameraSwich() {
    if (this.currentUserIsDoctor) {
      if (this.doctorStream.isVideoOn()) {
        this.doctorStream.muteVideo();
        this.settings.cameraOn = false;
      } else {
        this.doctorStream.unmuteVideo();
        this.settings.cameraOn = true;
      }
    } else {
      if (this.patientStream.isVideoOn()) {
        this.patientStream.muteVideo();
        this.settings.cameraOn = false;
      } else {
        this.patientStream.unmuteVideo();
        this.settings.cameraOn = true;
      }
    }
  }

  // Function to stops the streaming session, performs necessary cleanup, and sends a message to the patient to stop.
  stopStreaming() {
    this.timer.stop();
    if (this.currentUserIsDoctor) {
      this.doctorRtmClient
        .sendMessageToPeer(
          { text: 'STP' }, // An RtmMessage object.
          this.factors.patientId
        )
        .then((sendResult) => {
          if (sendResult.hasPeerReceived) {
            this.rtcClient.unpublish(this.doctorStream);
            this.doctorHasSession = false;
            this.currentPatient = null;
            this.factors.patientId = null;
          } else {
            // Your code for handling the event when the message is received by the server but the remote user cannot be reached.
          }
        })
        .catch((error) => {
          // Your code for handling the event when the message fails to be sent.
        });
    }
  }

  // Function to navigates back to the previous screen or route.
  goBack() {
    // swal()
    if (!this.currentUserIsDoctor) {
      this.localService.removeData('srp');
    }
    this.router.navigate(['e-services']);
  }

  // Function to initiates a call to the patient identified by the given ID.
  callPatient(patient) {
    if (this.currentUserIsDoctor) {
      this.factors.patientId = patient;
      this.currentPatient = this.patientsList.find(
        (f) => f.patientId === patient
      );
      console.log(this.currentPatient);

      this.localInvitation = this.doctorRtmClient.createLocalInvitation(
        patient
      );
      this.localInvitation.on('LocalInvitationRefused', (patientresponse) => {
        this.localInvitation = null;
        this.doctorHasSession = false;
        this.factors.patientId = null;
        this.stopOutgoingCallAudio();

        swal.close(() => {
          swal(
            `<i class="ft-phone-missed f-18 mt-1 mr-1 text-danger"></i>`,
            this.translate.instant('onlineSessions.rejected'),
            'error'
          );
        });
      });
      this.localInvitation.on('LocalInvitationAccepted', (patientresponse) => {
        swal.close();
        this.publish();
        this.stopOutgoingCallAudio();
        this.doctorHasSession = true;
        this.timer.start();
      });
      this.localInvitation.send();
      this.timer.reset();
      this.showDoctorCallDialog();
      this.playOutgoingCallAudio();
    }
  }

  // Function to shows a call dialog for the doctor indicating that the call is in progress.
  showDoctorCallDialog() {
    swal({
      title: '<i class="icon-call-out f-18 mt-1 mr-1 text-info"></i>',
      text: `${this.translate.instant('onlineSessions.ringing')}`,
      allowOutsideClick: false,
      backdrop: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: `${this.translate.instant('onlineSessions.endCall')}`,
    }).then((value) => {
      this.stopOutgoingCallAudio();
      if (this.localInvitation) {
        this.localInvitation.cancel();
        this.factors.patientId = null;
      }
    });
  }

  // Function to shows a call dialog for the patient allowing them to accept or reject the incoming call.
  showPatientCallDialog() {
    swal({
      title: '<i class="icon-call-in f-18 mt-1 mr-1 text-success"></i>',
      text: `${this.translate.instant('onlineSessions.incomingCall')}`,
      allowOutsideClick: false,
      backdrop: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: `${this.translate.instant('onlineSessions.reject')}`,
      confirmButtonText: `${this.translate.instant('onlineSessions.accept')}`,
    }).then((result) => {
      if (result.value) {
        if (this.patientCallnvitation) {
          this.patientCallnvitation.accept();
          this.initLocalStream(() => {
            this.join(
              (uid) => {
                this.publish();
              },
              (error) => console.error(error)
            );
          });
          this.stopIncominggCallAudio();

          this.doctorHasSession = true;
          this.timer.start();
        }
      } else {
        if (this.patientCallnvitation) {
          this.patientCallnvitation.refuse();
          this.patientCallnvitation = null;
          this.stopIncominggCallAudio();
        }
      }
    });
  }
}
export interface IMessageTemplate {
  user: string;
  message: string;
}
export interface IRTMC {
  appId: string;
  channelName: string;
  doctorRtmToken: string;
  patientRtmToken?: string;
  doctorId: string | number;
  patientId?: string | number;
  doctorRtcToken: string;
  patientRtcToken?: string;
  doctorCode: string;
}
