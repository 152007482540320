import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ICreateUser } from '../../../core/interfaces/IUser';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  /** @type {string} */
  lang: string;
  /** @type {FormGroup} */
  form: FormGroup;
  /** @type {string} */
  selectedPersonId = '';
  /** @type {RegExp} */
  mobilePattern = /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;

  /** @type {Array<Object>} */
  roles = [
    {
      id: 1,
      name: 'Admin'
    },
    {
      id: 2,
      name: 'Enaya Store Management'
    },
    {
      id: 4,
      name: 'Restaurant Management'
    },
    {
      id: 5,
      name: 'Gallery Management'
    },
    {
      id: 6,
      name: 'Customer Services'
    },
    {
      id: 7,
      name: 'Departments Managers'
    },
  ];

  /**
   * Constructor to initialize FormBuilder and UserService.
   * @param {FormBuilder} fb - Form builder to create the form.
   * @param {UserService} userService - Service to handle user-related operations.
   */
  constructor(private fb: FormBuilder,
              private userService: UserService) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
  }

  /**
   * OnInit lifecycle hook to initialize the form.
   */
  ngOnInit() {
    this.form = this.fb.group({
      userId: ['', Validators.compose([ Validators.required, Validators.maxLength(6)]) ],
      password: ['', [Validators.required, Validators.pattern(/^.*(?=.{2,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/)]],
      mobile: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(this.mobilePattern),
      ]],
      userRole: [null, [Validators.required]],
      nameAr: [''],
      nameEn: [''],
    });
  }

  get userId() { return this.form.get('userId'); }
  get password() { return this.form.get('password'); }
  get mobile() { return this.form.get('mobile'); }
  get role() { return this.form.get('userRole'); }
  get name() { return this.form.get('nameEn'); }
  get nameAr() { return this.form.get('nameAr'); }

  /**
   * Function to clear/reset the form.
   */
  clear = () => {
    this.form.reset();
  }

  /**
   * Function to handle form submission for creating a user using userService.
   * @returns {boolean} - Returns false if the form is invalid.
   */
  onSubmit() {
    if (this.form.invalid) {
      return false;
    }

    const body: ICreateUser = {
      userType: this.role.value,
      mobile: this.mobile.value,
      password: this.password.value,
      udhId: this.userId.value,
      surname: this.name.value,
      surnameAr: this.nameAr.value,
    };
    this.userService.createUser(body, this.clear);
  }

  /**
   * Function to handle keypress events allowing only alphanumeric and specific Arabic characters.
   * @param {KeyboardEvent} event - The keyboard event.
   * @returns {boolean} - Returns true if the input is valid, otherwise false.
   */
  keyPressAlphanumeric(event: KeyboardEvent): boolean {
    const input = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z-ا-ي-ؤ-ئ-ء]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
