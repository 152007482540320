// Importing necessary modules and services from Angular and third-party libraries
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { decode } from '../../utils';
import { SnotifyService } from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { FileUploadService } from './file-upload.service';
import { IGiftStore } from '../interfaces/IGiftStore';
import { ORDER_TYPE } from '../enums/order-type.enum';
import { ITags } from '../interfaces/ITags';

@Injectable({
  providedIn: 'root',
})
export class ApiHandlerService {
  // Define HTTP options including headers for API requests
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      Authorization: `ak ${environment.key}`,
      'jwt-token': decode('token'),
    }),
  };

  // BehaviorSubjects to hold and broadcast data to subscribers
  giftStores: BehaviorSubject<IGiftStore[]> = new BehaviorSubject<IGiftStore[]>(
    null
  );
  restaurants: BehaviorSubject<IGiftStore[]> = new BehaviorSubject<
    IGiftStore[]
  >(null);
  giftTags: BehaviorSubject<ITags[]> = new BehaviorSubject<ITags[]>(null);
  restaurantTags: BehaviorSubject<ITags[]> = new BehaviorSubject<ITags[]>(null);
  type: ORDER_TYPE;
  item: BehaviorSubject<IGiftStore> = new BehaviorSubject<IGiftStore>(null);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly snotifyService: SnotifyService,
    private readonly translate: TranslateService,
    private readonly fileUploadService: FileUploadService
  ) { }

  /**  @description Convert Object to query string * */
  objToQuery(body) {
    let params = '';
    if (body) {
      params = new URLSearchParams(body).toString();
    }
    return params;
  }

  /**
   * @description upload image
   * */

  commenImageUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(
      `${environment.apiUrl}/enaya/upload-image`,
      formData,
      this.httpOptions
    );
  }

  /**
   * @description Uploads a patient file to the server.
   * @param file - The file to be uploaded.
   * @returns An Observable representing the HTTP POST request to upload the file.
   */
  patientFileUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(`${environment.apiUrl}/patient-files/upload-file`, formData, this.httpOptions);
  }

  /**
   * @description Uploads patient file data to the server.
   * @param body
   * @returns An Observable representing the HTTP POST request to upload patient file data.
   */
  uploadPatientFileData(body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.post(`${environment.apiUrl}/patient-files?${params}`, null, this.httpOptions);
  }

  /**
   * @description  Calls a public list query.
   * @param type
   * @param body
   * @returns An Observable representing the HTTP POST request for the public list query.
   */
  callPublicListQuery(type, body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.post(
      `${environment.apiUrl}/enaya/${type}?${params}`,
      {}
    );
  }

  /**
 * @description Calls an appointments get query without authentication.
 * @param type
 * @param body
 * @returns An Observable representing the HTTP GET request for the appointments query.
 */
  callAppointmentsGetQuery(type, body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(
      `${environment.apiUrl}/book/appointments/${type}?${params}`,
      {}
    );
  }

  /**
 * @description Calls an appointments get query with authentication.
 * @param type
 * @param body
 * @returns An Observable representing the authenticated HTTP GET request for the appointments query.
 */
  callAppointmentsGetQuerywithAuth(type, body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(
      `${environment.apiUrl}/book/appointments/${type}?${params}`,
      this.httpOptions
    );
  }

  /**
   * @description Retrieves book information based on type and query parameters.
   * @param {string} type
   * @param {any} body
   * @returns {Observable<any>} - An observable representing the HTTP GET request.
   */
  callBookGetQuery(type, body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(
      `${environment.apiUrl}/book/${type}?${params}`,
      {}
    );
  }

  /**
   * @description Creates a new appointment booking without authentication.
   * @param {string} type
   * @param {any} body
   * @returns {Observable<any>} - An observable representing the HTTP POST request.
   */
  callAppointmentsBook(type, body: any) {
    return this.httpClient.post(`${environment.apiUrl}/book/${type}`, body);
  }

  /**
   * @description Creates a new appointment booking with authentication.
   * @param {string} type
   * @param {any} body
   * @returns {Observable<any>} - An observable representing the HTTP POST request.
   */
  callAppointmentsBookWithAuth(type, body: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/book/${type}`,
      body,
      this.httpOptions
    );
  }

  /**
   * @description Performs a public POST request with a specific type and request body.
   * @param {string} type
   * @param {any} body
   * @returns {Observable<any>} - An observable representing the HTTP POST request.
   */
  publicPostQueryWithBody(type, body: any) {
    return this.httpClient.post(`${environment.apiUrl}/enaya/${type}`, body);
  }

  /**
   * Updates data using a PUT request with specific type, body, and identifier.
   * @param {any} type
   * @param {any} body
   * @param {any} id - The identifier of the data to update.
   * @returns {Observable<any>} - An observable representing the HTTP PUT request.
   */
  updatePutQuery(type: any, body: any, id: any) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.put(
      `${environment.apiUrl}/enaya/${type}/${id}?${params}`,
      null,
      this.httpOptions
    );
  }

  /**
   * @description Retrieves an invoice based on transaction ID.
   * @param {any} body
   * @returns {Observable<any>} - An observable representing the HTTP GET request.
   */
  getInvoiceByTrsId(body: any) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(
      `${environment.paymentApiUrl}/invoices/invoice-trs?${params}`,
      {}
    );
  }

  /**
   * @description Make a payment using the Urway payment gateway.
   * @param body
   * @returns
   */
  makeUrwayPayment(body: any) {
    return this.httpClient.post(
      `${environment.paymentApiUrl}/urway-payments/make-payment`,
      body,
      {}
    );
  }

  /**
   * @description Handle payment callback from Urway.
   * @param body
   * @returns
   */
  paymentCallback(body: any) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(
      `${environment.paymentApiUrl}/urway-payments/callback?${params}`,
      {}
    );
  }

  /**
   * @description Make a payment using the Telr payment gateway.
   * @param body
   * @returns
   */
  makeTelrPayment(body: any) {
    return this.httpClient.post(
      `${environment.paymentApiUrl}/telr-payments/make-payment`,
      body,
      {}
    );
  }

  /**
   * @description Handle payment callback from Telr.
   * @param body
   * @returns
   */
  telrPaymentCallback(body: any) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/telr-payments/callback/${body}`,
      {}
    );
  }

  /**
   * @description Cancel an appointment.
   * @param body
   * @returns
   */
  cancelAppointment(body) {
    return this.httpClient.post(
      `${environment.paymentApiUrl}/book/cancel-appointment`,
      body,
      this.httpOptions
    );
  }

  /**
   * @description Make a payment using the Hyper payment gateway.
   * @param body
   * @returns
   */
  makeHyperPayment(body) {
    return this.httpClient.post(
      `${environment.paymentApiUrl}/hyper-payments/make-payment`,
      body,
      {}
    );
  }

  /**
   * @description Handle payment callback from Hyper.
   * @param body
   * @returns
   */
  hyperPaymentCallback(body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(
      `${environment.paymentApiUrl}/hyper-payments/callback?${params}`
    );
  }

  /**
   * @description Get the list of medical files for a patient.
   * @param body
   * @returns
   */
  getMedicalFilesList(body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.post(`${environment.paymentApiUrl}/patient-files/get?${params}`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  /**
   * @description Delete a medical file by its ID.
   * @param id - The ID of the file to be deleted.
   * @returns
   */
  deleteFile(id) {
    return this.httpClient.delete(
      `${environment.paymentApiUrl}/patient-files/${id}`,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  callDashboaredDataGetQuery(type, body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(`${environment.apiUrl}/dashboard/${type}?${params}`, this.httpOptions);
  }

  getReportsPrint(type, body) {
    let params = new URLSearchParams(body).toString();
    return this.httpClient.get(`${environment.apiUrl}/report/private/${type}?${params}`, this.httpOptions);
  }
}
