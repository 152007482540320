import { Langs } from './language.interface';

export class BaseComponent {
  lang: any = localStorage.getItem('lang');
  private _dir = this.lang === Langs.AR ? 'rtl' : 'ltr';
  private _reverseDir = this.lang === Langs.EN ? 'rtl' : 'ltr';

  get dir() {
    return this._dir;
  }

  get reverseDir() {
    return this._reverseDir;
  }

  set dir(value) {
    this._dir = value;
  }

  get isAr() {
    return this.lang === Langs.AR;
  }
}
