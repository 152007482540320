// Import necessary Angular modules and services
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../login/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Component for handling verification process.
 * @component
 */
@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit, OnDestroy {
  /**
   * Countdown timer.
   * @type {number}
   */
  timer: number;

  /**
   * Minutes for the timer.
   * @type {number}
   */
  minute: number;

  /**
   * Seconds for the timer.
   * @type {any}
   */
  second: any;

  /**
   * Interval for the countdown timer.
   * @type {any}
   */
  interval: any;

  /**
   * Flag to hide/show elements.
   * @type {boolean}
   */
  hide: boolean;

  /**
   * Language setting.
   * @type {string}
   */
  lang: string;

  /**
   * Direction setting (ltr/rtl).
   * @type {string}
   */
  dir: string;

  /**
   * Form group for verification.
   * @type {FormGroup}
   */
  verifyForm: FormGroup;

  /**
   * Input property to check if forgot password.
   * @type {boolean}
   */
  @Input() forgotPassword: boolean;

  /**
   * Input property for modal.
   * @type {any}
   */
  @Input() modal: any;

  /**
   * Constructor to initialize dependencies and set language and direction.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(private authService: AuthService) {
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.verifyForm = new FormGroup({
      code: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized a component's view.
   */
  ngAfterContentInit() {
    this.authService.verifyPage.subscribe((val) => {
      if (val) {
        this.startTimer();
      }
    });
  }

  /**
   * Lifecycle hook that is called when a directive, pipe, or service is destroyed.
   */
  ngOnDestroy() {
    this.authService.verifyPage.next(false);
  }

  /**
   * Start the countdown timer.
   */
  startTimer() {
    this.timer = 120;
    this.interval = setInterval(() => {
      this.minute = Math.floor(this.timer / 60);
      this.second =
        Math.floor(this.timer % 60) === 0 ? '00' : Math.floor(this.timer % 60);
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.interval);
        this.hide = false;
      }
    }, 1000);
  }

  /**
   * Resend the verification code.
   */
  reSend() {
    this.authService.resend('verify');
  }

  /**
   * Verify the code.
   */
  verification() {
    const body = {
      passcode: this.verifyForm.controls.code.value,
    };

    this.authService.verification(body);
  }

  /**
   * Close the modal.
   */
  closeModal() {
    this.modal.close();
    this.authService.verifyPage.next(false);
  }
}
