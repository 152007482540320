import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { IUser } from '../../../core/interfaces/IUser';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { UserType } from '../../../core/enums/user-type.enum';

declare var require: any;
const data: any = require('../../../shared/data/company.json');

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  /** @type {string} */
  lang: string;
  /** @type {IUser[]} */
  users: IUser[];
  /** @type {boolean} */
  loadingIndicator: boolean = true;
  /** @type {boolean} */
  reorderable: boolean = true;
  /** @type {Array<any>} */
  temp = [];
  /** @type {Object} */
  editing = {};
  /** @type {Array<Object>} */
  roles = [
    {
      id: 1,
      name: 'Admin'
    },
    {
      id: 2,
      name: 'Gift Store Management'
    },
    {
      id: 4,
      name: 'Restaurant Management'
    },
    {
      id: 5,
      name: 'Gallery Management'
    },
    {
      id: 6,
      name: 'Customer Services'
    },
    {
      id: 7,
      name: 'Departments Managers'
    },
  ];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  /** @type {ColumnMode} */
  ColumnMode = ColumnMode;
  /** @type {UserType} */
  Role = UserType;

  /**
   * Constructor to initialize UserService and set language from localStorage.
   * @param {UserService} userService - Service to handle user-related operations.
   */
  constructor(private userService: UserService) {
    // Set language from localStorage
    this.lang = localStorage.getItem('lang');
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
  }

  /**
   * OnInit lifecycle hook to initialize user data.
   */
  ngOnInit() {
    if (!this.userService.getUser()) {
      this.userService.getAllUsers();
    }

    // Subscribe to the users observable from userService.
    this.userService.users.subscribe(val => {
      if (val) {
        this.users = val;
        this.users.map(item => {
          item['Last Login'] = item.lastLoginDate && moment(item.lastLoginDate).format('MMM Do YY, H:mm:ss a');
          item['isSuspended'] = item.isSuspend ? 'Yes' : 'No';
          item['Role'] = UserType[item.userType];
        });
        this.temp = val;
      }
    });
  }

  /**
   * Function to update the displayed users based on a filter value.
   * @param {Event} $event - The input event containing the filter value.
   */
  updateFilter($event: Event) {
    const val = ($event.target as HTMLInputElement).value.toLowerCase();

    const temp = [];
    temp.push(...this.temp.filter(function (d) {
      return d.udhId.toLowerCase().indexOf(val) !== -1 ||
        (d.surname && d.surname.toLowerCase().indexOf(val) !== -1) ||
        (d.surnameAr && d.surnameAr.toLowerCase().indexOf(val) !== -1) ||
        d.mobile.toLowerCase().indexOf(val) !== -1 ||
        d.Role.toLowerCase().indexOf(val) !== -1 || !val;
    }));
    this.users = temp;
    this.table.offset = 0;
  }

  /**
   * Function to update a specific cell value in the users array and make an HTTP PUT request.
   * @param {any} event - The event containing the new value.
   * @param {string} cell - The name of the cell being edited.
   * @param {any} rowIndex - The index of the row being edited.
   * @param {any} id - The ID of the user being edited.
   */
  updateValue(event, cell: string, rowIndex: any, id: any) {
    let date;
    if (event.hasOwnProperty('id')) {
      this.editing[rowIndex + '-' + cell] = false;
      date = event.id;
      this.users[rowIndex][cell] = event.name;
      this.users = [...this.users];
    } else {
      date = event.target.value;
      this.editing[rowIndex + '-' + cell] = false;
      this.users[rowIndex][cell] = date;
      this.users = [...this.users];
    }

    const body = {
      [cell]: cell === 'isSuspend' ? ((date === 'Yes' || date === 'yes')) : date
    };
    this.userService.putInfo(body, id);
  }

  /**
   * Function to update the suspension status of a user.
   * @param {boolean} event - The new suspension status.
   * @param {any} id - The ID of the user being updated.
   */
  updateSuspend(event: boolean, id: any) {
    const body = {
      isSuspend: event
    };
    this.userService.putInfo(body, id);
  }

  /**
   * Function to trigger a password reset for a user with the specified ID.
   * @param {any} id - The ID of the user whose password is to be reset.
   */
  resetPassword(id: any) {
    this.userService.resetPassword(id);
  }
}
