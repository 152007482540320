// Import necessary modules and components from Angular and external dependencies.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { decode } from '../../../utils';

@Injectable({
  providedIn: 'root',
})
export class MedicalRecordService {
  todayDate: Date = new Date();
  before90DaysDate: Date = new Date();
  todayDateFixed: any;
  before90DaysDateFixed: any;
  public last90DaysMedicalRecords: any;
  // Define HTTP options with headers for authentication and token handling.
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
      'jwt-token': decode('token'),
    }),
  };
  httpPostOptions = {
    method: 'POST',
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) { }

  // Method to fetch medical records for the last 90 days.
  getLast90DaysData() {
    this.todayDateFixed = new Date()
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-');
    this.before90DaysDate = new Date(
      new Date().setMonth(new Date().getMonth() - 3)
    );
    this.before90DaysDateFixed = this.before90DaysDate
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-');

    // Make an HTTP GET request to retrieve medical records.
    return this.httpClient.get(
      `${environment.paymentApiUrl}/enaya/allVisits?from=${this.before90DaysDateFixed}&to=${this.todayDateFixed}&channel=1`,
      this.httpOptions
    );
  }

  /**
 * @description Retrieves historical medical records data for a specific date range.
 * @param fromDate
 * @param toDate
 * @returns Observable of historical medical records data.
 */
  getHistoricalData(fromDate, toDate) {
    // Make HTTP GET request to retrieve historical medical recordsc
    return this.httpClient.get(
      `${environment.paymentApiUrl}/enaya/allVisits?from=${fromDate}&to=${toDate}`,
      this.httpOptions
    );
  }

  /**
    * @description Retrieves detailed information about a specific medical visit.
    * @param visitNumber
    * @returns
    */
  getVisitDetails(visitNumber) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/enaya/visitDrags?visitNumber=${visitNumber}&channel=1`,
      this.httpOptions
    );
  }

  /**
 * @description Sends feedback for a specific medical visit.
 * @param visitNumber
 * @param visitDate 
 * @param comment
 * @returns Observable indicating the success of the operation.
 */
  sendFeedback(visitNumber, visitDate, comment) {
    return this.httpClient.post(
      `${environment.paymentApiUrl}/enaya/visitNotes?visitNumber=${visitNumber}&visitDate=${visitDate}&comment=${comment}&channel=1`,
      null,
      this.httpOptions
    );
  }

  /**
   * @description Retrieves feedback for a specific medical visit.
   * @param visitNumber
   * @returns Observable of feedback data.
   */
  getFeedback(visitNumber) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/enaya/getVisitNotes/${visitNumber}`,
      this.httpOptions
    );
  }

  /**
 * @description Updates feedback for a specific medical visit.
 * @param visitNumber
 * @param comment 
 * @returns Observable indicating the success of the operation.
 */
  updateFeedback(visitNumber, comment) {
    return this.httpClient.put(
      `${environment.paymentApiUrl}/enaya/updateVisitNotes?visitNumber=${visitNumber}&comment=${comment}`,
      null,
      this.httpOptions
    );
  }

  /**
 * @description Sends a WhatsApp message.
 * @param body 
 * @returns Observable indicating the success of the operation.
 */
  sendWhatsapp(body) {
    return this.httpClient.post(
      `${environment.paymentApiUrl}/enaya/enaya-order`,
      body,
      this.httpOptions
    );
  }

  /**
 * @description Retrieves invoice details for a specific medical visit.
 * @param visitNumber
 * @returns Observable of invoice details.
 */
  getInvoiceDetails(visitNumber) {
    return this.httpClient.get(
      `${environment.paymentApiUrl}/enaya/getInvoice/${visitNumber}`,
      this.httpOptions
    );
  }
}
