// Import necessary Angular modules and services
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../login/auth.service';

@Component({
  selector: 'app-congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss'],
})
export class CongratulationComponent implements OnInit {
  /**
   * Input property to receive the modal instance
   * @type {any}
   */
  @Input() modal: any;

  /**
   * Input property to indicate if it's a sign-up modal
   * @type {boolean}
   */
  @Input() isSignupModal: boolean;

  /**
   * Constructor to inject dependencies
   * @param {AuthService} authService - The authentication service
   */
  constructor(private authService: AuthService) { }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    // Subscribe to the 'lastPage' observable in the AuthService
    this.authService.lastPage.subscribe((val) => {
      if (val) {
        setTimeout(() => {
          // Close the modal
          this.modal.close();
          this.authService.lastPage.next(false);
        }, 3000);
      }
    });
  }
}
