import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };
  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };
  constructor(private http: HttpClient) {}

  // Function to fetch eligibility information from a remote API.
  getEligibility(eligibilityInfo) {
    let providerCode = eligibilityInfo.provider_code;
    let membershipNo = eligibilityInfo.comp_id_code;
    let policyNo = eligibilityInfo.ins_no;
    let clinicCode = eligibilityInfo.wsl_dept_code;
    let patientID = eligibilityInfo.id_card;

    var result = this.http.get(
      `
    https://udheligibility.5thdimension.eu/api/Eligibility/${providerCode}/${membershipNo}/${policyNo}/${clinicCode}/${patientID}
    `,
      this.httpOptions
    );

    return result;
  }
}
