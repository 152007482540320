// Importing necessary modules and components from Angular and third-party libraries
import { Component, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment-timezone';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { UserService } from 'app/core/services/user.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import localeAr from '@angular/common/locales/ar';
import localeEn from '@angular/common/locales/en';
import { DatePipe, registerLocaleData } from '@angular/common';
import Swal from 'sweetalert2';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { CalendarService } from 'app/home/calendar.service';
import { ApiService } from '../reservations/api.service';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-my-appointments',
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.scss'],
  providers: [
    localStorage.getItem('lang') == 'ar'
      ? { provide: LOCALE_ID, useValue: 'ar' }
      : { provide: LOCALE_ID, useValue: 'en' },
  ],
})
export class MyAppointmentsComponent implements OnInit {
  @Output() countChanged = new EventEmitter();
  upcomingAppointments: any[];
  previousAppointments: any[];
  invoices: any;
  dir: string;
  lang: string;
  active = 1;
  model: any = {};
  availableAppointments = [];
  openReschedulePage: boolean = false;
  currentDateStr: any;
  serviceDetails: any;
  zoom = 17;
  latitude: any;
  longitude: any;
  addressLink: any;
  bsConfig: Partial<BsDatepickerConfig>;
  bsValue: Date = new Date();
  currentUserDetails: any = {};
  mrn: any;
  objFilter: any = {
    startDate: '',
    endDate: '',
    SERVICE_CATEGORY: '',
  };
  form: FormGroup;
  mobilePattern = /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  formatted_address: any;
  serviceCategory: any;
  serviceIcons = [
    'nurse_icon.svg',
    'lab_icon.svg',
    'profile_icon.svg',
    'therapy_icon.svg',
    'nutrition_icon.svg',
    'assistant_icon.svg',
    'xray_icon.svg',
    'vitamin_icon.svg',
  ];
  appointmentData: any = {};
  appointmentBooked: boolean = false;
  isWaitingCancel: boolean = false;
  categoryList: any;
  orderStatus: any;
  constructor(
    private readonly router: Router,
    private readonly snotifyService: SnotifyService,
    private translate: TranslateService,
    private apiHandler: ApiHandlerService,
    private modalService: NgbModal,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private calendarService: CalendarService,
    private datePipe: DatePipe,
    private apiDataService: ApiService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang === 'en' ? 'ltr' : 'rtl';
    this.form = new FormGroup(
      {
        fullName: new FormControl(null, [
          Validators.required,
          Validators.minLength(3),
        ]),
        phoneNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
        ]),
        city: new FormControl(null, [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ]),
        formattedAddress: new FormControl(null, []),
      },
      { updateOn: 'blur' }
    );
    if (this.lang == 'ar') {
      registerLocaleData(localeAr);
      this.locale = 'ar';
    }
    if (this.lang == 'en') {
      registerLocaleData(localeEn);
      this.locale = 'en';
    }
  }

  ngOnInit() {
    this.appointmentData = {};
    this.userService.getPatientInfo();

    // Subscribe to the patient data observable
    this.userService.patient.subscribe((val) => {
      if (val && val.length > 0) {
        this.currentUserDetails = val[0];
        this.form.patchValue({
          fullName:
            this.lang == 'ar'
              ? this.currentUserDetails.PAT_ANAME
              : this.currentUserDetails.PAT_ENAME,
          city: this.lang == 'ar' ? 'جدة' : 'Jeddah', // this.currentUserDetails.CITY,
          phoneNumber: this.currentUserDetails.MOBILE,
          email: this.currentUserDetails.email,
        });
        this.mrn = this.currentUserDetails.PAT_CODE;
        this.addressLink = this.currentUserDetails.GPS_LINK;
        this.latitude = +this.currentUserDetails.GPS_LAT;
        this.longitude = +this.currentUserDetails.GPS_LNG;
        this.formatted_address = this.currentUserDetails.FORMATTED_ADDRESS;
      }
    });
    this.getAllAppointments();
    this.currentDateStr = moment().format('YYYY-MM-DD').toString();
    const currentDate = new Date();
    this.bsValue = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );

    this.bsConfig = Object.assign(
      {},
      {
        minDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1
        ),
        maxDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          31
        ),
        showWeekNumbers: false,
      }
    );
    this.getServiceCategoryList();
  }

  get fullName() {
    return this.form.get('fullName');
  }
  get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  get city() {
    return this.form.get('city');
  }
  get formattedAddress() {
    return this.form.get('formattedAddress');
  }

  // Function to fetch all appointments based on specified filters.
  getAllAppointments() {
    let params = {
      startDate: this.objFilter.startDate
        ? moment(this.objFilter.startDate).format('YYYY-MM-DD').toString()
        : '',
      endDate: this.objFilter.endDate
        ? moment(this.objFilter.endDate).format('YYYY-MM-DD').toString()
        : '',
      SERVICE_CATEGORY: this.objFilter.SERVICE_CATEGORY
        ? this.objFilter.SERVICE_CATEGORY
        : '',
      SORT_BY: 'ASC',
    };
    this.spinnerService.show();
    this.upcomingAppointments = [];
    this.previousAppointments = [];
    this.apiHandler
      .callAppointmentsGetQuerywithAuth('specific-patient', params)
      .subscribe(
        (val: any) => {
          if (val && val.data && val.data.length > 0) {
            this.spinnerService.hide();
            val.data.forEach((el, ind) => {
              if (el.RES_TYPE != '3') {
                if (+el['SERVICE_CATEGORY'] > this.serviceIcons.length) {
                  el['icon'] = '../../../assets/img/svg/assistant_icon.svg';
                } else {
                  el['icon'] = `../../../assets/img/svg/${this.serviceIcons[+el['SERVICE_CATEGORY'] - 1]
                    }`;
                }
              }
              if (new Date(el.RES_TIME) > new Date()) {
                this.upcomingAppointments.push(el);
                this.upcomingAppointments.sort(
                  (a, b) =>
                    new Date(a.RES_DATE).getTime() -
                    new Date(b.RES_DATE).getTime()
                );
              } else {
                this.previousAppointments.push(el);
                this.previousAppointments.sort(
                  (a, b) =>
                    new Date(b.RES_DATE).getTime() -
                    new Date(a.RES_DATE).getTime()
                );
              }
            });
          } else {
            this.spinnerService.hide();
            console.error('Error');
          }
        },
        (err) => {
          this.spinnerService.hide();

          console.log('error====>', err);
        }
      );
  }

  // Function to navigate to the file upload page for a specific appointment.
  uploadFiles(item) {
    this.router.navigate(['/e-services/medical-files/upload'], { queryParams: { type: "appointment", resNo: item.RES_NO, orderId: item.ORDER_ID } })
  }

  // Function to set the active button.
  setActiveBtn(active) {
    this.active = active;
  }

  // Function to navigate back to the appointments page.
  backToAppointments() {
    this.openReschedulePage = false;
    // this.router.navigate(['/e-services/my-reservations'])
  }

  // Function to initiate the rescheduling process for a specific appointment.
  rescheduleApp(item: any) {
    if (
      item.RES_TIME &&
      new Date(item.RES_TIME).getTime() - new Date().getTime() <
      1000 * 60 * 60 * 24
    ) {
      Swal({
        title: `${this.translate.instant('dashboard.cantReschedule')}`,
        html:
          this.lang == 'en'
            ? `<b>notice</b>,
        For assistance, please reach out to our customer service team.
        You can contact them via <a href="https://wa.me/+966535353404" target="_blank">WhatsApp</a> at <span class='text-underline'><a href="tel:+966535353404">0535353404</a></span> or call the same number.`
            : `<b>ملاحظة</b>,
        للحصول على المساعدة، يرجى التواصل مع فريق خدمة العملاء لدينا. يمكنكم التواصل معهم عبر <a href="https://wa.me/+966535353404" target="_blank">الواتساب</a> على الرقم <a href="tel:+966535353404">0535353404</a> أو الاتصال على نفس الرقم.`,
        type: 'error',
        confirmButtonColor: '#2740b6',
        confirmButtonText: this.lang == 'en' ? 'Ok' : 'تم',
      });
    } else {
      this.openReschedulePage = true;
      this.model = item;
    }
  }

  // Function to reschedule an appointment.
  rescheduleAppointment() {
    let data1 = {
      old_app_serial: this.model.APP_SERIAL,
      res_no: this.model.RES_NO,
      app_serial: this.model.slotSerialNumber,
      appointment_type: +this.model.VIRTUAL,
    };
    this.appointmentData = {};
    this.apiHandler
      .callAppointmentsBookWithAuth('reschedule-appointment', data1)
      .subscribe(
        (data: any) => {
          if (data && data.error) {
            console.log('error====>', data.error);
          }
          if (data && data.success) {
            this.appointmentBooked = true;
            this.appointmentData = {
              order_number: data.order_number,
              service_price: this.model.SERVICE_PRICE,
              date_and_time: this.model.selectedTime,
              category_en: this.model.TYPE_EN,
              category_ar: this.model.TYPE_AR,
              service_name_en: this.model.SERVICE_NAME_EN,
              service_name_ar: this.model.SERVICE_NAME_AR,
              client_name: this.fullName.value,
              client_mobile: this.phoneNumber.value,
            };
            setTimeout(() => {
              window.location.reload();
            }, 15000);
          }
        },
        (err) => {
          console.log('error====>', err);
        }
      );
  }

  // Function to reload the page.
  goToHome() {
    window.location.reload();
  }

  // Function to reset the appointment booking state.
  newAppointment() {
    this.appointmentBooked = false;
    // window.location.reload();
  }

  // Function triggered on date change to fetch available appointments.
  dateChange(value: Date): void {
    this.currentDateStr = moment(value).format('YYYY-MM-DD').toString();
    this.getAvailableAppointments();
    this.model.selectedDate = value;
  }

  // Function to fetch available appointments based on the selected date.
  getAvailableAppointments() {
    let params = {
      SpecificDate: this.currentDateStr,
    };

    this.availableAppointments = [];
    if (this.model && this.model.RES_TYPE === '3') {
      if (this.model.DOC_CODE) {
        params['ClinCode'] = this.model.DOC_CODE;
        // params['specialization'] = this.model.selectedSpecialityType;
      }

      this.apiHandler.callAppointmentsGetQuery('specific', params).subscribe(
        (data: any) => {
          if (
            data &&
            data.status == 'success' &&
            data.data &&
            data.data.length > 0
          ) {
            this.availableAppointments = data.data.filter((el) => {
              el.resTime = el.resTime.replace('Z', '');
              return (
                new Date(this.currentDateStr).getDate() ==
                new Date(el.resTime).getDate() && (new Date().getTime() < new Date(el.resTime).getTime())
              );
            });
          }
        },
        (err) => {
          console.log('error====>', err);
        }
      );
    } else {
      if (this.model.SERVICE_CATEGORY) {
        params['category_number'] = +this.model.SERVICE_CATEGORY;
      }

      this.apiHandler
        .callAppointmentsGetQuery('specific-category', params)
        .subscribe(
          (data: any) => {
            if (
              data &&
              data.status == 'success' &&
              data.data &&
              data.data.length > 0
            ) {
              this.availableAppointments = data.data.filter((el) => {
                el.resTime = el.resTime.replace('Z', '');
                return (
                  new Date(this.currentDateStr).getDate() ==
                  new Date(el.resTime).getDate() && (new Date().getTime() < new Date(el.resTime).getTime())
                );
              });
            }
          },
          (err) => {
            console.log('error====>', err);
          }
        );
    }
  }

  // Function to handle the selection of a time slot.
  selectTimeSlot(event, item) {
    if (item && item.appSerial) {
      this.model.slotSerialNumber = item.appSerial;
      this.model.selectedTime = item.resTime;
    }
  }

  // Function to open a details popup for a selected item.
  openDetailsPopup(content, item) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    if (this.model.RES_TYPE === '3') {
      this.serviceDetails = this.model;
    } else {
      let tempService = {
        cat_code_oradb: this.model.SERVICE_CATEGORY,
        cat_name_ar_oradb: this.model.TYPE_AR,
        cat_name_en_oradb: this.model.TYPE_EN,
        service_code_oradb: this.model.SERVICE_CODE,
        service_price_oradb: this.model.SERVICE_PRICE,
        service_mkt_ar: this.model.SERVICE_NAME_AR,
        service_mkt_en: this.model.SERVICE_NAME_EN,
      };
      this.serviceDetails = this.serviceCategory
        ? this.serviceCategory
        : tempService;
    }
  }

  // Function to fetch service details based on the selected category and service code.
  getServicewiseCategory() {
    let params = {
      // sort: 'created_at',
      sort_by: 'DESC',
    };
    if (
      this.model.RES_TYPE != '3' &&
      this.model.SERVICE_CATEGORY &&
      this.model.SERVICE_CODE
    ) {
      this.spinnerService.show();
      params['cat_code_oradb'] = this.model.SERVICE_CATEGORY.toString();
      params['service_code_oradb'] = this.model.SERVICE_CODE.toString();

      this.apiHandler
        .callPublicListQuery('public-enaya-category-service', params)
        .subscribe(
          (data: any) => {
            this.spinnerService.hide();
            if (data && data.length > 0) {
              this.serviceCategory = data[0];
            }
          },
          (err) => {
            console.log('error====>', err);
            this.spinnerService.hide();
          }
        );
    }
  }

  // Function to reset the appointment filter.
  resetFilter() {
    this.objFilter.endDate = '';
    this.objFilter.startDate = '';
    this.objFilter.SERVICE_CATEGORY = '';
    this.getAllAppointments();
  }

  // Function to close the modal popup.
  closePopup() {
    this.modalService.dismissAll();
  }

  // Function to handle the change in address and update related properties.
  public handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.formatted_address = address.formatted_address;
    this.addressLink =
      `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.replace(
        /\s/g,
        '+'
      );
  }

  // Function to set the current location based on the user's geolocation.
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.addressLink =
          `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.toLowerCase();
        this.formatted_address = '';
      });
    }
  }

  // Callback function for handling date range change.
  onDateRangeChange(event, type) {
    if (event) {
      this.objFilter[type] = event;
    }
  }

  // Function to save the appointment to the device's calendar.
  saveAppointmentToCalendar(appointmentData) {
    const startDate = new Date(appointmentData.date_and_time);
    const endDate = new Date(appointmentData.date_and_time);
    const title =
      this.lang == 'ar'
        ? `موعد - عناية متجددة للرعاية الصحية`
        : `Appointment - Enaya Health Care`;
    const location = '';
    const description =
      this.lang == 'ar'
        ? `الخدمة: ${appointmentData.service_name_ar}`
        : `Service Name: ${appointmentData.service_name_en}`;
    this.calendarService.addEventToCalendar(
      title,
      startDate,
      endDate,
      description,
      location
    );
  }

  // Function to share appointment details via WhatsApp.
  shareWhatsapp(appointmentData) {
    const date = new Date(appointmentData.date_and_time);
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd, , h:mm a');
    const message =
      this.lang == 'en'
        ? `*Enaya For Health Care*\nYour appointment has been booked successfully\nOrder Number: ${appointmentData.order_number}\nPatient Name: ${appointmentData.client_name}\nService Name: ${appointmentData.service_name_en}\nService Type: ${appointmentData.category_en}\nAppointment Date & Time: ${formattedDate}`
        : `*عناية متجددة للرعاية الصحية*\nتم حجز موعدكم بنجاح\nرقم الطلب: ${appointmentData.order_number}\nاسم المريض: ${appointmentData.client_name}\nاسم الخدمة: ${appointmentData.service_name_ar}\nنوع الخدمة: ${appointmentData.category_ar}\nتاريخ الموعد: ${formattedDate}`;

    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    window.location.href = url;
  }

  // Function to cancel an appointment.
  cancelAppointment(appointment) {
    this.isWaitingCancel = true;
    if (
      appointment.RES_TIME &&
      new Date(appointment.RES_TIME).getTime() - new Date().getTime() <
      1000 * 60 * 60 * 24
    ) {
      Swal({
        title: `${this.translate.instant('errorMsg.cantCancel')}`,
        html:
          this.lang == 'en'
            ? `<b>notice</b>,
        For assistance, please reach out to our customer service team.
        You can contact them via <a href="https://wa.me/+966535353404" target="_blank">WhatsApp</a> at <span class='text-underline'><a href="tel:+966535353404">0535353404</a></span> or call the same number.`
            : `<b>ملاحظة</b>,
        للحصول على المساعدة، يرجى التواصل مع فريق خدمة العملاء لدينا. يمكنكم التواصل معهم عبر <a href="https://wa.me/+966535353404" target="_blank">الواتساب</a> على الرقم <a href="tel:+966535353404">0535353404</a> أو الاتصال على نفس الرقم.`,
        type: 'error',
        confirmButtonColor: '#2740b6',
        confirmButtonText: this.lang == 'en' ? 'Ok' : 'تم',
      });
      this.isWaitingCancel = false;
    } else {
      const body = {
        app_serial: appointment.APP_SERIAL,
        res_no: appointment.RES_NO,
        order_id: appointment.ORDER_ID,
      };
      Swal({
        title: this.lang == 'en' ? 'Cancel Appointment' : 'إلغاء الحجز',
        text:
          this.lang == 'en'
            ? "Your reservation will be cancelled. Are you sure you want to cancel your reservation?'"
            : 'سوف يتم إلغاء الحجز, هل أنت متأكد من إلغاء الحجز؟',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:
          this.lang == 'en' ? 'Cancel reservation' : 'إلغاء الحجز',
        cancelButtonText: this.lang == 'en' ? 'No' : 'لا',
      })
        .then((result) => {
          if (result.value) {
            this.apiHandler.cancelAppointment(body).subscribe(
              (data) => {
                if (data) {
                  Swal({
                    title: `${this.translate.instant(
                      'public.appointmentCanceled'
                    )}`,
                    type: 'success',
                    confirmButtonText: this.lang == 'en' ? 'Ok' : 'تم',
                  }).then((res) => {
                    this.getAllAppointments();
                  });
                  this.isWaitingCancel = false;
                } else {
                  Swal({
                    title: `${this.translate.instant('errorMsg.cancelError')}`,
                    type: 'error',
                    confirmButtonText: this.lang == 'en' ? 'Ok' : 'تم',
                  });
                  this.isWaitingCancel = false;
                }
              },
              (err) => {
                this.isWaitingCancel = false;
                Swal({
                  title: `${this.translate.instant('errorMsg.cancelError')}`,
                  type: 'error',
                  confirmButtonText: this.lang == 'en' ? 'Ok' : 'تم',
                });
              }
            );
          }
        })
        .catch(Swal.noop)
        .then(() => {
          this.isWaitingCancel = false;
        });
    }
  }

  // Function to retrieve the list of service categories.
  getServiceCategoryList() {
    this.apiDataService.getServiceCategories().subscribe(
      (items) => {
        if (items) {
          this.categoryList = items;
        } else {
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }



  // Function to navigate to the medical records page.
  goToMedicalRecords() {
    this.router.navigate(['/e-services/medical-record']);
  }

  // Function to toggle tracking order status for an appointment.
  changeTrackOrder(item) {
    this.upcomingAppointments.map((el) => {
      if (el == item) {
        el['isTrackOrder'] = !el['isTrackOrder'];
      } else {
        el['isTrackOrder'] = false;
      }
    });

    if (item && item.CLOSE_TYPE) {
      this.orderStatus = {
        STATUS_AR: item.STATUS_AR,
        STATUS_EN: item.STATUS_EN,
        CLOSE_TYPE: item.CLOSE_TYPE,
        SHOW_ONLINE: item.SHOW_ONLINE
      }
    }
  }
}
