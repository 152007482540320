// Import necessary components and services from Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHandlerService } from 'app/core/services/api-handler.service';

@Component({
  selector: 'app-hyper-holder',
  templateUrl: './hyper-holder.component.html',
  styleUrls: ['./hyper-holder.component.scss'],
})
export class HyperHolderComponent implements OnInit {
  checkoutId: string;
  invoiceData: any;
  params: any;

  /**
   * Constructor to inject necessary services and initialize component.
   * @param {ActivatedRoute} route - The current route snapshot.
   * @param {Router} router - The router for navigation.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiHandler: ApiHandlerService
  ) { }

  ngOnInit() {
    // Subscribe to query parameters to check for transCount
    this.route.queryParams.subscribe(
      (params) => {
        if (params) {
          this.params = params;
          // Call hyperPaymentCallback method from ApiHandlerService
          this.apiHandler
            .hyperPaymentCallback(this.params)
            .subscribe((data: any) => {
              if (data && data.status == true) {
                // Store the response in local storage
                localStorage.setItem('hyper-response', JSON.stringify(data));
                this.router.navigate([
                  'payments/hyper-complete/' + data.transCount,
                ]);
              } else {
                this.router.navigate([
                  `payments/hyper-error/` + data.transCount,
                ]);
              }
            });
        }
      },
      (error) => {
        // Navigate to 'e-services' in case of an error
        console.log('Error=========>', error);
        this.router.navigate(['e-services']);
      }
    );
  }
}
