// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserChoice, Clinics, Doctors } from './userChoice';
import { ApiService } from './api.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { observable, Observable, timer } from 'rxjs';
import * as moment from 'moment-timezone';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'url';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthService } from '../../../pages/content-pages/login/auth.service';
import { decode } from '../../../utils';
import { TrackingService } from 'app/core/services/tracking.service';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss'],
})
export class ReservationsComponent implements OnInit {
  showSpiner: boolean;
  // recap = environment.recaptchaSite;
  tdate = new Date();
  today: NgbDateStruct;
  isError = 0;
  reservationTypes = {
    live: '1',
    visit: '0',
  };

  // ViewChild reference for the wizard component
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  clinics: Array<Clinics>;
  isLoading = false;
  doctors: Array<Doctors>;
  model: UserChoice;
  times: any[] = [];
  reservationStatus: any[] = [];
  morningTimes: any[] = [];
  nightTimes: any[] = [];
  allTimes: any[] = [];
  reservationForm: FormGroup;
  mobilePattern = /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  // pNamePattern = /^[a-zA-Z\u0621-\u064A ]+$/;
  pNamePattern = /^[a-zA-Z\u0621-\u064A ]{3,}\s[a-zA-Z\u0621-\u064A ]{3,}(\s[a-zA-Z\u0621-\u064A ]{3,})*$/;
  transNo = 0;
  sameDate = 1;
  nextDate: string;
  currentJustify: string;
  patName: any;
  patMobile: any;
  rDate: any;
  currentRating = 10;
  done = 0;
  now = new Date();
  currentDate = {
    year: this.now.getFullYear(),
    month: this.now.getMonth() + 1,
    day: this.now.getDate(),
  };
  showDetails = false;
  resTypeQS;
  queryParams: any;
  selectedDateQueryParams: Date;
  timerId: any;
  timeLeft: any = 15;
  user: any;

  lang: string;
  dir: string;
  activeTab = 'morning';
  mdate: any;

  constructor(
    private data: ApiService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _trackingService: TrackingService
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';

    this.reservationForm = new FormGroup(
      {
        fullName: new FormControl(null, [
          Validators.required,
          Validators.minLength(3),
          // Validators.pattern(this.pNamePattern),
          this.noWhitespaceValidator
        ]),
        mobileNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
        ]),
        // recaptcha: new FormControl(null, [Validators.required]),
      },
      { updateOn: 'blur' }
    );
    this.today = {
      year: this.tdate.getFullYear(),
      month: this.tdate.getMonth() + 1,
      day: this.tdate.getDate(),
    };
    this.model = new UserChoice();
    this.clinics = new Array<Clinics>();
    this.doctors = new Array<Doctors>();
    this.getClinics();

    this.user = decode('currentUser');

    // Subscribe to changes in the currentUser observable.
    this.authService.currentUser.subscribe(val => {
      if (val) {
        this.user = val;
        this.pathValue(val.name, val.mobile);
      }
    });

    if (this.user) {
      this.pathValue(this.user.name, this.user.mobile);
    }
  }

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    if (this.lang == 'en') {
      $('aw-wizard').attr('dir', 'ltr');
      $('ng-select').attr('dir', 'ltr');
      $('.ng-option-label').addClass('text-left');
    } else {
      $('aw-wizard').removeAttr('dir');
      $('ng-select').removeAttr('dir');
      $('.ng-option-label').removeClass('dir');
    }
    this.model.selectedDate = this.currentDate;
  }

  /**
   * Function to add tracking log when booking an appointment.
   */
  addTrackingLog(){
    this._trackingService.addTrackingLog(this._trackingService.tracking_type.BOOK_APPOINTMENT, this.user.id? this.user.id : "").subscribe(data =>{
      return data
    })
  }

  /**
   * Callback function for reCAPTCHA response.
   * @param {string} captchaResponse - The response string from reCAPTCHA.
   */
  resolved(captchaResponse: string) {
    if (captchaResponse != null && captchaResponse != undefined) {
    }
  }

  /**
   * Function to check for query parameters in the route.
   */
  checkForQueryParams() {
    // Subscribe to query parameters to check for resType
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
      if (
        params['resType'] &&
        (params['resType'] === '1' || params['resType'] === '0')
      ) {
        this.resTypeQS = params['resType'];
      }
      if (this.resTypeQS === '0' || this.resTypeQS === '1') {
        this.model.reservationType = this.resTypeQS;
      } else {
        this.model.reservationType = '0';
      }
      this.setClinicFromQueryParams(params);
    });
  }

  /**
   * Function to set clinic based on query parameters.
   * @param {any} params - The query parameters from the route.
   */
  setClinicFromQueryParams(params) {
    if (params['cliniccod']) {
      this.model.selectedClinic = this.clinics.filter((clinic) => {
        return (
          clinic.CLIN_CODE.toLowerCase() == params['cliniccod'].toLowerCase()
        );
      })[0];
      if (this.model.selectedClinic) {
        this.isLoading = true;
        this.getDoctors(this.model.selectedClinic.CLIN_CODE);
      }
    }
  }

  /**
   * Function to set doctor based on query parameters.
   * @param {any} params - The query parameters from the route.
   */
  setDoctorFromQueryParams(params) {
    if (params['doc_cod']) {
      const doc = this.doctors.filter((doc) => {
        return doc.DOC_CODE.toLowerCase() == params['doc_cod'].toLowerCase();
      })[0];
      if (doc) {
        this.model.selectedDoctor = doc;
        this.wizard.model.navigationMode.goToStep(1);
        if (
          this.model.selectedClinic &&
          this.model.selectedDoctor &&
          params['date']
        ) {
          this.setDateFromQueryParams(this.queryParams);
        }
      }
    }
  }

  /**
   * Function to set date based on query parameters.
   * @param {any} params - The query parameters from the route.
   */
  setDateFromQueryParams(params) {
    const date = new Date(params['date']);
    if (date.toString() != 'Invalid Date') {
      this.selectedDateQueryParams = date;
      this.model.selectedDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      // Can not go to step 2 from 0
      setTimeout(() => {
        this.wizard.model.navigationMode.goToStep(2);
      }, 100);
    }
  }

  /**
   * Function to fetch clinics and check for query parameters.
   */
  getClinics() {
    this.data.getClinics().subscribe(
      (r) => {
        for (let i = 0; i < Number(r['Count']); i++) {
          const element = r['Clinics'][i];
          this.clinics.push({
            CLIN_CODE: element['CODE'].trim(),
            CLIN_ANAME: element['A_NAME'].trim(),
            CLIN_ENAME: element['E_NAME'].trim(),
          });
        }
      },
      (err) => {},
      () => {
        this.checkForQueryParams();
      }
    );
  }

  /**
   * Function to fetch doctors based on clinic code.
   * @param {string} clinicCode - The code of the clinic.
   */
  getDoctors(clinicCode) {
    this.isLoading = true;
    this.data.getDoctors(clinicCode).subscribe(
      (r) => {
        this.isLoading = false;
        for (let i = 0; i < Number(r['Count']); i++) {
          const element = r['Doctor'][i];
          this.doctors.push({
            DOC_CODE: element['DOC_CODE'].trim(),
            DOC_ANAME: element['CLINC_ANAME'].trim(),
            DOC_ENAME: element['CLINC_ENAME'].trim(),
            WEB_SER: element['WEB_SER'],
          });
        }
      },
      (e) => {
        this.isLoading = false;
      },
      () => {
        this.doctors.sort((a, b) =>
          a.WEB_SER > b.WEB_SER ? 1 : b.WEB_SER > a.WEB_SER ? -1 : 0
        );
        this.setDoctorFromQueryParams(this.queryParams);
      }
    );
  }

  /**
   * Event handler when clinic selection changes.
   * @param {any} event - The event object containing clinic selection details.
   */
  clinicChanged(event) {
    this.doctors = [];
    if (event.CLIN_CODE != null) {
      this.isLoading = true;
      this.model.selectedDoctor = null;
      this.getDoctors(event.CLIN_CODE);
    }
  }

  /**
   * Function to clear the selected doctor.
   */
  unsignDoc() {
    this.model.selectedDoctor = null;
  }

  /**
   * Function to fetch available times for selected date and doctor.
   */
  getTimes() {
    this.showSpiner = true;
    this.times = [];
    this.morningTimes = [];
    this.nightTimes = [];
    this.allTimes = [];
    try {
      if (
        this.model.selectedDate != null &&
        this.model.selectedDate != undefined
      ) {
        // let temp = new Date(this.model.selectedDate.year, this.model.selectedDate.month - 1, this.model.selectedDate.day)
        // console.log(temp);
        const temp = moment({
          year: this.model.selectedDate.year,
          month: this.model.selectedDate.month - 1,
          day: this.model.selectedDate.day,
        });
        this.mdate = moment(temp);
        this.data
          .getTimesOld(
            // this.mdate.format('DD-MMM-YYYY').toString(),
            this.mdate.format('YYYY-MMM-DD').toString(),
            this.model.selectedDoctor.DOC_CODE
          )
          .subscribe(
            (timesR) => {
              console.log('RESPONSE GET APPOINTMENTS', timesR)

              if (Number(timesR['Count']) > 0) {
                this.times = [...timesR['Times']];
                // this.reservationStatus = [...timesR['ReservType']];
                this.nextDate = '';
                this.sameDate = 1;
              } else {
                this.sameDate = 0;
                this.timesInterval(this.mdate, 1);
              }
            },
            (error) => {
              this.showSpiner = false;
              console.log('ERROR', error)
            },
            () => {
              this.morningTimes = [];
              this.nightTimes = [];
              this.allTimes = [];
              for (let i = 0; i < this.times.length; i++) {
                const time = this.times[i];
                // const status = this.reservationStatus[i];
                // if (time.includes('am') || time.includes('AM')) {
                //   //status 1 represents appointment is avaliable
                //   if(status == 1){
                //     this.morningTimes.push(time);
                //     this.allTimes.push({ time: time, status: 1});
                //     this.activeTab = 'morning';
                //   } else {
                //     this.allTimes.push({ time: time, status: 2});
                //   }
                // } else {
                //   if(status == 1){
                //     this.nightTimes.push(time);
                //     this.allTimes.push({ time: time, status: 1});
                //     // this.activeTab = 'night';
                //     this.activeTab = 'morning';
                //   } else {
                //     this.allTimes.push({ time: time, status: 2});
                //   }
                // }
                if (time.includes('am') || time.includes('AM')) {
                  this.morningTimes.push(time);
                  this.allTimes.push({ time: time, status: 1});
                  this.activeTab = 'morning';
                } else {
                  this.nightTimes.push(time);
                  this.allTimes.push({ time: time, status: 1});
                  this.activeTab = 'night';
                }
              }
              this.showSpiner = false;
            }
          );
      }
    } catch (error) {
      this.showSpiner = false;
    }
  }

  /**
   * Recursive function to fetch times for the next 7 days if no times are available for the current date.
   * @param {moment.Moment} uDate - The date to start fetching times from.
   * @param {number} i - The iteration count for recursion.
   */
  timesInterval(uDate, i) {
    const idate = moment(uDate);
    idate.add(1, 'days');
    if (i < 7) {
      this.data
        .getTimesOld(
          // this.mdate.format('DD-MMM-YYYY').toString(),
          this.mdate.format('YYYY-MMM-DD').toString(),
          this.model.selectedDoctor.DOC_CODE
        )
        .subscribe(
          (timesList) => {
            if (Number(timesList['Count']) > 0) {
              this.times = [];
              this.times = [...timesList['Times']];
              // this.reservationStatus = [...timesList['ReservType']];
              this.nextDate = idate.format('YYYY-MM-DD').toString();
              this.mdate = this.nextDate;
              return;
            }
          },
          (error) => {},
          () => {
            if (this.times.length > 0) {
              this.morningTimes = [];
              this.nightTimes = [];
              this.allTimes = [];
              for (let i = 0; i < this.times.length; i++) {
                const time = this.times[i];
                // const status = this.reservationStatus[i];
                // if (time.includes('am') || time.includes('AM')) {
                //   //status 1 represents appointment is avaliable
                //   if(status == 1){
                //     this.morningTimes.push(time);
                //     this.allTimes.push({ time: time, status: 1});
                //     this.activeTab = 'morning';
                //   } else {
                //     this.allTimes.push({ time: time, status: 2});
                //   }
                // } else {
                //   if(status == 1){
                //     this.nightTimes.push(time);
                //     this.allTimes.push({ time: time, status: 1});
                //     // this.activeTab = 'night';
                //     this.activeTab = 'morning';
                //   } else {
                //     this.allTimes.push({ time: time, status: 2});
                //   }
                // }
                if (time.includes('am') || time.includes('AM')) {
                  this.morningTimes.push(time);
                  this.allTimes.push({ time: time, status: 1});
                  this.activeTab = 'morning';
                } else {
                  this.nightTimes.push(time);
                  this.allTimes.push({ time: time, status: 1});
                  this.activeTab = 'night';
                }
              }
              i = 8;
              return;
            } else {
              const x = i + 1;
              this.timesInterval(idate, x);
            }
          }
        );
    }
  }

  /**
   * Function to initiate a new appointment booking.
   */
  newAppBook() {
    try {
      this.addTrackingLog()
      // if (
      //   // this.model.selectedDoctor.DOC_CODE.substring(0, 2) !=
      //   //   this.model.selectedClinic.CLIN_CODE.substring(0, 2) &&
      //   // this.model.selectedClinic.CLIN_CODE.substring(0, 2) != 'NU'
      // ) {
      //   this.isError = 1;
      //   return;
      // } else {
      // this.rDate = new Date(this.model.selectedDate.year, this.model.selectedDate.month - 1, this.model.selectedDate.day)
      this.rDate = moment(this.mdate).format('YYYY-MM-DD').toString();

      const resInfo = {
        CLIN_CODE: this.model.selectedDoctor.DOC_CODE,
        RES_DATE: this.rDate,
        TIME: this.model.selectedTime,
        USER_CODE: 'WEB',
        PAT_NAME: this.reservationForm.get('fullName').value,
        MOBILE: this.reservationForm.get('mobileNumber').value,
        resType: this.model.reservationType,
      };
      this.patName = resInfo.PAT_NAME;
      this.patMobile = resInfo.MOBILE;
      this.data.newBook(resInfo).subscribe(
        (r) => {
          //countdown to redirect to home page
          // this.timeLeft = 15;
          // this.timerId = setInterval( () => {
          //   this.countdown()
          // }, 1000)
          if (r != null && r['TransNumber'] != 0) {
            this.transNo = r['TransNumber'];
          } else {
            this.isError = 1;
          }
        },
        (error) => {
          this.isError = 1;
        },
        () => {
          this.showDetails = true;
          this.reservationForm.reset();
          this.isError = 0;
          this.done = 1;
        }
      );
      // }
    } catch (error) {
      this.isError = 1;
      this.addTrackingLog()
    }
  }

  /**
   * Function to handle the countdown for redirection.
   */
  countdown() {
    if (this.timeLeft == 0) {
      this.goToHome()
    } else {
      this.timeLeft--;
    }
  }

  /**
   * Function to navigate to the home page.
   */
  goToHome() {
    clearTimeout(this.timerId);
    this.router.navigate(['/e-services'])
  }

  /**
   * Function to reset the appointment booking form.
   */
  resetFormAppointment() {
    this.getClinics();
    this.model.reservationType = '0';
    this.model.selectedTime = '';
    // this.model.selectedDoctor = {
    //   DOC_CODE: '',
    //   DOC_ANAME: '',
    //   DOC_ENAME: '',
    //   WEB_SER: ''
    // }
    // this.model.selectedClinic = {
    //   CLIN_CODE: '',
    //   CLIN_ANAME: '',
    //   CLIN_ENAME: ''
    // }
    this.model.selectedDate = this.currentDate;
  }

  /**
   * Validator function to check for whitespace in form control.
   * @param {FormControl} control - The form control to validate.
   * @returns {any} - An object with validation errors if invalid, otherwise null.
   */
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  /**
   * Function to populate form fields with user data.
   * @param {string} fullName - The full name of the user.
   * @param {string} mobile - The mobile number of the user.
   */
  pathValue(fullName, mobile) {
    this.reservationForm.patchValue({
      fullName,
      mobileNumber: mobile
    })
    this.reservationForm.controls.fullName.disable({
      onlySelf: true,
    });
    this.reservationForm.controls.mobileNumber.disable({
      onlySelf: true,
    });
  }
}
