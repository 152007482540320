// Importing necessary modules and components from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../core/services/user.service";
import { IEditInformation } from "../../../core/interfaces/IUser";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  lang: string;
  form: FormGroup;
  mobilePattern = /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;

  /**
   * Constructor to initialize form builder and user service.
   * @param {FormBuilder} fb - FormBuilder service to create forms.
   * @param {UserService} userService - UserService to interact with user-related data.
   */
  constructor(private fb: FormBuilder, private userService: UserService) { }

  /**
   * Initializes the component, sets up the form, and subscribes to user data.
   */
  ngOnInit() {
    // Calling a service method to get current user information.
    this.userService.getCurrentUserInformation();
    // Creating a form using Angular FormBuilder.
    this.form = this.fb.group({
      nameEn: [null],
      nameAr: [null],
      mobile: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(this.mobilePattern)
      ]],
      email: [null, Validators.email]
    });

    // Subscribing to the user observable in the userService to get updates.
    this.userService.user.subscribe(val => {
      if (val) {
        this.form.patchValue({
          nameEn: val.surname,
          nameAr: val.surnameAr,
          mobile: val.mobile,
          email: val.email,
        });
      }
    });
  }

  /**
   * Getter for English name field.
   * @returns {AbstractControl} The English name form control.
   */
  get nameEn() { return this.form.get('nameEn'); }

  /**
   * Getter for Arabic name field.
   * @returns {AbstractControl} The Arabic name form control.
   */
  get nameAr() { return this.form.get('nameAr'); }

  /**
   * Getter for mobile number field.
   * @returns {AbstractControl} The mobile number form control.
   */
  get mobile() { return this.form.get('mobile'); }

  /**
   * Getter for email field.
   * @returns {AbstractControl} The email form control.
   */
  get email() { return this.form.get('email'); }

  /**
   * Clears the form by resetting all fields.
   */
  clear = () => {
    this.form.reset();
  }

  /**
   * Handles form submission.
   * If the form is invalid, submission is aborted.
   * Otherwise, it sends the form data to the user service to update user information.
   */
  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const body: IEditInformation = {
      surname: this.nameEn.value,
      surnameAr: this.nameAr.value,
      mobile: this.mobile.value,
      email: this.email.value,
    };

    this.userService.editInformation(body, this.clear);
  }

  /**
   * Function to handle key presses for alphanumeric characters.
   * Prevents input of non-alphanumeric characters.
   * @param {KeyboardEvent} event - The keyboard event triggered on key press.
   * @returns {boolean} True if the key press is valid, otherwise false.
   */
  keyPressAlphanumeric(event) {
    // Get the character corresponding to the key code of the pressed key
    var input = String.fromCharCode(event.keyCode);

    // Check if the input character is a valid alphanumeric character or Arabic letters
    // If valid, allow the key press otherwise prevent the key press
    if (/[a-zA-Z-ا-ي-ؤ-ئ-ء]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
