import { Providers } from './userChoice';
import { Clinics } from '../reservations/userChoice';

export const providersArray: Providers[]=[
  {
    PROVIDER_CODE:"20147",
    PROVIDER_ENAME:"BUPA",
    PROVIDER_ANAME:"بوبا"
  },
  {
    PROVIDER_CODE:"102",
    PROVIDER_ENAME:"TAWUNIYA",
    PROVIDER_ANAME:"تعاونية"
  },
  {
    PROVIDER_CODE:"201",
    PROVIDER_ENAME:"MALATH",
    PROVIDER_ANAME:"ملاذ"
  },
  {
    PROVIDER_CODE:"204",
    PROVIDER_ENAME:"AXA",
    PROVIDER_ANAME:"أكسا"
  },
  {
    PROVIDER_CODE:"205",
    PROVIDER_ENAME:"SAICO",
    PROVIDER_ANAME:"سايكو"
  },
  {
    PROVIDER_CODE:"207",
    PROVIDER_ENAME:"AL RAJHI TAKAFUL",
    PROVIDER_ANAME:"الراجحي تكافل"
  },
  {
    PROVIDER_CODE:"301",
    PROVIDER_ENAME:"ARABIAN SHIELD",
    PROVIDER_ANAME:"الدرع العربي"
  },
  {
    PROVIDER_CODE:"300",
    PROVIDER_ENAME:"MEDGULF",
    PROVIDER_ANAME:"ميدغلف"
  },
  {
    PROVIDER_CODE:"208",
    PROVIDER_ENAME:"AL SAGR",
    PROVIDER_ANAME:"الصقر"
  },
  {
    PROVIDER_CODE:"209",
    PROVIDER_ENAME:"WALAA",
    PROVIDER_ANAME:"ولاء"
  },
  {
    PROVIDER_CODE:"302",
    PROVIDER_ENAME:"ALLIANZ SAUDI FRANSI",
    PROVIDER_ANAME:"اليانز السعودي الفرنسي"
  }

]
export const WasselDepartments:Clinics[]=[
  {CLIN_CODE:"7",CLIN_ENAME:"ENT",CLIN_ANAME:"انف وأذن وحنجره"},
  {CLIN_CODE:"17",CLIN_ENAME:"Internal Medicine",CLIN_ANAME:"أمراض باطنية"},
  {CLIN_CODE:"23",CLIN_ENAME:"Obstetrics",CLIN_ANAME:"نساء و ولادة-متابعة حمل"},
  {CLIN_CODE:"13",CLIN_ENAME:"Gynecology",CLIN_ANAME:"نساء و ولادة-امراض نساء"},
  {CLIN_CODE:"34",CLIN_ENAME:"Plastic Surgery",CLIN_ANAME:"جراحة تجميل"},
  {CLIN_CODE:"35",CLIN_ENAME:"Rheumatology",CLIN_ANAME:"أمراض روماتيزمية"},
  {CLIN_CODE:"29",CLIN_ENAME:"Pediatric Surgery",CLIN_ANAME:"جراحة أطفال"},
  {CLIN_CODE:"6",CLIN_ENAME:"Endocrinology",CLIN_ANAME:"غدد صماء"},
  {CLIN_CODE:"19",CLIN_ENAME:"Nephrology",CLIN_ANAME:"أمراض الكلى"},
  {CLIN_CODE:"20",CLIN_ENAME:"Neurology",CLIN_ANAME:"أمراض عصبية"},
  {CLIN_CODE:"33",CLIN_ENAME:"Psychiatry",CLIN_ANAME:"الطب النفسي"},
  {CLIN_CODE:"2",CLIN_ENAME:"Cardiology",CLIN_ANAME:"أمراض قلب"},
  {CLIN_CODE:"5",CLIN_ENAME:"DermaVen",CLIN_ANAME:"جلدية و تناسلية"},
  {CLIN_CODE:"21",CLIN_ENAME:"Neuro-Surgery",CLIN_ANAME:"جراحة مخ وأعصاب"},
  {CLIN_CODE:"24",CLIN_ENAME:"Oncology",CLIN_ANAME:"أورام"},
  {CLIN_CODE:"28",CLIN_ENAME:"Paediatrics",CLIN_ANAME:"أطفال"},
  {CLIN_CODE:"10",CLIN_ENAME:"General Practice",CLIN_ANAME:"طبيب عام"},
  {CLIN_CODE:"30",CLIN_ENAME:"Pulmonology",CLIN_ANAME:"امراض صدريه"},
  {CLIN_CODE:"10",CLIN_ENAME:"EMERGENCY DOCTORS طبيب طوارئ",CLIN_ANAME:"طبيب طوارئ"},
  {CLIN_CODE:"25",CLIN_ENAME:"Opthalmic",CLIN_ANAME:"عيون"},
  {CLIN_CODE:"31",CLIN_ENAME:"Urology",CLIN_ANAME:"مسالك بولية"},
  {CLIN_CODE:"27",CLIN_ENAME:"Orthopedic",CLIN_ANAME:"عظام"},
  {CLIN_CODE:"11",CLIN_ENAME:"General Surgery",CLIN_ANAME:"جراحة عامة"},
  {CLIN_CODE:"4",CLIN_ENAME:"Dental",CLIN_ANAME:"أسنان"},
  {CLIN_CODE:"66",CLIN_ENAME:"Hearing Aids",CLIN_ANAME:"السمعيات"},
  {CLIN_CODE:"1",CLIN_ENAME:"Allergy/Imunology",CLIN_ANAME:"امراض الحساسية والمناعة"},
  {CLIN_CODE:"9",CLIN_ENAME:"Gastroenterology",CLIN_ANAME:"امراض الجهاز الهضمي"},
  {CLIN_CODE:"14",CLIN_ENAME:"Hematology",CLIN_ANAME:"امراض الدم"}
]

export const DHSDepartments:Clinics[]=[
  {CLIN_CODE:"10",CLIN_ENAME:"Internal Medicine",CLIN_ANAME:"الباطنيه"},
  {CLIN_CODE:"42",CLIN_ENAME:"Laboratory",CLIN_ANAME:"المختبر"},
  {CLIN_CODE:"120",CLIN_ENAME:"Radiology",CLIN_ANAME:"الاشعه العاديه"},
  {CLIN_CODE:"234",CLIN_ENAME:"ER Department",CLIN_ANAME:"قسم الطوارئ"},
  {CLIN_CODE:"11",CLIN_ENAME:"Cardiology",CLIN_ANAME:"القلب"},
  {CLIN_CODE:"6",CLIN_ENAME:"General Dentist",CLIN_ANAME:"الأسنان"},
  {CLIN_CODE:"20",CLIN_ENAME:"Dermatology",CLIN_ANAME:"جلدية و تناسلية"},
  {CLIN_CODE:"218",CLIN_ENAME:"E.N.T",CLIN_ANAME:"انف واذن وحنجره"},
  {CLIN_CODE:"50",CLIN_ENAME:"General Surgery",CLIN_ANAME:"جراحه عامه"},
  {CLIN_CODE:"19",CLIN_ENAME:"Neurology",CLIN_ANAME:"جراحة المخ والأعصاب"},
  {CLIN_CODE:"1",CLIN_ENAME:"Obestetric and Gynoecia",CLIN_ANAME:"نساء و ولادة"},
  {CLIN_CODE:"58",CLIN_ENAME:"Ophthalmology",CLIN_ANAME:"العيون"},
  {CLIN_CODE:"51",CLIN_ENAME:"Orthopedic surgery",CLIN_ANAME:"العظام"},
  {CLIN_CODE:"233",CLIN_ENAME:"Pediatrics",CLIN_ANAME:"الأطفال"},
  {CLIN_CODE:"52",CLIN_ENAME:"Urology",CLIN_ANAME:"المسالك البوليه"},
  {CLIN_CODE:"19",CLIN_ENAME:"Neurology",CLIN_ANAME:"الاعصاب"},
]
