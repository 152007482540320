import { Component, OnInit } from '@angular/core';

/**
 * Component for the notification sidebar of the application.
 * 
 * @example
 * <app-notification-sidebar></app-notification-sidebar>
 */
@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit {

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Loads the notification-sidebar JavaScript file.
   * 
   * @returns {void}
   */
  ngOnInit(): void {
    // notification-sidebar JS File
    $.getScript('./assets/js/notification-sidebar.js');
  }

}
