// Importing necessary modules and components from Angular
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-readiology',
  templateUrl: './public-readiology.component.html',
  styleUrls: ['./public-readiology.component.scss'],
})
export class PublicReadiologyComponent implements OnInit {
  lang: string;
  dir: string;
  sidebarClose: Boolean = false;
  constructor() {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() {}
  countChangedHandler(count) {
    this.sidebarClose = count;
  }
}
