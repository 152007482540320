/**
 * Contains the route paths for different sections of the application.
 * @constant
 * @type {object}
 */
export const routes = {
  ADMIN: {
    /**
     * Path to the admin dashboard.
     * @type {string}
     */
    DASHBOARD: '/admin/dashboard',
    
    /**
     * Path to user management in the admin section.
     * @type {string}
     */
    USER_MANAGEMENT: '/admin/user-management',
    
    /**
     * Path to gallery management in the admin section.
     * @type {string}
     */
    GALLERY_MANAGEMENT: '/admin/gallery-management',
    
    /**
     * Path to Enaya store management in the admin section.
     * @type {string}
     */
    ENAYA_STORE_MANAGEMENT: '/admin/enaya-store-management',
    
    /**
     * Path to restaurant management in the admin section.
     * @type {string}
     */
    RESTAURANT_MANAGEMENT: '/admin/restaurant-management',
    
    /**
     * Path to add new gallery management item in the admin section.
     * @type {string}
     */
    GALLERY_MANAGEMENT_ADD: '/admin/gallery-management/add',
    
    /**
     * Path to view all gallery management items in the admin section.
     * @type {string}
     */
    GALLERY_MANAGEMENT_ALL: '/admin/gallery-management/all',
    
    /**
     * Path to add a new user in the admin section.
     * @type {string}
     */
    USER_MANAGEMENT_ADD: '/admin/user-management/add',
    
    /**
     * Path to view all users in the admin section.
     * @type {string}
     */
    USER_MANAGEMENT_ALL: '/admin/user-management/all',
    
    /**
     * Path to change password in the user management section.
     * @type {string}
     */
    CHANGE_PASSWORD: '/admin/user-management/change-password',
    
    /**
     * Path to edit user information in the user management section.
     * @type {string}
     */
    EDIT_INFORMATION: '/admin/user-management/user-profile',
    
    /**
     * Path to view gift orders in the admin section.
     * @type {string}
     */
    GIFT_ORDERS: '/admin/gift-store-management/orders',
    
    /**
     * Path to set up Enaya products in the admin section.
     * @type {string}
     */
    ENAYA_PRODUCTS_SETUP: '/admin/enaya-store-management/products-setup',
    
    /**
     * Path to view restaurant orders in the admin section.
     * @type {string}
     */
    RESTAURANT_ORDERS: '/admin/restaurant-management/orders',
    
    /**
     * Path to set up restaurant products in the admin section.
     * @type {string}
     */
    RESTAURANT_PRODUCTS_SETUP: '/admin/restaurant-management/products-setup',
    
    /**
     * Path to set up restaurant tags in the admin section.
     * @type {string}
     */
    RESTAURANT_TAGS_SETUP: '/admin/restaurant-management/tags-setup',
    
    /**
     * Path to set up gift tags in the admin section.
     * @type {string}
     */
    GIFT_TAGS_SETUP: '/admin/enaya-store-management/tags-setup',
    
    /**
     * Path to view list of gift tags in the admin section.
     * @type {string}
     */
    GIFT_TAGS_LIST: '/admin/enaya-store-management/tags-list',
    
    /**
     * Path to view list of restaurant tags in the admin section.
     * @type {string}
     */
    RESTAURANT_TAGS_LIST: '/admin/restaurant-management/tags-list',
    
    /**
     * Path to view patient requests in the admin section.
     * @type {string}
     */
    PATIENTS_REQUESTS: '/admin/patients-requests',
    
    /**
     * Path to view advanced reports in the admin section.
     * @type {string}
     */
    ADVANCED_REPORTS: '/admin/advanced-reports',
    
    /**
     * Path to view list of services in the admin section.
     * @type {string}
     */
    SERVICES_LIST: '/admin/services-list',
    
    /**
     * Path to manage doctors in the admin section.
     * @type {string}
     */
    DOCTORS_MANAGEMENT: '/admin/doctors-management',
    
    /**
     * Path to set up a doctor in the admin section.
     * @type {string}
     */
    DOCTOR_SETUP: '/admin/doctors-management/doctor-setup',
  },
};