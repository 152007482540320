import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent, ColumnMode } from "@swimlane/ngx-datatable";
import { ServiceTypeEnum } from "../../core/enums/service-type.enum";

/**
 * Component for displaying open requests in a datatable.
 * 
 * @example
 * <app-open-requests [rows]="requestData"></app-open-requests>
 */
@Component({
  selector: 'app-open-requests',
  templateUrl: './open-requests.component.html',
  styleUrls: ['./open-requests.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class OpenRequestsComponent implements OnInit {
  /**
   * Reference to the DatatableComponent instance.
   * 
   * @type {DatatableComponent}
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * Enum for column mode settings in the datatable.
   * 
   * @type {ColumnMode}
   */
  ColumnMode = ColumnMode;

  /**
   * Array of rows to be displayed in the datatable.
   * 
   * @type {any[]}
   */
  @Input() rows: any[];

  /**
   * Enum for service types.
   * 
   * @type {ServiceTypeEnum}
   */
  ServiceType = ServiceTypeEnum;

  /**
   * Constructs the OpenRequestsComponent.
   */
  constructor() { }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * 
   * @returns {void}
   */
  ngOnInit(): void { }

}
