// Import necessary modules and dependencies
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from './api.service';
import { observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { AuthService } from '../../../pages/content-pages/login/auth.service';
import { decode, encode } from '../../../utils';
import { TrackingService } from 'app/core/services/tracking.service';

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.scss'],
})
export class LabsComponent implements OnInit {
  // recap = environment.recaptchaSite;

  patInfoForm: FormGroup;
  // Regular expression pattern for validating mobile numbers
  mobilePattern =
    /^(05|\u0660\u0665)(5|0|3|6|4|9|1|8|7|\u0665|\u0660|\u0663|\u0666|\u0664|\u0669|\u0661|\u0668|\u0667)([0-9\u0660-\u0669]{7})$/;
  isError = 0;
  showForm = 1;
  showData = 0;
  labsData: any[] = [];
  patCode: string;
  idCard: string;
  noResult: number;
  lang: string;
  dir: string;
  userId: any;
  private user: any;
  constructor(
    private data: ApiService,
    private spinner: SpinnerService,
    private authService: AuthService,
    private _trackingService: TrackingService
  ) {
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ar';
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';

    // Initialize the patient information form with form controls and validators
    this.patInfoForm = new FormGroup(
      {
        profileNo: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        mobileNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(1),
          // Validators.pattern(this.mobilePattern),
        ]),
        // recaptcha: new FormControl(null, [Validators.required]),
      },
      { updateOn: 'blur' }
    );
  }
  resolved(captchaResponse: string) {
    if (captchaResponse != null && captchaResponse != undefined) {
    }
  }
  ngOnInit() {
    this.user = decode('currentUser');

    // Subscribe to changes in the authentication service's user observable
    this.authService.user.subscribe((val) => {
      if (val) {
        this.user = val;
      }
    });
  
    // If user is authenticated, fetch labs data and set up the form with user information
    if (this.user) {
      this.getLabsDataWithUserLogin();
      this.patchValue(this.user.udhId, this.user.mobile);
      this.spinner.show();
      this.addTrackingLog();
    }
    // if (localStorage.getItem('currentUser')) {
    //   this.userId = decode('currentUser')
    //  } else{
    //   this.userId = null
    //  }
  }

  // Log tracking information for lab results
  addTrackingLog() {
    this._trackingService
      .addTrackingLog(
        this._trackingService.tracking_type.LAB_RESULT,
        this.user.id ? this.user.id : ''
      )
      .subscribe((data) => {
        return data;
      });
  }

  // Fetch labs data using the patient information from the form
  getLabsData() {
    if (this.patInfoForm.valid) {
      this.patCode = this.patInfoForm.get('profileNo').value;
      this.idCard = this.patInfoForm.get('mobileNumber').value;
      encode('patInfo', this.patInfoForm.value);
      this.data.getLabsPublic(this.patCode, this.idCard).subscribe(
        (r) => {
          // this.data.getLabs('263574', '0554952250').subscribe(r => {
          if (r['status'] == 'success') {
            this.labsData = [...r['data']];
            this.labsData.sort((a: any, b: any): number => {
              let firstDate: any = new Date(b.DENOTED_DATE);
              let secondDate: any = new Date(a.DENOTED_DATE);
              let result = firstDate - secondDate;
              return result;
            });
            this.showData = 1;
            this.showForm = 0;
            this.isError = 0;
            this.noResult = 0;
          } else {
            this.showForm = 1;
            this.noResult = 1;
            this.isError = 0;
            this.showData = 0;
          }
        },
        (error) => {
          this.isError = 1;
          this.showForm = 1;
          this.showData = 0;
          this.noResult = 0;
        }
      );
    }
    this.addTrackingLog();
  }

  // Fetch labs data for authenticated users
  getLabsDataWithUserLogin() {
    this.data.getLabs().subscribe(
      (r) => {
        // this.data.getLabs('263574', '0554952250').subscribe(r => {
        if (r['status'] == 'success') {
          this.labsData = [...r['data']];
          this.labsData.sort((a: any, b: any): number => {
            let firstDate: any = new Date(b.DENOTED_DATE);
            let secondDate: any = new Date(a.DENOTED_DATE);
            let result = firstDate - secondDate;
            return result;
          });
          this.showData = 1;
          this.showForm = 0;
          this.isError = 0;
          this.noResult = 0;
        } else {
          this.showForm = 0;
          this.noResult = 0;
          this.isError = 0;
          this.showData = 1;
        }

        this.spinner.hide();
      },
      (error) => {
        this.isError = 1;
        this.showForm = 1;
        this.showData = 0;
        this.noResult = 0;
        this.spinner.hide();
      }
    );
    // this.addTrackingLog()
  }

  // Set initial form values with user information
  private patchValue(udhId, mobile) {
    this.patInfoForm.patchValue({
      profileNo: udhId,
      mobileNumber: mobile,
    });
  }
}
