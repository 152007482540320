// Importing necessary modules and components from Angular and third-party libraries
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Creating a custom Angular Pipe called 'FilterPipe'.
 * @pipe
 */
@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Transform method to filter an array of items based on a specified key and filter data.
   * @param {any[]} items - The array of items to be filtered.
   * @param {string} key - The key in the item object to filter by.
   * @param {string} filterdata - The filter data to be matched.
   * @returns {any[]} The filtered array of items.
   */
  transform(items: any[], key: string, filterdata: string): any[] {
    if (!items) return [];

    // If no filter data is provided, return the original array 'items'.
    if (!filterdata) return items;
    filterdata = filterdata.toString().toLowerCase();

    // If no specific key is provided for filtering, default to filtering by 'name' property.
    if (!key) {
      return items.filter(it => {
        return it.name ? it.name.toLowerCase().includes(filterdata) : false;
      });
    }

    // If a specific key is provided, filter items based on that key.
    return items.filter(it => {
      return it[key] ? it[key].toLowerCase().includes(filterdata) : false;
    });
  }
}
