// Importing necessary modules and components from Angular and third-party libraries
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * If the application is running in production mode, enable production mode.
 */
if (environment.production) {
  enableProdMode();
}

/**
 * Placeholder for the API key used for Google Maps.
 * @constant {string}
 */
const apiKeyPlaceholder = 'API_KEY_PLACEHOLDER';

/**
 * Actual API key for Google Maps, retrieved from environment settings.
 * @constant {string}
 */
const apiKey = environment.googleMaps.apiKey;

// The following block is commented out, but it's intended to find and replace 
// an existing script's content if it includes the placeholder
// const scripts: any = document.querySelector(
//   'script[src*="API_KEY_PLACEHOLDER"]'
// ) as HTMLScriptElement;
// for (const script of scripts) {
//   if (script.textContent.includes(apiKeyPlaceholder)) {
//     script.textContent = script.src.replace(
//       apiKeyPlaceholder,
//       `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en`
//     );
//     console.log(script);
//   }
// }

/**
 * Dynamically create and append a script element to load the Google Maps API.
 * The API key is injected into the URL.
 */
let script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en`;
document.getElementsByTagName('body')[0].appendChild(script);

/**
 * Adds a script element to the document head with the specified source URL.
 * @param {string} link - The URL of the script to add.
 */
function addScript(link) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = link;
  document.head.append(script);
}

/**
 * Adds a link element to the document head with the specified stylesheet URL.
 * @param {string} css - The URL of the stylesheet to add.
 */
function addLink(css) {
  const link = document.createElement('link');
  link.href = css;
  link.rel = 'stylesheet';
  document.head.append(link);
}

// Adding external script and stylesheet links.
addScript('https://code.jquery.com/jquery-3.4.1.min.js');
addScript('https://cdn.jsdelivr.net/npm/summernote@0.8.15/dist/summernote-lite.min.js');
addLink('https://cdn.jsdelivr.net/npm/summernote@0.8.15/dist/summernote-lite.min.css');

/**
 * Bootstrap the Angular module to launch the application.
 */
platformBrowserDynamic().bootstrapModule(AppModule);
