import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './all-user/user.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditInformationComponent } from './edit-information/edit-information.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

/**
 * Defines the routes for the User module.
 * @constant {Routes}
 */
const routes: Routes = [
  {
    path: 'all',
    component: UserComponent
  },
  {
    path: 'add',
    component: AddUserComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'edit-information',
    component: EditInformationComponent
  },
  {
    path: 'user-profile',
    component: UserProfileComponent
  },
];

/**
 * NgModule that provides routing configuration for the User module.
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
