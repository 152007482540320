// Importing necessary modules and components from Angular
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-lab-details',
  templateUrl: './public-lab-details.component.html',
  styleUrls: ['./public-lab-details.component.scss'],
})
export class PublicLabDetailsComponent implements OnInit {
  // Declare class properties
  lang: string;
  dir: string;
  sidebarClose: Boolean = false;
  constructor() {

    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit() { }

  countChangedHandler(count) {
    this.sidebarClose = count;
  }
}
