import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { decode } from 'app/utils';
import Swal from 'sweetalert2';

/**
 * Component to handle canceled payment transactions.
 * @component
 */
@Component({
  selector: 'app-canceled',
  templateUrl: './canceled.component.html',
  styleUrls: ['./canceled.component.scss'],
})
export class CanceledComponent implements OnInit {
  /**
   * Transaction count.
   * @type {any}
   */
  transCount: any;

  /**
   * Invoice data.
   * @type {any}
   */
  invoiceData: any;

  /**
   * Parameters from the route.
   * @type {any}
   */
  params: any;

  /**
   * User ID.
   * @type {any}
   */
  userId: any;

  /**
   * Constructor to inject necessary services and initialize the component.
   * @param {ActivatedRoute} route - The activated route service to access route parameters.
   * @param {Router} router - The router service for navigation.
   * @param {TranslateService} translate - The translation service.
   * @param {ApiHandlerService} apiHandler - The API handler service.
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private apiHandler: ApiHandlerService
  ) {
    // Subscribe to query parameters to check for numberValue
    this.route.queryParams.subscribe(
      (params) => {
        if (params) {
          // Extract numberValue from query parameters
          const params = this.route.snapshot.paramMap;
          let numberValue: number | null = null;
          params.keys.forEach((key) => {
            const value = +params.get(key);
            if (!isNaN(value)) {
              numberValue = value;
              return;
            }
          });
          // If a valid numberValue is found, make API call
          if (numberValue !== null) {
            this.apiHandler
              .telrPaymentCallback(numberValue)
              .subscribe((data: any) => {
                if (data) {
                  this.transCount = data.transCount;
                  this.getInvoiceDetails();
                }
              });
          } else {
            this.router.navigate(['e-services']);
          }
        }
      },
      (error) => {
        console.log('Error=========>', error);
        // Navigate to 'e-services' in case of an error
        this.router.navigate(['e-services']);
      }
    );
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Sets the user ID from localStorage.
   * @returns {void}
   */
  ngOnInit(): void {
    if (localStorage.getItem('currentUser')) {
      this.userId = decode('currentUser');
    } else {
      this.userId = null;
    }
  }

  /**
   * Retrieves invoice details based on the transaction count.
   * @returns {void}
   */
  getInvoiceDetails(): void {
    this.apiHandler.getInvoiceByTrsId({ trsCount: this.transCount }).subscribe(
      (invoice) => {
        this.invoiceData = invoice;
        const idNumber: string = this.invoiceData.customer_id;
        this.invoiceData.customer_id = idNumber.replace(
          idNumber.substring(1, 7),
          '******'
        );
      },
      (err) => {
        Swal({
          title: `${this.translate.instant('Invalid Invoice')}`,
          text: `${this.translate.instant(err.error.message)}`,
          type: 'error',
          confirmButtonColor: '#2740b6',
        });
      }
    );
  }

  /**
   * Navigates to the home page.
   * @returns {void}
   */
  goToHome(): void {
    this.router.navigate(['/e-services']);
  }

  /**
   * Navigates to the payment details page.
   * @returns {void}
   */
  goToDetails(): void {
    this.router.navigate([`payments/checkout/${this.transCount}`]);
  }

  /**
   * Navigates to the place order page.
   * @returns {void}
   */
  placeOrder(): void {
    this.router.navigate([`payments/invoices/${this.transCount}`]);
  }
}
