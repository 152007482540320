import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalService } from 'app/local.service';

/**
 * @description Service for handling authentication tasks such as signup, signin, and logout.
 */
@Injectable()
export class AuthService {
  /**
   * Token for the authenticated user.
   * @type {string}
   */
  token: string;

  /**
   * @constructor
   * @param {LocalService} localService - The service to interact with local storage.
   */
  constructor(private localService: LocalService) {}

  /**
   * Registers a new user with the given email and password.
   * @param {string} email - The email address of the user.
   * @param {string} password - The password for the user account.
   */
  signupUser(email: string, password: string): void {
    // your code for signing up the new user
  }

  /**
   * Authenticates a user with the given email and password.
   * @param {string} email - The email address of the user.
   * @param {string} password - The password for the user account.
   */
  signinUser(email: string, password: string): void {
    // your code for checking credentials and getting tokens for signing in user
  }

  /**
   * Logs out the current user by clearing the token.
   */
  logout(): void {
    this.token = null;
  }

  /**
   * Retrieves the current authentication token.
   * @returns {string} - The current authentication token.
   */
  getToken(): string {
    return this.token;
  }

  /**
   * Checks if the user is authenticated by verifying tokens in local storage.
   * @returns {boolean} - Returns true if the user is authenticated; otherwise, false.
   */
  isAuthenticated(): boolean {
    return !!(this.localService.getJsonValue('srd') || this.localService.getJsonValue('srp'));
  }
}
