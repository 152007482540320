// Line Chart

/**
 * Data for line chart.
 * @type {Array<{data: Array<number>, label: string}>}
 */
export let lineChartData: Array<{data: Array<number>, label: string}> = [
  { data: [], label: '' },
  { data: [], label: '' },
  { data: [], label: '' }
];

/**
 * Labels for line chart.
 * @type {Array<any>}
 */
export let lineChartLabels: Array<any> = [];

/**
 * Options for line chart.
 * @type {Object}
 */
export let lineChartOptions: any = {
  animation: {
    duration: 1000, // general animation time
    easing: 'easeOutBack'
  },
  hover: {
    animationDuration: 1000, // duration of animations when hovering an item
    mode: 'label'
  },
  responsiveAnimationDuration: 1000, // animation duration after a resize
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom'
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            day: 'YYYY-MMM D', // This is the default
          },
        },
      },
    ],
    yAxes: [{
      display: true,
      ticks: {
        suggestedMin: 60,
      },
      gridLines: {
        color: '#f3f3f3',
        drawTicks: false
      },
      scaleLabel: {
        display: true,
        labelString: 'Value'
      }
    }]
  },
  title: {
    display: false,
    text: 'Chart.js Line Chart - Legend'
  }
};

/**
 * Colors for line chart.
 * @type {Array<any>}
 */
export let lineChartColors: Array<any> = [
  {
    fill: false,
    borderColor: '#74d77a',
    pointRadius: 0
  },
  {
    fill: false,
    borderColor: '#36adee',
    pointBorderColor: '#36adee',
    pointBackgroundColor: '#FFF',
    pointBorderWidth: 2,
    pointHoverBorderWidth: 2,
    pointRadius: 4
  },
  {
    fill: false,
    borderColor: '#d8b871',
    pointRadius: 0
  }
];

/**
 * Show legend on line chart.
 * @type {boolean}
 */
export let lineChartLegend = false;

/**
 * Type of chart for line chart.
 * @type {string}
 */
export let lineChartType = 'line';

// Area Chart

/**
 * Data for area chart.
 * @type {Array<{data: Array<number>, label: string}>}
 */
export let areaChartData: Array<{data: Array<number>, label: string}> = [
  { data: [0, 150, 140, 105, 210, 140, 270], label: 'Series A' },
  { data: [0, 90, 120, 240, 140, 250, 190], label: 'Series B' }
];

/**
 * Labels for area chart.
 * @type {Array<any>}
 */
export let areaChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

/**
 * Options for area chart.
 * @type {Object}
 */
export let areaChartOptions: any = {
  animation: {
    duration: 1000, // general animation time
    easing: 'easeOutBack'
  },
  hover: {
    animationDuration: 1000 // duration of animations when hovering an item
  },
  responsiveAnimationDuration: 1000, // animation duration after a resize
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      display: true,
      gridLines: {
        color: '#f3f3f3',
        drawTicks: false
      },
      scaleLabel: {
        display: true,
        labelString: 'Month'
      }
    }],
    yAxes: [{
      display: true,
      gridLines: {
        color: '#f3f3f3',
        drawTicks: false
      },
      scaleLabel: {
        display: true,
        labelString: 'Value'
      }
    }]
  }
};

/**
 * Colors for area chart.
 * @type {Array<any>}
 */
export let areaChartColors: Array<any> = [
  {
    backgroundColor: 'rgba(255, 95, 32, 0.4)',
    borderColor: 'transparent',
    pointBackgroundColor: '#FFF',
    pointBorderColor: 'rgba(255, 95, 32,1)',
    pointHoverBackgroundColor: 'rgba(255, 95, 32,1)',
    pointRadius: '5',
    pointHoverBorderColor: '#FFF',
    pointHoverRadius: '5',
    pointBorderWidth: '2'
  },
  {
    backgroundColor: 'rgba(102, 110, 232, 0.7)',
    borderColor: 'transparent',
    pointBackgroundColor: '#FFF',
    pointBorderColor: 'rgba(102, 110, 232, 1)',
    pointHoverBackgroundColor: 'rgba(102, 110, 232, 1)',
    pointRadius: '5',
    pointHoverBorderColor: '#FFF',
    pointHoverRadius: '5',
    pointBorderWidth: '2'
  }
];

/**
 * Show legend on area chart.
 * @type {boolean}
 */
export let areaChartLegend = true;

/**
 * Type of chart for area chart.
 * @type {string}
 */
export let areaChartType = 'line';

// Bar Chart

/**
 * Options for bar chart.
 * @type {Object}
 */
export let barChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Number of Reads",
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax : 500,
          stepValue: 500
        }
      },
    ],
  }
};

/**
 * Labels for bar chart.
 * @type {Array<string>}
 */
export let barChartLabels: string[] = ['Paid', 'UnPaid', 'Pending'];

/**
 * Type of chart for bar chart.
 * @type {string}
 */
export let barChartType = 'bar';

/**
 * Show legend on bar chart.
 * @type {boolean}
 */
export let barChartLegend = true;

/**
 * Data for bar chart.
 * @type {Array<any>}
 */
export let barChartData: any[] = [
  { data: [] },
];

/**
 * Colors for bar chart.
 * @type {Array<any>}
 */
export let barChartColors: Array<any> = [
  {
    backgroundColor: ['#62D2FF', '#4639A2', '#FF5050'],
    borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  },
  {
    backgroundColor: '#4639A2',
    borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  },
  {
    backgroundColor: '#FF5050',
    borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }
];

// Doughnut Chart

/**
 * Labels for doughnut chart.
 * @type {Array<string>}
 */
export let doughnutChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];

/**
 * Data for doughnut chart.
 * @type {Array<number>}
 */
export let doughnutChartData: number[] = [350, 450, 100];

/**
 * Colors for doughnut chart.
 * @type {Array<any>}
 */
export let doughnutChartColors: any[] = [{ backgroundColor: ['rgba(102, 110, 232, 0.8)', 'rgba(30, 159, 242, 0.8)', 'rgba(255, 95, 32, 0.8)'] }];

/**
 * Type of chart for doughnut chart.
 * @type {string}
 */
export let doughnutChartType = 'doughnut';

/**
 * Options for doughnut chart.
 * @type {Object}
 */
export let doughnutChartOptions: any = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false
};

// Radar Chart

/**
 * Labels for radar chart.
 * @type {Array<string>}
 */
export let radarChartLabels: string[] = ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'];

/**
 * Data for radar chart.
 * @type {Array<{data: Array<number>, label: string}>}
 */
export let radarChartData: any = [
  { data: [65, 59, 90, 81, 56, 55, 40], label: 'Series A' },
  { data: [28, 48, 40, 19, 96, 27, 100], label: 'Series B' }
];

/**
 * Type of chart for radar chart.
 * @type {string}
 */
export let radarChartType = 'radar';

/**
 * Colors for radar chart.
 * @type {Array<any>}
 */
export let radarChartColors: any[] = [
  {
    backgroundColor: ['rgba(255, 95, 32, 0.8)']
  },
  {
    backgroundColor: ['rgba(102, 110, 232, 0.8)']
  }
];

/**
 * Options for radar chart.
 * @type {Object}
 */
export let radarChartOptions: any = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false
};

// Pie Chart

/**
 * Labels for pie chart.
 * @type {Array<string>}
 */
export let pieChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail Sales'];

/**
 * Data for pie chart.
 * @type {Array<number>}
 */
export let pieChartData: number[] = [300, 500, 100];

/**
 * Type of chart for pie chart.
 * @type {string}
 */
export let pieChartType = 'pie';

/**
 * Colors for pie chart.
 * @type {Array<any>}
 */
export let pieChartColors: any[] = [{ backgroundColor: ['rgba(102, 110, 232, 0.8)', 'rgba(30, 159, 242, 0.8)', 'rgba(255, 95, 32, 0.8)'] }];

/**
 * Options for pie chart.
 * @type {Object}
 */
export let pieChartOptions: any = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false
};

// Polar Area Chart

/**
 * Labels for polar area chart.
 * @type {Array<string>}
 */
export let polarAreaChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail Sales', 'Telesales', 'Corporate Sales'];

/**
 * Data for polar area chart.
 * @type {Array<number>}
 */
export let polarAreaChartData: number[] = [300, 500, 100, 40, 120];

/**
 * Show legend on polar area chart.
 * @type {boolean}
 */
export let polarAreaLegend = true;

/**
 * Colors for polar area chart.
 * @type {Array<any>}
 */
export let ploarChartColors: any[] = [{ backgroundColor: ['rgba(102, 110, 232, 0.8)', 'rgba(30, 159, 242, 0.8)', 'rgba(255, 95, 32, 0.8)', 'rgba(40, 208, 148, 0.8)', 'rgba(205, 73, 97, 0.8)'] }];

/**
 * Type of chart for polar area chart.
 * @type {string}
 */
export let polarAreaChartType = 'polarArea';

/**
 * Options for polar area chart.
 * @type {Object}
 */
export let polarChartOptions: any = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false
};
