import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'app/home/base.component';
import { ApiService } from '../reservations/api.service';
import { getDay, startOfToday, addDays, subDays, isSameDay } from 'date-fns';
import * as moment from 'moment-timezone';
import { timer } from 'rxjs';
import * as _ from 'underscore';
@Component({
  selector: 'app-appointments-new',
  templateUrl: './appointments-new.component.html',
  styleUrls: ['./appointments-new.component.scss']
})
export class AppointmentsNewComponent extends BaseComponent implements OnInit {
  selectedClinic;
  selectedDate = new Date();
  today = new Date();
  days: Date[];
  clinics: any[] = [];
  doctors: any[] = [];
  currentRating = 4;
  doctorsArchive: IDoctorInfo[];
  originDoctorsData: IDoctorInfo[];
  page = 1;
  times: string[] = [];
  morningTimes: any[] = [];
  nightTimes: any[] = [];
  mdate: any;
  sameDate: number;
  activeTab: string;
  doctorsTimes: IDoctorTimes[] = [];
  timesReady = false;
  doctorAvaliableTime: any[];
  constructor(
    public translate: TranslateService,
    private dataService: ApiService
  ) {
    super();

    if (!this.days || !this.days.length) {
      this.days = new Array<Date>();
      for (let index = 0; index <= 4; index++) {
        this.days[index] = addDays(this.today, index);
      }
    }
  }

  // represent the first 5 days of the week starting from the last day
  nextWeek() {
    const lastDay = this.days[4];
    for (let index = 0; index <= 4; index++) {
      this.days[index] = addDays(lastDay, index + 1);
    }
  }

  // Updates the days to represent the previous week  
  pastWeek() {
    const firstDay = this.days[0];
    if (isSameDay(firstDay, this.today)) {
      return;
    } else {
      for (let index = 0; index <= 4; index++) {
        this.days[index] = subDays(firstDay, index + 1);
      }
      this.days.reverse();
    }
  }

  ngOnInit() {
    this.getArchivedData();
    this.getClinics();
  }

  // Call the data service to get doctor archive data based on the language
  getArchivedData() {
    this.dataService
      .getDoctorArchive(this.isAr ? 'ar' : 'en')
      .subscribe((doctorsList: any[]) => {
        this.originDoctorsData = doctorsList.map(m => ({
          wpId: m.data.id,
          code: m.data.Doctor_Code,
          clinicCode: m.data.Doctor_Code.toString().substring(0, 2),
          name: m.data.Doctor_Name,
          bio: m.data.cert,
          level: m.data.Level_sp,
          specialties: m.data.specialties,
          image: m.data.PhotoURL,
          profile: m.data.ProfileLink
        }));
        // this.doctorsArchive = this.originDoctorsData.filter(
        //   f => f.clinicCode === 'OB'
        // );
      });
  }

  // Call the data service to get clinic data
  getClinics() {
    this.clinics = [];
    this.dataService.getClinics().subscribe(_clinics => {
      if (_clinics && Number(_clinics['Count']) > 0) {
        // this.clinics = _clinics['Clinics'];
        for (let i = 0; i < Number(_clinics['Count']); i++) {
          const element = _clinics['Clinics'][i];
          this.clinics.push({
            CLIN_CODE: element['CODE'].trim(),
            CLIN_ANAME: element['A_NAME'].trim(),
            CLIN_ENAME: element['E_NAME'].trim()
          });
        }
      }
    });
  }

  // Call the data service to get doctor data for the specified clinic code
  getDoctors(clinicCode: string) {
    this.doctors = [];
    this.doctorsArchive = [];
    this.dataService.getDoctors(clinicCode).subscribe(r => {
      for (let i = 0; i < Number(r['Count']); i++) {
        const element = r['Doctor'][i];
        this.doctors.push({
          DOC_CODE: element['DOC_CODE'].trim(),
          DOC_ANAME: element['CLINC_ANAME'].trim(),
          DOC_ENAME: element['CLINC_ENAME'].trim(),
          WEB_SER: element['WEB_SER']
        });
      }
    });
    this.doctors.sort((a, b) =>
      a.WEB_SER > b.WEB_SER ? 1 : b.WEB_SER > a.WEB_SER ? -1 : 0
    );
    this.doctorsArchive = [];
    this.doctorsArchive = this.originDoctorsData.filter(
      f => f.clinicCode === clinicCode
    );
    this.getTimes();
  }

  // Fetches and organizes appointment times for doctors on specified days
  async getTimes() {
    this.times = [];
    this.morningTimes = [];
    this.nightTimes = [];
    this.doctorsTimes = [];
    try {
      if (this.days && this.days.length > 0) {
        for await (const doctor of this.doctorsArchive) {
          for await (const day of this.days) {
            this.mdate = moment(day);
            await this.getDoctorsTimes(day, doctor.code);
          }
        }
        this.doctorsTimes = _.sortBy(this.doctorsTimes, 'doctorId');
        // this.doctorsTimes = _.groupBy(this.doctorsTimes, 'doctorId');
        this.timesReady = true;
      }
    } catch (error) {}
  }

  // Call the data service to get appointment times for a specific doctor on a given day
  async getDoctorsTimes(day, doctor) {
    this.dataService
      .getTimesOld(this.mdate.format('YYYY-MM-DD').toString(), doctor)
      // .getAppointments(this.mdate.format('YYYY-MM-DD').toString(), doctor)
      // .subscribe(async timesR => {
      //   if (Number(timesR['Count']) > 0) {
      //     this.times = [...timesR['Times']];
      //     for (let index = 0; index < this.times.length; index++) {
      //       this.times[index] = this.times[index].replace(/:00 /, ' ');
      //     }

      //     this.doctorsTimes.push({
      //       doctorId: doctor,
      //       date: day,
      //       times: this.times
      //     });
      //   } else {
      //     this.doctorsTimes.push({
      //       doctorId: doctor,
      //       date: day,
      //       times: null
      //     });
      //   }
      // });
      .toPromise()
      .then(async timesR => {
        if (Number(timesR['Count']) > 0) {
          this.times = [...timesR['Times']];
          for (let index = 0; index < this.times.length; index++) {
            this.times[index] = this.times[index].replace(/:00 /, ' ');
          }

          this.doctorsTimes.push({
            doctorId: doctor,
            date: day,
            times: this.times
          });
        } else {
          this.doctorsTimes.push({
            doctorId: doctor,
            date: day,
            times: null
          });
        }
      });
  }
}

export interface IDoctorInfo {
  wpId: string;
  code: string;
  name: string;
  bio: string;
  level: string;
  specialties: string;
  image: string;
  profile: string;
  rate?: number;
  clinicCode?: string;
}

export interface IDoctorTimes {
  doctorId: string;
  date: Date;
  times: string[];
}
