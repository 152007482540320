// Importing necessary modules and components from Angular and third-party libraries
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Cacheable } from 'ngx-cacheable';
import { Subject, from, of } from 'rxjs';
import { environment } from 'environments/environment.prod';
import { decode } from 'app/utils';

const cacheBuster$ = new Subject<void>();

/**
 * Service for interacting with the API.
 * @service
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * Common HTTP options with authorization header using API key.
   * @type {Object}
   */
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  /**
   * Common HTTP options for POST requests with authorization header using API key.
   * @type {Object}
   */
  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
    }),
  };

  /**
   * Dummy response for testing purposes.
   * @type {Object}
   */
  dummyResponse = {
    Count: 35,
    Times: [
      '11:00 AM', '11:10 AM', '11:20 AM', '11:30 AM', '11:40 AM', '11:50 AM',
      '12:00 PM', '12:10 PM', '12:20 PM', '12:30 PM', '12:40 PM', '12:50 PM',
      '01:00 PM', '01:10 PM', '01:20 PM', '01:30 PM', '01:40 PM', '01:50 PM',
      '02:00 PM', '02:10 PM', '02:20 PM', '02:30 PM', '02:40 PM', '02:50 PM',
      '03:00 PM', '03:10 PM', '03:20 PM', '03:30 PM', '03:40 PM', '03:50 PM',
      '04:00 PM', '04:10 PM', '04:20 PM', '04:30 PM', '04:40 PM',
    ],
    ReservType: [
      2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
      1, 1, 2, 1, 1, 2, 2, 2, 2, 2,
    ],
  };

  constructor(private http: HttpClient) {}

  /**
   * Fetches clinics data from the API.
   * @returns {Observable<any>} Observable containing clinics data.
   */
  getClinics() {
    return this.http.get(`${environment.apiUrl}/specs/web`, this.httpOptions);
  }

  /**
   * Fetches doctors data based on clinic code.
   * @param {string} clinicCode - The clinic code.
   * @returns {Observable<any>} Observable containing doctors data.
   */
  getDoctors(clinicCode: string) {
    return this.http.get(
      `${environment.apiUrl}/doctors/web/spec/${clinicCode} `,
      this.httpOptions
    );
  }

  /**
   * Fetches doctor archive data based on language.
   * @param {string} lang - Language preference ('ar' or 'en').
   * @returns {Observable<any>} Observable containing doctor archive data.
   */
  getDoctorArchive(lang: string) {
    return lang === 'ar'
      ? this.http
          .get(`https://udh.businessexp.net/wp-json/doct-api/v1/doctors`)
          .pipe(shareReplay(1))
      : this.http
          .get(`https://udh.businessexp.net/en/wp-json/doct-api/v1/doctors`)
          .pipe(shareReplay(1));
  }

  /**
   * Fetches available appointment times for a doctor and date.
   * @param {string} rData - Date for appointments.
   * @param {string} doctorCode - Code of the doctor.
   * @returns {Observable<any>} Observable containing available appointment times.
   */
  getTimesOld(rData: string, doctorCode: string) {
    return this.http.get(
      `${environment.apiUrl}/reservations/times/${doctorCode}/${rData}`,
      this.httpOptions
    );
  }

  /**
   * Fetches available appointment times for a doctor and date using new API endpoint.
   * @param {string} rData - Date for appointments.
   * @param {string} doctorCode - Code of the doctor.
   * @returns {Observable<any>} Observable containing available appointment times.
   */
  getTimes(rData: string, doctorCode: string) {
    return this.http.get(
      `https://netapi.enaya.med.sa/udhapi/reservations/appointments/${rData}/${doctorCode}`,
      this.httpOptions
    );
  }

  /**
   * Creates a new booking.
   * @param {Object} resInfo - Information for the new booking.
   * @returns {Observable<any>} Observable containing the new booking response.
   */
  newBook(resInfo: Object) {
    return this.http.post(
      `${environment.apiUrl}/reservations/nBook`,
      { resInfo: resInfo },
      this.httpOptionsPost
    );
  }

  /**
   * Calls reservations test API.
   * @returns {Observable<any>} Observable containing the test response.
   */
  test() {
    return this.http.post(
      `${environment.apiUrl}/reservations/test`,
      {},
      this.httpOptionsPost
    );
  }

  /**
   * Retrieves active bookings based on transaction number and mobile number.
   * @param {string} transNo - Transaction number.
   * @param {string} mobileNumber - Mobile number associated with the booking.
   * @returns {Observable<any>} Observable containing the active bookings response.
   */
  getActiveBook(transNo: string, mobileNumber: string) {
    return this.http.get(
      `${environment.apiUrl}/reservations/appointments?transNo=${transNo}&mobileNumber=${mobileNumber}`,
      this.httpOptions
    );
  }

  /**
   * Retrieves all books based on specified filters.
   * @param {Object} filters - Filters for the books.
   * @returns {Observable<any>} Observable containing all books response.
   */
  getAllBooks(filters: Object) {
    return this.http.post(
      `${environment.apiUrl}/book/private/orders-all`,
      filters,
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  /**
   * Cancels a booked appointment.
   * @param {string} transNo - Transaction number of the appointment.
   * @param {string} mobileNumber - Mobile number associated with the appointment.
   * @returns {Observable<any>} Observable containing the cancel response.
   */
  cancelBook(transNo: string, mobileNumber: string) {
    return this.http.post(
      `${environment.apiUrl}/reservations/appointments/close`,
      { transNo: transNo, mobileNumber: mobileNumber },
      this.httpOptionsPost
    );
  }

  /**
   * Fetches a list of order statuses.
   * @returns {Observable<any>} Observable containing order statuses.
   */
  getOrderStatusList() {
    return this.http.get(`${environment.apiUrl}/book/order-status`, {
      headers: new HttpHeaders({
        Authorization: `ak ${environment.key}`,
        'Content-Type': 'application/json',
        'jwt-token': decode('token'),
      }),
    });
  }

  /**
   * Retrieves service categories.
   * @returns {Observable<any>} Observable containing service categories.
   */
  getServiceCategories() {
    return this.http.post(
      `${environment.apiUrl}/enaya/public-enaya-service`,
      {},
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  /**
   * Retrieves order details based on order number.
   * @param {string} orderNumber - Order number.
   * @returns {Observable<any>} Observable containing order details.
   */
  getOrderDetails(orderNumber: string) {
    return this.http.post(
      `${environment.apiUrl}/book/private/orders`,
      { orderNumber },
      {
        headers: new HttpHeaders({
          Authorization: `ak ${environment.key}`,
          'Content-Type': 'application/json',
          'jwt-token': decode('token'),
        }),
      }
    );
  }

  /**
   * Fetches public order details.
   * @param {Object} body - Request body containing order details.
   * @returns {Observable<any>} Observable containing public order details.
   */
  getPublicOrderDetails(body: Object) {
    return this.http.post(`${environment.apiUrl}/book/orders`, body, {});
  }
}
